/**
 * Customer entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import { CustomerModel, CustomerResponseModel, CustomerTextFields } from '@/interfaces/models/customer.interface';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import { tabsNav } from './entityTabs';
import EntityModel from './entityModel';
import CustomersModule from './index';

import { getClientById, addNewClient, saveClient, getClientMinById } from '@/api/client';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import userModule from '@/store/user';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { CatchFormResponse } from '@/interfaces/shared';

export const MODULE_NAME = 'customer';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomerEntityModule extends EntityBaseModule {
  model: CustomerModel;
  allTabs = tabsNav;
  tabsNav: TabsNavInterface = {};

  constructor(module: CustomerEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_TABS_NAV(list: TabsNavInterface) {
    this.tabsNav = list;
  }

  @Action({ rawError: true })
  initTabsItems() {
    const tabsByUser: TabsNavInterface = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(tabsNav)) {
      if (userModule.canUserRead(menuItem.pathName)) {
        tabsByUser[menuItemKey] = menuItem;
      }
    }

    this.SET_TABS_NAV(tabsByUser);
  }

  @Action({ rawError: true })
  async initAdd() {
    await this.resetModel();
  }

  @Action({ rawError: true })
  async initTitle(id: string) {
    try {
      const result = await getClientMinById(id);

      CustomersModule.updateTitleEdit(result.name);
      this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: result.isTest });
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    await this.getById(id);
  }

  @Action({ rawError: true })
  async getById(id: string) {
    try {
      this.updateIsLoading(true);

      const result = await getClientById(id);

      await this.context.dispatch('setInfo', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  setInfo(info: CustomerResponseModel) {
    CustomersModule.updateTitleEdit(info.name);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'legalName', value: info.legalName });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalName', value: info.externalName });
    this.context.commit('SET_MODEL_VALUE', { key: 'lunchSchemes', value: info.lunchSchemes });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'legalAddress',
      value: info.legalAddress,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'actualAddress',
      value: info.actualAddress,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'legalForm',
      value: info.legalForm,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'type',
      value: info.type,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'email',
      value: info.email,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'phone',
      value: info.phone,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'fastReplacementFees',
      value: (info.fastReplacementFees && Object.values(info.fastReplacementFees)) ?? [],
    });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: info.isTest });
    this.context.commit('SET_MODEL_VALUE', { key: 'hasCabinet', value: info.hasCabinet });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckIn', value: info.autoCheckIn });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckup', value: info.autoCheckup });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentDivided', value: info.paymentDivided });

    if (info.clientSubcontractors) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'subcontractors',
        value: info.clientSubcontractors,
      });
    }

    if (info.contractor) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'contractor',
        value: info.contractor,
      });
    }

    if (info.accountManager) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'accountManager',
        value: info.accountManager,
      });
    }
    this.context.commit('SET_MODEL_VALUE', {
      key: 'importShiftsSource',
      value: info.importShiftsSource,
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'isClientCustomer',
      value: info.isClientCustomer,
    });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: info.inn });
    this.context.commit('SET_MODEL_VALUE', { key: 'kpp', value: info.kpp });
    this.context.commit('SET_MODEL_VALUE', { key: 'ogrn', value: info.ogrn });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: info.bank });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'paymentAccount',
      value: info.paymentAccount,
    });
    this.context.commit('SET_MODEL_VALUE', { key: 'ks', value: info.ks });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'bic',
      value: info.bic ?? '',
    });

    if (info.responsible) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsibleName',
        value: info.responsible.name,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsiblePosition',
        value: info.responsible.position,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsibleEmail',
        value: info.responsible.email,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsiblePhone',
        value: info.responsible.phone,
      });
    }

    this.context.commit('SET_CREATED_AT', info.createdAt);
    this.context.commit('SET_UPDATED_AT', info.updatedAt);

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async addNew(textFieldsData: CustomerTextFields) {
    try {
      const result = await addNewClient(textFieldsData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Клиент создан',
          type: 'ok',
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      const errorData = (error as CatchFormResponse)?.response?.data;
      let message = errorData?.message;

      if (errorData?.errors) {
        message ||= 'Изменения не сохранены';
      }

      ResponseHandlerModule.showNotify({ message, type: 'fail' });

      return errorData?.errors?.fields;
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { customerId: string; textFieldsData: CustomerTextFields }) {
    try {
      const response = await saveClient(params.customerId, params.textFieldsData);

      if (response.message) {
        ResponseHandlerModule.showNotify({
          message: response.message,
          type: 'fail',
        });
      } else if (response.result) {
        ResponseHandlerModule.showNotify({
          message: 'Изменения сохранены',
          type: 'ok',
        });

        this.setInfo(response.result.client);
      }
    } catch (error) {
      const errorData = (error as CatchFormResponse)?.response?.data;
      let message = errorData?.message || 'Изменения не сохранены';

      if (errorData?.errors) {
        message = Object.values(errorData?.errors.fields).join('. ');
      }

      ResponseHandlerModule.showNotify({ message, type: 'fail' });

      return errorData?.errors?.fields;
    }
  }

  @Action({ rawError: true })
  resetModel() {
    CustomersModule.updateTitleEdit('');

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'legalName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'lunchSchemes', value: [] });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'legalAddress',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'actualAddress',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'legalForm',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'type',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'email',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'phone',
      value: '',
    });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'fastReplacementFees',
      value: [],
    });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'hasCabinet', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckIn', value: true });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckup', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentDivided', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'subcontractors', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'contractor', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'accountManager', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'importShiftsSource', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isClientCustomer', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'kpp', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'ogrn', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentAccount', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'ks', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bic', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsibleName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsiblePosition', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsibleEmail', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsiblePhone', value: '' });

    this.context.commit('SET_CREATED_AT', '');
    this.context.commit('SET_UPDATED_AT', '');
  }
}

export default getModule(CustomerEntityModule);
