import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "cp-main__wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_warning_modal = _resolveComponent("warning-modal")!
  const _component_markdown_info = _resolveComponent("markdown-info")!
  const _component_modal_block = _resolveComponent("modal-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_component_warning_modal),
    (_ctx.modals.markdown)
      ? (_openBlock(), _createBlock(_component_modal_block, {
          key: 0,
          title: "Справка по разметке Markdown",
          class: "cp-modal__max",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal({ name: 'markdown', flag: false })))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_markdown_info)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}