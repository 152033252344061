import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class CustomersFilter {
  filterHandlers = {
    update: 'CustomersMainFilter/updateFilter',
    reset: 'CustomersMainFilter/resetFilter',
    resetState: 'CustomersMainFilter/resetFilterState',
    removeSelected: 'CustomersMainFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('name');
    filter.setPlaceholder('name', 'По наименованию');
    filter.setName('name', 'search_name');
    filter.setPreviewName('name', 'Клиент');
    filter.setIconClassName('name', 'icon-clients');
    filter.setAction('name', 'CustomersMainFilter/updateSearch');

    this.filterModel = filter.filterModel;
  }
}
