const AUTH_LOGIN = 'api/auth/login/';
const AUTH_LOGOUT = 'api/auth/logout/';
const USER_INIT = 'api/init/';

//
// CAN_READ_WHOLE_CLIENTS_STATISTIC
//
const ANALYTICS_BY_CUSTOMERS = 'api/admin/client/statistics/';
//
// CAN_READ_EMPLOYEE_STATISTICS
//
const ANALYTICS_BY_EMPLOYEES = 'api/admin/employee/statistics/';

//
// CAN_EDIT_BONUS
//
const BONUS_ENTITY_DELETE = 'api/admin/bonus/$1/';
const BONUS_CREATE = 'api/admin/bonus/create/';
const BONUS_ENTITY_EDIT = 'api/admin/bonus/$1/';
//
const BONUS_ENTITY_READ = 'api/admin/bonus/$1/';
const BONUS_LIST = 'api/admin/bonus/list/';
const BONUS_ACTIVE_LIST = 'api/admin/bonus/available/$1/';

//
// CAN_EDIT_YELLOW_CARD
//
const CARDS_DEL = 'api/admin/card/delete/$1/';
const CARD_CREATE = 'api/admin/card/create/';
const CARDS_EDIT = 'api/admin/card/update/$1/';
const CARDS_LIST = 'api/admin/card/list/';
const CARD_TYPES = 'api/admin/card/types/';
const CARDS_RATING_LIST = 'api/admin/card/rating/';
const CARDS_CANCEL_CARD = 'api/admin/card/cancel/$1/';

//
const CITIZENSHIP_LIST_ALL = 'api/admin/citizenship/all/';

//
const CLIENT_READ = 'api/admin/client/$1/';
const CLIENT_READ_MIN = 'api/admin/client/min/$1/';
const CLIENT_LIST = 'api/admin/client/list/';
//
// CAN_EDIT_CLIENT
//
const CLIENT_ADD = 'api/admin/client/create/';
const CLIENT_UPDATE = 'api/admin/client/$1/';

//
const CLIENT_LIST_ALL = 'api/admin/client/all/';
//
// CAN_EDIT_CLIENT
//
const CLIENT_STATISTICS = 'api/admin/client/$1/statistics/';
//
// CAN_READ_CLIENT_PHONE
//
const CLIENT_GET_PHONE = 'api/admin/client/$1/phone/';
//
// CAN_READ_CLIENT_LUNCH_SCHEMES
//
const SHIFT_LUNCH_SCHEMES = '/api/admin/market/shift/$1/lunch_scheme';

const CLIENT_LUNCH_SCHEMES = 'api/admin/client/$1/lunch_scheme/';

const CLIENT_SERVICE_TYPE_LIST = (clientId: string) => `/api/admin/client/${clientId}/client_service_type/list/`;
const CLIENT_SERVICE_TYPE_CREATE = (clientId: string) => `/api/admin/client/${clientId}/client_service_type/create/`;
const CLIENT_SERVICE_TYPE_UPDATE = (clientId: string, clientServiceTypeId: string) =>
  `/api/admin/client/${clientId}/client_service_type/${clientServiceTypeId}/`;
const CLIENT_SERVICE_TYPE_DELETE = (clientId: string, clientServiceTypeId: string) =>
  `/api/admin/client/${clientId}/client_service_type/${clientServiceTypeId}/`;

//
// CAN_EDIT_COMPETENCE
//
const COMPETENCE_DELETE = 'api/admin/competence/$1/';
const COMPETENCE_EDIT = 'api/admin/competence/$1/';
const COMPETENCE_CREATE = 'api/admin/competence/create/';
//
// CAN_READ_COMPETENCE
//
const COMPETENCE_READ = 'api/admin/competence/$1/';
const COMPETENCE_ALL = 'api/admin/competence/all/';
const COMPETENCE_LIST = 'api/admin/competence/list/';

const QUIZ_LIST = 'api/admin/quiz/list/';
const QUIZ_CREATE = 'api/admin/quiz/create/';
const QUIZ_READ = (id: string): string => `api/admin/quiz/${id}/`;
const QUIZ_UPDATE = (id: string | number): string => `api/admin/quiz/${id}/`;
const QUIZ_QUESTION_CREATE = (quizId: string): string => `api/admin/quiz/${quizId}/question/create/`;
const QUIZ_QUESTION_UPDATE = (quizId: string, questionId: string): string =>
  `api/admin/quiz/${quizId}/question/${questionId}/`;
const QUIZ_QUESTION_DELETE = (quizId: string, questionId: string): string =>
  `api/admin/quiz/${quizId}/question/${questionId}/`;
const QUIZ_ANSWER_CREATE = (questionId: string): string => `api/admin/quiz/question/${questionId}/answer/create/`;
const QUIZ_ANSWER_UPDATE = (questionId: string, answerId: string): string =>
  `api/admin/quiz/question/${questionId}/answer/${answerId}/`;
const QUIZ_ANSWER_DELETE = (questionId: string, answerId: string): string =>
  `api/admin/quiz/question/${questionId}/answer/${answerId}/`;

const LABOR_RELATIONS_LIST = 'api/admin/labor_relations/list';
const LABOR_RELATIONS_CREATE = 'api/admin/labor_relations/create';
const LABOR_RELATIONS_DELETE = (id: string): string => `api/admin/labor_relations/${id}/delete`;

//
// CAN_READ_EMPLOYEE
//
const EMPLOYEES_LIST = 'api/admin/employee/list/';
const EMPLOYEE_READ = 'api/admin/employee/$1/';
//
// CAN_EDIT_EMPLOYEE
//
const EMPLOYEE_CREATE = 'api/admin/employee/create/';
const EMPLOYEE_EDIT = 'api/admin/employee/$1/';

//
// CAN_READ_EMPLOYEE
//
const EMPLOYEES_STATISTICS = 'api/admin/employee-market/list/$1/';
//
// CAN_READ_EMPLOYEE
//
const EMPLOYEE_POINT_LIST = 'api/admin/point/list/$1/';
const EMPLOYEE_SUITABLE_POINTS = 'api/admin/point/suitable/$1/';
//
// CAN_EDIT_EMPLOYEE
//
const EMPLOYEE_POINTS_DELETE = 'api/admin/point/$1/';
const EMPLOYEE_POINT_CREATE = 'api/admin/point/create/$1/';
const EMPLOYEE_POINT_UPDATE = 'api/admin/point/update/$1/';
//
// CAN_EDIT_EMPLOYEE_SHIFT
//
const EMPLOYEES_DELETE_READY_TIME = 'api/admin/employee/shift/delete/$1/';
const EMPLOYEE_ADD_NEW_READY_TIME = 'api/admin/employee/shift/create/';
const EMPLOYEE_UPDATE_READY_TIME = 'api/admin/employee/shift/update/$1/';
//
// CAN_READ_EMPLOYEE
//
const EMPLOYEES_SHIFT = 'api/admin/employee/shift/$1/$2';
//
// CAN_EDIT_EMPLOYEE_SHIFT
//
const EMPLOYEES_DELETE_SHIFT = 'api/admin/employee/shift/$1/$2';
//
// CAN_EDIT_EMPLOYEE
//
const EMPLOYEE_DELETE_DUPLICATE = '/api/admin/employee/double/$1/';
//
// CAN_IMPORT_BULK_EMPLOYEE
//
const EMPLOYEE_BULK_IMPORT = 'api/admin/employee/bulk_import/';
//
// CAN_READ_EMPLOYEE
//
const EMPLOYEE_RELATION = '/api/admin/employee/status/$1/';
const EMPLOYEE_RATINGS = (id: string): string => `/api/admin/employee/${id}/marks/`;
//
// CAN_EDIT_EMPLOYEE
//
const EMPLOYEE_MARKET_TOTAL = 'api/admin/employee-market/total/$1/';
//
// CAN_READ_EMPLOYEE
//
const EMPLOYEES_SHOPS = 'api/admin/employee-market/$1/$2/';
//
const EMPLOYEE_VACCINATION_STATUSES = '/api/admin/employee/vaccination-statuses/';
//
const EMPLOYEE_ROCKET_WORK = 'api/admin/employee/rocket_work/$1/';
//
const EMPLOYEE_ROLES = 'api/admin/employee/roles/';
//
//
//
const EMPLOYEE_COMMENTS = 'api/admin/employee_comment/list/?page=1&items=$2&filters[0][id]=employee&filters[0][value]=$1';
const EMPLOYEE_CREATE_COMMENT = 'api/admin/employee_comment/create/';
const EMPLOYEE_UPDATE_COMMENT = 'api/admin/employee_comment/$1/';
const EMPLOYEE_DELETE_COMMENT = 'api/admin/employee_comment/$1/';
//
// CAN_READ_EMPLOYEE_PHONE
//
const EMPLOYEE_GET_PHONE = 'api/admin/employee/$1/phone/';

const EMPLOYEE_CHANGE_VIEWED = (id: string): string => `api/admin/employee/${id}/view/`;

//
// CAN_READ_DATE_BRIEF
//
const FINANCE_DATE_BRIEF_LIST = 'api/admin/finance/date_brief/list/';
const FINANCE_READ_PENALTY = 'api/admin/finance/date_brief/penalty/$1/';
//
// CAN_EDIT_DATE_BRIEF_PENALTY
//
const FINANCE_CREATE_PENALTY = 'api/admin/finance/date_brief/penalty/';
//
// CAN_EDIT_DATE_BRIEF_PENALTY
//
const FINANCE_DELETE_PENALTY = 'api/admin/finance/date_brief/penalty/$1/';
//
// CAN_READ_CLIENT_FINANCE
//
const FINANCE_CLIENT_LIST = 'api/admin/finance/client/list/';
//
// CAN_EXPORT_CLIENT_FINANCE
//
const FINANCE_CLIENT_FINANCE_EXPORT = 'api/admin/finance/client/export/';
const FINANCE_DOWNLOAD_FILE = 'api/admin/finance/client/download/?file=';
//
// CAN_READ_EMPLOYEE_FINANCE
//
const FINANCE_EMPLOYEE = 'api/admin/finance/employee/$1/?periodStart=$2&periodEnd=$3';

//
// CAN_READ_MOBILE_FINANCE_PERIOD
//
const FINANCE_PERIODS_LIST = 'api/admin/mobile/finance/periods/$1/';
//
// CAN_READ_MOBILE_TEST_FREE_SHIFTS
//
const FINANCE_PERIODS_DETAIL = 'api/admin/mobile/finance/detail/$1/';

//
// CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED
//
const FIRST_SHIFT_LIST = 'api/admin/first_shift/list/';
//
// CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED
//
const FIRST_SHIFT_EDIT = 'api/admin/first_shift/processed/';

//
// CAN_EDIT_SHIFT_AFTER_SKIP
//
const FOUND_EMPLOYEES_LIST = 'api/admin/shift-after-skip/list/';
const FOUND_EMPLOYEES_EDIT = 'api/admin/shift-after-skip/$1/';

//
// CAN_READ_FREE_MARKET_SHIFT
//
const FREE_MARKET_SHIFT_LIST = 'api/admin/free_shift/list/';
const ORDER_LIST = 'api/admin/order/list';
//
// CAN_READ_EMPLOYEE
//
const FREE_MARKET_SHIFT_EMPLOYEES_LIST = 'api/admin/market/shift/employees/';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
const FREE_MARKET_SHIFT_ADD_EMPLOYEE = 'api/admin/market/shift/add_employee/$1/';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
const FREE_MARKET_SHIFT_DELETE_EMPLOYEE = 'api/admin/market/shift/delete_employee/$1/$2/';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
const FREE_MARKET_SHIFT_REFUSE = 'api/admin/market/shift/refuse/';

const GEO_ADDRESS_SUGGEST = 'api/admin/geo/address_suggest/?q=$1';
const GEO_ADDRESS_COORDINATES = 'api/admin/geo/coordinates/?address=$1';
const GET_ADDRESS_VIA_COORDINATES = 'api/admin/geo/address_via_coords/?lat=$1&long=$2';
const GEO_HELPER_READ = 'api/admin/assistant/$1/';
const GEO_BANK_SEARCH = 'api/admin/geo/bank/?search=$1';
//
// CAN_EDIT_CLIENT
//
const GEO_HELPER_CREATE = 'api/admin/assistant/create/';
const GEO_HELPER_EDIT = 'api/admin/assistant/$1/';
const GEO_HELPER_LIST = 'api/admin/assistant/list/$1/';
const GEO_HELPER_LIST_ALL = 'api/admin/assistant/total/';
const GEO_HELPER_SEARCH = 'api/admin/assistant/search/';

//
// CAN_READ_MAILING
//
const MAILING_READ = 'api/admin/mailing/$1/';
const MAILINGS_LIST = 'api/admin/mailing/list/';
//
// CAN_EDIT_MAILING
//
const MAILING_DELETE = 'api/admin/mailing/$1/delete/';
const MAILING_EDIT = 'api/admin/mailing/$1/';
const MAILING_CREATE = 'api/admin/mailing/create/';
const MAILING_CANCEL = 'api/admin/mailing/$1/cancel/';
const MAILING_COPY = 'api/admin/mailing/$1/copy/';
//
// CAN_PREPARE_MAILING
//
const MAILING_PREPARE = 'api/admin/mailing/$1/prepare/';
//
// CAN_READ_MAILING
//
const MAILING_STATUS_LIST = 'api/admin/mailing/statuses/';
const MAILING_PARTICIPANT_LIST = 'api/admin/mailing-participant/list/';
//const MAILING_PARTICIPANT_STATUSES = 'api/admin/mailing-participant/statuses/';
const MAILING_MARKERS = 'api/admin/mailing/markers/';

//
// CAN_EDIT_NOTIFICATION_PARAMETER_INFO
//
const NOTIFICATIONS_INFO_UPDATE = 'api/admin/notification/parameter/info/update/';
const NOTIFICATIONS_FIRST_SHIFT_UPDATE = 'api/admin/notification/parameter/first_shift/update/';
const NOTIFICATIONS_FIRST_SHIFT_DONE_UPDATE = 'api/admin/notification/parameter/first_shift_done/update/';
const NOTIFICATIONS_CANCEL_SHIFT_UPDATE = 'api/admin/notification/parameter/cancel_shift/update/';
const NOTIFICATIONS_REACTIVATION_UPDATE = 'api/admin/notification/parameter/employee_reactivation/update/';
const NOTIFICATIONS_REMIND_UPDATE = 'api/admin/notification/parameter/remind/update/';
const NOTIFICATIONS_CHECKIN_UPDATE = 'api/admin/notification/parameter/checkin/update/';
const NOTIFICATIONS_NEW_MARKET_UPDATE = 'api/admin/notification/parameter/new_market/update/';
const NOTIFICATIONS_AUTO_ASSIGNED_UPDATE = 'api/admin/notification/parameter/auto_assigned_shift/update/';
//
// CAN_READ_NOTIFICATION_PARAMETER_INFO
//
const NOTIFICATIONS_MARKERS = 'api/admin/notification/parameter/markers/';
const NOTIFICATIONS_INFO_LIST = 'api/admin/notification/parameter/info/list/';
const NOTIFICATIONS_FIRST_SHIFT_LIST = 'api/admin/notification/parameter/first_shift/list/';
const NOTIFICATIONS_FIRST_SHIFT_DONE_LIST = 'api/admin/notification/parameter/first_shift_done/list/';
const NOTIFICATIONS_CANCEL_SHIFT_LIST = 'api/admin/notification/parameter/cancel_shift/list/';
const NOTIFICATIONS_REACTIVATION_LIST = 'api/admin/notification/parameter/employee_reactivation/list/';
const NOTIFICATIONS_REMIND_LIST = 'api/admin/notification/parameter/remind/list/';
const NOTIFICATIONS_CHECKIN_LIST = 'api/admin/notification/parameter/checkin/list/';
const NOTIFICATIONS_NEW_MARKET_LIST = 'api/admin/notification/parameter/new_market/list/';
const NOTIFICATIONS_AUTO_ASSIGNED_LIST = 'api/admin/notification/parameter/auto_assigned_shift/list/';

const NOTIFICATIONS_SUBSCRIBE = 'api/profile/notification/subscribe';
const NOTIFICATIONS_UNSUBSCRIBE = 'api/profile/notification/unsubscribe';

//
// CAN_MARK_GOING_OUT_VIEWED
//
const OUTPUTS_LIST = 'api/admin/going_out/list/';
const OUTPUTS_EDIT = 'api/admin/going_out/$1/viewed/';

//
//
//
const PARTNERS_LIST_ALL = 'api/admin/partner/all/';
const PARTNERS_LIST = 'api/admin/partner/list/';
//
// CAN_READ_PARTNER
//
const PARTNER_READ = 'api/admin/partner/$1/';
const PARTNER_SHOPS_LIST = 'api/admin/partner/markets/?';
//
// CAN_EDIT_PARTNER
//
const PARTNER_CREATE = 'api/admin/partner/create/';
const PARTNER_EDIT = 'api/admin/partner/$1/';

//
// CAN_READ_FINANCE_PAYMENT
//
const PAYMENTS_LIST = 'api/admin/finance/payments/list/';
//
// CAN_PAYOUT_FINANCE_PAYOUT
//
const PAYMENTS_EXPORT = 'api/admin/finance/payments/export/';
const PAYMENTS_EXPORT_STATUS = 'api/admin/finance/payments/export/status';

const PAYMENTS_BALANCE = '/api/admin/finance/payments/rocket_work/balance/';
//
// CAN_READ_FINANCE_PAYMENT
//
const PAYMENTS_DOWNLOAD = 'api/admin/finance/payments/download/';

const PAYMENT_STRATEGIES = '/api/admin/payment_strategy/all/';

//
// CAN_PAYOUT_FINANCE_PAYOUT
//
const PAYOUTS_NEW_PAYOUT = 'api/admin/finance/payouts/payout/$1/';
//
// CAN_EDIT_FINANCE_PAYOUT
//
const PAYOUTS_DELETE_PAYOUTS = 'api/admin/finance/payouts/delete/';
const PAYOUTS_UPDATE_PAYOUT = 'api/admin/finance/payouts/edit_payout/$1/';
//
// CAN_READ_FINANCE_PAYOUT
//
const PAYOUTS_LIST = 'api/admin/finance/payouts/list/';
//
// CAN_EXPORT_FINANCE_PAYOUT
// CAN_DOWNLOAD_FINANCE_PAYOUT
//
const PAYOUTS_EXPORT = 'api/admin/finance/payouts/export/';
//
// CAN_PAYOUT_FINANCE_PAYOUT
//
const PAYOUTS_NEW_MULTIPLE_PAYOUTS = 'api/admin/finance/payouts/payouts/';
//
// CAN_READ_FINANCE_PAYOUT
//
const PAYOUTS_ROCKET_WORK_STATUSES = 'api/admin/finance/payouts/rocket_work_statuses/';

//
// CAN_EDIT_REACTIVATION_TASK
//
const REACTIVATION_READ_TASK = 'api/admin/reactivation-task/$1/';
const REACTIVATION_TASK_LIST = 'api/admin/reactivation-task/list/';
const REACTIVATION_TIME_PERIODS = 'api/admin/reactivation-task/time-periods/';
const REACTIVATION_UPDATE_TASK = 'api/admin/reactivation-task/$1/';
const REACTIVATION_EMPLOYEE_STATUSES = 'api/admin/reactivation-task/employee-statuses/';
const REACTIVATION_TASK_STATUSES = 'api/admin/reactivation-task/statuses/';
const REACTIVATION_LOCK_TASK = 'api/admin/reactivation-task/$1/lock';
const REACTIVATION_RELEASE_TASK = 'api/admin/reactivation-task/$1/release';

//
// CAN_EDIT_REGION
//
const REGIONS_EDIT = 'api/admin/region/$1/';
const REGIONS_CREATE = 'api/admin/region/create/';
//
// CAN_READ_REGION
//
const REGIONS_READ = 'api/admin/region/$1/';
const REGIONS_LIST = 'api/admin/region/list/';
//
const REGIONS_ALL = 'api/admin/region/all/';
const REGIONS_GEO_ALL = 'api/admin/geo-region/codes/';

//
// CAN_READ_WORK_MONTH
//
const WORK_MONTHS_YEAR = 'api/admin/work_month/list/';
//
// CAN_EDIT_WORK_MONTH
//
const WORK_MONTHS_YEAR_UPDATE = 'api/admin/work_month/year/';

//
// CAN_EDIT_SHIFT_EMPLOYEE_CHECKUP
//
const REVISE_SHOP_LIST = '/api/admin/checkup/market/list/';
const REVISE_SHOP_TOTAL = '/api/admin/checkup/market/total/';
//
// CAN_EDIT_SHIFT_EMPLOYEE_CHECKUP
//
const REVISE_READ_SHIFT_EMPLOYEE = '/api/admin/checkup/shift_employee/$1/';
const REVISE_EDIT_SHIFT_EMPLOYEE = '/api/admin/checkup/shift_employee/$1/';
const REVISE_SHOP_REPORT = '/api/admin/checkup/market/$1/shift_employee/report';
const REVISE_SHOP_REPORT_DOWNLOAD = '/api/admin/checkup/market/download/';
const REVISE_STATUS = '/api/admin/checkup/market/status/';
const REVISE_DURATION = '/api/admin/checkup/shift_employee/$1/duration?start=$2&end=$3&lunch=$4';

//
const SHOP_READ = 'api/admin/market/$1/';
const SHOP_READ_MIN = 'api/admin/market/min/$1/';
//
// CAN_EDIT_CLIENT_MARKET
//
const SHOP_CREATE = 'api/admin/market/create/$1/';
const SHOP_EDIT = 'api/admin/market/$1/';
const SHOP_DELETE = 'api/admin/market/$1/';
//
const SHOP_LIST = 'api/admin/market/list/$1/';
//
const SHOP_TOTAL_LIST = 'api/admin/market-total/list/';
//
// CAN_READ_EMPLOYEE
//
const SHOP_EMPLOYEES_LIST = 'api/admin/market/employee_list/$1/';
const SHOP_EMPLOYEES = 'api/admin/market-employee/list/$1/';
const SHOP_FAVORITE_EMPLOYEE = 'api/admin/market-employee/$1/$2/';
//
// CAN_EDIT_CLIENT_MARKET_EMPLOYEE
//
const SHOP_FAVORITE_EMPLOYEE_CREATE = 'api/admin/market-employee/$1/$2/';
const SHOP_FAVORITE_EMPLOYEE_DELETE = 'api/admin/market-employee/$1/$2/';
//
// CAN_READ_EMPLOYEE
//
const SHOP_EMPLOYEE_SEARCH = 'api/admin/market-employee/search/?q=$1';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
const SHOP_SHIFT_DELETE = 'api/admin/market/shift/delete/$1/';
const SHOP_SHIFT_DELETE_PERIOD = 'api/admin/market/shift/$1/period/$2';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
const SHOP_SHIFT_CREATE = 'api/admin/market/shift/$1/create/';
//
// CAN_READ_CLIENT_MARKET_SHIFT
//
const SHOP_SHIFT = 'api/admin/market/shift/$1/';
//
// CAN_READ_CLIENT_MARKET_SHIFT
//
const SHOP_CALENDAR = 'api/admin/market/shift/$1/calendar/?serviceType=$2&startWeek=$3&endWeek=$4&employee=$5';
//
const SHOP_SHIFT_POPULAR_TIME = 'api/admin/market/shift/time/?serviceType=$1&market=$2';
//
const SHOP_STATISTICS = 'api/admin/market/statistics/$1/shift/';
//
const SHOP_SHIFT_COUNT_PROCESSED = 'api/admin/market/shift/count_processed/';
//
// CAN_READ_EMPLOYEE
//
const SHOP_EMPLOYEE_SEARCH_SHIFT = 'api/admin/market/shift/search/?q=$1&shift=$2';
//
// CAN_EDIT_CLIENT_MARKET_SHIFT
//
// const SHOP_CHECK_EMPLOYEE = 'api/admin/market/shift/check/{clientMarketShift}/{employee}/';
//
const SHOP_CHECKIN_CODE = 'api/admin/checkin/checkin_code/?market=$1&day=$2';
const SHOP_CHECKOUT_CODE = 'api/admin/checkin/checkout_code/?market=$1&day=$2';
const SHOP_MAKE_CHECK_IN = 'api/admin/checkin/make_checkin/';
//
// CAN_READ_CLIENT_MARKET_PHONES
//
const SHOP_GET_PHONES = 'api/admin/market/$1/phones/';

const SHOP_CREATE_COMMENT = 'api/admin/market/comment/';
const SHOP_UPDATE_COMMENT = 'api/admin/market/comment/$1';
const SHOP_DELETE_COMMENT = 'api/admin/market/comment/$1';

const SHOP_SUBSCRIPTION_LIST = (shopId: string | number) => `api/admin/market/${shopId}/subscription/list/`;
const SHOP_SUBSCRIPTION_CREATE = 'api/admin/market/subscription/create/';
const SHOP_SUBSCRIPTION_DELETE = (subscriptionId: string | number) =>
  `api/admin/market/subscription/${subscriptionId}/delete/`;

//
// CAN_EDIT_SPLIT
//
const SPLIT_CREATE = 'api/admin/split/create/';
const SPLIT_EDIT = 'api/admin/split/$1/';
//
const SPLIT_LIST = 'api/admin/split/list/';
//
const SPLIT_ALL = 'api/admin/split/all/';
//
// CAN_READ_SPLIT_STATISTICS
//
const SPLIT_STATISTIC_LIST = 'api/admin/split/statistic_list/';

//
// CAN_EDIT_TRAINING_CENTER
//
const TRAINING_CENTER_DELETE = 'api/admin/training_center/$1/';
const TRAINING_CENTER_CREATE = 'api/admin/training_center/';
const TRAINING_CENTER_EDIT = 'api/admin/training_center/$1/';
//
// CAN_READ_TRAINING_CENTER
//
const TRAINING_CENTER_READ = 'api/admin/training_center/$1/';
const TRAINING_CENTER_LIST = 'api/admin/training_center/list/';

//
// CAN_READ_SHIFT_TEMPLATE
//
const VACANCY_READ = 'api/admin/vacancy/$1/';
//
// CAN_EDIT_SHIFT_TEMPLATE
//
const VACANCY_ADD = 'api/admin/vacancy/create/$1/';
const VACANCY_UPDATE = 'api/admin/vacancy/update/$1/';
const VACANCY_DELETE = 'api/admin/vacancy/$1/';
//
// CAN_READ_SHIFT_TEMPLATE
//
const VACANCY_LIST = 'api/admin/vacancy/list/$1/';
const VACANCY_ALL = 'api/admin/service-type/all/$1';
const VACANCY_ALL_BY_STORE = 'api/admin/vacancy/all_by_store/$1/';
const VACANCY_TOTAL = '/api/admin/shift_template/total/';
//
const VACANCY_VACCINATION_REQUIREMENTS = '/api/admin/vacancy/vaccination-requirements/';
//
// CAN_READ_SHIFT_TEMPLATE
//
const VACANCY_ALL_WITH_CLIENTS = 'api/admin/service-type/all_with_clients/';
const VACANCY_ACTIVE_BY_CLIENTS = 'api/admin/service-type/listByClients/';

//
// CAN_READ_CLIENT_TEMPLATE
//
const TEMPLATE_READ = 'api/admin/shift_template/$1/';
//
// CAN_EDIT_CLIENT_TEMPLATE
//
const TEMPLATE_ADD = 'api/admin/shift_template/create/$1/';
const TEMPLATE_UPDATE = 'api/admin/shift_template/update/$1/';
const TEMPLATE_DELETE = 'api/admin/shift_template/$1/';
//
// CAN_READ_CLIENT_TEMPLATE
//
const TEMPLATE_LIST = 'api/admin/shift_template/list/$1/';
const TEMPLATE_ALL = 'api/admin/shift_template/all/$1';
const TEMPLATE_ALL_BY_STORE = 'api/admin/shift_template/all_by_store/$1/';
const TEMPLATE_TOTAL = '/api/admin/shift_template/total/';
//
const TEMPLATE_VACCINATION_REQUIREMENTS = '/api/admin/shift_template/vaccination-requirements/';
//
// CAN_READ_CLIENT_TEMPLATE
//
const TEMPLATE_ALL_WITH_CLIENTS = 'api/admin/shift_template/all_with_clients/';
const TEMPLATE_ACTIVE_BY_CLIENTS = 'api/admin/shift_template/listByClients/';

//
// CAN_READ_SERVICE_TYPE
//
const SERVICE_TYPE_READ = 'api/admin/service-type/all/';
const SERVICE_TYPES_BY_STORE = (marketId: string | number): string => `api/admin/service-type/all_by_store/${marketId}/`;

//
// CAN_READ_UNIT_TYPE
//
const UNIT_TYPE_READ = 'api/admin/unit-type/all/';

//
// CAN_EDIT_USER
//
const USER_CREATE = 'api/admin/user/create/';
const USER_UPDATE = 'api/admin/user/update/$1/';
//
const USERS_LIST = 'api/admin/user/list/';
const USER_GET = 'api/admin/user/$1/';
const USER_ALL = 'api/admin/user/all/';
//
// CAN_EDIT_USER
//
const USER_CHIEFS_LIST = 'api/admin/user/chiefs/';
//
// CAN_EDIT_SECURITY_GROUP
//
const SECURITY_GROUPS_LIST = 'api/admin/security-group/list/';
const SECURITY_GROUP_READ = 'api/admin/security-group/$1/';
const SECURITY_GROUPS_ALL_LIST = 'api/admin/security-group/all/';
const SECURITY_GROUP_DELETE = 'api/admin/security-group/$1/';
const SECURITY_GROUP_CREATE = 'api/admin/security-group/create/';
const SECURITY_GROUP_SET_PERMISSIONS = 'api/admin/security-group/$1/permissions/';
//
const SECURITY_GROUP_PERMISSION_LIST = 'api/admin/security-group/permissions/';

//
// CAN_READ_LOG_MARKET_SHIFT_LIST
//
const LOGS_SHOP_SHIFT = 'api/admin/log/shift_list/';
// const SHOP_SHIFT_TYPE = 'api/admin/log/shift_type/';
//
// CAN_READ_LOG_MARKET_LIST
//
const LOGS_SHOP = 'api/admin/log/market_list/';
//
const LOGS_TYPES_SHOP = 'api/admin/log/market_type/';
//
// CAN_READ_LOG_CLIENT_LIST
//
const LOGS_CLIENT = 'api/admin/log/client_list/';
//
const LOGS_TYPES_CLIENT = 'api/admin/log/client_type/';
//
// CAN_READ_LOG_EMPLOYEE_LIST
//
const LOG_EMPLOYEE = '/api/admin/log/employee_list/';
const LOG_TYPE_EMPLOYEE = '/api/admin/log/employee_list_type/';

const LOGS_PARTNER = '/api/admin/log/partner/list/';
const LOG_TYPE_PARTNER = '/api/admin/log/partner/type/list/';

//
const HRM_SETTINGS_LIST = 'api/admin/hrm_settings/list/';
const HRM_READ_SINGLE_SETTING = 'api/admin/hrm_settings/get/$1/';
const HRM_UPDATE_SINGLE_SETTING = 'api/admin/hrm_settings/';
const FREE_MARKET_SHIFT_SEND_PUSH = 'api/admin/shift/push/send/';

export default {
  auth: {
    login: AUTH_LOGIN,
    logout: AUTH_LOGOUT,
  },
  analytics: {
    byCustomer: ANALYTICS_BY_CUSTOMERS,
    byEmployee: ANALYTICS_BY_EMPLOYEES,
  },
  bonus: {
    getList: BONUS_LIST,
    getActiveList: BONUS_ACTIVE_LIST,
    getEntity: BONUS_ENTITY_READ,
    createEntity: BONUS_CREATE,
    editEntity: BONUS_ENTITY_EDIT,
    deleteEntity: BONUS_ENTITY_DELETE,
  },
  cards: {
    getList: CARDS_LIST,
    getRatingList: CARDS_RATING_LIST,
    create: CARD_CREATE,
    edit: CARDS_EDIT,
    delete: CARDS_DEL,
    getCardTypes: CARD_TYPES,
    cancelCard: CARDS_CANCEL_CARD,
  },
  citizenship: {
    getAll: CITIZENSHIP_LIST_ALL,
  },
  client: {
    getTotalList: CLIENT_LIST_ALL,
    getList: CLIENT_LIST,
    read: CLIENT_READ,
    readMin: CLIENT_READ_MIN,
    create: CLIENT_ADD,
    update: CLIENT_UPDATE,
    statistics: CLIENT_STATISTICS,
    getPhone: CLIENT_GET_PHONE,
    getLunchSchemes: SHIFT_LUNCH_SCHEMES,
    getClientLunchSchemes: CLIENT_LUNCH_SCHEMES,
    getServiceTypeList: CLIENT_SERVICE_TYPE_LIST,
    createServiceType: CLIENT_SERVICE_TYPE_CREATE,
    updateServiceType: CLIENT_SERVICE_TYPE_UPDATE,
    deleteServiceType: CLIENT_SERVICE_TYPE_DELETE,
  },
  competence: {
    getAll: COMPETENCE_ALL,
    getList: COMPETENCE_LIST,
    read: COMPETENCE_READ,
    edit: COMPETENCE_EDIT,
    delete: COMPETENCE_DELETE,
    create: COMPETENCE_CREATE,
  },
  quiz: {
    list: QUIZ_LIST,
    create: QUIZ_CREATE,
    read: QUIZ_READ,
    update: QUIZ_UPDATE,
    questionCreate: QUIZ_QUESTION_CREATE,
    questionUpdate: QUIZ_QUESTION_UPDATE,
    questionDelete: QUIZ_QUESTION_DELETE,
    answerCreate: QUIZ_ANSWER_CREATE,
    answerUpdate: QUIZ_ANSWER_UPDATE,
    answerDelete: QUIZ_ANSWER_DELETE,
  },
  laborRelations: {
    list: LABOR_RELATIONS_LIST,
    create: LABOR_RELATIONS_CREATE,
    delete: LABOR_RELATIONS_DELETE,
  },
  employees: {
    getList: EMPLOYEES_LIST,
    read: EMPLOYEE_READ,
    edit: EMPLOYEE_EDIT,
    create: EMPLOYEE_CREATE,
    statistics: EMPLOYEES_STATISTICS,
    shops: EMPLOYEES_SHOPS,
    deleteReadyTime: EMPLOYEES_DELETE_READY_TIME,
    shift: EMPLOYEES_SHIFT,
    deleteShift: EMPLOYEES_DELETE_SHIFT,
    addNewReadyTime: EMPLOYEE_ADD_NEW_READY_TIME,
    updateReadyTime: EMPLOYEE_UPDATE_READY_TIME,
    roles: EMPLOYEE_ROLES,
    pointsList: EMPLOYEE_POINT_LIST,
    pointsSuitable: EMPLOYEE_SUITABLE_POINTS,
    pointsDelete: EMPLOYEE_POINTS_DELETE,
    pointCreate: EMPLOYEE_POINT_CREATE,
    pointUpdate: EMPLOYEE_POINT_UPDATE,
    marketTotal: EMPLOYEE_MARKET_TOTAL,
    relation: EMPLOYEE_RELATION,
    ratings: EMPLOYEE_RATINGS,
    vaccinationStatuses: EMPLOYEE_VACCINATION_STATUSES,
    deleteDuplicate: EMPLOYEE_DELETE_DUPLICATE,
    rocketWork: EMPLOYEE_ROCKET_WORK,
    bulkImport: EMPLOYEE_BULK_IMPORT,
    getComments: EMPLOYEE_COMMENTS,
    createComment: EMPLOYEE_CREATE_COMMENT,
    updateComment: EMPLOYEE_UPDATE_COMMENT,
    deleteComment: EMPLOYEE_DELETE_COMMENT,
    getPhone: EMPLOYEE_GET_PHONE,
    changeViewed: EMPLOYEE_CHANGE_VIEWED,
  },
  finance: {
    getList: FINANCE_DATE_BRIEF_LIST,
    penaltyDetail: FINANCE_READ_PENALTY,
    penaltyCreate: FINANCE_CREATE_PENALTY,
    penaltyDelete: FINANCE_DELETE_PENALTY,
    client: FINANCE_CLIENT_LIST,
    export: FINANCE_CLIENT_FINANCE_EXPORT,
    downloadFile: FINANCE_DOWNLOAD_FILE,
    getEmployeeInvoices: FINANCE_EMPLOYEE,
  },
  financePeriods: {
    getList: FINANCE_PERIODS_LIST,
    getDetail: FINANCE_PERIODS_DETAIL,
  },
  firstShift: {
    getList: FIRST_SHIFT_LIST,
    edit: FIRST_SHIFT_EDIT,
  },
  foundEmployees: {
    getList: FOUND_EMPLOYEES_LIST,
    edit: FOUND_EMPLOYEES_EDIT,
  },
  freeMarketShifts: {
    getList: FREE_MARKET_SHIFT_LIST,
    getEmployeesList: FREE_MARKET_SHIFT_EMPLOYEES_LIST,
    addEmployee: FREE_MARKET_SHIFT_ADD_EMPLOYEE,
    deleteEmployee: FREE_MARKET_SHIFT_DELETE_EMPLOYEE,
    refuseShift: FREE_MARKET_SHIFT_REFUSE,
    sendPushMessage: FREE_MARKET_SHIFT_SEND_PUSH,
  },
  geo: {
    address: GEO_ADDRESS_SUGGEST,
    coordinates: GEO_ADDRESS_COORDINATES,
    addressViaCoordinates: GET_ADDRESS_VIA_COORDINATES,
    bank: GEO_BANK_SEARCH,
  },
  logs: {
    client: LOGS_CLIENT,
    clientTypes: LOGS_TYPES_CLIENT,
    employee: LOG_EMPLOYEE,
    employeeTypes: LOG_TYPE_EMPLOYEE,
    shop: LOGS_SHOP,
    shopTypes: LOGS_TYPES_SHOP,
    shift: LOGS_SHOP_SHIFT,
    partner: LOGS_PARTNER,
    partnerTypes: LOG_TYPE_PARTNER,
  },
  geoHelper: {
    getList: GEO_HELPER_LIST,
    getAllList: GEO_HELPER_LIST_ALL,
    read: GEO_HELPER_READ,
    create: GEO_HELPER_CREATE,
    edit: GEO_HELPER_EDIT,
    search: GEO_HELPER_SEARCH,
  },
  mailings: {
    getList: MAILINGS_LIST,
    read: MAILING_READ,
    edit: MAILING_EDIT,
    create: MAILING_CREATE,
    copy: MAILING_COPY,
    prepare: MAILING_PREPARE,
    cancel: MAILING_CANCEL,
    delete: MAILING_DELETE,
    status: MAILING_STATUS_LIST,
    participantList: MAILING_PARTICIPANT_LIST,
    markers: MAILING_MARKERS,
  },
  notifications: {
    getMarkers: NOTIFICATIONS_MARKERS,
    getRemindList: NOTIFICATIONS_REMIND_LIST,
    updateRemind: NOTIFICATIONS_REMIND_UPDATE,
    getInfoList: NOTIFICATIONS_INFO_LIST,
    updateInfo: NOTIFICATIONS_INFO_UPDATE,
    getFirstShiftList: NOTIFICATIONS_FIRST_SHIFT_LIST,
    updateFirstShift: NOTIFICATIONS_FIRST_SHIFT_UPDATE,
    getFirstShiftDoneList: NOTIFICATIONS_FIRST_SHIFT_DONE_LIST,
    updateFirstShiftDone: NOTIFICATIONS_FIRST_SHIFT_DONE_UPDATE,
    getCancelShiftList: NOTIFICATIONS_CANCEL_SHIFT_LIST,
    updateCancelShift: NOTIFICATIONS_CANCEL_SHIFT_UPDATE,
    getReactivationList: NOTIFICATIONS_REACTIVATION_LIST,
    updateReactivation: NOTIFICATIONS_REACTIVATION_UPDATE,
    getCheckinList: NOTIFICATIONS_CHECKIN_LIST,
    updateCheckin: NOTIFICATIONS_CHECKIN_UPDATE,
    getNewMarketList: NOTIFICATIONS_NEW_MARKET_LIST,
    updateNewMarket: NOTIFICATIONS_NEW_MARKET_UPDATE,
    getAutoAssignedList: NOTIFICATIONS_AUTO_ASSIGNED_LIST,
    updateAutoAssigned: NOTIFICATIONS_AUTO_ASSIGNED_UPDATE,
  },
  notificationSubscriptions: {
    subscribe: NOTIFICATIONS_SUBSCRIBE,
    unsubscribe: NOTIFICATIONS_UNSUBSCRIBE,
  },
  outputs: {
    getList: OUTPUTS_LIST,
    edit: OUTPUTS_EDIT,
  },
  orders: {
    getList: ORDER_LIST,
  },
  partners: {
    getListAll: PARTNERS_LIST_ALL,
    getList: PARTNERS_LIST,
    read: PARTNER_READ,
    create: PARTNER_CREATE,
    edit: PARTNER_EDIT,
    getShops: PARTNER_SHOPS_LIST,
  },
  payments: {
    getList: PAYMENTS_LIST,
    getStrategies: PAYMENT_STRATEGIES,
    export: PAYMENTS_EXPORT,
    exportStatus: PAYMENTS_EXPORT_STATUS,
    getBalance: PAYMENTS_BALANCE,
    download: PAYMENTS_DOWNLOAD,
  },
  payouts: {
    getPayoutsList: PAYOUTS_LIST,
    newPayout: PAYOUTS_NEW_PAYOUT,
    multiplePayouts: PAYOUTS_NEW_MULTIPLE_PAYOUTS,
    updatePayout: PAYOUTS_UPDATE_PAYOUT,
    deletePayout: PAYOUTS_DELETE_PAYOUTS,
    export: PAYOUTS_EXPORT,
    rocketWorkStatuses: PAYOUTS_ROCKET_WORK_STATUSES,
  },
  reactivation: {
    getList: REACTIVATION_TASK_LIST,
    getTimePeriods: REACTIVATION_TIME_PERIODS,
    getEmployeeStatuses: REACTIVATION_EMPLOYEE_STATUSES,
    getTaskStatuses: REACTIVATION_TASK_STATUSES,
    readTask: REACTIVATION_READ_TASK,
    updateTask: REACTIVATION_UPDATE_TASK,
    lockTask: REACTIVATION_LOCK_TASK,
    releaseTask: REACTIVATION_RELEASE_TASK,
  },
  regions: {
    getAll: REGIONS_ALL,
    getGeoAll: REGIONS_GEO_ALL,
    getList: REGIONS_LIST,
    read: REGIONS_READ,
    edit: REGIONS_EDIT,
    create: REGIONS_CREATE,
  },
  workMonths: {
    getYear: WORK_MONTHS_YEAR,
    updateYear: WORK_MONTHS_YEAR_UPDATE,
  },
  revise: {
    getShopList: REVISE_SHOP_LIST,
    getShopTotal: REVISE_SHOP_TOTAL,
    getShopReport: REVISE_SHOP_REPORT,
    downloadShopReport: REVISE_SHOP_REPORT_DOWNLOAD,
    getStatus: REVISE_STATUS,
    getDuration: REVISE_DURATION,
    readShiftEmployee: REVISE_READ_SHIFT_EMPLOYEE,
    editShiftEmployee: REVISE_EDIT_SHIFT_EMPLOYEE,
  },
  settings: {
    getList: HRM_SETTINGS_LIST,
    getSingleSetting: HRM_READ_SINGLE_SETTING,
    updateSingleSetting: HRM_UPDATE_SINGLE_SETTING,
  },
  shops: {
    getTotalList: SHOP_TOTAL_LIST,
    getList: SHOP_LIST,
    read: SHOP_READ,
    readMin: SHOP_READ_MIN,
    create: SHOP_CREATE,
    edit: SHOP_EDIT,
    delete: SHOP_DELETE,
    employees: SHOP_EMPLOYEES,
    favorite: SHOP_FAVORITE_EMPLOYEE,
    favoriteCreate: SHOP_FAVORITE_EMPLOYEE_CREATE,
    favoriteDelete: SHOP_FAVORITE_EMPLOYEE_DELETE,
    searchEmployee: SHOP_EMPLOYEE_SEARCH,
    searchEmployeeShift: SHOP_EMPLOYEE_SEARCH_SHIFT,
    shift: SHOP_SHIFT,
    shiftDeletePeriod: SHOP_SHIFT_DELETE_PERIOD,
    shiftDelete: SHOP_SHIFT_DELETE,
    calendar: SHOP_CALENDAR,
    shiftCreate: SHOP_SHIFT_CREATE,
    mostPopularTime: SHOP_SHIFT_POPULAR_TIME,
    getEmployeesList: SHOP_EMPLOYEES_LIST,
    countOfProcessed: SHOP_SHIFT_COUNT_PROCESSED,
    statistics: SHOP_STATISTICS,
    checkInCode: SHOP_CHECKIN_CODE,
    checkOutCode: SHOP_CHECKOUT_CODE,
    makeCheckIn: SHOP_MAKE_CHECK_IN,
    getPhones: SHOP_GET_PHONES,
    createComment: SHOP_CREATE_COMMENT,
    updateComment: SHOP_UPDATE_COMMENT,
    deleteComment: SHOP_DELETE_COMMENT,
    subscriptionList: SHOP_SUBSCRIPTION_LIST,
    subscriptionCreate: SHOP_SUBSCRIPTION_CREATE,
    subscriptionDelete: SHOP_SUBSCRIPTION_DELETE,
  },
  split: {
    getAll: SPLIT_ALL,
    getList: SPLIT_LIST,
    getStatisticList: SPLIT_STATISTIC_LIST,
    create: SPLIT_CREATE,
    edit: SPLIT_EDIT,
  },
  trainingCenter: {
    getList: TRAINING_CENTER_LIST,
    read: TRAINING_CENTER_READ,
    create: TRAINING_CENTER_CREATE,
    edit: TRAINING_CENTER_EDIT,
    delete: TRAINING_CENTER_DELETE,
  },
  user: {
    init: USER_INIT,
  },
  users: {
    getList: USERS_LIST,
    getChiefs: USER_CHIEFS_LIST,
    get: USER_GET,
    getAll: USER_ALL,
    create: USER_CREATE,
    edit: USER_UPDATE,
    getSecurityGroups: SECURITY_GROUPS_LIST,
    getAllSecurityGroups: SECURITY_GROUPS_ALL_LIST,
    readSecurityGroup: SECURITY_GROUP_READ,
    createSecurityGroup: SECURITY_GROUP_CREATE,
    updateSecurityGroup: SECURITY_GROUP_READ,
    deleteSecurityGroup: SECURITY_GROUP_DELETE,
    setSecurityGroupPermission: SECURITY_GROUP_SET_PERMISSIONS,
    getPermissionsList: SECURITY_GROUP_PERMISSION_LIST,
  },
  vacancies: {
    getAll: VACANCY_ALL,
    getAllByStore: VACANCY_ALL_BY_STORE,
    getList: VACANCY_LIST,
    read: VACANCY_READ,
    create: VACANCY_ADD,
    update: VACANCY_UPDATE,
    delete: VACANCY_DELETE,
    total: VACANCY_TOTAL,
    getVaccinationRequirements: VACANCY_VACCINATION_REQUIREMENTS,
    getAllWithClients: VACANCY_ALL_WITH_CLIENTS,
    getActiveByClients: VACANCY_ACTIVE_BY_CLIENTS,
  },
  templates: {
    getAll: TEMPLATE_ALL,
    getAllByStore: TEMPLATE_ALL_BY_STORE,
    getList: TEMPLATE_LIST,
    read: TEMPLATE_READ,
    create: TEMPLATE_ADD,
    update: TEMPLATE_UPDATE,
    delete: TEMPLATE_DELETE,
    total: TEMPLATE_TOTAL,
    getVaccinationRequirements: TEMPLATE_VACCINATION_REQUIREMENTS,
    getAllWithClients: TEMPLATE_ALL_WITH_CLIENTS,
    getActiveByClients: TEMPLATE_ACTIVE_BY_CLIENTS,
    getServiceType: SERVICE_TYPE_READ,
    getServiceTypesByStore: SERVICE_TYPES_BY_STORE,
    getUnitType: UNIT_TYPE_READ,
  },
};
