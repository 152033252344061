import { InputSuggestion } from '@/lib/formFactory/inputSuggestion.interface';
export default class InputSuggestionEntity {
  defaultValue: InputSuggestion;
  entityName: string;
  entity: any;

  constructor(name: string) {
    this.defaultValue = {
      key: name,
      type: 'input-suggestion',
      title: '',
      name: name,
      placeholder: '',
      value: '',
      current: {},
      hintsList: {},
      required: true,
      size: 200,
      error: {
        class: '',
        message: '',
      },
      validation: {
        empty: 'Поле не заполено',
      },
      autocomplete: 'off',
    };
    this.entityName = name;
    this.entity = { [this.entityName]: this.defaultValue };
  }

  setType(type: string) {
    this.entity[this.entityName]['type'] = type;
  }

  setTitle(title: string) {
    this.entity[this.entityName]['title'] = title;
  }

  setPlaceholder(placeholder: string) {
    this.entity[this.entityName]['placeholder'] = placeholder;
  }

  setRequired(required: boolean) {
    this.entity[this.entityName]['required'] = required;
  }

  setMask(mask: {
    regex?: string;
    mask?: string;
    alias?: string;
    inputFormat?: string;
    clearIncomplete?: boolean;
    placeholder?: string;
    minChars?: number;
    maxChars?: number;
  }) {
    this.entity[this.entityName]['mask'] = mask;
  }

  setValidation(validation: any) {
    this.entity[this.entityName]['validation'] = validation;
  }
}
