import * as Firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: 'AIzaSyCpCguGTO5P1yTtXOhyjX1cFjKu12usQrU',
  authDomain: 'gigant-employee.firebaseapp.com',
  databaseURL: 'https://gigant-employee.firebaseio.com',
  projectId: 'gigant-employee',
  storageBucket: 'gigant-employee.appspot.com',
  messagingSenderId: '353036639481',
  appId: '1:353036639481:web:250f19899a6c156cdb5628',
  measurementId: 'G-PQYDN6N8RG',
};
export const fireApp = Firebase.initializeApp(config);
