// import Vue, { PropOptions } from 'vue'
import { PropOptions, Vue } from 'vue-class-component';
import { createDecorator } from 'vue-class-component';
// import { Constructor } from 'vue/types/options'
import { applyMetadata } from '../helpers/metadata';

type Constructor = {
  new (...args: any[]): any;
};

/**
 * decorator of a prop
 * @param  options the options for the prop
 * @return PropertyDecorator | void
 */
export function Prop(options: PropOptions | Constructor[] | Constructor = {}) {
  return (target: Vue, key: string) => {
    applyMetadata(options, target, key);
    createDecorator((componentOptions, k) => {
      (componentOptions.props || ((componentOptions.props = {}) as any))[k] = options;
    })(target, key);
  };
}
