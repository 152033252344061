/**
 * Entity base module.
 *
 */

import { VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Input } from '@/lib/formFactory/input.interface';
import { setByPath } from '@/lib/Utils';
import moment from 'moment';
import { MultiSelect } from '@/lib/formFactory/multiSelect.interface';
import { clearPhone } from '@/lib/Utils';

export default abstract class EntityBaseModule extends VuexModule {
  id = '';
  isLoading = true;
  model: any;

  @Mutation
  SET_IS_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  SET_ID(id: string) {
    this.id = id;
  }

  @Mutation
  SET_IS_ACTIVE(bool: boolean) {
    this.model.isActive.list[0].checked = bool;
  }

  @Mutation
  SET_NAME(name: string) {
    this.model.name.value = name;
  }

  @Mutation
  SET_LAST_NAME(lastName: string) {
    this.model.lastName.value = lastName;
  }

  @Mutation
  SET_FIRST_NAME(firstName: string) {
    this.model.firstName.value = firstName;
  }

  @Mutation
  SET_MIDDLE_NAME(middleName: string) {
    this.model.middleName.value = middleName;
  }

  @Mutation
  SET_PHONE(phone: string) {
    this.model.phone.value = phone;
  }

  @Mutation
  SET_EMAIL(email: string) {
    this.model.email.value = email;
  }

  @Mutation
  SET_VACANCIES(vacancies: string[]) {
    this.model.vacancies = vacancies;
  }

  @Mutation
  SET_PARTY(party: string) {
    this.model.party.value = party;
  }

  @Mutation
  SET_BIRTHDAY(birthday: string) {
    this.model.birthday.value = birthday;
  }

  @Mutation
  SET_LEGAL_FORM(selected: {}) {
    this.model.legalForm.current = selected;
  }

  @Mutation
  SET_SYSTEM(selected: {}) {
    this.model.system.current = selected;
  }

  @Mutation
  SET_TIMES_OF_DAY(selected: {}) {
    this.model.timesOfDay.current = selected;
  }

  @Mutation
  SET_REGIONS(list: []) {
    this.model.region.list = list;
  }

  @Mutation
  SET_REGION(selected: {}) {
    this.model.region.current = selected;
  }

  @Mutation
  SET_CUSTOMER(selected: {}) {
    this.model.customer.current = selected;
  }

  @Mutation
  SET_CUSTOMERS(list: []) {
    this.model.customer.list = list;
  }

  @Mutation
  UPDATE_PHONE(params: { value: string; index: string }) {
    for (const phone in this.model.marketPhones) {
      if (this.model.marketPhones[phone].id === params.index) {
        this.model.marketPhones[phone].value = params.value;

        return;
      }
    }
  }

  @Mutation
  UPDATE_POSITION(selected: {}) {
    this.model.positions.list = selected;
  }

  @Mutation
  UPDATE_POSITION_HINTS(list: {}) {
    this.model.positions.hintsList = list;
  }

  @Mutation
  SET_COMPETENCE_LIST(list: {}) {
    this.model.competence.list = list;
  }

  @Mutation
  SET_COMPETENCE_VALUE(value: string) {
    this.model.competence.value = value;
  }

  @Mutation
  SET_COMPETENCE(params: { id: string; value: string }) {
    this.model.competence.current = params;
  }

  @Mutation
  SET_CODE(code: string) {
    this.model.code.value = code;
  }

  @Mutation
  UPDATE_ADDRESS_HINTS(address: any) {
    this.model.address.hintsList = address;
  }

  @Mutation
  SET_ADDRESS(address: any) {
    this.model.address.value = address.name;
    this.model.address.current = address;
  }

  @Mutation
  SET_ADDRESS_COORDINATES(coordinates: {}) {
    this.model.addressCoordinates.value = coordinates;
  }

  @Mutation
  UPDATE_ASSISTANT_HINTS(assistant: any) {
    this.model.assistant.hintsList = assistant;
  }

  @Mutation
  SET_ASSISTANT(assistant: any) {
    this.model.assistant.value = assistant.name;
    this.model.assistant.current = assistant;
  }

  @Mutation
  SET_INN(inn: string) {
    this.model.inn.value = inn;
  }

  @Mutation
  SET_KPP(kpp: string) {
    this.model.kpp.value = kpp;
  }

  @Mutation
  SET_BANK(bank: string) {
    this.model.bank.value = bank;
  }

  @Mutation
  SET_PAYMENT_ACCOUNT(value: string) {
    this.model.paymentAccount.value = value;
  }

  @Mutation
  SET_KS(ks: string) {
    this.model.ks.value = ks;
  }

  @Mutation
  SET_BIC(bic: string) {
    this.model.bic.value = bic;
  }

  @Mutation
  SET_BIC_SELECTED(bic: any) {
    this.model.bic.value = bic.value;
    this.model.bic.current = bic;
  }

  @Mutation
  UPDATE_BIC_HINTS(banks: any) {
    this.model.bic.hintsList = banks;
  }

  @Mutation
  SET_CREATED_AT(date: string) {
    this.model.createdAt = date;
  }

  @Mutation
  SET_UPDATED_AT(date: string) {
    this.model.updatedAt = date;
  }

  @Mutation
  SET_GENDER(value: string) {
    this.model.gender.value = value;
  }

  @Mutation
  SET_UUID(value: string) {
    this.model.uuid = value;
    this.model.link = `${location.origin}/employee/${value}`;
  }

  @Mutation
  SET_AMOUNT(amount: string | number) {
    this.model.amount.value = amount;
  }

  @Mutation
  SET_API_ID(apiId: string | number) {
    this.model.apiId.value = apiId;
  }

  @Mutation
  SET_API_HASH(apiHash: string) {
    this.model.apiHash.value = apiHash;
  }

  @Mutation
  SET_WEEKS(weeks: string | number) {
    this.model.weeks.value = weeks;
  }

  @Mutation
  SET_RATE(rate: string) {
    this.model.rate.value = rate;
  }

  @Mutation
  SET_EXTERNAL_RATE(externalRate: string) {
    this.model.externalRate.value = externalRate;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    let _this: any = null;
    _this = this;

    _this.model[params.key] = params.value;
  }

  @Mutation
  SET_ERROR_BY_FIELD(entity: { field: string; message: string }) {
    setByPath(this.model, `${entity.field}/error/class`, 'cp-error');
    setByPath(this.model, `${entity.field}/error/message`, entity.message);
  }

  @Mutation
  RESET_ERROR_BY_FIELD(field: string) {
    setByPath(this.model, `${field}/error/class`, '');
    setByPath(this.model, `${field}/error/message`, '');
  }

  @Action({ rawError: true })
  setModelValue(params: { key: string; value: string | boolean }) {
    this.context.commit('SET_MODEL_VALUE', params);
  }

  @Action({ rawError: true })
  updateName(name: string) {
    this.context.commit('SET_NAME', name);

    if (this.model.name.required) {
      this.context.dispatch('setValidateState', { index: 'name', field: this.model.name });
    }
  }

  @Action({ rawError: true })
  updateRate(rate: string) {
    this.context.commit('SET_RATE', rate);

    if (this.model.rate.required) {
      this.context.dispatch('setValidateState', { index: 'rate', field: this.model.rate });
    }
  }

  @Action({ rawError: true })
  updateExternalRate(externalRate: string) {
    this.context.commit('SET_EXTERNAL_RATE', externalRate);

    if (this.model.externalRate.required) {
      this.context.dispatch('setValidateState', { index: 'externalRate', field: this.model.externalRate });
    }
  }

  @Action({ rawError: true })
  updateLastName(lastName: string) {
    this.context.commit('SET_LAST_NAME', lastName);

    if (this.model.lastName.required) {
      this.context.dispatch('setValidateState', { index: 'lastName', field: this.model.lastName });
    }
  }

  @Action({ rawError: true })
  updateFirstName(firstName: string) {
    this.context.commit('SET_FIRST_NAME', firstName);

    if (this.model.firstName.required) {
      this.context.dispatch('setValidateState', { index: 'firstName', field: this.model.firstName });
    }
  }

  @Action({ rawError: true })
  updateMiddleName(middleName: string) {
    this.context.commit('SET_MIDDLE_NAME', middleName);
  }

  @Action({ rawError: true })
  updateBirthday(birthday: string) {
    this.context.commit('SET_BIRTHDAY', birthday);
  }

  @Action({ rawError: true })
  updatePhone(phone: any) {
    this.context.commit('SET_PHONE', phone);
    this.context.dispatch('setValidatePhone', { index: 'phone', field: this.model.phone });
  }

  @Action({ rawError: true })
  updateEmail(email: string) {
    this.context.commit('SET_EMAIL', email);
  }

  @Action({ rawError: true })
  updateApiId(apiId: string | number) {
    this.context.commit('SET_API_ID', apiId);
  }

  @Action({ rawError: true })
  updateApiHash(apiHash: string) {
    this.context.commit('SET_API_HASH', apiHash);
  }

  @Action({ rawError: true })
  updateComment(comment: string) {
    this.context.commit('SET_COMMENT', comment);
  }

  @Action({ rawError: true })
  updateVacancies(vacancies: { id: number; name: string }[]) {
    this.context.commit('SET_VACANCIES', vacancies);
  }

  @Action({ rawError: true })
  updateParty(party: string) {
    this.context.commit('SET_PARTY', party);
  }

  @Action({ rawError: true })
  updateRegionsList(list: {}) {
    this.context.commit('SET_REGIONS', list);
  }

  @Action({ rawError: true })
  updateRegion(selected: {}) {
    this.context.commit('SET_REGION', selected);
  }

  @Action({ rawError: true })
  updateInn(inn: string) {
    this.context.commit('SET_INN', inn);
  }

  @Action({ rawError: true })
  updateKpp(kpp: string) {
    this.context.commit('SET_KPP', kpp);
  }

  @Action({ rawError: true })
  updateBank(bank: string) {
    this.context.commit('SET_BANK', bank);
  }

  @Action({ rawError: true })
  updatePaymentAccount(value: string) {
    this.context.commit('SET_PAYMENT_ACCOUNT', value);
  }

  @Action({ rawError: true })
  updateKS(ks: string) {
    this.context.commit('SET_KS', ks);
  }

  @Action({ rawError: true })
  updateBic(bic: string) {
    this.context.commit('SET_BIC', bic);
  }

  @Action({ rawError: true })
  updateIsLoading(isLoading: boolean) {
    this.context.commit('SET_IS_LOADING', isLoading);
  }

  @Action({ rawError: true })
  updateIsActive(bool: boolean | null) {
    this.context.commit('SET_IS_ACTIVE', bool);
  }

  @Action({ rawError: true })
  updateAmount(amount: string | number) {
    this.context.commit('SET_AMOUNT', amount);
  }

  @Action({ rawError: true })
  updateWeeks(weeks: string | number) {
    this.context.commit('SET_WEEKS', weeks);
  }

  @Action({ rawError: true })
  processDataForMultiSelect(params: { data: any; key: string; handlerList: string; handlerValue: string }) {
    type dataItem = {
      createdAt: string;
      id: number;
      isActive: boolean | null;
      name: string;
      updatedAt: string;
      hasOverNeed?: boolean | null;
    };

    let value = '';
    const values: string[] = [];
    const multiSelectList = (this.model[params.key] as MultiSelect).list;
    const resultList = Object.values(multiSelectList).map((resultItem: { id: number; name: string; selected: boolean }) => {
      const match = params.data.filter((item: dataItem) => item.id === resultItem.id)[0];
      if (match) {
        resultItem.selected = true;
        values.push(resultItem.name);
      }

      return resultItem;
    });

    value = values.length > 0 ? values.join(', ') : '';

    this.context.commit(params.handlerList, resultList);
    this.context.commit(params.handlerValue, value);
  }

  @Action({ rawError: true })
  setValidateEmail(entity: { index: string; field: any }) {
    if (
      entity.field.value &&
      entity.field.value.length > 0 &&
      !new RegExp(entity.field.mask.regex).test(entity.field.value)
    ) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.regex });
    } else if (entity.field.value) {
      this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }
  }

  @Action({ rawError: true })
  setValidateByRegex(entity: { index: string; field: any }) {
    if (
      entity.field.value &&
      entity.field.value.length > 0 &&
      !new RegExp(entity.field.mask.regex).test(entity.field.value)
    ) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.regex });
    } else if (entity.field.value) {
      this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }
  }

  @Action({ rawError: true })
  setValidateState(entity: { index: string; field: Input }) {
    if (!entity.field.value) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
    } else {
      this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }
  }

  @Action({ rawError: true })
  setValidatePhone(params: { index: string; field: any }) {
    // TODO:: issue params autoUnmask
    let phoneValue = clearPhone(params.field.value);
    phoneValue = phoneValue.substr(0, 1) === '7' && !params.field.mask.autoUnmask ? phoneValue.substr(1) : phoneValue;

    if ((params.field.required || phoneValue) && phoneValue.length !== params.field.mask.minChars) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.length });
    } else {
      this.context.commit('RESET_ERROR_BY_FIELD', params.index);
    }
  }

  @Action({ rawError: true })
  setValidateBirthday(params: { index: string; field: any }) {
    this.context.commit('RESET_ERROR_BY_FIELD', params.index);
    if (!params.field.value) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });

      return;
    }
    if (params.field.value.match(/\d{2}\.\d{2}\.\d{4}/) === null) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.regex });

      return;
    }

    const ageLimitMin = moment().startOf('day').subtract(18, 'year');
    const ageLimitMax = moment().startOf('day').subtract(100, 'year');

    const birthday = moment(params.field.value, 'DD.MM.YYYY');
    const isValid = birthday.isSameOrBefore(ageLimitMin) && birthday.isSameOrAfter(ageLimitMax);

    if (isValid === false) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.limit });
    }
    if (isValid === true) {
      this.context.commit('RESET_ERROR_BY_FIELD', params.index);
    }
  }

  @Action({ rawError: true })
  setValidateSearchInput(params: { index: string; field: any }) {
    if (Object.values(params.field.list).length === 0) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });
    } else if (Object.values(params.field.list).length > 0) {
      this.context.commit('RESET_ERROR_BY_FIELD', params.index);
    }
  }

  @Action({ rawError: true })
  setValidatePaymentAccount(params: { index: string; field: any }) {
    if (!params.field.value) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.empty });

      return;
    }
    if (params.field.value[0] !== '4') {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.regex });

      return;
    }
    if (params.field.value.length !== params.field.mask.minChars) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: params.index, message: params.field.validation.length });

      return;
    }

    this.context.commit('RESET_ERROR_BY_FIELD', params.index);
  }

  @Action({ rawError: true })
  setValidateSelect(entity: { index: string; field: any }) {
    if (!entity.field.current.value || entity.field.current.value.length === 0) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
    } else {
      this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }
  }

  @Action({ rawError: true })
  resetError(field: string) {
    this.context.commit('RESET_ERROR_BY_FIELD', field);
  }
}
