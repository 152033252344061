/**
 * Customers helpers store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getClientGeoHelperById } from '@/api/geo';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

export const MODULE_NAME = 'customersHelpers';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersHelpersModule extends PageBaseModule {
  customerId = '';

  constructor(module: CustomersHelpersModule) {
    super(module);

    const page = new PageEntity();
    page.setTitleAdd('Добавление территориального помощника');
    page.values.actionPagination = 'customersHelpers/updatePage';
    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        fullName: 'edit',
      },
    };
  }

  @Mutation
  SET_CUSTOMER_ID(id: string) {
    this.customerId = id;
  }

  @Action({ rawError: true })
  async initListById(customerId: string) {
    await this.context.commit('SET_CUSTOMER_ID', customerId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getListById(customerId);
  }

  @Action({ rawError: true })
  async getListById(customerId: string) {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getClientGeoHelperById(customerId, this.pageSettings.pageCurrent, itemsQuery);
      this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response?.data?.message ?? error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const tableInfo = uiTable
      .removeColumn('firstName')
      .removeColumn('middleName')
      .removeColumn('lastName')

      .addColumnByIndex(0, { id: 'fullName', name: 'ФИО', visible: true })
      .getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getListById(this.customerId);
  }
}

export default getModule(CustomersHelpersModule);
