/**
 * Global API for shop.
 *
 */

import API from '@/config/api-routers';
import { makeGetFileRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { PaymentsExportStatusResponse } from '@/store/finance/payments/export';
import { HumanType } from '@/interfaces/humanType.interface';

/**
 * Get shops list.
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_FINANCE_PAYMENT
 */
export const getPaymentsList = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.payments.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Export payments list
 *
 * @param filter
 *
 * @access CAN_PAYOUT_FINANCE_PAYOUT
 */
export const exportPayments = async (filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.payments.export);
  const url = assemblerUrl.addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

export const getLastExportStatus = async (): Promise<PaymentsExportStatusResponse> => {
  const result = await makeGetRequest(API.payments.exportStatus);

  return result.data;
};

export const getBalance = async () => {
  const result = await makeGetRequest(API.payments.getBalance);

  return result.data.result;
};

/**
 * Download payments
 *
 * @param filter
 *
 * @access CAN_READ_FINANCE_PAYMENT
 */
export const download = async (filter: any) => {
  const url = `${API.payments.download}${filter}`;

  return await makeGetFileRequest(url);
};

export const getPaymentStrategies = async (): Promise<{
  defaultStrategy: string;
  paymentStrategies: HumanType[];
}> => {
  const url = API.payments.getStrategies;
  const result = await makeGetRequest(url, { withCache: true });

  return result.data;
};
