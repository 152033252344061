import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
// import SettingsModule from '@/store/settings';
import CardsDatabaseEntity from '@/store/cards/database/databaseEntity';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.name === 'cards_database_edit') {
    if (!Object.values(CardsDatabaseEntity.editCard).length) {
      next({ name: 'cards_database' });
    }
  }

  next();
};

export default guard;
