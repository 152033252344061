import { computed, watch } from 'vue';
import { Ref, ComputedRef } from '@vue/reactivity/dist/reactivity';

export interface FormInputErrorParams {
  emit: (attr1: string, attr2: unknown) => void;
  error: Ref<string | boolean>;
  errorMessages: Ref<string | unknown[]>;
  validate: Ref<boolean>;
  validateMessage: Ref<string | string[]>;
  rules: Ref<boolean>;
  rulesMessage: Ref<string | string[]>;
}

export interface FormInputErrorResult {
  result: ComputedRef<boolean>;
  messages: ComputedRef<string | unknown[]>;
}

export default function formInputError(params: FormInputErrorParams): FormInputErrorResult {
  const result = computed(() => {
    if (params.error.value) {
      return true;
    }

    if (Array.isArray(params.errorMessages.value) && params.errorMessages.value.length) {
      return true;
    }

    if (params.errorMessages.value) {
      return true;
    }

    if (!params.rules.value) {
      return true;
    }

    if (!params.validate.value) {
      return true;
    }

    return false;
  });

  const messages = computed(() => {
    if (Array.isArray(params.errorMessages.value) && params.errorMessages.value.length) {
      return params.errorMessages.value.join(', ');
    }

    if (params.errorMessages.value) {
      return params.errorMessages.value;
    }

    if (params.rulesMessage.value) {
      return params.rulesMessage.value;
    }

    if (params.validateMessage.value) {
      return params.validateMessage.value;
    }

    return '';
  });

  watch(result, () => {
    params.emit('update:isError', {
      result: result.value,
      messages: messages.value,
    });
  });

  return {
    result,
    messages,
  };
}
