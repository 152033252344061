/**
 * Global site state.
 *
 */

import { AxiosError } from 'axios';
import { VuexModule, Action, Mutation, Module, getModule } from 'vuex-module-decorators';
import { UNAUTHORIZED, FORBIDDEN } from '@/lib/HttpStatusCodes';

import { getSiteInit } from '@/api/site';
import store from '@/store';
import userModule from './user';
import menuModule from './menu/index';
import { initMessaging } from '@/lib/Firebase';
import { identifyUser as identifyUserInSentry } from '@/lib/Sentry';

@Module({ dynamic: true, store, name: 'site', namespaced: true })
class SiteModule extends VuexModule {
  errors: string[] = [];
  isGlobalError = false;
  isBlock = false;
  menuItems = {};

  @Mutation
  setMenuItems() {
    this.menuItems = menuModule.menuItems;
  }

  @Mutation
  clearMenu() {
    this.menuItems = {};
  }

  @Mutation
  addError(error: string) {
    this.errors.push(error);
  }

  @Mutation
  setGlobalError(isGlobalError: boolean) {
    this.isGlobalError = isGlobalError;
  }

  @Mutation
  SET_IS_BLOCK(bool: boolean) {
    this.isBlock = bool;
  }

  @Action
  setIsBlock(bool: boolean) {
    this.context.commit('SET_IS_BLOCK', bool);
  }

  @Action
  fetchApiResponseError(error: AxiosError) {
    if (error.response?.status === FORBIDDEN) {
      this.context.commit('addError', 'Ошибка доступа');

      return;
    }

    if (error.response?.status === UNAUTHORIZED) {
      this.context.commit('addError', 'Ошибка доступа');
      userModule.setAuthenticated(false);

      return;
    }

    this.context.commit('addError', error.response?.data.message ?? 'Упс... Неизвестная ошибка на сервере');
  }

  @Action
  fetchApiRequestError(error: AxiosError) {
    this.context.commit('addError', error.response?.data.message ?? 'Упс... Неизвестная сетевая ошибка');
  }

  @Action
  async initUser() {
    const { user, permissions, notifySubscriptions } = await getSiteInit();
    await userModule.setUser({ user, permissions, notifySubscriptions });
    userModule.setAuthenticated(true);

    return user;
  }

  @Action
  async init() {
    try {
      const user = await this.initUser();

      identifyUserInSentry({
        id: `${user.id}`,
        username: `${user.lastName} ${user.firstName}`,
        email: user.email ?? '',
      });

      await menuModule.initMenuItems();
      await menuModule.updateMenuItemsHints();
      await initMessaging();

      this.context.commit('setMenuItems');
    } catch (error) {
      userModule.setToken('');
      if (error.response?.status !== UNAUTHORIZED) {
        this.context.commit('setGlobalError', false);
      }
    }
  }
}

export default getModule(SiteModule);
