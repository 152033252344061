/**
 * Reset mailing model
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import MailingModule from '@/store/settings/mailings/index';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.name === 'mailings_add' || to.name === 'mailings_edit') {
    MailingModule.resetModel();
  }

  next();
};

export default guard;
