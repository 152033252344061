/*
 *
 * */

import { InputDatePicker } from '@/lib/formFactory/inputDatePicker.interface';
export default class InputDatePickerEntity {
  defaultValue: InputDatePicker;
  entityName: string;
  entity: any;

  constructor(name: string) {
    this.defaultValue = {
      key: name,
      type: 'text',
      title: '',
      value: '',
      userValue: '',
      defaultValue: '',
      action: '',
      required: false,
      size: 40,
      mask: {
        regex: '\\d*',
        placeholder: ' ',
        minChars: 1,
      },
      error: {
        class: '',
        message: '',
      },
      validation: {
        empty: 'Поле не заполено',
      },
    };
    this.entityName = name;
    this.entity = { [this.entityName]: this.defaultValue };
  }

  setType(type: string) {
    this.entity[this.entityName]['type'] = type;
  }

  setTitle(title: string) {
    this.entity[this.entityName]['title'] = title;
  }

  setPlaceholder(placeholder: string) {
    this.entity[this.entityName]['placeholder'] = placeholder;
  }

  setRequired(required: boolean) {
    this.entity[this.entityName]['required'] = required;
  }

  setMask(mask: {
    regex?: string;
    mask?: string;
    alias?: string;
    inputFormat?: string;
    clearIncomplete?: boolean;
    placeholder?: string;
    minChars?: number;
  }) {
    this.entity[this.entityName]['mask'] = mask;
  }

  setValidation(validation: any) {
    this.entity[this.entityName]['validation'] = validation;
  }

  setSize(size: number) {
    this.entity[this.entityName]['size'] = size;
  }

  setAction(action: string) {
    this.entity[this.entityName]['action'] = action;
  }
}
