/**
 * Response Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { CatchFormResponse } from '@/interfaces/shared';

export const MODULE_NAME = 'responseHandler';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ResponseHandlerModule extends VuexModule {
  isShow = false;
  message = '';
  type = '';

  @Mutation
  SET_ERROR(message: string) {
    this.message = message;
  }

  @Mutation
  SET_SHOW_STATE(payload: { isShow: boolean; type: string }) {
    this.isShow = payload.isShow;
    this.type = payload.type;
  }

  @Action({ rawError: true })
  showNotify(payload: { message: string; type: string; defaultMessage?: string }) {
    const that = this;
    this.context.commit('SET_ERROR', payload.message || payload.defaultMessage || '');
    this.context.commit('SET_SHOW_STATE', { isShow: true, type: payload.type });

    setTimeout(function () {
      that.context.dispatch('hideNotify');
    }, 4000);
  }

  @Action({ rawError: true })
  hideNotify() {
    this.context.commit('SET_ERROR', '');
    this.context.commit('SET_SHOW_STATE', { isShow: false, type: '' });
  }

  @Action({ rawError: true })
  handleResponseError({
    error,
    defaultMessage,
    checkErrorFields = false,
    rethrowStrategy = 'none',
  }: {
    error: unknown;
    defaultMessage?: string;
    checkErrorFields?: boolean;
    rethrowStrategy?: 'none' | 'emptyMessage' | 'anyway';
  }) {
    const errorFields = (error as CatchFormResponse)?.response?.data?.errors?.fields;
    const errorFieldValues = errorFields && Object.values(errorFields);

    let errorMessage = (error as CatchFormResponse)?.response?.data?.message;

    if (checkErrorFields && errorFieldValues?.length) {
      errorMessage = errorFieldValues.join('. ');
    }

    errorMessage ||= defaultMessage ?? '';

    if (errorMessage) {
      this.context.dispatch('showNotify', { message: errorMessage, type: 'fail' });
    } else if (rethrowStrategy === 'emptyMessage') {
      throw error;
    }

    if (rethrowStrategy === 'anyway') {
      throw error;
    }
  }
}

export default getModule(ResponseHandlerModule);
