/**
 * Finance common store.
 *
 */

import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import { tabsNav } from './tabs';

import PageEntity from '@/lib/layouts/page/pageEntity';
import userModule from '@/store/user';
import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const MODULE_NAME = 'settings';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class SettingsModule extends PageBaseModule {
  allTabs = tabsNav;
  tabsNav: TabsNavInterface = {};
  isAdd = false;
  isEdit = false;

  constructor(module: SettingsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('');
    this.pageSettings = page.values;
  }

  @Mutation
  SET_IS_ADD(isAdd: boolean) {
    this.isAdd = isAdd;
  }

  @Mutation
  SET_IS_EDIT(isEdit: boolean) {
    this.isEdit = isEdit;
  }

  @Mutation
  SET_TABS_NAV(list: { [index: string]: {} }) {
    this.tabsNav = list as TabsNavInterface;
  }

  @Action({ rawError: true })
  reset() {
    this.SET_IS_ADD(false);
    this.SET_IS_EDIT(false);
  }

  @Action({ rawError: true })
  initTabsItems() {
    const tabsByUser: { [index: string]: {} } = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(tabsNav)) {
      if (userModule.canUserRead(menuItem.pathName)) {
        tabsByUser[menuItemKey] = menuItem;
      }
    }

    this.SET_TABS_NAV(tabsByUser);
  }
}

export default getModule(SettingsModule);
