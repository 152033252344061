/**
 * Global API for partners.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from '@/api/requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { FormData, FormResponse } from '@/interfaces/shared';
import { PartnerResponseModel, PartnerSelectListItem } from '@/interfaces/partner.interface';

/**
 * Get partners all
 *
 * @access CAN_GET_ALL_PARTNERS
 */
export const getAllPartnersList = async (): Promise<PartnerSelectListItem[]> => {
  const url = API.partners.getListAll;
  const result = await makeGetRequest(url, { withCache: true });

  return result.data.result.partners;
};

/**
 * Get partners list.
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_GET_ALL_PARTNERS
 */
export const getPartnersList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.partners.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get partner by id
 *
 * @param partnerId
 *
 * @access CAN_READ_PARTNER
 */
export const getPartnerById = async (partnerId: string): Promise<PartnerResponseModel> => {
  const url = API.partners.read.replace('$1', partnerId);
  const result = await makeGetRequest(url);

  return result.data.result.partner;
};

/**
 * Add new partner
 *
 * @param data
 *
 * @access CAN_EDIT_PARTNER
 */
export const addNewPartner = async (data: any) => {
  const url = API.partners.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save partner
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_PARTNER
 */
export const savePartner = async (id: string, data: FormData): Promise<FormResponse> => {
  const url = API.partners.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get partner's shops list
 *
 * @param pageCurrent
 * @param filter
 * @param pageAmountItems
 * @param sort
 *
 * @access CAN_READ_PARTNER
 */
export const getShopsListByPartnerId = async (
  pageCurrent: number,
  filter: string,
  pageAmountItems: string,
  sort: string
) => {
  const assemblerUrl = new AssemblerUrl(API.partners.getShops);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};
