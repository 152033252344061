/**
 * Reset region model.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import trainingCenterEntityModule from '@/store/settings/trainingCenter/entity';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.name === 'training_center_add' || to.name === 'training_center_edit') {
    trainingCenterEntityModule.resetModel();
  }

  next();
};

export default guard;
