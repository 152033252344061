import { NavigationGuard } from 'vue-router';

const guard: NavigationGuard = (to, _from, next): void => {
  const max32BitInteger = 0x7fffffff;

  if (!Array.isArray(to.meta.requiresNumericParams)) {
    next();
  }

  const normalizedParams: Record<string, string> = {};
  for (const paramForCheck of to.meta.requiresNumericParams as string[]) {
    const isExistsParameter = Object.prototype.hasOwnProperty.call(to.params, paramForCheck);
    const passedParam = to.params[paramForCheck];

    if (!isExistsParameter && typeof passedParam !== 'string') {
      return next({ name: 'not_found' });
    }

    const parsedParam = parseInt(passedParam as string);
    if (isNaN(parsedParam) || parsedParam > max32BitInteger) {
      return next({ name: 'not_found' });
    }

    const stringifiedParsedParam = parsedParam.toString();
    if (stringifiedParsedParam !== passedParam) {
      normalizedParams[paramForCheck] = stringifiedParsedParam;
    }
  }

  if (!Object.keys(normalizedParams).length) {
    next();
  }

  next({
    ...to,
    params: {
      ...to.params,
      ...normalizedParams,
    },
  });
};

export default guard;
