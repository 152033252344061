/**
 * Guard check if user have access rights to block (menu item).
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';

import userModule from '@/store/user';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.meta.isBlock && !userModule.isBlockAllowed(to.name as string)) {
    next({ name: to.name ?? undefined });
  } else {
    next();
  }
};

export default guard;
