import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  profile: {
    id: 'profile',
    name: 'Профиль',
    template: 'profile',
    pathName: 'customer_edit',
    icon: 'clients',
    iconSize: '15',
  },
  shops: {
    id: 'shops',
    name: 'Объекты',
    template: 'shops',
    pathName: 'customers_shops',
    icon: 'store',
    iconSize: '14',
  },
  templates: {
    id: 'templates',
    name: 'Шаблоны заказов',
    template: 'templates',
    pathName: 'customers_vacancies',
    icon: 'book',
    iconSize: '15',
  },
  helpers: {
    id: 'helpers',
    name: 'Терр. помощники',
    template: 'helpers',
    pathName: 'customers_helpers',
    icon: 'user',
    iconSize: '15',
  },
  statistics: {
    id: 'statistics',
    name: 'Статистика',
    template: 'statistics',
    pathName: 'customers_statistics',
    icon: 'analytics',
    iconSize: '16',
  },
  logs: {
    id: 'logs',
    name: 'Логи',
    template: 'logs',
    pathName: 'customers_logs',
    icon: 'log',
    iconSize: '15',
  },
};
