
import { Component, Vue, Prop } from '@/lib/decorator';
import IconFont from '@/components/icons/IconFont.vue';
import { EventBus } from '@/lib/event-bus';
import { GuiFormButton } from '@library/gigant_ui';

interface AdditionalIcon {
  iconName: string;
  eventType: string;
}

@Component({
  components: {
    GuiFormButton,
    IconFont,
  },
  emits: ['click'],
})
export default class Modal extends Vue {
  @Prop({ required: false }) readonly title?: string;
  @Prop({ required: false }) readonly notification?: string;
  @Prop({ required: false }) readonly additionalIcon?: AdditionalIcon;
  @Prop({ required: false }) readonly withoutTitle?: boolean;
  @Prop({ required: false }) readonly absoluteCenter?: boolean;
  @Prop({ required: false }) readonly uuid?: string;

  isCopyLink = false;

  closeModal() {
    this.$emit('click');
    this.$emit('closeModal');
  }

  additionalAction() {
    if (this.additionalIcon?.eventType) {
      this.$emit(this.additionalIcon.eventType);
    }
  }

  funcCopyLink() {
    this.isCopyLink = !this.isCopyLink;
    const path =
      location.origin.indexOf('https') != -1
        ? location.origin.replace('hrm', 'zakaz')
        : process.env.VUE_APP_DEMO_DOMAIN.replace('hrm', 'zakaz');
    const link = path + '/shift/' + this.uuid;
    navigator.clipboard.writeText(link).then(() => {
      setTimeout(() => {
        this.isCopyLink = !this.isCopyLink;
      }, 1500);
    });
  }

  created() {
    EventBus.$on('pressEsc', this.closeModal);
  }

  unmounted() {
    EventBus.$off('pressEsc', this.closeModal);
  }
}
