import { watch, ref, inject } from 'vue';
import { clearPhone } from '@/lib/Utils';
import moment from 'moment';
import { Ref } from '@vue/reactivity';

export interface ParamsValidateMessages {
  empty?: string;
  phone?: string;
  email?: string;
  minChars?: string;
  maxChars?: string;
  rangeChars?: string;
}

export interface FormInputValidateParams {
  type?: string;
  value: Ref<string | number>;
  validateOff: boolean;
  required: boolean;
  validateMessages?: ParamsValidateMessages;
  minChars?: string | number;
  maxChars?: string | number;
}

export interface FormInputValidateResult {
  value: Ref<string | number>;
  validate: Ref<boolean>;
  validateMessage: Ref<string>;
}

export default function formInputValidate(params: FormInputValidateParams): FormInputValidateResult {
  const trigger: any = inject('validateInputs');
  const value = params.value;

  const validateMessages = {
    empty: params.validateMessages?.empty ?? 'Поле не заполнено',
    phone: params.validateMessages?.phone ?? 'Некорректный телефон',
    email: params.validateMessages?.email ?? 'Невалидный Email',
    rangeChars: params.validateMessages?.rangeChars ?? `Не менее ${params.minChars} и не более ${params.maxChars} символов`,
    minChars: params.validateMessages?.minChars ?? `Не менее ${params.minChars} символов`,
    maxChars: params.validateMessages?.maxChars ?? `Не более ${params.maxChars} символов`,
    birthday: {
      regex: 'Некорректная дата',
      limit: 'Возраст от 18 до 100 лет',
    },
    date: 'Некорректная дата',
    number: 'Допустимые символы: 0-9',
  };

  const validate = ref(true);
  const validateMessage = ref('');

  //patterns
  const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const patternNumber = /^\d+$/;

  const setError = (message: string) => {
    validate.value = false;
    validateMessage.value = message;
  };

  const setSuccess = () => {
    validate.value = true;
    validateMessage.value = '';
  };

  const setValidate = () => {
    if (params.validateOff) {
      return false;
    }

    let tmpValue = value.value;
    if (typeof tmpValue !== 'string') {
      tmpValue = value.value.toString();
    }

    //empty
    if (params.required && !tmpValue.length) {
      setError(validateMessages.empty);

      return;
    }

    //phone
    if (params.type === 'phone' && !params.required && !tmpValue.length) {
      setSuccess();

      return;
    }

    if (params.type === 'phone' && clearPhone(tmpValue).length != 11) {
      setError(validateMessages.phone);

      return;
    }

    //email
    if (params.type === 'email' && !params.required && !tmpValue.length) {
      setSuccess();

      return;
    }

    if (params.type === 'email' && !patternEmail.test(tmpValue)) {
      setError(validateMessages.email);

      return;
    }

    // number
    if (params.type === 'number' && tmpValue.length !== 0 && !patternNumber.test(tmpValue)) {
      setError(validateMessages.number);

      return;
    }

    //range-chars
    if ((params.minChars || params.maxChars) && !params.required && !tmpValue.length) {
      setSuccess();

      return;
    }

    if (params.minChars && params.maxChars && (tmpValue.length < +params.minChars || tmpValue.length > +params.maxChars)) {
      setError(validateMessages.rangeChars);

      return;
    }

    //min-chars
    if (params.minChars && tmpValue.length < +params.minChars) {
      setError(validateMessages.minChars);

      return;
    }

    //max-chars
    if (params.maxChars && tmpValue.length > +params.maxChars) {
      setError(validateMessages.maxChars);

      return;
    }

    //birthday
    if (params.type === 'birthday' && !params.required && !tmpValue.length) {
      setSuccess();

      return;
    }

    if (params.type === 'birthday' && tmpValue.match(/\d{2}\.\d{2}\.\d{4}/) === null) {
      setError(validateMessages.birthday.regex);

      return;
    }

    if (params.type === 'birthday') {
      const ageLimitMin = moment().startOf('day').subtract(18, 'year');
      const ageLimitMax = moment().startOf('day').subtract(100, 'year');

      const birthday = moment(value.value, 'DD.MM.YYYY');
      const isValid = birthday.isSameOrBefore(ageLimitMin) && birthday.isSameOrAfter(ageLimitMax);

      if (!isValid) {
        setError(validateMessages.birthday.limit);

        return;
      }
    }

    if (params.type === 'date' && !params.required && !tmpValue.length) {
      setSuccess();

      return;
    }

    if (params.type === 'date' && tmpValue.match(/\d{2}\.\d{2}\.\d{4}/) === null) {
      setError(validateMessages.date);

      return;
    }

    setSuccess();
  };

  watch(value, setValidate);
  watch(trigger, setValidate);

  return {
    value,
    validate,
    validateMessage,
  };
}
