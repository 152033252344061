import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class PayoutsFilter {
  filterHandlers = {
    update: 'payoutsFilter/updateFilter',
    reset: 'payoutsFilter/resetFilter',
    resetState: 'payoutsFilter/resetFilterState',
    removeSelected: 'payoutsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fio');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setClassName('fullName', 'col-1-row-1');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setAction('fullName', 'payoutsFilter/updateSearch');

    filter.createMultiSelectEntity('rocketWorkStatuses');
    filter.setTitle('rocketWorkStatuses', 'По статусу РВ');
    filter.setName('rocketWorkStatuses', 'multi_select_rocket_work_status');
    filter.setPreviewName('rocketWorkStatuses', 'Статус РВ');
    filter.setInitAction('rocketWorkStatuses', 'payouts/initRocketWorkStatuses');
    filter.setClassName('rocketWorkStatuses', 'col-2-row-1');
    filter.setIconClassName('rocketWorkStatuses', 'icon-payout');
    filter.setAction('rocketWorkStatuses', 'payoutsFilter/updateMultiSelect');

    filter.createSelectEntity('partnerUuid');
    filter.setTitle('partnerUuid', 'По партнёру');
    filter.setName('partnerUuid', 'select_partnerUuid');
    filter.setPreviewName('partnerUuid', 'По партнёру');
    filter.setInitAction('partnerUuid', 'payouts/initPartnersFilter');
    filter.setClassName('partnerUuid', 'col-1-row-2');
    filter.setIconClassName('partnerUuid', 'icon-partners');
    filter.setAction('partnerUuid', 'payoutsFilter/updateSelect');
    filter.setSearchEnabled('partnerUuid', true);
    watch(
      () => UserModule.securityGroup?.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partnerUuid', !isSupervisor);
        filter.setClassName('isNotTest', isSupervisor ? 'col-1-row-3' : 'col-1-row-2');
      }
    );

    filter.createRadioEntity('hasVoucher');
    filter.setTitle('hasVoucher', 'По Наличию чека');
    filter.setName('hasVoucher', 'radio_voucher');
    filter.setPreviewName('hasVoucher', 'Чек');
    filter.setIgnoreNull('hasVoucher', true);
    filter.setList('hasVoucher', [
      { id: 0, name: 'Есть чек', checked: false, value: 'true' },
      { id: 1, name: '', checked: false },
      { id: 2, name: 'Нет чека', checked: false, value: 'false' },
    ]);
    filter.setAction('hasVoucher', 'payoutsFilter/updateRadio');
    filter.setClassName('hasVoucher', 'col-2-row-2');

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'payoutsFilter/updateCheckbox');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
