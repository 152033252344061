/**
 * Global API for users.
 *
 */

import API from '@/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData } from '@/interfaces/shared';
import { UserEntityInterface, UserListItem } from '@/interfaces/models/user.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

/**
 * Get all users list (for dropdowns)
 */
export const getAllUsersList = async (isActive = false): Promise<UserListItem[]> => {
  let url = API.users.getAll;

  if (isActive) {
    url += '?isActive=1';
  }

  const result = await makeGetRequest(url);

  return result.data.result.users;
};

/**
 * Get users list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 */
export const getUsersList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.users.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get chiefs
 *
 * @access CAN_EDIT_USER
 */
export const getChiefs = async (): Promise<UserListItem[]> => {
  const url = API.users.getChiefs;
  const result = await makeGetRequest(url);

  return result.data.result.users;
};

/**
 * Get user by id
 *
 * @param id
 */
export const getInfoById = async (id: string): Promise<UserEntityInterface> => {
  const url = API.users.get.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.user;
};

/**
 * Create new user
 *
 * @param data
 *
 * @access CAN_EDIT_USER
 */
export const createUser = async (data: FormData) => {
  const url = API.users.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Edit existing user
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_USER
 */
export const updateUser = async (id: string, data: FormData) => {
  const url = API.users.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get groups list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const getSecurityGroupsList = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.users.getSecurityGroups);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get ALL security groups (for select)
 */
export const getSecurityGroupsListAll = async () => {
  const url = API.users.getAllSecurityGroups;
  const result = await makeGetRequest(url);

  return result.data.result.securityGroups;
};

/**
 * Get security group info
 *
 * @param id
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const readSecurityGroup = async (id: string) => {
  const url = API.users.readSecurityGroup.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Add security group
 *
 * @param data
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const createSecurityGroup = async (data: FormData) => {
  const url = API.users.createSecurityGroup;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Edit security group
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const updateSecurityGroup = async (id: string, data: FormData) => {
  const url = API.users.updateSecurityGroup.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete security group
 *
 * @param id
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const deleteSecurityGroup = async (id: string) => {
  const url = API.users.deleteSecurityGroup.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Get permissions list
 */
export const getPermissionsList = async () => {
  const url = API.users.getPermissionsList;
  const result = await makeGetRequest(url);

  return result.data;
};

/**
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_SECURITY_GROUP
 */
export const setPermissions = async (id: string, data: FormData) => {
  const url = API.users.setSecurityGroupPermission.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};
