/*
 *
 * */

import { Select, SelectList, SelectItem } from '@/lib/formFactory/select.interface';
export default class SelectEntity {
  defaultValue: Select;
  entityName: string;
  entity: any;

  constructor(name: string) {
    this.defaultValue = {
      key: name,
      type: 'select',
      title: '',
      list: [],
      current: {
        id: '',
        value: '',
      },
      required: false,
      error: {
        class: '',
        message: '',
      },
      validation: {
        empty: 'Поле не заполнено',
        regex: '',
      },
    };
    this.entityName = name;
    this.entity = { [this.entityName]: this.defaultValue };
  }

  setTitle(title: string) {
    this.entity[this.entityName]['title'] = title;
  }

  setList(list: SelectList) {
    this.entity[this.entityName]['list'] = list;
  }

  setCurrent(selected: SelectItem) {
    this.entity[this.entityName]['current'] = selected;
  }

  setAction(action: string) {
    this.entity[this.entityName]['action'] = action;
  }

  setRequired(required: boolean) {
    this.entity[this.entityName]['required'] = required;
  }

  setValidation(validation: any) {
    this.entity[this.entityName]['validation'] = validation;
  }
}
