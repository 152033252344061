/**
 * Global API for region.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { regionsResponse, regionResponse, regionEntity, geoRegionEntity } from '@/interfaces/regionResponse.interface';
import { FormData } from '@/interfaces/shared';
import { AssemblerUrl } from '@/lib/UtilsApi';

/**
 * Get regions list
 *
 * @param pageCurrent
 * @param pageAmountItems
 *
 * @access CAN_READ_REGION
 */
export const getRegionsList = async (pageCurrent?: number, pageAmountItems?: string) => {
  const assemblerUrl = new AssemblerUrl(API.regions.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).getUrl();
  const result = await makeGetRequest<regionsResponse>(url);

  return result.data.result.table;
};

/**
 * Get all regions
 */
export const getRegionsAll = async (): Promise<regionEntity[]> => {
  const url = API.regions.getAll;
  const result = await makeGetRequest(url);

  return result.data.result.regions;
};

/**
 * Get all geo regions
 *
 * @access CAN_GET_ALL_REGION
 */
export const getRegionsGeoAll = async (): Promise<geoRegionEntity[]> => {
  const url = API.regions.getGeoAll;
  const result = await makeGetRequest(url);

  return result.data.result.regions;
};

/**
 * Get regions by id
 *
 * @param id
 *
 * @access CAN_READ_REGION
 */
export const getRegionById = async (id: string) => {
  const url = API.regions.read.replace('$1', id);
  const result = await makeGetRequest<regionResponse>(url);

  return result.data.result.region;
};

/**
 * Add new Region
 *
 * @param data
 *
 * @access CAN_EDIT_REGION
 */
export const addNewRegion = async (data: FormData) => {
  const url = API.regions.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save Region
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_REGION
 */
export const saveRegion = async (id: string, data: FormData) => {
  const url = API.regions.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};
