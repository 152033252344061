/**
 * Users store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';

import store from '@/store';
import PageBaseModule from '@/store/page';
import ModalsModule from '@/store/modals';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';

import { getTrainingCenterList } from '@/api/trainingCenter';
import { deleteTrainingCenter } from '@/api/trainingCenter';

export const MODULE_NAME = 'trainingCenter';
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TrainingCenterModule extends PageBaseModule {
  modalParams = {
    delete: {
      id: '',
      router: null,
      name: '',
      text: 'Ты действительно хочешь удалить этот учебный центр?',
      handlers: {
        yes: 'trainingCenter/deleteTrainingCenterById',
        no: '',
      },
    },
  };

  constructor(module: TrainingCenterModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('УЦ');
    page.setTitleAdd('НОВЫЙ УЧЕБНЫЙ ЦЕНТР');
    page.setTitleEdit('УЧЕБНЫЕ ЦЕНТРЫ');
    page.values.actionPagination = 'settingsUsers/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  SET_DELETE_ID(id: string) {
    this.modalParams.delete.id = id;
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getTrainingCenterList(this.pageSettings.pageCurrent, itemsQuery);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors ? error.response.data.errors.fields : error.response.data.message,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const tableInfo = uiTable.getTable();
    this.context.commit('SET_TABLE', tableInfo as any);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  deleteModal(id: string) {
    this.context.commit('SET_DELETE_ID', id);
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deleteTrainingCenterById(params: { id: string; router: any }) {
    try {
      const result = await deleteTrainingCenter(params.id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Учебный центр удалён',
          type: 'ok',
        });
        params.router.push({ name: 'training_center' });
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.message,
        type: 'fail',
      });
    }
  }
}

export default getModule(TrainingCenterModule);
