/**
 * Reset employee model.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import EmployeeEntityModule from '@/store/employees/entity';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.name === 'employees') {
    EmployeeEntityModule.resetTitle();
  }

  if (to.name === 'employee_edit' || to.name === 'employee_add') {
    EmployeeEntityModule.resetModel();
  }

  next();
};

export default guard;
