
import { Component, Vue } from '@/lib/decorator';
import ResponseHandlerStaticModule from '@/store/modules/responseHandlerStatic';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);
@Component({})
export default class ResponseHandlerStatic extends Vue {
  get isShow() {
    return ResponseHandlerStaticModule.isShow;
  }

  get type() {
    return ResponseHandlerStaticModule.type;
  }

  get content() {
    return ResponseHandlerStaticModule.content;
  }

  closeNotify() {
    ResponseHandlerStaticModule.hideNotify();
  }
}
