import { CustomerModel } from '@/interfaces/models/customer.interface';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionEntity';

export default class EntityModel {
  model: CustomerModel;

  constructor() {
    const bicEntity = new InputSuggestionEntity('bic');
    bicEntity.setTitle('БИК');
    bicEntity.setRequired(true);
    bicEntity.setMask({
      regex: '[0-9]{9}',
      placeholder: ' ',
      minChars: 9,
      maxChars: 9,
    });
    bicEntity.setValidation({
      empty: 'Поле не заполнено',
      regex: 'Некорректный БИК',
    });

    this.model = {
      name: '',
      isActive: true,
      legalName: '',
      externalName: '',
      legalAddress: '',
      actualAddress: '',
      legalForm: '',
      type: '',
      email: '',
      phone: '',
      fastReplacementFees: [],
      isTest: false,
      hasCabinet: false,
      autoCheckIn: true,
      autoCheckup: false,
      paymentDivided: false,
      contractor: { inn: '', isSupplier: false, legalName: '', uuid: '' },
      partner: null,
      accountManager: null,
      importShiftsSource: '',
      isClientCustomer: false,
      inn: '',
      kpp: '',
      ogrn: '',
      bank: '',
      paymentAccount: '',
      ks: '',
      bic: bicEntity.entity[bicEntity.entityName],
      responsibleName: '',
      responsiblePosition: '',
      responsibleEmail: '',
      responsiblePhone: '',
      lunchSchemes: [],
    };
  }
}
