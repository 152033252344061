/**
 * Payouts module
 *
 */

import { watch } from 'vue';
import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';

import store from '@/store';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import filterModel from './filter';

import { prepareList, getDateRange } from '@/lib/Payouts';
import { getPayoutsList, updatePayout, deletePayouts, getRocketWorkStatuses } from '@/api/payouts';
import { modalParams, PayoutsModalParamsType } from './modalParams';
import PayoutsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { download } from '@/api/payments';
import { getAllPartnersList } from '@/api/partners';

import moment from 'moment';
import ModalsModule from '@/store/modals';
import SiteModule from '@/store/site';

import { downloadFile } from '@/lib/Utils';
import { strings } from '@/lib/stringConst';
import { FormResponse } from '@/interfaces/shared';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';

export const MODULE_NAME = 'payouts';

export interface PayoutItemInterface {
  id: string;
  comment: string;
  day: string;
  debit: number;
  employeeId: number;
  fullName: string;
  rocketWorkStatus: string;
  voucher: boolean;
  date: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Payouts extends PageBaseModule {
  modalParams: PayoutsModalParamsType = modalParams;
  filter: Filter;
  selectedPayouts: number[] = [];
  selectAllCheckbox: {
    key: string;
    name: string;
    type: string;
    list: {
      0: {
        id: number;
        name: string;
        checked: boolean;
      };
    };
    handler: string;
  };

  constructor(module: Payouts) {
    super(module);

    const page = new PageEntity();
    page.setTitle('БД выплат');

    this.pageSettings = page.values;
    this.pageSettings.actionPagination = 'payouts/updatePage';
    this.pageSettings.actionsHandler = {
      additional: {
        debit: 'payouts/setById',
        comment: 'payouts/setById',
      },
    };
    this.selectAllCheckbox = {
      key: 'isSelectedAll',
      name: '',
      type: 'checkbox',
      list: {
        0: {
          id: 0,
          name: '',
          checked: false,
        },
      },
      handler: 'payouts/updateIsSelectedAll',
    };

    const filter = new filterModel();
    this.filter = getModule(PayoutsFilter);
    this.filter.setFilterName('payoutsFilter');
    this.filter.setTemplateClassName('template-md');
    this.filter.setBtnClassName('col-1-row-4');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.securityGroup?.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partnerUuid');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string | number }) {
    (this as any).model[params.key] = params.value;
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value = date;
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value = date;
  }

  @Mutation
  SET_IS_SHOW_EDIT_PAYOUT(bool: boolean) {
    this.modalParams.editPayout.isShow = bool;
  }

  @Mutation
  SET_SELECTED(values: number[]) {
    this.selectedPayouts = Object.assign([], values);
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();

    await this.initDateRange();
    await this.getList();
  }

  @Action({ rawError: true })
  initDateRange() {
    const range = getDateRange();

    this.context.commit('UPDATE_DATE_START', range.startDay);
    this.context.commit('UPDATE_DATE_END', range.endDay);
  }

  @Action({ rawError: true })
  async initPartnersFilter() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const filter = this.filter.filterSettings.filter;
      const dates = await this.getFilterDates();
      const filterString = `${dates}${filter}`;
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getPayoutsList(this.pageSettings.pageCurrent, itemsQuery, filterString);
      await this.setList(result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  getFilterDates() {
    const dateStart = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value;
    const dateStartFormatted = moment(dateStart, 'DD/MM/YY').format('YYYY-MM-DD');
    const dateEnd = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value;
    const dateEndFormatted = moment(dateEnd, 'DD/MM/YY').format('YYYY-MM-DD');

    let filterDates = `&filters[0][id]=periodStart&filters[0][value]=${dateStartFormatted}`;
    filterDates += `&filters[1][id]=periodEnd&filters[1][value]=${dateEndFormatted}&`;

    return filterDates;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const tableInfo = prepareList(table);

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateStart(date: string) {
    await this.context.commit('UPDATE_DATE_START', date);

    const dataEnd = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value.split('/');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value.split(
      '/'
    );
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp > dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_END', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateEnd(date: string) {
    await this.context.commit('UPDATE_DATE_END', date);

    const dataEnd = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value.split('/');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value.split(
      '/'
    );
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp >= dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_START', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  prepareData(item: PayoutItemInterface) {
    const data: { day: string; amount: string; voucher: number; comment: string } = {
      day: '',
      amount: '',
      voucher: 0,
      comment: '',
    };
    try {
      data['day'] = moment(item.date).format('YYYY-MM-DD');
      data['amount'] = Number(item.debit).toFixed();
      data['voucher'] = item.voucher ? 1 : 0;
      data['comment'] = item.comment || '';

      return data;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(item: PayoutItemInterface) {
    try {
      SiteModule.SET_IS_BLOCK(true);

      const data = await this.prepareData(item);
      const result = await updatePayout(item.id, data);

      this.successResponse(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    } finally {
      SiteModule.SET_IS_BLOCK(false);
    }
  }

  @Action({ rawError: true })
  successResponse(response: FormResponse) {
    if (!response.message) {
      this.getList();
      ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      this.closeModalEditPayout();
    } else {
      ResponseHandlerModule.showNotify({ message: response.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  showModalDelete() {
    ModalsModule.updateParams(this.modalParams.deleteWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deletePayouts() {
    try {
      const data = await this.prepareDeleteData();
      const response: unknown = await deletePayouts(data);

      this.successResponse(response as FormResponse);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  prepareDeleteData() {
    const data: Record<string, number> = {};
    try {
      for (const key in this.selectedPayouts) {
        data[`payouts[${key}]`] = this.selectedPayouts[key];
      }

      return data;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  showModalEditPayout() {
    this.context.commit('SET_IS_SHOW_EDIT_PAYOUT', true);
  }

  @Action({ rawError: true })
  closeModalEditPayout() {
    this.context.commit('SET_IS_SHOW_EDIT_PAYOUT', false);
  }

  @Action({ rawError: true })
  async export(fileName: string) {
    const result = await download(`?file=${fileName}`);
    await downloadFile({ fileName, file: result.data });
  }

  @Action({ rawError: true })
  async initRocketWorkStatuses() {
    try {
      const result = await getRocketWorkStatuses();

      return result.map((item: { type: string; humanType: string }) => {
        return {
          id: item.type,
          value: item.humanType,
          name: item.humanType,
        };
      });
    } catch (error) {
      return [];
    }
  }
}

export default getModule(Payouts);
