import { TableTitle } from '@/lib/layouts/page/table.interface';

export const tableTitles: TableTitle[] = [
  {
    id: 'id',
    name: '',
    visible: false,
  },
  {
    id: 'selected',
    name: '',
    visible: true,
  },
  {
    id: 'fullName',
    name: 'ФИО',
    visible: true,
  },
  {
    id: 'worked',
    name: 'Выработка',
    visible: true,
  },
  {
    id: 'earned',
    name: 'Заработано',
    visible: true,
  },
  {
    id: 'earnedTax',
    name: 'Сумма с налогом',
    visible: true,
  },
  {
    id: 'forPayout',
    name: 'Сумма к оплате',
    visible: true,
  },
  {
    id: 'isSelfEmployed',
    name: 'Реквизиты',
    visible: true,
  },
  {
    id: '_actions',
    name: 'Добавить выплату',
    visible: true,
  },
  {
    id: 'isAccountUpdated',
    name: 'Недавно поменяли реквизиты',
    visible: false,
  },
  {
    id: 'hasNotPayoutAndSelfEmployed',
    name: 'Раньше не платили, но стал самозянятым',
    visible: false,
  },
  {
    id: 'hasNotPayout',
    name: 'Раньше не платили',
    visible: false,
  },
];

export const requisitesTitles = [
  {
    id: 'inn',
    name: 'ИНН',
    visible: true,
  },
  {
    id: 'bank',
    name: 'Банк',
    visible: true,
  },
  {
    id: 'paymentAccount',
    name: 'Расчётный счёт',
    visible: true,
  },
  {
    id: 'ks',
    name: 'К/с',
    visible: true,
  },
  {
    id: 'bic',
    name: 'Бик',
    visible: true,
  },
  {
    id: 'cardNumber',
    name: 'Номер карты',
    visible: true,
  },
];
