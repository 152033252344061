
import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'IconFont',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
    },
    boxed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClasses(): string[] {
      const { icon } = this;
      const classNames = [`icon-${icon}`];

      if (this.boxed) {
        classNames.push('icon_boxed');
      }

      return classNames;
    },
    style(): string {
      const sizePx = `${this.size}px`;

      return this.boxed ? `--fontIconSize: ${sizePx}` : `font-size: ${sizePx}`;
    },
  },
});

export default component;
