import { computed } from 'vue';
import { ComputedRef, Ref } from '@vue/reactivity/dist/reactivity';

export interface FormInputNameParams {
  dataName: Ref<string | undefined>;
  label: Ref<string | undefined>;
}

export interface FormInputNameResult {
  nameInput: ComputedRef<string | undefined>;
}

export default function formInputName(params: FormInputNameParams): FormInputNameResult {
  const nameInput = computed(() => {
    if (params.dataName) {
      return params.dataName.value;
    }

    if (params.label) {
      return params.label.value;
    }

    return '';
  });

  return {
    nameInput,
  };
}
