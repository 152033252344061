/**
 * Response Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';

export const MODULE_NAME = 'responseHandlerStatic';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ResponseHandlerStaticModule extends VuexModule {
  isShow = false;
  content = '';
  type = '';

  @Mutation
  setShowState(payload: { isShow: boolean; content: string; type: string }) {
    this.isShow = payload.isShow;
    this.content = payload.content;
    this.type = payload.type;
  }

  @Action({ rawError: true })
  showNotify(params: { content: string; type: string }) {
    this.context.commit('setShowState', { isShow: true, content: params.content, type: params.type });
  }

  @Action({ rawError: true })
  hideNotify() {
    this.context.commit('setShowState', { isShow: false, content: '', type: '' });
  }
}

export default getModule(ResponseHandlerStaticModule);
