/**
 * Global API for template of client.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData, FormResponse } from '@/interfaces/shared';
import { TemplateRequestModel } from '@/interfaces/models/template.interface';
import { ServiceTypeMin, ServiceTypeMinWithStatistics } from '@/interfaces/models/serviceType.interface';
import { UnitType } from '@/interfaces/models/unitType.interface';

/**
 * Get template info by client id
 *
 * @param id
 *
 * @access CAN_READ_CLIENT_TEMPLATE
 */
export const getTemplateInfo = async (id: string) => {
  const url = API.templates.read.replace('$1', id);
  const result = await makeGetRequest<{ result: { shiftTemplate: TemplateRequestModel } }>(url);

  return result.data.result.shiftTemplate;
};

/**
 * Add new template
 *
 * @param customerId
 * @param data
 *
 * @access CAN_EDIT_CLIENT_TEMPLATE
 */
export const addNewTemplate = async (customerId: string, data: {}) => {
  const url = API.templates.create.replace('$1', customerId);
  const result = await makeFormRequest<FormResponse<{ shiftTemplate: TemplateRequestModel }>>(url, data);

  return result.data;
};

/**
 * Save template
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_CLIENT_TEMPLATE
 */
export const updateTemplate = async (id: string | number, data: FormData) => {
  const url = API.templates.update.replace('$1', id.toString());
  const result = await makeFormRequest<FormResponse<{ shiftTemplate: TemplateRequestModel }>>(url, data);

  return result.data;
};

/**
 * Get all template
 *
 * @access CAN_READ_CLIENT_TEMPLATE
 */
export const getTotalTemplates = async () => {
  const url = API.templates.total;
  const result = await makeGetRequest<{ result: { vacancies: { id: number; name: string }[] } }>(url, { withCache: true });

  return result.data.result.vacancies;
};

/**
 * Get vaccination requirements
 */
export const getAllVaccinationStatusList = async () => {
  const url = API.templates.getVaccinationRequirements;
  const result = await makeGetRequest(url);

  return result.data.result;
};

export const getServiceTypeAll = async () => {
  const url = API.templates.getServiceType;
  const result = await makeGetRequest<{ result: { types: ServiceTypeMin[] } }>(url);

  return result.data.result.types;
};

export const getUnitTypeAll = async () => {
  const url = API.templates.getUnitType;
  const result = await makeGetRequest<{ result: { unitTypes: UnitType[] } }>(url);

  return result.data.result.unitTypes;
};

export const getServiceTypesByStore = async (marketId: string | number) => {
  const url = API.templates.getServiceTypesByStore(marketId);
  const result = await makeGetRequest<{
    result: ServiceTypeMinWithStatistics[];
  }>(url);

  return result.data.result;
};

/**
 * Get client template list
 *
 * @param id
 * @param pageCurrent
 * @param pageAmountItems
 * @param filter
 *
 * @access CAN_READ_CLIENT_TEMPLATE
 */
export const getClientTemplatesById = async (id: string, pageCurrent: number, pageAmountItems: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.templates.getList.replace('$1', id));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get all templates by store
 *
 * @param shopId
 *
 * @access CAN_READ_CLIENT_TEMPLATE
 */
export const getAllTemplatesByShopId = async (shopId: string) => {
  const url = API.templates.getAllByStore.replace('$1', shopId);
  const result = await makeGetRequest<{
    result: {
      count: number;
      externalRate: number;
      filledShifts: number;
      id: number;
      name: string;
      needShifts: number;
      rate: number;
      training: boolean;
    }[];
  }>(url);

  return result.data.result;
};

/**
 * Get all templates
 *
 * @param clientId
 */
export const getAllTemplates = async (clientId?: string) => {
  clientId = clientId && parseInt(clientId) !== 0 ? `${clientId}/` : '';
  const url = API.templates.getAll.replace('$1', clientId);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get templates by client ids
 *
 * @param clientIds
 */
export const getActiveTemplateByClients = async (clientIds?: number[]) => {
  const url = API.templates.getActiveByClients + '?clients[]=' + clientIds?.join('&clients[]=');
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * get all templates list with clients
 */
export const getTemplatesWithClientsList = async () => {
  const url = API.templates.getAllWithClients;
  const result = await makeGetRequest(url);

  return result.data.result.templates;
};
