/**
 * User entity store.
 *
 */
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';

import store from '@/store';
import EntityBaseModule from '@/store/entity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ResponseHandlerStaticModule from '@/store/modules/responseHandlerStatic';
import TrainingCenterModule from '@/store/settings/trainingCenter/index';

import { getAddressViaCoordinates, searchAddress } from '@/api/geo';
import { addTrainingCenter, getInfoById, updateTrainingCenter } from '@/api/trainingCenter';

import { strings } from '@/lib/stringConst';
import MapEntity from '@/lib/formFactory/mapEntity';
import { coordinates, Map } from '@/lib/formFactory/map.interface';

interface TextFieldsData {
  id?: string;
  name: string;
  address: string;
  description: string;
  phone: string;
  isActive: number;
}

interface TrainingCenterEntity {
  id: string;
  name: string;
  address: string;
  addressCoordinates: {
    latitude: number;
    longitude: number;
  };
  phone: string;
  cityKladrId: string;
  isActive: boolean;
  description: string;
  competences: {
    id: string;
    name: string;
  }[];
}

interface TrainingCenterModel {
  id?: string;
  name: string;
  address: { id: string; value: string };
  addressCoordinates: Map;
  phone: string;
  cityKladrId: string;
  isActive: number;
  description: string;
  competence: { id: string; value: string }[];
}

export const MODULE_NAME = 'trainingCenterEntity';
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class TrainingCenterEntityModule extends EntityBaseModule {
  titleEdit = '';
  trainingCenter = {};
  model: TrainingCenterModel;
  selectedAddress = {};

  constructor(module: TrainingCenterEntityModule) {
    super(module);

    const addressCoordinates = new MapEntity('addressCoordinates');
    addressCoordinates.setAction('customers/shops/updateAddressCoordinates');

    this.model = {
      name: '',
      address: { id: '', value: '' },
      addressCoordinates: addressCoordinates.entity[addressCoordinates.entityName],
      phone: '',
      cityKladrId: '',
      isActive: 0,
      description: '',
      competence: [],
    };
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    (this as any).model[params.key] = params.value;
  }

  @Mutation
  SET_TITLE_EDIT(title: string) {
    this.titleEdit = title;
  }

  @Mutation
  SET_TRAINING_CENTER(trainingCenter: TrainingCenterModel) {
    this.trainingCenter = trainingCenter;
  }

  @Mutation
  SET_SELECTED_ADDRESS(address: { address: string; latitude: number; longitude: number }) {
    this.selectedAddress = address;
  }

  @Action({ rawError: true })
  updateTitleEdit(title: string) {
    this.context.commit('SET_TITLE_EDIT', title);
  }

  @Action({ rawError: true })
  setCurrentTrainingCenter(trainingCenter: TrainingCenterModel) {
    const trainingCenterProcessed = {
      id: trainingCenter.id,
      name: trainingCenter.name,
      phone: trainingCenter.phone,
      isActive: trainingCenter.isActive,
      description: trainingCenter.description,
      competence: trainingCenter.competence,
      address: trainingCenter.address,
      cityKladrId: trainingCenter.cityKladrId,
    };

    this.context.commit('SET_TRAINING_CENTER', trainingCenterProcessed);
  }

  @Action({ rawError: true })
  async initAdd() {
    await this.updateAddressCoordinates(this.model.addressCoordinates.default);
    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    await this.getInfoById(id);
  }

  @Action({ rawError: true })
  async getInfoById(id: string) {
    try {
      const result = await getInfoById(id);

      await this.context.dispatch('setInfo', result.trainingCenter);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action
  async searchAddress(value: string) {
    try {
      if (value.length === 0) {
        return;
      }

      const result = await searchAddress(value);
      this.prepareAutocomplete(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action
  prepareAutocomplete(address: Record<string, { address: string }>) {
    this.context.commit('UPDATE_ADDRESS_HINTS', {});
    const positionProcessed = Object.values(address).map((position: { address: string }) => {
      return {
        id: position.address,
        name: position.address,
      };
    });
    this.context.commit('UPDATE_ADDRESS_HINTS', positionProcessed);
  }

  @Action
  updateAddress(address: {}) {
    this.context.commit('SET_ADDRESS', address);
  }

  @Action
  updateAddressCoordinates(coordinates: {}) {
    this.context.commit('SET_ADDRESS_COORDINATES', coordinates);
  }

  @Action({ rawError: true })
  async addNew(params: { customerId?: string; textFieldsData: TextFieldsData }) {
    try {
      const data = await this.prepareData(params.textFieldsData);
      const result = await addTrainingCenter(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Объект создан', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      ResponseHandlerModule.showNotify({ message: errorData.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  showUniqueError(errorData: { type: string; errors: { fields: Record<string, string> } }) {
    ResponseHandlerStaticModule.hideNotify();
    let message = '';
    let fieldText = '';
    let errorEntity: any;
    for (errorEntity of Object.values(errorData.errors)) {
      if (errorEntity.field === 'address') {
        fieldText = 'Объект с таким адрессом уже существует ';
      }

      if (errorEntity.field === 'code') {
        fieldText = 'Объект с таким кодом уже существует ';
      }

      const fullName = errorEntity.custom_data.fullname;
      const id = errorEntity.custom_data.id;
      const profileLink = `<a href="${window.location.origin}/shops/${id}" target="_blank">${fullName}</a>`;
      message += `${fieldText}: ${profileLink} <br>`;

      ResponseHandlerStaticModule.showNotify({ content: message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; textFieldsData: TextFieldsData }) {
    try {
      const data = await this.prepareData(params.textFieldsData);
      const result = await updateTrainingCenter(params.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      ResponseHandlerModule.showNotify({
        message: error.root && error.root.length ? error.root.join(',') : errorData.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  prepareData(textFieldsData?: TextFieldsData) {
    const data = {
      lat: (this.model.addressCoordinates.value as coordinates).latitude,
      long: (this.model.addressCoordinates.value as coordinates).longitude,
    };

    return Object.assign(data, textFieldsData);
  }

  @Action({ rawError: true })
  resetTitle() {
    TrainingCenterModule.updateTitleEdit('');
  }

  @Action
  setInfo(info: TrainingCenterEntity) {
    TrainingCenterModule.updateTitleEdit(info.name);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'description', value: info.description });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: info.phone });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'cityKladrId', value: info.cityKladrId });

    this.updateAddress({ name: info.address });
    this.updateAddressCoordinates(info.addressCoordinates ? info.addressCoordinates : this.model.addressCoordinates.default);
    if (info.address) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'address',
        value: { id: info.address, value: info.address ?? '' },
      });
    }

    if (info.competences && info.competences.length) {
      const resultCompetence = info.competences.map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          value: item.name,
          name: item.name,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'competence', value: resultCompetence });
    }

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  updateSelectedAddress(coordinates: { latitude: number; longitude: number }) {
    getAddressViaCoordinates(coordinates.latitude, coordinates.longitude).then((addressString) => {
      if (addressString !== '') {
        this.context.commit('SET_SELECTED_ADDRESS', {
          address: addressString,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        });
      }
    });
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'description', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'competence', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'address', value: { id: null, value: '' } });

    this.updateIsLoading(true);
  }
}

export default getModule(TrainingCenterEntityModule);
