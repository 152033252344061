/**
 * Menu store.
 *
 */

import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import { menuItems } from './items';

import { getCountOfProcessed } from '@/api/shop';
import { MenuItems } from '@/interfaces/menuItem.interface';
import userModule from '@/store/user';

export const MODULE_NAME = 'menu';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class MenuModule extends VuexModule {
  menuItems: MenuItems = {};

  constructor(module: MenuModule) {
    super(module);
  }

  @Mutation
  SET_MENU_ITEMS(items: MenuItems) {
    this.menuItems = items;
  }

  @Mutation
  SET_MENU_ITEM_PROP(params: { itemName: string; prop: string; value: string }) {
    if (!this.menuItems[params.itemName]) {
      return;
    }

    this.menuItems[params.itemName][params.prop] = params.value;
  }

  @Action({ rawError: true })
  initMenuItems() {
    const menuByUser: MenuItems = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(menuItems())) {
      if (userModule.canUserRead(menuItem.path ?? '')) {
        menuByUser[menuItemKey] = menuItem;
      }
    }
    this.SET_MENU_ITEMS(menuByUser);
  }

  @Action({ rawError: true })
  updateTasksHint(count: { total: number; today: number; tomorrow: number }) {
    if (count.total > 0) {
      this.context.commit('SET_MENU_ITEM_PROP', { itemName: 'tasks', prop: 'hint', value: count.total.toString() });
      this.context.commit('SET_MENU_ITEM_PROP', {
        itemName: 'tasks',
        prop: 'hintClass',
        value: count.today > 0 ? 'red' : 'yellow',
      });

      return;
    }

    this.context.commit('SET_MENU_ITEM_PROP', { itemName: 'tasks', prop: 'hint', value: '' });
    this.context.commit('SET_MENU_ITEM_PROP', { itemName: 'tasks', prop: 'hintClass', value: '' });
  }

  @Action({ rawError: true })
  async updateMenuItemsHints() {
    try {
      if (!userModule.userHasPermission('CAN_READ_FREE_MARKET_SHIFT')) {
        return;
      }

      const count = await getCountOfProcessed();
      this.updateTasksHint(count);
    } catch (e) {
      return;
    }
  }
}

export default getModule(MenuModule);
