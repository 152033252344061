import * as Sentry from '@sentry/vue';
import { Vue as VueIntegration, CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { Integrations as IntegrationsTracing } from '@sentry/tracing';

function createSentryForDemo(app?: any) {
  Sentry.init({
    logErrors: true,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_VERSION,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [
      new VueIntegration({ Vue: app }),
      new CaptureConsoleIntegration({ levels: ['error'] }),
      new IntegrationsTracing.BrowserTracing(),
    ],
    tracingOptions: {
      trackComponents: true,
    },
    tracesSampleRate: 1.0,
  });
}

function createSentryForProd(app?: any) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_VERSION,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    integrations: [new VueIntegration({ Vue: app }), new CaptureConsoleIntegration({ levels: ['error'] })],
  });
}

export function init(app?: any) {
  if (process.env.VUE_APP_SENTRY_SWITCH !== 'true') return;

  process.env.VUE_APP_VERSION === 'PROD' ? createSentryForProd(app) : createSentryForDemo(app);
}

export function identifyUser(user: { id?: string; username?: string; email?: string }) {
  Sentry.setUser({
    id: user.id,
    username: user.username,
    email: user.email,
    ip_address: '{{auto}}',
  });
}
