import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class InlineMailingsFilter {
  filterHandlers = {
    update: 'mailingsInlineFilter/updateFilter',
    reset: 'mailingsInlineFilter/resetFilter',
    resetState: 'mailingsInlineFilter/resetFilterState',
    removeSelected: 'mailingsInlineFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createMultiSelectEntity('status');
    filter.setTitle('status', 'Состояния рассылки');
    filter.setName('status', 'multi_select_state');
    filter.setList('status', []);
    filter.setAction('status', 'mailingsInlineFilter/updateMultiSelect');

    this.filterModel = filter.filterModel;
  }
}
