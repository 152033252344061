import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  byCustomers: {
    id: 'byCustomers',
    name: 'По клиентам',
    template: 'byCustomers',
    pathName: 'analytics_by_customers',
    icon: 'clients',
    iconSize: '15',
  },
  byEmployees: {
    id: 'byEmployees',
    name: 'По исполнителям',
    template: 'byEmployees',
    pathName: 'analytics_by_employees',
    icon: 'employee',
    iconSize: '15',
  },
};
