/**
 * Axios initialization for application.
 *
 */

import axios, { AxiosError } from 'axios';
import siteModule from '@/store/site';
import router from '@/router';
import { strings } from '@/lib/stringConst';

export const BASE_URL = process.env.VUE_APP_DEMO_DOMAIN || location.origin;

const INSTANCE = axios.create({
  baseURL: BASE_URL,
});

INSTANCE.interceptors.request.use(undefined, (error: AxiosError) => {
  siteModule.fetchApiRequestError(error);

  return Promise.reject(error);
});

INSTANCE.interceptors.response.use(undefined, (error: AxiosError) => {
  handleNotFoundResponse(error);
  handleForbiddenEntityResponse(error);
  siteModule.fetchApiResponseError(error);

  return Promise.reject(error);
});

function handleNotFoundResponse(error: AxiosError): void {
  if (error.response?.status !== 404) {
    return;
  }

  const isDefaultSymfonyMessage = error.response.data.message.match('object not found');

  if (isDefaultSymfonyMessage) {
    error.response.data.message = strings.NOT_FOUND;
  }

  const entityRoute = router.currentRoute.value.meta.entityRoute;

  if (entityRoute) {
    let apiRoutePattern = '';

    if (typeof entityRoute === 'string') {
      apiRoutePattern = entityRoute.replaceAll(/\$\d/g, '.+?');
    } else if (typeof entityRoute === 'function') {
      const numOfArgs = entityRoute.length;
      const args = new Array(numOfArgs).fill('.+?');
      const patternResult = entityRoute.call(null, ...args);

      if (typeof patternResult === 'string') {
        apiRoutePattern = patternResult;
      }
    }

    if (apiRoutePattern) {
      const apiRouteRegexp = new RegExp(apiRoutePattern);

      const isEntityRoute = error.config.url?.match(apiRouteRegexp);
      const isNotFoundStatus = error.response?.status === 404;

      if (isEntityRoute && isNotFoundStatus) {
        return void router.push({ name: 'not_found' });
      }
    }
  }
}

function handleForbiddenEntityResponse(error: AxiosError): void {
  if (error.config.method === 'get' && error.response?.status === 403) {
    const previousRoute = sessionStorage.getItem('previousRoute');
    const nextRoute = sessionStorage.getItem('nextRoute');
    if (!previousRoute) {
      return void router.push({ name: 'home' });
    }
    const shouldExcludeRoute = (route: string) => {
      const excludedRoutes = ['employees_schedule', 'employee_ratings'];

      return excludedRoutes.includes(route);
    };

    if (
      !error.config.url?.includes('lunch_scheme') &&
      !shouldExcludeRoute(previousRoute ?? '') &&
      !shouldExcludeRoute(nextRoute ?? '')
    ) {
      window.history.back();
    }
  }
}

export default INSTANCE;
