import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  freeMarketShifts: {
    id: 'freeMarketShifts',
    name: 'Дыры',
    template: 'freeMarketShifts',
    pathName: 'free_market_shifts',
    icon: 'free-shifts',
    iconSize: '16',
  },
  outputs: {
    id: 'outputs',
    name: 'Выходы',
    template: 'outputs',
    pathName: 'outputs',
    icon: 'outputs',
    iconSize: '17',
  },
  foundEmployees: {
    id: 'foundEmployees',
    name: 'Найден исполн.',
    template: 'foundEmployees',
    pathName: 'found_employees',
    icon: 'user',
    iconSize: '16',
  },
  firstShift: {
    id: 'firstShift',
    name: 'Первый заказ',
    template: 'firstShift',
    pathName: 'first_shift',
    icon: 'first-shift',
    iconSize: '16',
  },
  beginners: {
    id: 'beginners',
    name: 'Новички',
    template: 'beginners',
    pathName: 'beginners',
    icon: 'beginners',
    iconSize: '16',
  },
  reactivation: {
    id: 'reactivation',
    name: 'Статусы',
    template: 'reactivation',
    pathName: 'reactivation',
    icon: 'phone',
    iconSize: '16',
  },
  revise: {
    id: 'revise',
    name: 'Сверка',
    template: 'revise',
    pathName: 'revise',
    icon: 'revise',
    iconSize: '16',
  },
};
