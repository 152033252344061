import { NavigationGuard, RouteLocationNormalized } from 'vue-router';

import UserModule from '@/store/user';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';

const guard: NavigationGuard = (to: RouteLocationNormalized, _from, next): void => {
  if (to.meta.permission && !UserModule.userHasPermission(to.meta.permission as string)) {
    ResponseHandlerModule.showNotify({ message: strings.errorAccessDenied, type: 'fail' });

    return next({ name: 'home' });
  }

  next();
};

export default guard;
