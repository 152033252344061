/*
 * default side menu
 * */
import { MenuItem } from '@/interfaces/menuItem.interface';
import userModule from '@/store/user';

import { tabsNav as settingsTabs } from '@/store/settings/tabs';
import { tabsNav as tasksTabs } from '@/store/tasks/entityTabs';
import { tabsNav as financeTabs } from '@/store/finance/tabs';
import { tabsNav as cardsTabs } from '@/store/cards/tabs';
import { tabsNav as analyticsTabs } from '@/store/analytics/entityTabs';

export function menuItems(): Record<string, MenuItem> {
  const settingsPath = Object.values(settingsTabs)
    .filter((tab: { pathName: string }) => {
      return userModule.canUserRead(tab.pathName);
    })
    .shift();

  const tasksPath = Object.values(tasksTabs)
    .filter((tab: { pathName: string }) => {
      return userModule.canUserRead(tab.pathName);
    })
    .shift();

  const financePath = Object.values(financeTabs)
    .filter((tab: { pathName: string }) => {
      return userModule.canUserRead(tab.pathName);
    })
    .shift();

  const cardsPath = Object.values(cardsTabs)
    .filter((tab: { pathName: string }) => {
      return userModule.canUserRead(tab.pathName);
    })
    .shift();

  const analyticsPath = Object.values(analyticsTabs)
    .filter((tab: { pathName: string }) => {
      return userModule.canUserRead(tab.pathName);
    })
    .shift();

  return {
    orders: {
      name: 'Заявки',
      path: 'orders',
      code: 'orders',
      isActive: true,
      class: 'shift',
      hint: '',
      hintClass: '',
      iconSize: '22',
    } as MenuItem,
    shops: {
      name: 'Объекты',
      path: 'shops',
      code: 'shops',
      isActive: true,
      class: 'store',
      hint: '',
      hintClass: '',
      iconSize: '18',
    } as MenuItem,
    employees: {
      name: 'Исполнители',
      path: 'employees',
      code: 'employees',
      isActive: true,
      class: 'employee',
      hint: '',
      hintClass: '',
      iconSize: '19',
    } as MenuItem,
    customers: {
      name: 'Клиенты',
      path: 'customers',
      code: 'customers',
      isActive: true,
      class: 'clients',
      hint: '',
      hintClass: '',
      iconSize: '19',
    } as MenuItem,
    partners: {
      name: 'Партнёры',
      path: 'partners',
      code: 'partners',
      isActive: true,
      class: 'partners',
      hint: '',
      hintClass: '',
      iconSize: '19',
    },
    tasks: {
      name: 'Задачи',
      path: tasksPath ? tasksPath.pathName : '',
      code: 'tasks',
      isActive: true,
      class: 'task-sheet',
      hint: '',
      hintClass: '',
      iconSize: '18',
    } as MenuItem,
    finance: {
      name: 'Финансы',
      path: financePath ? financePath.pathName : '',
      code: 'finance',
      isActive: true,
      class: 'finance',
      hint: '',
      hintClass: '',
      iconSize: '20',
    } as MenuItem,
    cards: {
      name: 'Карточки',
      path: cardsPath ? cardsPath.pathName : '',
      code: 'cards',
      isActive: true,
      class: 'attention',
      hint: '',
      hintClass: '',
      iconSize: '20',
    } as MenuItem,
    analytics: {
      name: ' Аналитика',
      path: analyticsPath ? analyticsPath.pathName : '',
      code: 'analytics',
      isActive: true,
      class: 'analytics',
      hint: '',
      hintClass: '',
      iconSize: '24',
    } as MenuItem,
    settings: {
      name: 'Настройки',
      path: settingsPath ? settingsPath.pathName : '',
      code: 'settings',
      isActive: true,
      class: 'settings',
      hint: '',
      hintClass: '',
      iconSize: '20',
    } as MenuItem,
  };
}
