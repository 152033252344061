/**
 * Global API for shop.
 *
 */

import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData, FormResponse } from '@/interfaces/shared';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import {
  ShopComment,
  ShopEntity,
  ShopMinEntity,
  ShopSubscriptionCreatePayload,
  ShopSubscriptionGroup,
} from '@/interfaces/models/shop.interface';
import { EmployeeSearchItem, EmployeeSearchItemWithRotation } from '@/interfaces/shift.interface';

/**
 * Get shops list.
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access ???
 */
export const getShopsList = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  filter: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.shops.getTotalList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get shop info by id
 *
 * @param id
 */
export const getShopById = async (id: string): Promise<ShopEntity> => {
  const url = API.shops.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.marketMarket;
};

/**
 * Get shop min info by id
 *
 * @param id
 */
export const getShopMinById = async (id: string): Promise<ShopMinEntity> => {
  const url = API.shops.readMin.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.marketMarket;
};

/**
 * Add new shop
 *
 * @param customerId
 * @param data
 *
 * @access CAN_EDIT_CLIENT_MARKET
 */
export const addNewShop = async (customerId: string, data: FormData): Promise<ShopEntity | FormResponse> => {
  const url = API.shops.create.replace('$1', customerId);
  const result = await makeFormRequest(url, data);

  return result.data.result.marketMarket;
};

/**
 * Save shop
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_CLIENT_MARKET
 */
export const saveShop = async (id: string, data: FormData): Promise<ShopEntity | FormResponse> => {
  const url = API.shops.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data.result.marketMarket;
};

/**
 * Delete shop
 *
 * @param id
 *
 * @access CAN_EDIT_CLIENT_MARKET
 */
export const deleteShopById = async (id: string): Promise<string | FormResponse> => {
  const url = API.shops.delete.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data.result.status;
};

/**
 * Get shops list by client id.
 *
 * @param pageCurrent
 * @param id
 * @param pageAmountItems
 * @param sort
 */
export const getShopsListById = async (
  pageCurrent: number,
  id: string,
  pageAmountItems: string,
  sort: string
): Promise<TableInterface> => {
  const assemblerUrl = new AssemblerUrl(API.shops.getList.replace('$1', id));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get most popular time
 *
 * @param positionId
 * @param shopId
 */
export const getMostPopularTime = async (
  positionId: string,
  shopId: string
): Promise<{
  client_market_id: string;
  client_vacancy_id: string;
  count: string;
  end_time: string;
  start_time: string;
  0: {
    client_market_id: number;
    client_vacancy_id: number;
    count: number;
    start_time: string;
    end_time: string;
  };
}> => {
  const url = API.shops.mostPopularTime.replace('$1', positionId).replace('$2', shopId);
  const result = await makeGetRequest(url, { withCache: true });

  return result.data.result.result;
};

/**
 * Search employees for shop
 *
 * @param value
 * @param shiftId
 *
 * @access CAN_READ_EMPLOYEE
 */
export const searchShiftEmployee = async <T extends EmployeeSearchItem | EmployeeSearchItemWithRotation>(
  value: string,
  shiftId: string | number
): Promise<EmployeeSearchItem[]> => {
  const url = API.shops.searchEmployeeShift.replace('$1', value).replace('$2', shiftId.toString());
  const result = await makeGetRequest<{ result: T[] }>(url);
  const normalizedResult = result.data.result.map((employee: T) => {
    const hasRotation = '0' in employee;
    let result: EmployeeSearchItem;

    if (hasRotation) {
      result = {
        ...(employee as EmployeeSearchItemWithRotation)[0],
        calendarHours: employee.calendarHours,
        workHours: employee.workHours,
      };
    } else {
      result = employee as EmployeeSearchItem;
    }

    return result;
  });

  return normalizedResult;
};

/**
 * Get employees list by shop
 *
 * @param shopId
 * @param employeeFilter
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getShopEmployeesList = async (shopId: string, employeeFilter: string) => {
  const assemblerUrl = new AssemblerUrl(API.shops.getEmployeesList.replace('$1', shopId));
  const url = assemblerUrl.addFilter(employeeFilter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get number of processed shifts
 */
export const getCountOfProcessed = async () => {
  const url = `${API.shops.countOfProcessed}`;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get shop statistics
 *
 * @param shopId
 * @param filter
 */
export const getShopStatistics = async (shopId: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.shops.statistics.replace('$1', shopId));
  const url = assemblerUrl.addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.statistics;
};

/**
 * Get client shift by id
 *
 * @param shiftId
 *
 * @access CAN_READ_CLIENT_MARKET_SHIFT
 */
export const getClientShiftById = async (shiftId: string | number) => {
  const url = API.shops.shift.replace('$1', shiftId.toString());
  const response = await makeGetRequest(url);
  const result = response.data.result;

  if (result.marketShift) {
    result.marketShift.workHours = result.workHours;
    result.marketShift.recommendedEmployees = result.recommendedEmployees;
  }

  return result.marketShift;
};

/**
 * Get shifts list
 *
 * @param shopId
 * @param positionId
 * @param startWeek
 * @param endWeek
 * @param employeeId
 *
 * @access CAN_READ_CLIENT_MARKET_SHIFT
 */
export const getShopShifts = async (
  shopId: string,
  positionId: string,
  startWeek: number,
  endWeek: number,
  employeeId: number | null
) => {
  const url = API.shops.calendar
    .replace('$1', shopId)
    .replace('$2', positionId)
    .replace('$3', startWeek.toString())
    .replace('$4', endWeek.toString())
    .replace('$5', employeeId ? employeeId.toString() : '');
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Add new shift
 *
 * @param shopId
 * @param data
 *
 * @access CAN_EDIT_CLIENT_MARKET_SHIFT
 */
export const addNewShiftByShopId = async (shopId: string, data: FormData) => {
  const url = API.shops.shiftCreate.replace('$1', shopId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save shift
 *
 * @param id
 * @param data
 *
 * @access CAN_READ_CLIENT_MARKET_SHIFT
 */
export const saveShiftById = async (id: string, data: FormData) => {
  const url = API.shops.shift.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * delete shift
 *
 * @param shopId
 * @param params
 *
 * @access CAN_EDIT_CLIENT_MARKET_SHIFT
 */
export const deleteShiftByShopId = async (shopId: string, params: string) => {
  const url = API.shops.shiftDeletePeriod.replace('$1', shopId).replace('$2', params);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * delete shift
 *
 * @param id
 *
 * @access CAN_EDIT_CLIENT_MARKET_SHIFT
 */
export const deleteShiftById = async (id: string) => {
  const url = API.shops.shiftDelete.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Get favorite employee list.
 *
 * @param shopId
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getListById = async (shopId: string, pageCurrent: number, pageAmountItems: string, sort: string) => {
  const assemblerUrl = new AssemblerUrl(API.shops.employees.replace('$1', shopId));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get favorite employee by id.
 *
 * @param shopId
 * @param employeeId
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getFavoriteEmployeeById = async (shopId: string, employeeId: string) => {
  const url = API.shops.favorite.replace('$1', shopId).replace('$2', employeeId);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Save favorite employee for shop
 *
 * @param shopId
 * @param employeeId
 * @param data
 *
 * @access CAN_EDIT_CLIENT_MARKET_EMPLOYEE
 */
export const saveFavoriteEmployee = async (shopId: string, employeeId: string, data: {}) => {
  const url = API.shops.favoriteCreate.replace('$1', shopId).replace('$2', employeeId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete employee from favorite.
 *
 * @param shopId
 * @param id
 *
 * @access CAN_EDIT_CLIENT_MARKET_EMPLOYEE
 */
export const deleteEmployeeFromFavorite = async (shopId: string, id: string) => {
  const url = API.shops.favoriteDelete.replace('$1', shopId).replace('$2', id);
  const result = await makeDeleteRequest(url);

  return result.data.result;
};

/**
 * Get checkin code
 *
 * @param shopId
 * @param date
 */
export const getCheckInCode = async (shopId: string, date: string) => {
  const url = API.shops.checkInCode.replace('$1', shopId).replace('$2', date);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get checkout code
 *
 * @param shopId
 * @param date
 */
export const getCheckOutCode = async (shopId: string, date: string) => {
  const url = API.shops.checkOutCode.replace('$1', shopId).replace('$2', date);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * make check in
 *
 * @param data
 */
export const makeCheckIn = async (data: FormData) => {
  const url = API.shops.makeCheckIn;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * get shop phones
 *
 * @param shopId
 * @access CAN_READ_CLIENT_MARKET_PHONES
 */
export const getShopPhones = async (shopId: string) => {
  const url = API.shops.getPhones.replace('$1', shopId);
  const result = await makeGetRequest(url);

  return result.data.result.phones;
};

export const addNewComment = async (data: FormData): Promise<FormResponse & { result: { comment: ShopComment } }> => {
  const url = API.shops.createComment;
  const result = await makeFormRequest(url, data);

  return result.data;
};

export const updateComment = async (id: number, data: FormData): Promise<FormResponse> => {
  const url = API.shops.updateComment.replace('$1', id.toString());
  const result = await makeFormRequest(url, data);

  return result.data;
};

export const deleteComment = async (id: number): Promise<void> => {
  const url = API.shops.deleteComment.replace('$1', id.toString());

  await makeDeleteRequest(url);
};

export const getSubscriptions = async (shopId: string | number): Promise<ShopSubscriptionGroup[]> => {
  const url = API.shops.subscriptionList(shopId);
  const result = await makeGetRequest(url);

  return result.data.result;
};

export const createSubscription = async (payload: ShopSubscriptionCreatePayload): Promise<void> => {
  const url = API.shops.subscriptionCreate;
  await makeFormRequest(url, payload);
};

export const deleteSubscription = async (subscriptionId: string | number): Promise<void> => {
  const url = API.shops.subscriptionDelete(subscriptionId);
  await makeDeleteRequest(url);
};
