import { SelectItem } from '@/lib/formFactory/select.interface';

export interface HumanType {
  type: string;
  humanType: string;
}

export class HumanTypeHelper {
  static castToSelectItem(type: HumanType): SelectItem {
    return {
      id: type.type,
      value: type.humanType,
    };
  }
}
