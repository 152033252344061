/**
 * Shop shift store.
 *
 */

import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import CalendarBaseModule from '@/store/calendar';
import UserModule from '@/store/user';

import { modalParams, ShiftModalParamsInterface, ShiftEmployeeInterface } from './shiftModalParams';
import ShopsShiftModel from './shiftModel';
import CalendarAbstract from '@/lib/Calendar';
import { shiftsMarketProcessed } from '@/lib/Shift';
import { getShopShifts, addNewShiftByShopId, saveShiftById, deleteShiftByShopId, deleteShiftById } from '@/api/shop';
import { getAllPartnersList } from '@/api/partners';
import SiteModule from '@/store/site';
import ModalsModule from '@/store/modals';
import ShopEntityModule from '@/store/shops/entity';
import { InputSuggestion } from '@/lib/formFactory/inputSuggestion.interface';
import { Select, SelectItem, SelectList } from '@/lib/formFactory/select.interface';

import { getClientShiftById, makeCheckIn } from '@/api/shop';
import { getVacancyId } from '@/api/vacancies';
import { getServiceTypesByStore } from '@/api/templates';

import moment from 'moment';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { NewShiftEmployeeInterface, ShiftEntityInterface } from '@/store/shops/shiftModalParams';
import { strings } from '@/lib/stringConst';
import { GIGANT_INN } from '@/lib/util/consts';
import { CatchFormResponse } from '@/interfaces/shared';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { PaymentStrategies } from '@/lib/PaymentStrategies';
import { getLunchTimeFormat, getLateHours, getWorkHours, getCalendarWorkHours } from '@/lib/ShiftEmployees';

export const MODULE_NAME = 'shopsShifts';

export interface PositionSelectData {
  needShifts: number;
  filledShifts: number;
  count: number;
}

interface Model {
  position: Select<PositionSelectData>;
  employee: InputSuggestion;
}

export interface PartnerOption {
  id: string;
  value: string;
  inn?: string;
  isSupervisor?: boolean;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopShiftsModule extends CalendarBaseModule {
  partners: PartnerOption[] = [];
  model: Model;
  modalParams: ShiftModalParamsInterface = modalParams;
  employeeId: number | null;
  employeeIsSelected: boolean;
  uniqueShiftIds: Set<number> = new Set();
  employeePaymentPartners: string[] = [];

  get isShopOwner(): boolean {
    return ShopEntityModule.isShopOwner;
  }

  get gigantPartner(): PartnerOption | undefined {
    return this.partners.find(({ inn }) => inn === GIGANT_INN);
  }

  get paymentPartners(): PartnerOption[] {
    const partnerUuids = new Set(this.employeePaymentPartners);

    partnerUuids.add(this.modalParams.edit.model.partnerUuid);

    if (this.gigantPartner) {
      partnerUuids.add(this.gigantPartner.id);
    }

    const partnerOptions = Array.from(partnerUuids)
      .sort()
      .map((partnerUuid: string) => this.partners.find((partnerOption) => partnerOption.id === partnerUuid))
      .filter(Boolean);

    return partnerOptions as PartnerOption[];
  }

  constructor(module: ShopShiftsModule) {
    super(module);

    this.calendarEntity = new CalendarAbstract();
    const shopsShiftModel = new ShopsShiftModel();
    this.model = shopsShiftModel.model;
    this.employeeId = null;
    this.employeeIsSelected = false;
  }

  @Mutation
  SET_NEED_COUNT_EMPLOYEES(payload: { type: string; value: string }) {
    (this.modalParams[payload.type].model as any).needCountEmployees = payload.value;
  }

  @Mutation
  SET_MODAL_SHIFT_TITLE(payload: { type: string; title: string }) {
    this.modalParams[payload.type].title = payload.title;
  }

  @Mutation
  SET_MODAL_SHIFT_STORE(payload: { type: string; value: string }) {
    this.modalParams[payload.type].store = payload.value;
  }

  @Mutation
  SET_MODAL_TIMEZONE(payload: { type: string; value: string }) {
    this.modalParams[payload.type].timeZone = payload.value;
  }

  @Mutation
  SET_MODAL_SHIFT_POSITION(payload: { type: string; value: string }) {
    this.modalParams[payload.type].position = payload.value;
  }

  @Mutation
  SET_MODAL_RATE(payload: { type: string; value: string }) {
    this.modalParams[payload.type].rate = payload.value;
  }

  @Mutation
  SET_MODAL_EDITED_RATE(payload: { type: string; value: string }) {
    (this.modalParams[payload.type].model as any).editedRate = payload.value;
  }

  @Mutation
  SET_MODAL_EXTERNAL_RATE(payload: { type: string; value: string }) {
    this.modalParams[payload.type].externalRate = payload.value;
  }

  @Mutation
  SET_MODAL_PAYMENT_STRATEGY(payload: { type: string; value: { id: string; value: string } }) {
    this.modalParams[payload.type].paymentStrategy = payload.value;
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    this.modalParams[payload.type].isShow = payload.bool;
  }

  @Mutation
  SET_POSITIONS(selected: SelectItem<PositionSelectData>) {
    this.model.position.current = selected;
  }

  @Mutation
  SET_TRAINING(payload: { type: string; value: boolean }) {
    this.modalParams.add.training = payload.value;
  }

  @Mutation
  SET_POSITIONS_LIST(list: SelectList<PositionSelectData>) {
    this.model.position.list = list;
  }

  @Mutation
  SET_STATUSES(list: { id: string; value: string }[]) {
    this.modalParams.edit.model.status.list = list;
  }

  @Mutation
  SET_DELETE_SHOP_ID(id: string) {
    this.modalParams.deleteWarning.shopId = id;
  }

  @Mutation
  SET_DELETE_VACANCY_ID(id: string) {
    this.modalParams.deleteWarning.vacancyId = id;
  }

  @Mutation
  SET_DELETE_SHOP_ACTION(handler: string) {
    this.modalParams.deleteWarning.handlers.yes = handler;
  }

  @Mutation
  SET_SHOP_EMPLOYEE(value: { id: string; name: string }) {
    this.model.employee.value = value.name;
    this.model.employee.current = value;
  }

  @Mutation
  UPDATE_IS_SHOW_INFO_EMPLOYEE(payload: { employeeId: string; bool: boolean }) {
    let index: string;
    let employee: { employee: { id: string } };

    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.employeeId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].isShowInfo = payload.bool;
      break;
    }
  }

  @Mutation
  SET_EMPLOYEE_ID(id: number) {
    this.employeeId = id;
  }

  @Mutation
  SET_UNIQUE_IDS(ids: Set<number>) {
    this.uniqueShiftIds = ids;
  }

  @Mutation
  SET_EMPLOYEE_IS_SELECTED(bool: boolean) {
    this.employeeIsSelected = bool;
  }

  @Mutation
  SET_PARTNERS(partners: PartnerOption[]) {
    this.partners = partners;
  }

  @Mutation
  SET_EMPLOYEE_PAYMENT_PARTNERS(partners: string[]) {
    this.employeePaymentPartners = partners;
  }

  @Action({ rawError: true })
  setNeedCountEmployees(value: { type: string; value: string | number }) {
    this.context.commit('SET_NEED_COUNT_EMPLOYEES', value);
  }

  @Action({ rawError: true })
  setModalEditedRate(value: { type: string; value: number }) {
    this.context.commit('SET_MODAL_EDITED_RATE', value);
  }

  @Action({ rawError: true })
  async updateShifts(params: { vacancyId: string; shopId?: string }) {
    let result = [];

    if (params.shopId) {
      result = (await this.getShopServiceTypes(params.shopId)) || [];
    } else {
      const position = await getVacancyId(params.vacancyId);
      result.push(position);
    }

    const position = Object.values(result).map((position) => {
      return {
        id: position.id,
        value: position.name,
        data: {
          needShifts: position.needShifts,
          filledShifts: position.filledShifts,
          count: position.count,
        },
      };
    });

    let currentVacancy;
    if (params.vacancyId) {
      currentVacancy = Object.values(position).filter((vacancy) => parseInt(vacancy.id) === parseInt(params.vacancyId))[0];
    }

    try {
      this.updatePositionList(position);
      await this.updatePosition(currentVacancy || position[0]);
    } catch (err) {
      console.log(err);
    }
  }

  @Action({ rawError: true })
  async initShifts(shopId: string) {
    const result = await this.getShopServiceTypes(shopId);

    if (result) {
      const position = Object.values(result).map((position) => {
        return {
          id: position.id.toString(),
          value: position.name,
          data: {
            needShifts: position.needShifts,
            filledShifts: position.filledShifts,
            count: position.count,
          },
        };
      });

      this.updatePositionList(position);
      await this.updatePosition(position[0]);

      await this.defaultCreateCalendar(shopId);
    }
  }

  @Action({ rawError: true })
  async initShiftByServiceType(params: { shopId: string; serviceTypeId: string }) {
    const result = await this.getShopServiceTypes(params.shopId);

    const position = Object.values(result || []).map((position) => {
      return {
        id: position.id.toString(),
        value: position.name,
        data: {
          needShifts: position.needShifts,
          filledShifts: position.filledShifts,
          count: position.count,
        },
      };
    });

    this.updatePositionList(position);
    const currentServiceType = Object.values(position).find(
      (serviceType) => serviceType.id.toString() === params.serviceTypeId
    );

    if (currentServiceType) {
      await this.updatePosition(currentServiceType);
    }

    await this.defaultCreateCalendar(params.shopId);
  }

  @Action({ rawError: true })
  async getShopServiceTypes(shopId: string) {
    try {
      return await getServiceTypesByStore(shopId);
    } catch (error) {
      if (error.response.data.errors?.fields) {
        ResponseHandlerModule.showNotify({
          message: error.response.data.errors.fields,
          type: 'fail',
        });
      }
    }
  }

  @Action({ rawError: true })
  updatePositionList(list: {}) {
    this.context.commit('SET_POSITIONS_LIST', list);
  }

  @Action({ rawError: true })
  async updatePosition(selected: SelectItem<PositionSelectData>) {
    await this.context.commit('SET_POSITIONS', selected);
  }

  @Action({ rawError: true })
  async defaultCreateCalendar(shopId: string) {
    this.calendarEntity.startWeek = 0;
    await this.initWeeksState();
    await this.getShifts(shopId);
    this.initStatuses();
  }

  @Action({ rawError: true })
  async getShifts(shopId: string) {
    try {
      let result = {};

      if (this.model.position.current && this.model.position.current.id) {
        result = await getShopShifts(
          shopId,
          this.model.position.current.id,
          this.startWeek - 1,
          this.endWeek + 1,
          this.employeeId
        );
      } else {
        ResponseHandlerModule.showNotify({
          message: 'Создайте вакансии',
          type: 'fail',
        });
      }

      await this.setShifts(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>).response.data.errors.fields ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  setUniqueShiftIds(shiftsByDays: { setUniqueShiftIds: Set<number> }) {
    const { setUniqueShiftIds } = shiftsByDays;
    this.context.commit('SET_UNIQUE_IDS', setUniqueShiftIds);
  }

  @Action({ rawError: true })
  async setShifts(shifts: Record<string, Record<string, string | Record<string, ShopsShiftModel[]>>[]>) {
    const shiftsByDays = await shiftsMarketProcessed(shifts);

    this.setUniqueShiftIds(shiftsByDays as { setUniqueShiftIds: Set<number> });

    await this.context.commit('SET_SHIFTS', {});
    await this.context.commit('SET_SHIFTS', shiftsByDays);

    await this.calendarEntity.createWeeks(shiftsByDays);
    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);
  }

  @Action({ rawError: true })
  async getWeekByDate(params: { date: Date; shopId: string }) {
    try {
      if (isNaN(params.date?.valueOf())) {
        return;
      }

      const { startWeek, endWeek, isSameWeek } = await this.prepareWeekRange(params.date);

      if (isSameWeek) {
        return;
      }

      await this.context.commit('SET_IS_BLOCK', true);

      const shifts = await getShopShifts(
        params.shopId,
        this.model.position.current.id,
        this.startWeek - 1,
        this.endWeek,
        this.employeeId
      );

      const shiftsByDays = (await shiftsMarketProcessed(shifts)) as Record<string, unknown>;
      this.setUniqueShiftIds(shiftsByDays as { setUniqueShiftIds: Set<number> });

      await this.setCalendarRange({ startWeek, endWeek, shiftsByDays });
    } finally {
      await this.context.commit('SET_IS_BLOCK', false);
    }
  }

  @Action({ rawError: true })
  async getPrevWeek(shopId: string) {
    await this.context.commit('SET_IS_BLOCK', true);

    await this.context.commit('SET_START_WEEK', this.countWeekStart - 1);
    await this.context.commit('SET_END_WEEK', this.countWeekStart);

    const shifts = await getShopShifts(
      shopId,
      this.model.position.current.id,
      this.startWeek - 1,
      this.endWeek,
      this.employeeId
    );

    const shiftsByDays = await shiftsMarketProcessed(shifts);

    this.setUniqueShiftIds(shiftsByDays as { setUniqueShiftIds: Set<number> });

    await this.calendarEntity.addWeek(this.startWeek, shiftsByDays);
    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);

    this.context.commit('SET_COUNT_START_WEEK', this.countWeekStart - 1);

    await this.context.commit('SET_IS_BLOCK', false);
  }

  @Action({ rawError: true })
  async getNextWeek(shopId: string) {
    await this.context.commit('SET_IS_BLOCK', true);

    const nextEndWeek = this.countWeekEnd + 1;
    await this.context.commit('SET_START_WEEK', this.countWeekEnd);
    await this.context.commit('SET_END_WEEK', nextEndWeek);

    const shifts = await getShopShifts(
      shopId,
      this.model.position.current.id,
      this.startWeek,
      this.endWeek + 1,
      this.employeeId
    );

    const shiftsByDays = await shiftsMarketProcessed(shifts);

    this.setUniqueShiftIds(shiftsByDays as { setUniqueShiftIds: Set<number> });

    this.calendarEntity.addWeek(nextEndWeek, shiftsByDays);
    this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);

    this.context.commit('SET_COUNT_END_WEEK', this.countWeekEnd + 1);

    await this.context.commit('SET_IS_BLOCK', false);
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });

    return new Promise<void>((resolve) => {
      const unwatch = watch(
        () => this.modalParams[type].isShow,
        () => {
          resolve();
          unwatch();
        }
      );
    });
  }

  @Action({ rawError: true })
  async hideModal(type: string) {
    await this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
    this.resetModel();
    SiteModule.setIsBlock(false);
  }

  @Action({ rawError: true })
  modalInit(params: { type: string; date?: string; shopId?: string }) {
    this.setShiftDateDefault({
      type: params.type,
      date: params.date,
      shopId: params.shopId,
    });
  }

  @Action({ rawError: true })
  resetModel() {
    this.setNeedCountEmployees({ type: 'edit', value: '' });
    this.context.commit('SET_IS_SHIFT_LOAD', true);
    this.context.commit('UPDATE_PARTNER_UUID', { type: 'add', partnerUuid: '' });
    this.context.commit('UPDATE_PARTNER_NAME', { type: 'add', partnerName: '' });

    this.context.commit('UPDATE_LUNCH', {
      type: 'add',
      value: { type: 'hour', value: '00' },
    });

    this.context.commit('UPDATE_LUNCH', {
      type: 'add',
      value: { type: 'minute', value: '00' },
    });

    this.context.commit('SET_NOT_DIVIDE', false);
    this.context.commit('SET_INCLUDE_WEEKDAYS', true);
    this.context.commit('SET_INCLUDE_WEEKENDS', true);
  }

  @Action({ rawError: true })
  async setShiftDateDefault(params: { type: string; date?: string; shopId?: string }) {
    const current = params.date || moment().add(1, 'days').format('MM/DD/YYYY');

    if (params.type === 'add' && params.shopId) {
      this.updateDates([moment(current).format('YYYY-MM-DD')]);

      return;
    }

    this.updateDeleteDateStart(moment(current).format('DD.MM.YYYY'));
    this.updateDeleteDateEnd(moment(current).format('DD.MM.YYYY'));
  }

  @Action({ rawError: true })
  async getShiftInfoById(shiftId: string) {
    try {
      return await getClientShiftById(shiftId);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  initStatuses() {
    const statuses = [];
    for (const [index, status] of Object.entries(this.statusesByTime.default)) {
      statuses.push({
        id: index,
        value: status,
      });
    }
    this.context.commit('SET_STATUSES', statuses);
  }

  @Action({ rawError: true })
  updateStatusesForEdit(shift: ShiftEntityInterface) {
    const statusesByTime = this.statusesByTime;
    const nowDate = moment();
    const endDate = moment(shift.end).utcOffset(shift.clientMarket.timeZone, true);
    const startDate = moment(shift.start).utcOffset(shift.clientMarket.timeZone, true);
    const checkIsStartEarlierThan = (hours: number): boolean => startDate < nowDate.clone().add(hours, 'hours');

    const setStatuses = (key: keyof typeof statusesByTime): void => {
      const statuses = Object.entries(statusesByTime[key]).map(([id, value]) => ({ id, value }));
      this.context.commit('SET_STATUSES', statuses);
    };

    switch (true) {
      case endDate < nowDate:
        return setStatuses('shiftEnded');
      case startDate < nowDate:
        return setStatuses('shiftStarted');
      case checkIsStartEarlierThan(2):
        return setStatuses('beforeShift2hours');
      case checkIsStartEarlierThan(12):
        return setStatuses('beforeShift12hours');
      case checkIsStartEarlierThan(24):
        return setStatuses('beforeShift24hours');
      case checkIsStartEarlierThan(72):
        return setStatuses('beforeShift72hours');
      default:
        setStatuses('default');
    }
  }

  @Action({ rawError: true })
  getPaymentPartnerByStrategy(params: {
    shiftPartner: PartnerOption;
    employeePartner: PartnerOption;
    paymentStrategy: string;
  }) {
    let partner: PartnerOption | undefined;

    switch (params.paymentStrategy) {
      case PaymentStrategies.PartnerPays:
        partner = params.shiftPartner;
        break;
      case PaymentStrategies.GigantPays:
        partner = this.gigantPartner;
        break;
      case PaymentStrategies.PartnerAndGigantPays:
        partner = params.shiftPartner.id === params.employeePartner.id ? params.employeePartner : this.gigantPartner;
    }

    return partner;
  }

  @Action({ rawError: true })
  setShiftInfo(shift: ShiftEntityInterface) {
    const name = shift.clientMarket.fullName;
    const type = 'edit';

    this.context.commit('SET_MODAL_SHIFT_TITLE', {
      type,
      title: `Заказ ${moment(shift.start).format('dd DD.MM.YYYY')}`,
    });

    this.context.commit('SET_MODAL_TIMEZONE', {
      type,
      value: shift.clientMarket.timeZone,
    });

    this.context.commit('SET_MODAL_SHIFT_STORE', {
      type,
      value: name,
    });

    this.context.commit('SET_MODAL_SHIFT_POSITION', {
      type,
      value: this.model.position.current.value,
    });

    this.context.commit('SET_MODAL_EDITED_RATE', {
      type,
      value: shift.rate,
    });

    this.updateEditIntervalStart({
      type: 'hour',
      value: moment(shift.start).format('HH'),
    });

    this.updateEditIntervalStart({
      type: 'minute',
      value: moment(shift.start).format('mm'),
    });

    this.updateEditIntervalEnd({
      type: 'hour',
      value: moment(shift.end).format('HH'),
    });

    this.updateEditIntervalEnd({
      type: 'minute',
      value: moment(shift.end).format('mm'),
    });
    if (shift.paymentStrategy) {
      this.context.commit('UPDATE_PAYMENT_STRATEGY', { value: shift.paymentStrategy.type, type });
    }

    const lunchFormat = getLunchTimeFormat(shift.lunch ? shift.lunch : 0);

    this.context.commit('SET_LUNCH', {
      type,
      value: {
        type: 'hour',
        value: lunchFormat.hours,
      },
    });

    this.context.commit('SET_LUNCH', {
      type,
      value: {
        type: 'minute',
        value: lunchFormat.minutes,
      },
    });

    this.context.commit('SET_LUNCH_MINUTES', {
      type,
      value: shift.lunch,
    });

    this.setNeedCountEmployees({ type: 'edit', value: shift.needCount });
    this.updateIsAdditional({ bool: shift.isAdditional });
    this.updateIsShared({ bool: shift.sharing === 'all' });
    this.updateNotDevide({ bool: shift.notDevide });
    this.updatePartnerUuid({ partnerUuid: shift.partner?.uuid, type: 'edit' });
    this.updatePartnerName({ partnerName: shift.partner?.legalName, type: 'edit' });
    this.updatePaymentStrategy({ paymentStrategy: shift.paymentStrategy.type, type: 'edit' });

    const nowTimestamp = new Date().getTime() / 1000;
    const shiftStartTimestamp = new Date(shift.start).getTime() / 1000;
    const shiftEndTimestamp = new Date(shift.start).getTime() / 1000;
    shift.isStart = shiftStartTimestamp < nowTimestamp;
    shift.isStartIn12Hours = shiftStartTimestamp < nowTimestamp + 12 * 60 * 60;
    shift.isStartIn2Hours = shiftStartTimestamp < nowTimestamp + 2 * 60 * 60;
    shift.isStartPast45Minutes = shiftStartTimestamp + 45 * 60 > nowTimestamp;
    shift.isEndPast5Hours = shiftEndTimestamp + 5 * 60 * 60 < nowTimestamp;

    let countEmployee = 0;
    const employeePaymentPartners = new Set();

    const employees = Object.values(shift.shiftEmployees).map((employee: ShiftEmployeeInterface) => {
      employeePaymentPartners.add(employee.paymentPartner.uuid);

      const calendarWorkHours = getCalendarWorkHours(employee.employee.id, shift);
      const workHours = getWorkHours(employee.start, employee.end, employee.lunch ? employee.lunch : 0);
      const lateStatus = getLateHours(employee.start, shift.start);

      let icon = 'smile-neutral';
      icon = employee.employee.marketFavorite === 'is_liked' ? 'smile-like' : icon;
      icon = employee.employee.marketFavorite === 'is_favourite' ? 'smile-love' : icon;
      icon = employee.employee.marketFavorite === 'is_hated' ? 'smile-hated' : icon;
      icon = employee.isAutomatic ? `${icon}-a` : icon;

      const lunchEmployeeFormat = getLunchTimeFormat(employee.lunch ? employee.lunch : 0);

      if (!shift.shift.countEmployee && !this.notCountStatuses.includes(employee.generalStatus)) {
        countEmployee += 1;
      }

      let isEditInfo = false;
      if ((employee.generalStatus === 'came_out' || employee.generalStatus === 'fast_replacement') && shift.past) {
        isEditInfo = true;
      }

      if ((employee.generalStatus === 'is_planned' || employee.generalStatus === 'fast_replacement') && shift.isStart) {
        isEditInfo = true;
      }

      if (
        (employee.generalStatus === 'is_planned' || employee.generalStatus === 'fast_replacement') &&
        shift.isStartIn12Hours
      ) {
        isEditInfo = true;
      }

      if (calendarWorkHours) {
        Object.assign(employee.employee, {
          calendarHours: calendarWorkHours.workHours,
          workHours: calendarWorkHours.actualWork,
        });
      }

      return {
        id: employee.id,
        statusId: employee.statusId,
        generalStatus: employee.generalStatus,
        end: employee.end,
        start: employee.start,
        intervalStart: {
          hour: employee.start ? moment(employee.start).format('HH') : '',
          minute: employee.start ? moment(employee.start).format('mm') : '',
        },
        intervalEnd: {
          hour: employee.end ? moment(employee.end).format('HH') : '',
          minute: employee.end ? moment(employee.end).format('mm') : '',
        },
        workHours: workHours,
        lateTime: lateStatus.time,
        isLate: lateStatus.isLate,
        comment: employee.comment && employee.comment !== 'null' ? employee.comment : '',
        isAutomatic: employee.isAutomatic,
        employee: employee.employee,
        rate: employee.rate,
        icon: icon,
        lunch: {
          hour: lunchEmployeeFormat.hours,
          minute: lunchEmployeeFormat.minutes,
        },
        lunchMinutes: employee.lunch ? employee.lunch : 0,
        isShowInfo: false,
        isEditInfo: isEditInfo,
        belongToPartner: employee.belongToPartner,
        isStart: shift.isStart,
        isStartIn12Hours: shift.isStartIn12Hours,
        isStartIn2Hours: shift.isStartIn2Hours,
        isStartPast45Minutes: shift.isStartPast45Minutes,
        yellowCard: employee.yellowCard ? 1 : 0,
        checkedIn: employee.checkedIn,
        checkedOut: employee.checkedOut,
        needCheckin: shift.needCheckin,
        paymentPartner: employee.paymentPartner.uuid,
        paymentPartnerName: employee.paymentPartner.legalName,
        checkedInTime: employee.checkedInTime,
        checkedOutTime: employee.checkedOutTime,
      };
    });

    shift.shift.countEmployee = !shift.shift.countEmployee ? String(countEmployee) : shift.shift.countEmployee;

    this.context.commit('SET_EMPLOYEE_PAYMENT_PARTNERS', Array.from(employeePaymentPartners));
    this.context.commit('UPDATE_EMPLOYEE', employees);
    this.context.commit('SET_SHIFT', shift);
    this.context.commit('SET_IS_SHIFT_LOAD', false);
  }

  @Action({ rawError: true })
  async addNewShift(params: { shopId: string; lunchMinutes: number }) {
    try {
      const data = await this.prepareDataShift();
      if (data) {
        data.lunch = params.lunchMinutes;
      }
      const result = await addNewShiftByShopId(params.shopId, data as {});

      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Заказ создан',
          type: 'ok',
        });
        await this.hideModal('add');
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      SiteModule.setIsBlock(false);
      if (error.response.data.message) {
        ResponseHandlerModule.showNotify({
          message: error.response.data.message,
          type: 'fail',
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: error.response.data.errors.fields,
          type: 'fail',
        });
      }
    }
  }

  @Action({ rawError: true })
  async prepareDataShift() {
    try {
      const data: Record<string, string | number> = {
        vacancy: this.model.position.current.id,
        intervalStart: `${this.modalParams.add.model.intervalStart.hour}:${this.modalParams.add.model.intervalStart.minute}:00`,
        intervalEnd: `${this.modalParams.add.model.intervalEnd.hour}:${this.modalParams.add.model.intervalEnd.minute}:00`,
        needCountEmployees: this.modalParams.add.model.needCountEmployees,
      };

      data['includeWeekdays'] = this.modalParams.add.model.includeWeekdays.list[0].checked ? 1 : 0;
      data['includeWeekends'] = this.modalParams.add.model.includeWeekends.list[0].checked ? 1 : 0;
      data['isAdditional'] = this.modalParams.add.model.isAdditional.list[0].checked ? 1 : 0;
      data['sharing'] = this.modalParams.add.model.isShared.list[0].checked ? 'all' : 'none';
      data['notDevide'] = this.modalParams.add.model.notDevide.list[0].checked ? 1 : 0;

      if (this.modalParams.add.model.partnerUuid) {
        data['partner'] = this.modalParams.add.model.partnerUuid;
      }

      data['lunch'] = 0;
      if (this.modalParams.add.model.notDevide.list[0].checked) {
        data['lunch'] =
          parseInt(this.modalParams.add.model.lunch.hour) * 60 + parseInt(this.modalParams.add.model.lunch.minute);
      }
      data['rate'] = this.modalParams.add.model.editedRate;

      this.modalParams.add.model.dates.forEach((date: string, index: number) => {
        data[`dates[${index}]`] = date;
      });

      if (UserModule.isSupervisor) {
        data['paymentStrategy'] = this.modalParams.add.model.paymentStrategy;
      }

      return data;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updateEmployee(params: {
    id: string;
    name: string;
    rate: number;
    isSelfEmployed: boolean | null;
    partner: { uuid: string; legalName: string };
    calendarHours?: number | null;
    workHours?: number | null;
    sharing: string;
  }) {
    const list = this.modalParams.edit.model.employees.list;
    const listValues = Object.values(list);

    const isAdded = (listValues as { id: string }[]).filter((item: { id: string }) => item.id === params.id).length > 0;

    if (isAdded) {
      return;
    }

    let employee: Record<number, NewShiftEmployeeInterface> | {} = {};
    const index = listValues.length;
    let generalStatus: NewShiftEmployeeInterface['generalStatus'] = this.modalParams.edit.shift.past
      ? 'came_out'
      : 'is_planned';

    if (
      !this.modalParams.edit.shift.past &&
      Number(this.modalParams.edit.model.needCountEmployees) <=
        Object.values(this.modalParams.edit.model.employees.list).filter((obj) => {
          return obj.statusId == 'is_planned';
        }).length
    ) {
      generalStatus = 'reserve';
    }
    const icon = 'smile-neutral';

    const nowTimestamp = new Date().getTime() / 1000;
    const shiftStartTimestamp = new Date(this.modalParams.edit.shift.start).getTime() / 1000;
    this.modalParams.edit.shift.isStart = shiftStartTimestamp < nowTimestamp;
    this.modalParams.edit.shift.isStartIn12Hours = shiftStartTimestamp < nowTimestamp + 12 * 60 * 60;

    const paymentPartner = await this.context.dispatch('getPaymentPartnerByStrategy', {
      shiftPartner: { id: this.modalParams.edit.model.partnerUuid, value: this.modalParams.edit.model.partnerName },
      employeePartner: { id: params.partner.uuid, value: params.partner.legalName },
      paymentStrategy: this.modalParams.edit.model.paymentStrategy,
    });

    (employee as Record<number, NewShiftEmployeeInterface>)[index] = {
      isNew: true,
      id: params.id,
      name: params.name,
      value: params.name,
      statusId: generalStatus,
      generalStatus: generalStatus,
      paymentPartner: paymentPartner?.id,
      paymentPartnerName: paymentPartner?.value,
      intervalStart: {
        hour: this.modalParams.edit.model.intervalStart.hour,
        minute: this.modalParams.edit.model.intervalStart.minute,
      },
      intervalEnd: {
        hour: this.modalParams.edit.model.intervalEnd.hour,
        minute: this.modalParams.edit.model.intervalEnd.minute,
      },
      end: '',
      start: '',
      workHours: '',
      late: '',
      comment: '',
      isAutomatic: false,
      employee: {
        id: params.id,
        fullName: params.name,
        rate: params.rate,
        isSelfEmployed: params.isSelfEmployed,
        calendarHours: params.calendarHours,
        workHours: params.workHours,
        partner: params.partner,
        sharing: params.sharing,
      },
      rate: 0,
      icon: icon,
      lunch: {
        hour: this.modalParams.edit.model.lunch.hour,
        minute: this.modalParams.edit.model.lunch.minute,
      },
      lunchMinutes: this.modalParams.edit.model.lunch.lunchMinutes,
      isShowInfo: false,
      isStartIn12Hours: this.modalParams.edit.shift.isStartIn12Hours,
      quantity: 0,
      price: 0,
    };

    employee = { ...this.modalParams.edit.model.employees.list, ...employee };
    this.context.commit('UPDATE_EMPLOYEE', employee);
  }

  @Action({ rawError: true })
  updateIsShowInfoEmployee(payload: { employeeId: string; bool: boolean }) {
    this.context.commit('UPDATE_IS_SHOW_INFO_EMPLOYEE', payload);
  }

  @Action({ rawError: true })
  async updateShiftInfo() {
    try {
      const data = await this.prepareShiftInfo();
      if (data) {
        const employeeList = this.modalParams.edit.model.employees.list;

        for (const index in employeeList) {
          data[`employees[${index}][lunch]`] = employeeList[index].lunchMinutes;
        }
      }

      const result = await saveShiftById(this.modalParams.edit.shift.id, data as {});

      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Заказ обновлен',
          type: 'ok',
        });
        this.hideModal('edit');

        return true;
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });

        return false;
      }
    } catch (error) {
      const message = error.response.data.message
        ? error.response.data.message
        : Object.values(error.response.data.errors.fields).join(',');

      ResponseHandlerModule.showNotify({ message: message, type: 'fail' });

      return false;
    }
  }

  @Action({ rawError: true })
  async prepareShiftInfo() {
    try {
      const data: Record<string, string | number | boolean | null> = {
        intervalStart: `${this.modalParams.edit.model.intervalStart.hour}:${this.modalParams.edit.model.intervalStart.minute}:00`,
        intervalEnd: `${this.modalParams.edit.model.intervalEnd.hour}:${this.modalParams.edit.model.intervalEnd.minute}:00`,
        needCountEmployees: this.modalParams.edit.model.needCountEmployees,
        isAdditional: this.modalParams.edit.model.isAdditional.list[0].checked ? 1 : 0,
        sharing: this.modalParams.edit.model.isShared.list[0].checked ? 'all' : 'none',
        notDevide: this.modalParams.edit.model.notDevide.list[0].checked ? 1 : 0,
        rate: this.modalParams.edit.model.editedRate,
      };

      if (this.modalParams.edit.model.partnerUuid && this.modalParams.edit.model.partnerUuid.length) {
        data['partner'] = this.modalParams.edit.model.partnerUuid;
      }

      data['lunch'] = null;
      if (this.modalParams.edit.model.notDevide.list[0].checked || this.modalParams.edit.shift.past) {
        data['lunch'] = this.modalParams.edit.model.lunch.lunchMinutes;
      }

      if (UserModule.isSupervisor) {
        data['paymentStrategy'] = this.modalParams.edit.model.paymentStrategy;
      }

      let index: string;
      let employee: NewShiftEmployeeInterface;
      for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
        data[`employees[${index}][employee]`] = employee.employee.id;
        data[`employees[${index}][statusId]`] = employee.generalStatus;
        data[`employees[${index}][paymentPartner]`] = employee.paymentPartner;
        data[`employees[${index}][isStartIn12Hours]`] = this.modalParams.edit.shift.isStartIn12Hours;

        if (
          (employee.generalStatus === 'came_out' ||
            employee.generalStatus === 'is_planned' ||
            employee.generalStatus === 'fast_replacement') &&
          employee.intervalStart.hour &&
          employee.intervalEnd.hour
        ) {
          data[`employees[${index}][intervalStart]`] = `${employee.intervalStart.hour}:${employee.intervalStart.minute}:00`;
          data[`employees[${index}][intervalEnd]`] = `${employee.intervalEnd.hour}:${employee.intervalEnd.minute}:00`;
        }

        data[`employees[${index}][comment]`] = employee.comment;

        data[`employees[${index}][lunch]`] = parseInt(employee.lunch.hour) * 60 + parseInt(employee.lunch.minute);

        if (typeof employee.yellowCard !== 'undefined') {
          data[`employees[${index}][yellowCard]`] = employee.yellowCard ? 1 : 0;
        }
      }

      return data;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updateCalendarByCurrentWeeks(shopId: string) {
    const shifts = await this.getShiftByWeeks({
      shopId: shopId,
      start: this.countWeekStart,
      end: this.countWeekEnd,
    });

    const shiftsByDays = await shiftsMarketProcessed(shifts);

    this.setUniqueShiftIds(shiftsByDays as { setUniqueShiftIds: Set<number> });

    await this.context.commit('SET_SHIFTS', {});
    await this.context.commit('SET_SHIFTS', shiftsByDays);

    await this.calendarEntity.setStartWeek(this.countWeekStart);
    await this.calendarEntity.setEndWeek(this.countWeekEnd);
    await this.calendarEntity.createWeeks(shiftsByDays);
    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);
  }

  @Action({ rawError: true })
  async getShiftByWeeks(params: { shopId: string; start: number; end: number }) {
    try {
      return await getShopShifts(
        params.shopId,
        this.model.position.current.id,
        params.start - 1,
        params.end + 1,
        this.employeeId
      );
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>).response.data.errors.fields ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  deleteEmployeeFromShift(id: string) {
    const employees = Object.values(this.modalParams.edit.model.employees.list).filter(
      (employee: { employee: { id: string } }) => employee.employee.id !== id
    );
    this.context.commit('UPDATE_EMPLOYEE', employees);
  }

  @Action({ rawError: true })
  deleteShiftWarning(params: { vacancyId: string; shopId: string; handler: string }) {
    this.context.commit('SET_DELETE_SHOP_ID', params.shopId);
    this.context.commit('SET_DELETE_VACANCY_ID', params.vacancyId);
    this.context.commit('SET_DELETE_SHOP_ACTION', params.handler);
    ModalsModule.updateParams(this.modalParams.deleteWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deleteShift() {
    try {
      const params: string = await this.prepareDeleteShiftParams();
      const result = await deleteShiftByShopId(this.modalParams.deleteWarning.shopId, params);

      if (!result.message) {
        const deletedShiftsCounter = result.result.result.deletedCount;
        if (deletedShiftsCounter > 0) {
          ResponseHandlerModule.showNotify({
            message: `Заказы удалены. Количество: ${deletedShiftsCounter}`,
            type: 'ok',
          });
        } else {
          ResponseHandlerModule.showNotify({
            message: 'Заказов подходящих под критерии не обнаружено или их нельзя удалить',
            type: 'fail',
          });
        }

        this.hideModal('delete');
        await this.updateCalendarByCurrentWeeks(this.modalParams.deleteWarning.shopId);
        await this.updateShifts({
          shopId: this.modalParams.deleteWarning.shopId,
          vacancyId: this.modalParams.deleteWarning.vacancyId,
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      await this.updateCalendarByCurrentWeeks(this.modalParams.deleteWarning.shopId);
      if (!error.response.data.message) {
        for (const field of Object.values(error.response.data.errors.fields)) {
          ResponseHandlerModule.showNotify({
            message: (field as any)[0].message,
            type: 'fail',
          });
        }
        this.hideModal('delete');
      } else {
        ResponseHandlerModule.showNotify({
          message: error.response.data.message,
          type: 'fail',
        });
      }
    }
  }

  @Action({ rawError: true })
  async prepareDeleteShiftParams() {
    try {
      let params = `?serviceType=${this.model.position.current.id}`;

      const dateStart = this.modalParams.delete.model.dateStart.value.split('.');
      const dateEnd = this.modalParams.delete.model.dateEnd.value.split('.');
      params += `&dateStart=${dateStart[2]}-${dateStart[1]}-${dateStart[0]}&dateEnd=${dateEnd[2]}-${dateEnd[1]}-${dateEnd[0]}`;

      const deleteWeekends = this.modalParams.delete.model.isExcludeWeekends.list[0].checked ? 1 : 0;
      const deleteWeekdays = this.modalParams.delete.model.isExcludeWeekday.list[0].checked ? 1 : 0;
      params += `&deleteWeekends=${deleteWeekends}`;
      params += `&deleteWeekdays=${deleteWeekdays}`;

      if (this.modalParams.delete.model.isTimeInterval.list[0].checked) {
        params += `&intervalStart=${this.modalParams.delete.model.intervalStart.hour}:${this.modalParams.delete.model.intervalStart.minute}:00&intervalEnd=${this.modalParams.delete.model.intervalEnd.hour}:${this.modalParams.delete.model.intervalEnd.minute}:00`;
      }

      return params;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return '';
    }
  }

  @Action({ rawError: true })
  async deleteShiftById(params?: { id?: string }) {
    try {
      const result = await deleteShiftById(params?.id ? params.id : this.modalParams.edit.shift.id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Заказ удален',
          type: 'ok',
        });
        this.hideModal('edit');
        this.updateCalendarByCurrentWeeks(this.modalParams.deleteWarning.shopId);
        this.resetModel();
      } else {
        ResponseHandlerModule.showNotify({
          message: result.message,
          type: 'fail',
        });
      }
    } catch (error) {
      if (!error.response.data.message) {
        ResponseHandlerModule.showNotify({
          message: error.response.data.errors.fields,
          type: 'fail',
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: error.response.data.message,
          type: 'fail',
        });
      }
    }
  }

  @Action({ rawError: true })
  updateEmployeeId(value: string) {
    this.context.commit('SET_EMPLOYEE_ID', value);
  }

  @Action({ rawError: true })
  updateShiftsByEmployee(shopId: string) {
    this.getShifts(shopId);
  }

  @Action({ rawError: true })
  async updateCheckIn(cmseId: string) {
    try {
      await makeCheckIn({ cmseId });

      ResponseHandlerModule.showNotify({ message: 'Check in успешно проставлен', type: 'ok' });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  resetCalendarModel() {
    this.context.commit('SET_HOURS', {});
    this.context.commit('SET_CALENDAR', {});
    this.context.commit('SET_SHIFTS', {});
    this.context.commit('SET_EMPLOYEE_ID', null);
    this.context.commit('SET_SHOP_EMPLOYEE', { name: '', id: '' });
    this.context.commit('SET_EMPLOYEE_IS_SELECTED', false);
  }

  @Action({ rawError: true })
  async getAllPartners(): Promise<void> {
    let result: PartnerOption[] = [];

    try {
      const response: PartnerSelectListItem[] = await getAllPartnersList();

      result = Object.values(response)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => ({
          id: partner.uuid,
          value: partner.legalName,
          inn: partner.inn ?? '',
          isSupervisor: partner.isSupervisor,
        }));
    } catch (error) {
      console.error(error);
    } finally {
      this.SET_PARTNERS(result);
    }
  }

  get calendarInOrder() {
    const entries = Object.entries(this.calendar);

    if (!entries.length) {
      return { deviation: 0, arr: [] };
    }

    const sortEntries = entries.sort((a, b) => +a[0] - +b[0]);

    return {
      deviation: sortEntries[0][0],
      arr: sortEntries.map((el) => el[1]),
    };
  }
}

export default getModule(ShopShiftsModule);
