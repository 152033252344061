import moment from 'moment/moment.js';
import { getShortName } from '@/lib/Utils';

function parseShift(shift: any, statuses: any, timeZone: number) {
  const startMoment = moment(shift.start).utcOffset(timeZone, true);
  const endMoment = moment(shift.end).utcOffset(timeZone, true);
  const todayMoment = moment().utcOffset(timeZone);

  const startDate = startMoment.format('DD.MM.YYYY');
  const endDate = endMoment.format('DD.MM.YYYY');
  const todayTimestamp = todayMoment.unix();
  const startTimestamp = startMoment.unix();
  const endTimestamp = endMoment.unix();
  const dayOfWeekStart = startMoment.format('ddd');
  const dayStart = startMoment.format('D');
  const monthStart = startMoment.format('MMMM');
  const hoursStart = startMoment.format('H');
  const minutesStart = startMoment.format('mm');
  const hoursEnd = endMoment.format('H');
  const minutesEnd = endMoment.format('mm');

  const isReadyType = shift.entityType === 'ready';

  let classAdd = '';
  let text = '';

  if (isReadyType) {
    classAdd = 'cp-warning cp-ready';
    text = 'Готов работать';
  } else if (shift.entityType === 'busy') {
    classAdd = 'cp-neutral cp-busy cp-cursor-default';
    text = 'На заказе';
  }

  if (shift.entityType === 'shift') {
    switch (shift.status) {
      case 'refused_in_this_market': {
        classAdd = 'cp-warning';
        break;
      }
      case 'did_not_go_out': {
        classAdd = 'cp-error';
        break;
      }
    }
    classAdd = `${classAdd} cp-shift`;
    text = statuses[shift.status];
  }

  const isEndInPast = endTimestamp >= todayTimestamp;
  const isStartInFuture = startTimestamp >= todayTimestamp;
  const isPast = !(isEndInPast && (isStartInFuture || isReadyType));

  //
  // свободное время уже в прошлом - не отображаем его вообще
  //
  if (isPast && isReadyType) {
    return false;
  }

  if (isPast) {
    classAdd = `${classAdd} past`;
  }

  if (hoursEnd === '0' && startDate === endDate) {
    return false;
  }

  const rangeHours = [];
  const rangeHourEnd = parseInt(hoursStart) > parseInt(hoursEnd) ? 24 : parseInt(hoursEnd);
  for (let hour = parseInt(hoursStart); hour <= rangeHourEnd; hour++) {
    rangeHours.push(hour);
  }

  let maxDayEnd = hoursEnd === '0' ? startMoment.format('MM/DD/YYYY 23:59:59') : shift.end;
  if (parseInt(hoursEnd) > 0 && parseInt(hoursEnd) < parseInt(hoursStart)) {
    maxDayEnd = startMoment.format('MM/DD/YYYY 23:59:59');
  }

  const minDayStart = shift.start;

  let howMuchTimeFormatted = '';
  if (isReadyType) {
    const howMuchTime = Math.floor((endTimestamp - startTimestamp) / 60);
    const howMuchTimeHour = howMuchTime >= 60 ? Math.floor(howMuchTime / 60) : 0;
    const howMuchTimeMinute = howMuchTime >= 60 ? Math.ceil(howMuchTime - howMuchTimeHour * 60) : howMuchTime;

    const lunchHourFormat = howMuchTimeHour === 0 ? '0' : howMuchTimeHour.toString();
    let lunchMinuteFormat = howMuchTimeMinute ? howMuchTimeMinute.toString() : '00';

    if (howMuchTime < 10 && howMuchTime !== 0) {
      lunchMinuteFormat = `0${lunchMinuteFormat}`;
    }

    howMuchTimeFormatted = `${lunchHourFormat} ч ${lunchMinuteFormat} мин`;
  }

  return {
    id: ['ready', 'busy'].includes(shift.entityType) ? shift.id : shift.clientMarketShiftId,
    entityType: shift.entityType,
    start: shift.start,
    end: shift.end,
    dateStart: startDate,
    dayOfWeekStart: dayOfWeekStart,
    hoursStart: hoursStart,
    hoursEnd: hoursEnd,
    FormattedHoursStart: startMoment.format('H:mm'),
    FormattedHoursEnd: endMoment.format('H:mm'),
    rangeHours: rangeHours,
    dayStart: dayStart,
    monthStart: monthStart,
    past: isPast,
    classAdd: classAdd,
    gridRowStart: `${hoursStart}${minutesStart}`,
    gridRowEnd: parseInt(hoursEnd) !== 0 ? `${hoursEnd}${minutesEnd}` : '2400',
    text: text,
    clientMarketShiftId: shift.clientMarketShiftId,
    marketId: shift.marketId,
    marketName: shift.marketName,
    marketFullName: shift.marketFullName,
    clientName: shift.clientName,
    vacancyName: shift.vacancyName,
    marketAddress: shift.marketAddress,
    status: shift.status,
    minDayStart: minDayStart,
    maxDayEnd: maxDayEnd,
    howMuchTime: howMuchTimeFormatted,
    price: shift.price,
  };
}

/**
 * Returns the processed shifts for employee calendar
 *
 * @param {Object} shiftsList Employee shifts from API
 * @param {statuses} statuses of shifts
 * @returns {Object} shiftsProcessed
 */
export function shiftsEmployeeProcessed(shiftsList: any, statuses: any, timeZone: number) {
  return new Promise((resolve) => {
    const shiftsProcessed: any = {};

    let shift: any;
    for (shift of Object.values(shiftsList)) {
      const momentShiftStart = moment(shift.start);
      const momentShiftEnd = moment(shift.end);

      const startDate = momentShiftStart.format('DD.MM.YYYY');
      const isShiftEndAtMidnight = momentShiftEnd.format('HH:mm') === '00:00';
      let endDate = momentShiftEnd.format('DD.MM.YYYY');

      if (isShiftEndAtMidnight) {
        //
        // если интервал до 0 часов - это все еще предыдущий день для нас
        //
        endDate = momentShiftEnd.clone().subtract(1, 'day').format('DD.MM.YYYY');
      }

      if (startDate === endDate) {
        //
        // свободное время/смена в пределах одних суток
        //
        const newShift = parseShift(shift, statuses, timeZone);

        if (!newShift) {
          continue;
        }

        if (shiftsProcessed[startDate] && Object.values(shiftsProcessed[startDate])) {
          shiftsProcessed[startDate][Object.values(shiftsProcessed[startDate]).length] = newShift;
        } else {
          shiftsProcessed[startDate] = { 0: newShift };
        }
      } else {
        const daysDelta = moment(endDate, 'DD.MM.YYYY').diff(momentShiftStart.clone().startOf('day'), 'days');

        let currentDate = startDate;
        for (let i = 0; i <= daysDelta; i++) {
          if (currentDate === startDate) {
            //
            // это первый день в интервале
            //
            const newShift = parseShift(
              {
                ...shift,
                end: momentShiftStart.format('YYYY-MM-DDT24:00:00'),
              },
              statuses,
              timeZone
            );

            if (!newShift) {
              //
              // инкрементируем день
              //
              currentDate = moment(currentDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');
              continue;
            }

            if (shiftsProcessed[currentDate] && Object.values(shiftsProcessed[currentDate])) {
              shiftsProcessed[currentDate][Object.values(shiftsProcessed[currentDate]).length] = newShift;
            } else {
              shiftsProcessed[currentDate] = { 0: newShift };
            }
          } else {
            if (currentDate === endDate) {
              //
              // последний день в интервале
              //
              const newShift = parseShift(
                {
                  ...shift,
                  start: momentShiftEnd.format('YYYY-MM-DDT00:00:00'),
                  end: isShiftEndAtMidnight ? momentShiftEnd.format('YYYY-MM-DDT24:00:00') : shift.end,
                },
                statuses,
                timeZone
              );

              if (!newShift) {
                //
                // инкрементируем день
                //
                currentDate = moment(currentDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');
                continue;
              }

              if (shiftsProcessed[currentDate] && Object.values(shiftsProcessed[currentDate])) {
                shiftsProcessed[currentDate][Object.values(shiftsProcessed[currentDate]).length] = newShift;
              } else {
                shiftsProcessed[currentDate] = { 0: newShift };
              }
            } else {
              const newShift = parseShift(
                {
                  ...shift,
                  start: moment(currentDate, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00'),
                  end: moment(currentDate, 'DD.MM.YYYY').format('YYYY-MM-DDT24:00:00'),
                },
                statuses,
                timeZone
              );

              if (!newShift) {
                //
                // инкрементируем день
                //
                currentDate = moment(currentDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');
                continue;
              }

              if (shiftsProcessed[currentDate] && Object.values(shiftsProcessed[currentDate])) {
                shiftsProcessed[currentDate][Object.values(shiftsProcessed[currentDate]).length] = newShift;
              } else {
                shiftsProcessed[currentDate] = { 0: newShift };
              }
            }
          }

          //
          // инкрементируем день
          //
          currentDate = moment(currentDate, 'DD.MM.YYYY').add(1, 'day').format('DD.MM.YYYY');
        }
      }
    }

    Object.values(shiftsProcessed).map((shifts: unknown) => {
      const items = Object.values(shifts as Record<string, { id: string; start: string; end: string }>).filter(
        (item: unknown) => {
          (item as Record<string, string>).crossShiftsIndicator = '';

          return (item as Record<string, string>).entityType === 'shift';
        }
      );

      if (items.length > 1) {
        const crossIds = new Set('');

        items.forEach((shift: unknown, index: number) => {
          if (items[index + 1]) {
            const nextShift = items[index + 1];
            const end = moment((shift as { end: string }).end).unix();
            const start = moment((nextShift as { start: string }).start).unix();
            if (end > start) {
              crossIds.add((shift as { id: string }).id);
              crossIds.add((nextShift as { id: string }).id);
            }
          }
        });

        let crossShiftsIndicator = '';
        Array.from(crossIds).map((item: string) => {
          crossShiftsIndicator = `${crossShiftsIndicator}_${item}`;
        });

        let crossIndex = 0;
        items.map((item: unknown) => {
          if (crossIds.has((item as { id: string }).id)) {
            Object.assign(item, {
              styleMargin: crossIndex * (100 / crossIds.size),
              styleCrossingWidth: 100 / crossIds.size,
              crossShiftsIndicator,
            });
            crossIndex++;
          }
        });
      }
    });

    resolve(shiftsProcessed);
  });
}

/**
 * Returns the processed shifts for market calendar
 *
 * @param {Object} shiftsByDays The market shifts from API
 * @returns {Object} shiftsProcessed
 */
export function shiftsMarketProcessed(shiftsByDays: any) {
  return new Promise((resolve) => {
    const shiftIds: any = new Set();
    const shiftsProcessed: any = {};

    let key: string;
    let shiftsByDay: any;
    for ([key, shiftsByDay] of Object.entries(shiftsByDays)) {
      const dateFormat = moment(key).format('DD.MM.YYYY');
      shiftsProcessed[dateFormat] = {};
      let newShiftIndex = 0;

      let shifts: any;
      for (shifts of Object.values(shiftsByDay)) {
        let shiftIndex: string;
        let shift: any;
        for ([shiftIndex, shift] of Object.entries(shifts.shiftModels)) {
          const dayOfWeekStart = moment(shift.start).format('ddd');
          const dayStart = moment(shift.start).format('D');
          const monthStart = moment(shift.start).format('MMMM');
          const hoursStart = moment(shift.start).format('H');
          const minutesStart = moment(shift.start).format('mm');
          const hoursEnd = moment(shift.end).format('H');
          const minutesEnd = moment(shift.end).format('mm');
          const timeEnd = moment(shift.end).format('HH:mm:ss');

          const rangeHours = [];
          const rangeHourEnd = parseInt(hoursStart) > parseInt(hoursEnd) ? 24 : parseInt(hoursEnd);
          for (let hour = parseInt(hoursStart); hour <= rangeHourEnd; hour++) {
            rangeHours.push(hour);
          }

          let classAdd = shift.countEmployee === 0 ? 'cp-error' : '';
          classAdd = shift.countEmployee > 0 && shift.countEmployee < shift.needCount ? 'cp-warning' : classAdd;
          classAdd = shift.countEmployee > 0 && shift.countEmployee > shift.needCount ? 'cp-more' : classAdd;

          if (Object.values(shifts.shiftModels).length > 1) {
            classAdd = `${classAdd} cp-crossing-shift`;
          }

          if (shift.past) {
            classAdd = `${classAdd} past`;
          }

          if (hoursEnd === '0' && minutesEnd === '00') {
            continue;
          }

          let crossShiftsIndicator = '';
          const crossIds = Object.values(shifts.shiftModels).map((shift: any) => {
            crossShiftsIndicator = `${crossShiftsIndicator}_${shift.id}`;

            return shift.id;
          });

          const employees = shift.employees
            ? Object.values(shift.employees).map((employee: any) => {
                const shortName = getShortName(employee.fullName);

                return {
                  id: employee.id,
                  fullName: employee.fullName,
                  shortName: shortName,
                };
              })
            : {};

          let isShiftExtension = false;
          if (shiftIds.has(shift.id)) {
            isShiftExtension = true;
            shiftIds.delete(shift.id);
          } else {
            shiftIds.add(shift.id);
          }

          shiftsProcessed[dateFormat][newShiftIndex] = {
            id: shift.id,
            needCount: shift.needCount,
            countEmployee: shift.countEmployee,
            start: shift.start,
            end: shift.end,
            isAdditional: shift.isAdditional,
            isTraining: shift.isTraining,
            dateStart: moment(shift.start).format('DD.MM.YYYY'),
            dayOfWeekStart: dayOfWeekStart,
            hoursStart: hoursStart,
            hoursEnd: hoursEnd,
            FormattedHoursStart: moment(shift.start).format('H:mm'),
            FormattedHoursEnd: moment(shift.end).format('H:mm'),
            rangeHours: rangeHours,
            dayStart: dayStart,
            monthStart: monthStart,
            past: shift.past,
            classAdd: classAdd,
            gridRowStart: dateFormat === moment(shift.start).format('DD.MM.YYYY') ? `${hoursStart}${minutesStart}` : '000',
            gridRowEnd:
              dateFormat === moment(shift.end).format('DD.MM.YYYY') && timeEnd !== '23:59:59'
                ? `${hoursEnd}${minutesEnd}`
                : '2400',
            styleMargin: parseInt(shiftIndex) * (100 / Object.values(shifts.shiftModels).length),
            styleCrossingWidth: 100 / Object.values(shifts.shiftModels).length,
            minDayStart: shifts.minStart,
            maxDayEnd: shifts.maxEnd,
            crossIds: crossIds,
            crossShiftsIndicator: crossShiftsIndicator,
            employees: employees,
            crossingCount: Object.values(shifts.shiftModels).length,
            isShiftExtension,
          };

          newShiftIndex = newShiftIndex + 1;
        }
      }
    }

    shiftsProcessed.setUniqueShiftIds = shiftIds;

    resolve(shiftsProcessed);
  });
}
