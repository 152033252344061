import 'reflect-metadata';
import { createApp } from 'vue';

import siteModule from '@/store/site';
import App from './App.vue';
import router from './router';
import store from './store';
import userModule, { TOKEN_STORAGE_KEY } from '@/store/user';

import ResponseHandler from './components/base/ResponseHandler.vue';
import ResponseHandlerStatic from './components/base/ResponseHandlerStatic.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { init as sentryInit } from '@/lib/Sentry';
import TooltipConfig, { VTooltip } from 'v-tooltip';

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';

import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

use([CanvasRenderer, PieChart, BarChart, GridComponent, TooltipComponent]);

const main = async () => {
  userModule.setToken(window.localStorage[TOKEN_STORAGE_KEY]);
  await siteModule.init();

  const app = createApp(App);

  sentryInit(app);

  await app.use(store);
  await app.use(router);

  app.component('ResponseHandler', ResponseHandler);
  app.component('ResponseHandlerStatic', ResponseHandlerStatic);
  app.component('FontAwesomeIcon', FontAwesomeIcon);

  TooltipConfig.options.themes.tooltip.delay.show = 1500;
  TooltipConfig.options.themes.tooltip.delay.hide = 0;

  app.directive('tooltip', VTooltip);

  await router.isReady().then(() => app.mount('#app'));
};

main();
