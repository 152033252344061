import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  date_brief: {
    id: 'date_brief',
    name: 'Сводка по дате',
    template: 'date_brief',
    pathName: 'date_brief',
    icon: 'calendar-alt',
    iconSize: '16',
  },
  payments: {
    id: 'payments',
    name: 'Начисления исполнителям',
    template: 'payments',
    pathName: 'payments',
    icon: 'payments',
    iconSize: '16',
  },
  finance_employee: {
    id: 'finance_employee',
    name: 'Сверка с исполнителем',
    template: 'finance_employee',
    pathName: 'finance_employee',
    icon: 'compare',
    iconSize: '16',
  },
  finance_customer: {
    id: 'finance_customer',
    name: 'Расчёт с клиентом',
    template: 'finance_customer',
    pathName: 'finance_customer',
    icon: 'dollar',
    iconSize: '16',
  },
  payouts: {
    id: 'payouts',
    name: 'БД выплат',
    template: 'payouts',
    pathName: 'payouts',
    icon: 'analytics',
    iconSize: '18',
  },
  finance_periods: {
    id: 'finance_periods',
    name: 'Сверка для МП',
    template: 'finance_periods',
    pathName: 'finance_periods',
    icon: 'compare',
    iconSize: '16',
  },
};
