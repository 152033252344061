/**
 * Reset shop model.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import ShopEntityModule from '@/store/shops/entity';
import ShopShiftsModule from '@/store/shops/shift';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (
    to.name === 'shops' ||
    to.name === 'shop_edit' ||
    to.name === 'shop_add' ||
    to.name === 'customers_shop_add' ||
    to.name === 'customers_shop_edit'
  ) {
    ShopEntityModule.resetModel();
  }

  if (to.name === 'shop_shift' || to.name === 'customers_shop_shift') {
    ShopShiftsModule.resetCalendarModel();
  }

  next();
};

export default guard;
