import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import moment from 'moment/moment.js';
import { UITable } from '@/lib/util/tableUtils';

/**
 * Returns processed finance date brief info for payouts table
 *
 * @param {Object} table payouts table data
 * @returns {Object} tableInfo
 */

export function prepareList(table: TableApiInterface): any {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('isTest')
    .removeColumn('employeePartnerUuid')

    .setTotalData({ totalDebit: table.totalDebit as number })

    .setProperty('debit', 'align', 'right')
    .setProperty('voucher', 'name', 'Чек')

    .changeTitleOrder('partnerLegalName', 2)
    .changeTitleOrder('positionsList', 3)

    .getTable();
}

/**
 * Returns start and end dates for date filter range
 *
 * @returns {Object} dates range
 */
export function getDateRange() {
  const dateCurrent = moment().format('DD/MM/YY').split('/');
  const currentDay = parseInt(dateCurrent[0]);
  let startDay = '01';
  let endDay = '07';
  let month = dateCurrent[1];

  // 1-7
  if (currentDay >= 8 && currentDay <= 14) {
    startDay = '01';
    endDay = '07';
  }

  // 8-14
  if (currentDay >= 15 && currentDay <= 21) {
    startDay = '08';
    endDay = '14';
  }

  // 15-21
  if (currentDay >= 21 && currentDay <= 31) {
    startDay = '15';
    endDay = '21';
  }

  // 21 - ..
  if (currentDay >= 1 && currentDay <= 7) {
    startDay = '22';
    endDay = moment().subtract(1, 'months').endOf('month').date().toString();
    month = moment().subtract(1, 'months').format('MM');
  }

  return {
    startDay: `${startDay}/${month}/${dateCurrent[2]}`,
    endDay: `${endDay}/${month}/${dateCurrent[2]}`,
  };
}
