/*
 * page entity for state
 * */

import { PageInterface } from '@/lib/layouts/page/page.interface';
export default class PageEntity {
  values: PageInterface;

  constructor() {
    this.values = {
      title: '',
      titleAdd: '',
      titleEdit: '',
      table: {},
      pageCurrent: 1,
      pageAmountItems: '',
      sort: {},
      filter: '',
      filterAdditional: '',
      filterPreview: '',
      filterModel: {},
      filterValues: {},
      filterHandlers: {},
    };
  }

  setTitle(title: string): void {
    this.values.title = title;
  }

  setTitleAdd(title: string): void {
    this.values.titleAdd = title;
  }

  setTitleEdit(title: string): void {
    this.values.titleEdit = title;
  }
}
