const permissionsToRoutes: Record<string, string[]> = {
  CAN_READ_LOG_MARKET_LIST: ['shop_logs'],
  CAN_READ_EMPLOYEE: [
    'employees',
    'employee_edit',
    'shop_employees',
    'employee_relations',
    'employees_schedule',
    'employee_points',
    'employee_ratings',
  ],
  CAN_READ_LOG_EMPLOYEE_LIST: ['employee_logs'],
  CAN_EDIT_CLIENT: ['customers_statistics'],
  CAN_READ_LOG_CLIENT_LIST: ['customers_logs'],
  CAN_READ_PARTNER: ['partners', 'partner_edit', 'partner_shops'],
  CAN_READ_LOG_PARTNER_LIST: ['partner_logs'],
  CAN_READ_FREE_MARKET_SHIFT: ['free_market_shifts', 'orders'],
  CAN_MARK_GOING_OUT_VIEWED: ['outputs'],
  CAN_EDIT_SHIFT_AFTER_SKIP: ['found_employees'],
  CAN_SET_FIRST_EMPLOYEE_SHIFT_PROCESSED: ['first_shift'],
  CAN_EDIT_REACTIVATION_TASK: ['beginners', 'reactivation'],
  CAN_READ_SHIFT_EMPLOYEE_CHECKUP: ['revise'],
  CAN_READ_DATE_BRIEF: ['date_brief'],
  CAN_READ_FINANCE_PAYMENT: ['payments'],
  CAN_READ_EMPLOYEE_FINANCE: ['finance_employee'],
  CAN_READ_CLIENT_FINANCE: ['finance_customer'],
  CAN_READ_FINANCE_PAYOUT: ['payouts'],
  CAN_READ_MOBILE_FINANCE_PERIOD: ['finance_periods'],
  CAN_EDIT_YELLOW_CARD: ['cards_rating', 'cards_database'],
  CAN_READ_WHOLE_CLIENTS_STATISTIC: ['analytics_by_customers'],
  CAN_READ_EMPLOYEE_STATISTICS: ['analytics_by_employees'],
  CAN_READ_COMPETENCE: ['competence'],
  CAN_READ_REGION: ['regions'],
  CAN_EDIT_SECURITY_GROUP: ['groups'],
  CAN_EDIT_SPLIT: ['split'],
  CAN_READ_NOTIFICATION_PARAMETER_INFO: ['notifications'],
  CAN_READ_TRAINING_CENTER: ['training_center'],
  CAN_READ_MAILING: ['mailings'],
  CAN_IMPORT_BULK_EMPLOYEE: ['technical'],
  CAN_EDIT_QUIZZES: ['quizzes'],
  CAN_EDIT_USER: ['users'],
  CAN_EDIT_PARTNER_USER: ['users'],
  CAN_READ_CLIENT: ['customers', 'customer_edit', 'customers_shops', 'customers_helpers'],
  CAN_READ_CLIENT_MARKET: ['shops', 'shop_shift', 'shop_edit', 'shop_statistics'],
  CAN_READ_CLIENT_MARKET_SUBSCRIPTION: ['shop_subscriptions'],
  CAN_EDIT_BONUS: ['bonus'],
  CAN_READ_LABOR_RELATIONS: ['laborRelations'],
  CAN_READ_WORK_MONTH: ['workMonths'],
  CAN_EDIT_WORK_MONTH: ['workMonths'],
  CAN_EDIT_SHIFT_TEMPLATE: ['customers_vacancies'],
  CAN_READ_SHIFT_TEMPLATE: ['customers_vacancies'],
};

export function convertPermissionsToRoutes(permissions: string[]): string[] {
  const routes = new Set<string>();

  permissions.forEach((permission: string) => {
    permissionsToRoutes[permission]?.forEach((route: string) => {
      routes.add(route);
    });
  });

  return Array.from(routes);
}
