import { CatchFormResponse } from '@/interfaces/shared';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getPaymentStrategies } from '@/api/payments';
import { HumanType, HumanTypeHelper } from '@/interfaces/humanType.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { PartnerSelectItem } from '@/interfaces/ui/select.interface';

export class PaymentStrategies {
  private static instance: PaymentStrategies | null = null;

  public static readonly GigantPays = 'gigant_pays';
  public static readonly PartnerPays = 'partner_pays';
  public static readonly PartnerAndGigantPays = 'partner_and_gigant_pays';

  list: HumanType[] = [];
  default = '';

  constructor() {
    if (PaymentStrategies.instance) {
      return PaymentStrategies.instance;
    }

    PaymentStrategies.instance = this;
  }

  async init(): Promise<void> {
    try {
      const response = await getPaymentStrategies();
      this.list = response.paymentStrategies;
      this.default = response.defaultStrategy;
    } catch (error) {
      const message = (error as CatchFormResponse).response?.data?.message;

      if (message) {
        ResponseHandlerModule.showNotify({ message, type: 'fail' });
      } else {
        console.error(error);
      }
    }
  }

  getSelectItems(): SelectItem[] {
    return (
      this.list.map((strategy) => {
        return HumanTypeHelper.castToSelectItem(strategy);
      }) ?? []
    );
  }

  getSelectItemsForPartner(partner?: { isSupervisor?: boolean; value: string }): SelectItem[] {
    if (partner && partner.isSupervisor) {
      return [
        {
          id: PaymentStrategies.GigantPays,
          value: `${partner.value} оплачивает всех исполнителей`,
        },
      ];
    }

    return this.getSelectItems();
  }

  private getGigantPaymentSelectItem(partner: PartnerSelectItem): SelectItem {
    return {
      id: PaymentStrategies.GigantPays,
      value: `${partner.value} оплачивает всех исполнителей`,
    };
  }

  getSelectItemByTypeAndPartner(type?: string, partner?: PartnerSelectItem): SelectItem {
    if (partner && partner.isSupervisor) {
      return this.getGigantPaymentSelectItem(partner);
    }

    const none = { id: '', value: '' };

    if (!type) {
      return none;
    }

    const found = this.list.find((item) => item.type == type);

    return found ? { id: found.type, value: found?.humanType ?? '' } : none;
  }

  getSelectItemForPartner(partner?: PartnerSelectItem, shopDefault?: string): SelectItem {
    if (!partner) {
      return { id: '', value: '' };
    }

    const id = shopDefault ?? this.default;

    if (partner && partner.isSupervisor) {
      return this.getGigantPaymentSelectItem(partner);
    }

    const found = this.list.find((item) => {
      if (item.type == id) {
        return true;
      }
    });

    return { id, value: found?.humanType ?? '' };
  }
}
