/**
 * User entity store.
 *
 */
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import { UserEntityInterface, UserModel, UserTextFields } from '@/interfaces/models/user.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import UsersModule from './index';
import { FormData } from '@/interfaces/shared';

import { createUser, updateUser, getInfoById } from '@/api/users';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import moment from 'moment';

export const MODULE_NAME = 'settingsUser';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UserEntityModule extends EntityBaseModule {
  titleEdit = '';
  model: UserModel;

  constructor(module: UserEntityModule) {
    super(module);

    this.model = {
      firstName: '',
      lastName: '',
      login: '',
      comment: '',
      role: { id: null, value: '' },
      chief: { id: null, value: '' },
      isActive: false,
      workTimeFrom: { hour: '00', minute: '00' },
      workTimeTo: { hour: '00', minute: '00' },
      chiefState: false,
      securityGroup: { id: null, value: '' },
      partner: { id: null, value: '' },
    };
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_TITLE_EDIT(title: string) {
    this.titleEdit = title;
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    this.resetModel();
    await this.getInfoById(id);
  }

  @Action({ rawError: true })
  async getInfoById(id: string) {
    try {
      const result = await getInfoById(id);

      this.setInfo(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfo(info: UserEntityInterface) {
    UsersModule.updateTitleEdit(`${info.lastName} ${info.firstName}`);

    this.context.commit('SET_MODEL_VALUE', { key: 'lastName', value: info.lastName });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstName', value: info.firstName });
    this.context.commit('SET_MODEL_VALUE', { key: 'login', value: info.login });
    if (info.workTimeFrom) {
      const timeFrom = moment(info.workTimeFrom).format('HH:mm').split(':');
      this.context.commit('SET_MODEL_VALUE', {
        key: 'workTimeFrom',
        value: {
          hour: timeFrom[0],
          minute: timeFrom[1],
        },
      });
    }
    if (info.workTimeTo) {
      const timeTo = moment(info.workTimeTo).format('HH:mm').split(':');
      this.context.commit('SET_MODEL_VALUE', {
        key: 'workTimeTo',
        value: {
          hour: timeTo[0],
          minute: timeTo[1],
        },
      });
    }
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'securityGroup',
      value: {
        id: (info.securityGroup as {
          id: string;
          name: string;
        }).id,
        value: (info.securityGroup as {
          id: string;
          name: string;
        }).name,
      },
    });

    if (info.partner) {
      const { uuid: partnerUuid, legalName: partnerLegalName } = info.partner as PartnerSelectListItem;
      this.context.commit('SET_MODEL_VALUE', {
        key: 'partner',
        value: {
          id: partnerUuid,
          value: partnerLegalName,
        },
      });
    }

    if (info.chief && info.chief.id) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'chief',
        value: {
          id: info.chief.id,
          value: `${info.chief.lastName} ${info.chief.firstName}`,
        },
      });
    }
    this.context.commit('SET_MODEL_VALUE', { key: 'chiefState', value: info.chiefState });
    this.context.commit('SET_MODEL_VALUE', { key: 'comment', value: info.comment ?? '' });
  }

  @Action({ rawError: true })
  async addNew(params: UserTextFields) {
    try {
      const result = await createUser(params as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Пользователь создан', type: 'ok' });

        return true;
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return false;
    } catch (error) {
      const errorData = error.response.data;
      if (errorData.type === 'validation_error') {
        this.showUniqueError(error.response.data);
      }

      ResponseHandlerModule.showNotify({ message: errorData.message, type: 'fail' });

      return false;
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; data: UserTextFields }) {
    try {
      const result = await updateUser(params.id, params.data as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });

        return true;
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return false;
    } catch (error) {
      const errorData = error.response.data;
      ResponseHandlerModule.showNotify({ message: errorData.message, type: 'fail' });

      if (errorData.type === 'validation_error') {
        this.showUniqueError(errorData);
      }

      return false;
    }
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'lastName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'login', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'comment', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'role', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'chief', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'workTimeFrom', value: { hour: '00', minute: '00' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'workTimeTo', value: { hour: '00', minute: '00' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'securityGroup', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'partner', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'chiefState', value: false });
  }

  @Action({ rawError: true })
  showUniqueError(errorData: { type: string; errors: { fields: Record<string, string> } }) {
    const fields = errorData.errors.fields;
    ResponseHandlerModule.hideNotify();
    ResponseHandlerModule.showNotify({ message: Object.values(fields)[0] as string, type: 'fail' });
  }
}

export default getModule(UserEntityModule);
