/**
 * Mailings shifts store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import filterModel from './filter';
import MailingsFilter from './filterEntity';

import inlineFilterModel from './inlineFilter';
import MailingsInlineFilter from './inlineFilterEntity';

import { PageSort } from '@/lib/layouts/page/page.interface';
import { Filter } from '@/lib/layouts/page/filter.interface';

import { prepareList } from '@/lib/Recruits';
import { getMailingById, getMailingMarkers, getMailingsList, getMailingStatusList } from '@/api/mailings';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import EntityModel from '@/store/settings/mailings/entityModel';
import { getEmployeeRoleList } from '@/api/employees';
import { dateFullFormat } from '@/lib/Utils';
import { getAddressViaCoordinates } from '@/api/geo';
import { employeeStatusesList } from '@/lib/util/employeeStatuses';
import { MailingsEntityModel, MailingsModel } from '@/interfaces/models/mailings.interface';
import { Marker } from '@/interfaces/notifications.interface';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'mailings';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Mailings extends PageBaseModule {
  filter: Filter;
  inlineFilter: Filter;
  model: MailingsModel;
  selectedAddress = {};
  recipientsModalShown = false;
  markers: Marker[] = [];

  constructor(module: Mailings) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'mailings/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(MailingsFilter);
    this.filter.setFilterName('mailingsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setBtnClassName('row-4');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    const inlineFilter = new inlineFilterModel();
    this.inlineFilter = getModule(MailingsInlineFilter);
    this.inlineFilter.setFilterName('mailingsInlineFilter');
    this.inlineFilter.setFilterModel(inlineFilter.filterModel);
    this.inlineFilter.setFilterHandlers(inlineFilter.filterHandlers);
    this.inlineFilter.setDefaultIndex(0);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MARKERS(markers: Marker[]) {
    this.markers = markers;
  }

  @Mutation
  SET_RECIPIENTS_MODAL_SHOWN(value: boolean) {
    this.recipientsModalShown = value;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    (this as any).model[params.key] = params.value;
  }

  @Mutation
  SET_CREATED_AT(date: string) {
    this.model.createdAt = date;
  }

  @Mutation
  SET_UPDATED_AT(date: string) {
    this.model.updatedAt = date;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.mailingsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_SELECTED_ADDRESS(address: { address: string; latitude: number; longitude: number }) {
    this.selectedAddress = address;
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = `${this.inlineFilter.filterSettings.filter}${this.filter.filterSettings.filter}`;

      const result = await getMailingsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);

      this.context.dispatch('setList', result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  async initMarkers() {
    const result = await getMailingMarkers();
    this.context.commit('SET_MARKERS', result);
  }

  @Action({ rawError: true })
  async init() {
    await this.filter.init();
    await this.filter.updateFilter();

    await this.inlineFilter.init();
    await this.inlineFilter.updateFilter();

    this.context.commit('SET_SORT', window.localStorage.mailingsSort ? JSON.parse(window.localStorage.mailingsSort) : {});

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
  }

  @Action({ rawError: true })
  async getById(id: string) {
    try {
      const result = await getMailingById(id);

      await this.setInfo(result);

      return result;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setInfo(info: MailingsEntityModel) {
    this.updateTitleEdit(`${info.name}`);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: info.distance });
    this.context.commit('SET_MODEL_VALUE', { key: 'delayedDispatch', value: info.delayedDispatch });
    this.context.commit('SET_MODEL_VALUE', { key: 'useTimezone', value: info.useTimezone });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendPush', value: info.sendPush });
    this.context.commit('SET_MODEL_VALUE', { key: 'pushText', value: info.pushText });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendSms', value: info.sendSms });
    this.context.commit('SET_MODEL_VALUE', { key: 'smsText', value: info.smsText });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendNotification', value: info.sendNotification });
    this.context.commit('SET_MODEL_VALUE', { key: 'notificationTitle', value: info.notificationTitle });
    this.context.commit('SET_MODEL_VALUE', { key: 'notificationText', value: info.notificationText });
    this.context.commit('SET_MODEL_VALUE', { key: 'individualMailing', value: info.individualMailing });

    const roles = await getEmployeeRoleList();
    if (info.roles.length) {
      const result = roles
        .map((role: { type: string; humanType: string }) => {
          return {
            id: role.type,
            value: role.humanType,
          };
        })
        .filter((role: { id: string; value: string }) => {
          return ~info.roles.findIndex((id: string) => role.id === id);
        });

      this.context.commit('SET_MODEL_VALUE', { key: 'roles', value: result });
    }

    if (info.statuses && info.statuses.length) {
      const result = employeeStatusesList
        .map((status: { id: string; name: string }) => {
          return {
            id: status.id,
            value: status.name,
          };
        })
        .filter((status: { id: string; value: string }) => {
          return ~info.statuses.findIndex((id: string) => status.id === id);
        });

      this.context.commit('SET_MODEL_VALUE', { key: 'statuses', value: result });
    }

    if (info.address && info.coordinates) {
      this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: info.distance });
      this.context.commit('SET_MODEL_VALUE', { key: 'lat', value: info.coordinates.latitude });
      this.context.commit('SET_MODEL_VALUE', { key: 'long', value: info.coordinates.longitude });
      this.context.commit('SET_MODEL_VALUE', { key: 'address', value: info.address });
    }

    if (info.competences.length) {
      const result = info.competences.map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'competences', value: result });
    }

    if (info.clients.length) {
      const result = info.clients.map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'clients', value: result });
    }

    if (info.regions.length) {
      const result = info.regions.map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'regions', value: result });
    }

    if ((info.serviceTypes ?? []).length) {
      const result = info.serviceTypes.map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'serviceTypes', value: result });
    }

    if (info.employees && info.employees.length) {
      const result = info.employees.map((item: { id: string; fullNameWithBirthday: string }) => {
        return {
          id: item.id,
          value: item.fullNameWithBirthday,
        };
      });

      this.context.commit('SET_MODEL_VALUE', { key: 'employees', value: result });
    }

    if (info.employeesFile) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'employeesFile',
        value: { name: info.employeesFile.fileName },
      });
    }

    if (info.scheduledDate) {
      const date = dateFullFormat(info.scheduledDate).split(' ');
      const timeSplit = date[1].split(':');

      this.context.commit('SET_MODEL_VALUE', {
        key: 'scheduledDate',
        value: {
          date: date[0],
          hour: timeSplit[0],
          minute: timeSplit[1],
          time: date[1],
        },
      });
    }

    this.context.commit('SET_MODEL_VALUE', { key: 'excludeClients', value: info.excludeClients });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeCompetences', value: info.excludeCompetences });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeRegions', value: info.excludeRegions });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeRoles', value: info.excludeRoles });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeStatuses', value: info.excludeStatuses });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeServiceTypes', value: info.excludeServiceTypes });
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'individualMailing', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'lat', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'long', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'email', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'delayedDispatch', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'useTimezone', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendPush', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'pushText', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendSms', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'smsText', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'sendNotification', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'notificationTitle', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'notificationText', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'roles', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'statuses', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'employees', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'serviceTypes', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'regions', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'competences', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'clients', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'scheduledDate', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'address', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'lat', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'long', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'distance', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeClients', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeCompetences', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeRegions', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeRoles', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeStatuses', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'excludeServiceTypes', value: false });

    this.context.commit('SET_CREATED_AT', '');
  }

  @Action({ rawError: true })
  resetTitle() {
    this.updateTitleEdit('');
  }

  @Action({ rawError: true })
  async initState() {
    return (await getMailingStatusList()).map((status: { type: string; humanType: string }) => ({
      id: status.type,
      name: status.type,
      value: status.humanType,
      checked: false,
    }));
  }

  @Action({ rawError: true })
  updateSelectedAddress(coordinates: { latitude: number; longitude: number }) {
    getAddressViaCoordinates(coordinates.latitude, coordinates.longitude).then((addressString) => {
      if (addressString !== '') {
        this.context.commit('SET_SELECTED_ADDRESS', {
          address: addressString,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        });
      }
    });
  }

  @Action({ rawError: true })
  openRecipientsModal() {
    this.context.commit('SET_RECIPIENTS_MODAL_SHOWN', true);
  }

  @Action({ rawError: true })
  closeRecipientsModal() {
    this.context.commit('SET_RECIPIENTS_MODAL_SHOWN', false);
  }
}

export default getModule(Mailings);
