import { ref, watch, inject } from 'vue';
import { Ref } from '@vue/reactivity';

export interface FormInputRulesParams {
  value: Ref<unknown>;
  rules?: (attr: unknown) => boolean | string;
}

export interface FormInputRulesResult {
  validate: Ref<boolean>;
  validateMessage: Ref<string>;
}

export default function formInputRules(params: FormInputRulesParams): FormInputRulesResult {
  const trigger = inject('validateInputs') as Record<string, unknown>;

  const value = params.value;
  const validate = ref(true);
  const validateMessage = ref('');

  const setError = (message: string) => {
    validate.value = false;
    validateMessage.value = message;
  };

  const setSuccess = () => {
    validate.value = true;
    validateMessage.value = '';
  };

  const setResult = (result: string | boolean) => {
    if (typeof result === 'string') {
      setError(result);

      return false;
    }

    if (!result) {
      setError('Невалидное поле');
      console.warn('INPUT RULES: Нужно указать ошибку в виде string, иначе будет сообщение "Невалидное поле"');

      return false;
    }

    setSuccess();

    return true;
  };

  const setValidate = () => {
    if (!params.rules) {
      return false;
    }

    if (!Array.isArray(params.rules) && typeof params.rules != 'function') {
      console.error(
        'INPUT RULES: Параметр rules должен быть массивом функций или функцией, возвращающий true(при успехе) или string(при ошибке)'
      );

      return;
    }

    //if array
    if (Array.isArray(params.rules) && params.rules.length) {
      for (let i = 0; i < params.rules.length; i++) {
        const result = params.rules[i](params.value.value);
        const success = setResult(result);

        if (!success) {
          break;
        }
      }

      return;
    }

    //if string
    const result = params.rules(params.value.value);

    setResult(result);
  };

  watch(value, setValidate);
  watch(trigger, setValidate);

  return {
    validate,
    validateMessage,
  };
}
