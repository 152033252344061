/**
 * Global shop model interface.
 *
 */
import { SelectForm } from '@/lib/formFactory/selectForm.interface';
import { Autocomplete } from '@/lib/formFactory/autocomplete.interface';
import { Map } from '@/lib/formFactory/map.interface';
import { RadioForm } from '@/lib/formFactory/radioForm.interface';
import { HumanType } from '@/interfaces/humanType.interface';
import { FormData } from '@/interfaces/shared';
import { ClientPartners } from '@/interfaces/partner.interface';
import { PartnerSelectItem } from '@/interfaces/ui/select.interface';

export interface ShopModel {
  [key: string]:
    | string
    | string[]
    | number
    | boolean
    | (SelectForm & {
        timezoneNumber?: string | number;
      })
    | Map
    | ShopComment[]
    | MarketPartner[]
    | ClientPartners[]
    | PartnerSelectItem[];
  id: string;
  name: string;
  code: string;
  marketPhones: string[];
  marketPhonesBase: string;
  customer: SelectForm;
  region: SelectForm & { timezoneNumber: string | number };
  address: Autocomplete;
  addressCoordinates: Map;
  comments: ShopComment[];
  vacancies: string[];
  isActive: number | boolean;
  regularNeed: number | boolean;
  info: string;
  accountManager: SelectForm;
  customerSuccessManager: SelectForm;
  assistant: any;
  responsibleName: string;
  responsiblePhone: string;
  responsibleEmail: string;
  timezone: string;
  isTest: boolean;
  partnerUuid: string;
  paymentStrategy: string;
  clientId: string;
  autoCheckIn: boolean;
  autoCheckup: boolean;
  rating: string;
  contractor: string;
  clientPartners: MarketPartner[];
  clientSubcontractors: ClientPartners[];
  partner: PartnerSelectItem[];
}

export interface ShopTextFields {
  [key: string]: string | undefined | string[];
  code: string;
  name: string;
  comment: string;
  info: string;
  address: string;
  region: string;
  isActive: string;
  regularNeed: string;
  responsibleName: string;
  responsiblePhone: string;
  responsibleEmail: string;
  isTest: string;
  partnerUuid?: string;
  mainPartnerUuid?: string;
  paymentStrategy?: string;
  assistant?: string;
  customerId?: string;
  autoCheckIn?: string;
}

export interface ShopProcessedModel {
  id: string;
  name: string;
  code: string;
  address: string;
  clientId: string;
  clientName: string;
  regionId: string;
  regionName: string;
  marketPhones: string[];
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  regularNeed: boolean;
  info: string;
  isTest: boolean;
  partnerUuid: string;
  autoCheckIn: boolean;
  autoCheckup: boolean;
}

export interface ShopEntity {
  id: string;
  name: string;
  fullName: string;
  code: string;
  marketPhones: [];
  client: {
    id: string;
    name: string;
    contractor: Record<string, string>;
    clientSubcontractors: ClientPartners[];
  };
  region: {
    id: string;
    name: string;
    timezoneNumber: number | '';
    centerCoordinates: {
      latitude: number;
      longitude: number;
    };
  };
  address: string;
  addressCoordinates: {
    latitude: number;
    longitude: number;
  };
  comments: ShopComment[];
  vacancies: {
    id: number;
    name: string;
  }[];
  isActive: boolean;
  regularNeed: boolean;
  info: string;
  accountManager: {
    id: string;
    fullName: string;
  };
  customerSuccessManager: {
    id: string;
    fullName: string;
  };
  assistant: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    value: string;
  };
  responsible: {
    email: string;
    name: string;
    phone: string;
  };
  clientMarketSubcontractors: MarketPartner[];
  paymentStrategy: HumanType;
  timezone: number;
  sharing: 'all' | 'none';
  isTest: boolean;
  need: number;
  closedNeed: number;
  openedNeed: number;
  overNeed: number;
  createdAt: string;
  updatedAt: string;
  autoCheckIn: boolean;
  autoCheckup: boolean;
}

export interface ShopMinEntity {
  id: number;
  name: string;
  code: string;
  address: string;
  isTest: boolean;
  timeZone: number;
  ratingCount: number;
  hasCabinet: boolean;
  sharing: 'all' | 'none';
  partner: {
    uuid: string;
    legalName: string;
  };
  clientMarketSubcontractors: ClientPartners[];
  client: {
    id: number;
    name: string;
    clientSubcontractors: ClientPartners[];
    contractor: {
      inn: string;
      isSupplier: boolean;
      legalName: string;
      uuid: string;
    };
  };
  paymentStrategy: HumanType;
}

export interface ShopEmployeesEntityModel {
  employee: Autocomplete;
  isFavourite: RadioForm;
  comment: string;
}

export interface ShopComment {
  id: number;
  createdAt: string;
  updatedAt: string;
  text: string;
  user: {
    id: number;
    fullName: string;
  };
}

export interface ShopSubscription {
  id: number;
  email: string;
  fio: string;
}

export interface ShopSubscriptionGroup {
  id: number;
  name: string;
  subscriptions: ShopSubscription[];
}

export interface ShopSubscriptionCreatePayload extends FormData {
  type: string;
  market: string | number;
  email: string;
  fio?: string;
}

export enum ShopSubscriptionType {
  InviteRecommendEmployee = 'invite_recommend_employee',
}

export interface MarketPartner {
  id?: number;
  main: boolean;
  partner: {
    uuid: string;
    legalName: string;
    isSupplier?: boolean;
    isSupervisor: boolean;
    inn?: string;
  };
}
