import { TableApiInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import userModule from '@/store/user';

/**
 * Returns processed shops info for shops table
 *
 * @param {Object} table employees data
 * @param options
 * @returns {Object} tableInfo
 */
export function prepareList(
  table: TableApiInterface,
  options?: { order?: TableTitle[]; hidden?: TableTitle[]; sort?: { sort: string; key: string }[] }
): {
  titles: TableTitle[];
  rows: { [p: string]: string | number | boolean }[];
  pagination: {} | { currentPage: number; totalItems: number; totalPages: number };
  totalItems: number;
  filters: { name: string; value: string | null }[];
  totalData: { [index: string]: string | number } | undefined;
  additionalInfo: { [index: string]: string } | undefined;
  hiddenTitles: TableTitle[];
} {
  const uiTable = new UITable(
    table as {
      titles?: TableTitle[];
      rows?: { [p: string]: string | number | boolean }[];
      pagination?: { currentPage: number; totalItems: number; totalPages: number };
      totalItems?: number;
      sortFields?: string[];
      filters?: { name: string; value: string | null }[];
      totalData?: { [p: string]: string | number };
      additionalInfo?: { [p: string]: string };
    }
  );

  uiTable
    .removeColumn('uuid')
    .removeColumn('isTest')
    .removeColumn('vaccinationStatus')
    .removeColumn('underage')

    .setProperty('role', 'align', 'center')
    .changeTitleOrder('rating', 1);

  if (!userModule.userHasPermission('CAN_READ_EMPLOYEE_PHONE')) {
    uiTable.removeColumn('phone');
  }

  uiTable
    .setSortableValues(options && options.sort)
    .setHiddenTitles(options && options.hidden)
    .setOrderTitles(options && options.order);

  return uiTable.getTable();
}
