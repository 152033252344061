
import { defineComponent } from 'vue';
import { GuiFormButton } from '@library/gigant_ui';

export default defineComponent({
  name: 'form-button',
  emits: ['btn-click'],
  components: {
    GuiFormButton,
  },
  props: {
    text: {
      type: [String],
      required: true,
    },
    type: {
      type: [String],
      default: 'button',
    },
    loading: {
      type: [Boolean],
      default: false,
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    squared: {
      type: [Boolean],
      default: false,
    },
    isTextBtn: {
      type: [Boolean],
      default: false,
    },
    bordered: {
      type: [Boolean],
      default: false,
    },
    smSize: {
      type: [Boolean],
      default: false,
    },
    outline: {
      type: [Boolean],
      default: false,
    },
    isIconBtn: {
      type: [Boolean],
      default: false,
    },
    form: {
      type: [String],
      default: undefined,
    },
    textColor: {
      type: [String],
      default: '',
    },
  },
  methods: {
    btnClicked() {
      if (!this.disabled) {
        this.$emit('btn-click');
      }
    },
  },
});
