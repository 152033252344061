import { ShiftInterface } from '@/interfaces/shift.interface';
import { ShiftEmployeeStatusTitles } from '@/lib/util/consts';

export interface NewShiftEmployeeInterface {
  isNew: boolean;
  id: string;
  name: string;
  value: string;
  statusId: string;
  generalStatus: keyof typeof ShiftEmployeeStatusTitles;
  intervalStart: {
    hour: string;
    minute: string;
  };
  intervalEnd: {
    hour: string;
    minute: string;
  };
  end: string;
  start: string;
  workHours: string;
  late: string;
  comment: string;
  isAutomatic: boolean;
  paymentPartner: string;
  paymentPartnerName: string;
  employee: {
    id: string;
    fullName: string;
    rate: number;
    partner: { uuid: string; legalName: string };
    isTest?: boolean;
    isSelfEmployed?: boolean | null;
    calendarHours?: number | null;
    workHours?: number | null;
    sharing: string;
  };
  rate: number;
  icon: string;
  lunch: {
    hour: string;
    minute: string;
  };
  lunchMinutes: number;
  isShowInfo: boolean;
  isStartIn12Hours: boolean;
  yellowCard?: number;
  belongToPartner?: boolean;
  quantity: number;
  price: number;
}

export interface ShiftEmployeeInterface {
  id: string;
  byEmployee: boolean;
  comment: string;
  employee: {
    id: string;
    fullName: string;
    marketFavorite?: string;
  };
  rate: number;
  end: string;
  generalStatus: string;
  isAutomatic: boolean;
  lunch: number | null;
  start: string;
  statusId: string;
  substatusId: string;
  yellowCard: boolean;
  checkedIn: boolean;
  checkedOut: boolean;
  belongToPartner: boolean;
  paymentPartner: {
    uuid: string;
    legalName: string;
    inn: string;
  };
  quantity: number;
  price: number;
  checkedInTime: string | null;
  checkedOutTime: string | null;
}

export interface RecommendedEmployeeInterface {
  id: string;
  fullName: string;
}

export interface GroupShiftEmployeeItem {
  belongToPartner: boolean;
  sharing: string;
  isSelfEmployed: boolean;
  isTest: boolean;
  rate: number;
  employeeRate: number;
  calendarHours: number | null;
  workHours: number | null;
  status: string;
  comment: string;
}

export type GroupEmployeeItem<T extends boolean> = {
  isRecommended: T;
  id: string;
  fullName: string;
  icon: string;
  entity: T extends false ? NewShiftEmployeeInterface : RecommendedEmployeeInterface;
} & (T extends false ? GroupShiftEmployeeItem : Partial<GroupShiftEmployeeItem>);

export type GroupRecommendedEmployeeItem = GroupEmployeeItem<true>;

export interface ShiftEmployeeWorkHours {
  actualWork: number;
  workHours: number;
}

export interface ShiftEntityInterface {
  [index: string]:
    | undefined
    | number
    | string
    | boolean
    | null
    | Record<string, number | string>
    | Record<string, number | string>[]
    | ShiftInterface
    | Record<number, ShiftEmployeeInterface>
    | Record<string, ShiftEmployeeWorkHours>
    | RecommendedEmployeeInterface[];
  id: string;
  clientMarket: {
    id: number;
    name: string;
    fullName: string;
    timeZone: number;
    client: { id: number; contractor: string };
  };
  shiftTemplate: {
    id: number;
    name: string;
    hasRotation: boolean;
  } | null;
  serviceType: {
    id: number;
    name: string;
  };
  unitType: {
    id: number;
    name: string;
    shortName: string;
    isHourly: boolean;
  };
  quantity: number;
  price: number;
  partner: {
    uuid: string;
    legalName: string;
  };
  citizenship: {
    id: number;
    name: string;
    sortOrder: number;
  }[];
  end: string;
  start: string;
  isAdditional: boolean;
  isStart: boolean;
  isStartIn12Hours: boolean;
  isStartIn2Hours: boolean;
  isStartPast45Minutes: boolean;
  isEndPast5Hours: boolean;
  lunch: number | null;
  lunchIsPaid: boolean;
  countEmployee: number;
  needCount: number;
  notDevide: boolean;
  past: boolean;
  hasRotation: boolean;
  shift: ShiftInterface;
  rate: number;
  externalRate: number;
  paymentStrategy: { type: string; humanType: string };
  shiftEmployees: ShiftEmployeeInterface[];
  needCheckin: boolean;
  workHours?: Record<string, ShiftEmployeeWorkHours>;

  gender: null | 'm' | 'f';
  minHours: number;
  maxHours: number;
  training: boolean;
  hasLmk: boolean;
  age: { from: number; to: number };
  competence: null | { trainingWebLink: string };

  recommendedEmployees: RecommendedEmployeeInterface[];
  uuid: string;
}

export interface DateInputInterface {
  key: string;
  type: string;
  title: string;
  value: string;
  currentValue: {
    month: string;
    day: string;
  };
  action: string;
  required: boolean;
  size: number;
  mask: {
    regex: string;
    placeholder: string;
    minChars: number;
  };
  error: {
    class: string;
    message: string;
  };
  validation: {
    empty: string;
  };
}

export interface TimeInputInterface {
  key: string;
  type: string;
  title: string;
  value: string;
  hour: string;
  minute: string;
  action: string;
  required: boolean;
  start: number;
  end: number;
  step: number;
  error: {
    class: string;
    message: string;
  };
  validation: {
    empty: string;
  };
}

export interface LunchInputInterface extends TimeInputInterface {
  lunchMinutes: number;
  allowedHours: number[];
}

export interface CheckboxInterface {
  name: string;
  type: string;
  list: Record<
    number,
    {
      id: number;
      name: string;
      checked: boolean;
    }
  >;
  handler: string;
}

export interface ShiftModalParamsInterface {
  [index: string]: Record<
    string,
    | string
    | boolean
    | number
    | Record<
        string,
        | string
        | string[]
        | boolean
        | number
        | DateInputInterface
        | TimeInputInterface
        | LunchInputInterface
        | CheckboxInterface
        | Record<string, string | number | Record<string, number>>[]
        | Record<
            string,
            | string
            | boolean
            | number
            | number[]
            | Record<string, string>[]
            | Record<
                string | number,
                | string
                | number
                | NewShiftEmployeeInterface
                | Record<string, string | number | boolean | Record<string, string>>
              >
          >
      >
  >;
  add: {
    isShow: boolean;
    title: string;
    store: string;
    rate: number;
    externalRate: number;
    position: string;
    training: boolean;
    timeZone: number;
    model: {
      editedRate: number;
      intervalStart: TimeInputInterface;
      intervalEnd: TimeInputInterface;
      needCountEmployees: string;
      paymentStrategy: string;
      lunch: LunchInputInterface;
      dates: string[];
      dateStart: DateInputInterface;
      dateEnd: DateInputInterface;
      includeWeekdays: CheckboxInterface;
      includeWeekends: CheckboxInterface;
      isAdditional: CheckboxInterface;
      isShared: CheckboxInterface;
      notDevide: CheckboxInterface;
      partnerUuid: string;
    };
  };
  deleteWarning: {
    shopId: string;
    vacancyId: string;
    text: string;
    handlers: {
      yes: string;
      no: string;
    };
  };
  delete: {
    isShow: boolean;
    title: string;
    model: {
      dateStart: {
        key: string;
        type: string;
        title: string;
        value: string;
        currentValue: {
          month: string;
          day: string;
        };
        action: string;
        required: boolean;
        size: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      dateEnd: {
        key: string;
        type: string;
        title: string;
        value: string;
        currentValue: {
          month: string;
          day: string;
        };
        action: string;
        required: boolean;
        size: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      isTimeInterval: {
        name: string;
        type: string;
        list: Record<
          number,
          {
            id: number;
            name: string;
            checked: boolean;
          }
        >;
        handler: string;
      };
      intervalStart: {
        key: string;
        type: string;
        title: string;
        value: string;
        action: string;
        required: boolean;
        hour: string;
        minute: string;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      intervalEnd: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      isExcludeWeekday: {
        name: string;
        type: string;
        list: Record<
          number,
          {
            id: number;
            name: string;
            checked: boolean;
          }
        >;
        handler: string;
      };
      isExcludeWeekends: {
        name: string;
        type: string;
        list: Record<
          number,
          {
            id: number;
            name: string;
            checked: boolean;
          }
        >;
        handler: string;
      };
      needCountEmployees: string;
    };
  };
  edit: {
    isShow: boolean;
    title: string;
    store: string;
    rate: number;
    externalRate: number;
    position: string;
    timeZone: number;
    shift: {
      id: string;
      isStart: boolean;
      isStartIn12Hours: boolean;
      past: boolean;
      start: string;
      clientMarket: {
        id: string;
        client: { id: number | string };
      };
      clientVacancy: {
        hasRotation: boolean;
      };
      partnerUuid: string;
      rate: number;
      externalRate: number;
      lunchIsPaid: boolean;
      baseRate: number;
      needCount: number;
      training: boolean;
      shift: {
        countEmployee: string;
        price: number;
      };
    };
    model: {
      editedRate: number;
      paymentStrategy: string;
      intervalStart: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      intervalEnd: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      needCountEmployees: string;
      lunch: LunchInputInterface;
      isAdditional: CheckboxInterface;
      isShared: CheckboxInterface;
      notDevide: CheckboxInterface;
      partnerUuid: string;
      partnerName: string;
      employees: {
        key: string;
        type: string;
        title: string;
        placeholder: string;
        name: string;
        value: string;
        actionSearch: string;
        action: string;
        list: Record<string, NewShiftEmployeeInterface>;
        hintsList: {};
        size: number;
        required: boolean;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      status: {
        key: string;
        type: string;
        title: string;
        list: {
          id: string;
          value: string;
        }[];
        listPast: [];
        current: {};
        action: string;
        required: boolean;
        error: {
          class: string;
          message: string;
        };
      };
      employeeIntervalStart: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      employeeIntervalEnd: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
      employeeLunch: {
        key: string;
        type: string;
        title: string;
        value: string;
        hour: string;
        minute: string;
        lunchMinutes: number;
        action: string;
        required: boolean;
        start: number;
        end: number;
        step: number;
        error: {
          class: string;
          message: string;
        };
        validation: {
          empty: string;
        };
      };
    };
  };
}

export const modalParams: ShiftModalParamsInterface = {
  add: {
    isShow: false,
    title: 'Добавить заказ',
    store: '',
    rate: 0,
    externalRate: 0,
    position: '',
    training: false,
    timeZone: 0,
    model: {
      editedRate: 0,
      dates: [],
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Интервал смены',
        value: '17:00',
        hour: '17',
        minute: '00',
        action: 'shopsShifts/updateIntervalStart',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '22:00',
        hour: '22',
        minute: '00',
        action: 'shopsShifts/updateIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      needCountEmployees: '',
      paymentStrategy: '',
      lunch: {
        key: 'lunch',
        type: 'timePicker',
        title: '',
        value: '00:00',
        hour: '00',
        minute: '00',
        lunchMinutes: 0,
        action: 'shopsShifts/updateLunch',
        required: true,
        start: 0,
        end: 24,
        step: 5,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: '',
        },
        allowedHours: [0, 1, 2],
      },
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: 'С даты',
        value: '28.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'shopsShifts/updateDateStart',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: 'До даты',
        value: '28.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'shopsShifts/updateDateEnd',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      includeWeekdays: {
        name: 'includeWeekdays',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Добавить будни',
            checked: true,
          },
        },
        handler: 'shopsShifts/updateIncludeWeekdays',
      },
      includeWeekends: {
        name: 'includeWeekends',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Добавить выходные',
            checked: true,
          },
        },
        handler: 'shopsShifts/updateIncludeWeekends',
      },
      isAdditional: {
        name: 'isAdditional',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Дополнительная',
            checked: false,
          },
        },
        handler: 'shopsShifts/setIsAdditional',
      },
      isShared: {
        name: 'isShared',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Всем виден в МП',
            checked: false,
          },
        },
        handler: 'shopsShifts/setIsShared',
      },
      notDevide: {
        name: 'notDevide',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Не дробить',
            checked: false,
          },
        },
        handler: 'shopsShifts/setNotDevide',
      },
      partnerUuid: '',
    },
  },
  deleteWarning: {
    shopId: '',
    vacancyId: '',
    text: 'Вы уверены?',
    handlers: {
      yes: 'shopsShifts/deleteShift',
      no: '',
    },
  },
  delete: {
    isShow: false,
    title: 'Удалить заказ',
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: 'С даты',
        value: '28.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'shopsShifts/updateDeleteDateStart',
        required: true,
        size: 40,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: 'До даты',
        value: '29.02.2020',
        currentValue: {
          month: '',
          day: '',
        },
        action: 'shopsShifts/updateDeleteDateEnd',
        required: true,
        size: 40,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      isTimeInterval: {
        name: 'isTimeInterval',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Попадают в интервал',
            checked: false,
          },
        },
        handler: 'shopsShifts/updateIsTimeInterval',
      },
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Попадают в интервал',
        value: '17:00',
        action: 'shopsShifts/updateDeleteIntervalStart',
        required: true,
        hour: '17',
        minute: '00',
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '12:00',
        hour: '22',
        minute: '00',
        action: 'shopsShifts/updateDeleteIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      isExcludeWeekday: {
        name: 'isExcludeWeekday',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Удалить будни',
            checked: true,
          },
        },
        handler: 'shopsShifts/updateDeleteIsExcludeWeekday',
      },
      isExcludeWeekends: {
        name: 'isExcludeWeekends',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Удалить выходные (Сб, Вс)',
            checked: true,
          },
        },
        handler: 'shopsShifts/updateDeleteIsExcludeWeekends',
      },
      needCountEmployees: '',
    },
  },
  edit: {
    isShow: false,
    title: '',
    store: '',
    rate: 0,
    externalRate: 0,
    position: '',
    timeZone: 0,
    shift: {
      id: '',
      isStart: false,
      isStartIn12Hours: false,
      past: false,
      start: '',
      rate: 0,
      externalRate: 0,
      lunchIsPaid: false,
      baseRate: 0,
      needCount: 0,
      partnerUuid: '',
      training: false,
      clientMarket: {
        id: '',
        client: { id: '' },
      },
      clientVacancy: {
        hasRotation: false,
      },
      shift: {
        countEmployee: '0',
        price: 0,
      },
    },
    model: {
      editedRate: 0,
      paymentStrategy: '',
      intervalStart: {
        key: 'intervalStart',
        type: 'timePicker',
        title: 'Интервал смены',
        value: '17:00',
        hour: '17',
        minute: '00',
        action: 'shopsShifts/updateEditIntervalStart',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      intervalEnd: {
        key: 'intervalEnd',
        type: 'timePicker',
        title: '',
        value: '0:00',
        hour: '17',
        minute: '00',
        action: 'shopsShifts/updateEditIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 30,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      needCountEmployees: '',
      lunch: {
        key: 'lunch',
        type: 'timePicker',
        title: '',
        value: '00:00',
        hour: '00',
        minute: '00',
        lunchMinutes: 0,
        action: 'shopsShifts/updateEditLunch',
        required: true,
        start: 0,
        end: 24,
        step: 5,
        allowedHours: [0, 1, 2],
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: '',
        },
      },
      isAdditional: {
        name: 'isAdditional',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Дополнительная',
            checked: false,
          },
        },
        handler: 'shopsShifts/updateIsAdditional',
      },
      isShared: {
        name: 'isShared',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Всем виден в МП',
            checked: false,
          },
        },
        handler: 'shopsShifts/updateIsShared',
      },
      notDevide: {
        name: 'notDevide',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Не дробить',
            checked: false,
          },
        },
        handler: 'shopsShifts/updateNotDevide',
      },
      partnerUuid: '',
      partnerName: '',
      employees: {
        key: 'employees',
        type: 'input-search-drop',
        title: '',
        placeholder: 'Начните вводить имя или номер телефона',
        name: 'employees',
        value: '',
        actionSearch: 'shopsShifts/searchEmployee',
        action: 'shopsShifts/updateEmployee',
        list: {},
        hintsList: {},
        size: 250,
        required: true,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Не выбран',
        },
      },
      status: {
        key: 'status',
        type: 'select',
        title: '',
        list: [],
        listPast: [],
        current: {},
        action: 'shopsShifts/updateStatus',
        required: false,
        error: {
          class: '',
          message: '',
        },
      },
      employeeIntervalStart: {
        key: 'employeeIntervalStart',
        type: 'timePicker',
        title: 'Интервал смены',
        value: '17:00',
        hour: '17',
        minute: '00',
        action: 'shopsShifts/updateEditEmployeeIntervalStart',
        required: true,
        start: 0,
        end: 24,
        step: 5,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      employeeIntervalEnd: {
        key: 'employeeIntervalStart',
        type: 'timePicker',
        title: '',
        value: '22:00',
        hour: '22',
        minute: '00',
        action: 'shopsShifts/updateEditEmployeeIntervalEnd',
        required: true,
        start: 0,
        end: 24,
        step: 5,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      employeeLunch: {
        key: 'employeeLunch',
        type: 'timePicker',
        title: '',
        value: '00:00',
        hour: '00',
        minute: '00',
        lunchMinutes: 0,
        action: 'shopsShifts/updateEditEmployeeLunch',
        required: true,
        start: 0,
        end: 24,
        step: 5,
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: '',
        },
      },
    },
  },
};
