import { TemplateModel } from '@/interfaces/models/template.interface';

export default class EntityModel {
  model: TemplateModel;

  constructor() {
    this.model = {
      name: '',
      externalName: '',
      minHours: 0,
      maxHours: 0,
      serviceType: { id: null, value: '' },
      rate: 0,
      externalRate: 0,
      hasLmk: false,
      isActive: false,
      isTraining: false,
      gender: { id: 3, name: 'Не важно', value: '' },
      trainingWebLink: '',
      info: '',
      forLk: false,
      vaccinationRequirement: '',
      createdAt: '',
      updatedAt: '',
      ageFrom: '',
      ageTo: '',
      competence: { id: null, value: '' },
      hasRotation: false,
      isPreviouslyWorked: '',
      citizenship: [],
      unitType: { id: null, value: '' },
    };
  }
}
