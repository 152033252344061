/*
 *
 * */

import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { phoneMask } from '@/lib/layouts/page/filterMasks';

export default class EmployeeFilter {
  filterHandlers = {
    update: 'employeesFilterModule/updateFilter',
    reset: 'employeesFilterModule/resetFilter',
    resetState: 'employeesFilterModule/resetFilterState',
    removeSelected: 'employeesFilterModule/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По ФИО');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'ФИО');
    filter.setClassName('fullName', 'col-1-row-1');
    filter.setIconClassName('fullName', 'icon-user-search-light');
    filter.setAction('fullName', 'employeesFilterModule/updateSearch');

    filter.createMultiSelectEntity('belongingToCitizenship');
    filter.setTitle('belongingToCitizenship', 'По гражданству');
    filter.setName('belongingToCitizenship', 'multi_select_positions');
    filter.setPreviewName('belongingToCitizenship', 'Гражданство');
    filter.setInitAction('belongingToCitizenship', 'employees/initCitizenshipsSelect');
    filter.setClassName('belongingToCitizenship', 'col-2-row-1');
    filter.setIconClassName('belongingToCitizenship', 'icon-world');
    filter.setAction('belongingToCitizenship', 'employeesFilterModule/updateMultiSelect');
    filter.setSearchEnabled('belongingToCitizenship', true);

    filter.createSearchEntity('phone');
    filter.setPlaceholder('phone', 'По телефону');
    filter.setName('phone', 'search_phone');
    filter.setPreviewName('phone', 'Тел');
    filter.setClassName('phone', 'col-1-row-2 phone_mask');
    filter.setIconClassName('phone', 'icon-phone');
    filter.setAction('phone', 'employeesFilterModule/updateSearch');
    filter.setMask('phone', phoneMask);

    filter.createSearchEntity('email');
    filter.setPlaceholder('email', 'По email');
    filter.setName('email', 'search_email');
    filter.setPreviewName('email', 'Mail');
    filter.setClassName('email', 'col-1-row-3');
    filter.setIconClassName('email', 'icon-email');
    filter.setAction('email', 'employeesFilterModule/updateSearch');

    filter.createSearchEntity('comment');
    filter.setPlaceholder('comment', 'По комментарию');
    filter.setName('comment', 'search_comment');
    filter.setPreviewName('comment', 'Комм');
    filter.setClassName('comment', 'col-2-row-3');
    filter.setIconClassName('comment', 'icon-comment-second');
    filter.setAction('comment', 'employeesFilterModule/updateSearch');

    filter.createSearchEntity('inn');
    filter.setPlaceholder('inn', 'По ИНН');
    filter.setName('inn', 'search_inn');
    filter.setPreviewName('inn', 'ИНН');
    filter.setClassName('inn', 'col-1-row-4');
    filter.setIconClassName('inn', 'icon-tax');
    filter.setAction('inn', 'employeesFilterModule/updateSearch');
    filter.setMask('inn', {
      regex: '[0-9]{13}',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      placeholder: '',
      minChars: 10,
      maxChars: 13,
    });

    filter.createMultiSelectEntity('competences');
    filter.setTitle('competences', 'По компетенции');
    filter.setName('competences', 'multi_select_competences');
    filter.setPreviewName('competences', 'Компетенц');
    filter.setInitAction('competences', 'employees/initCompetences');
    filter.setClassName('competences', 'col-2-row-4');
    filter.setIconClassName('competences', 'icon-book');
    filter.setAction('competences', 'employeesFilterModule/updateMultiSelect');
    filter.setSearchEnabled('competences', true);

    filter.createSelectEntity('geoRegion');
    filter.setTitle('geoRegion', 'По региону');
    filter.setName('geoRegion', 'select_geoRegion');
    filter.setPreviewName('geoRegion', 'Регион');
    filter.setInitAction('geoRegion', 'employees/initRegionsSelect');
    filter.setAction('geoRegion', 'employeesFilterModule/updateSelect');
    filter.setClassName('geoRegion', 'col-1-row-5');
    filter.setIconClassName('geoRegion', 'icon-compass');
    filter.setClearAction('geoRegion', 'employeesFilterModule/resetSelect');
    filter.setSearchEnabled('geoRegion', true);

    filter.createSelectEntity('role');
    filter.setTitle('role', 'По активности');
    filter.setName('role', 'select_role');
    filter.setPreviewName('role', 'По активности');
    filter.setList('role', [
      { id: 'ROLE_EMPLOYEE', value: 'Активен' },
      { id: 'ROLE_DEACTIVATED', value: 'Заблокирован' },
      { id: 'ROLE_ONBOARDING', value: 'Авторизован' },
      { id: 'ROLE_REJECTED', value: 'Отклонен' },
    ]);
    filter.setClassName('role', 'col-2-row-5');
    filter.setIconClassName('role', 'icon-circle-check-mark');
    filter.setIconPreviewClassName('role', 'icon-is-active');
    filter.setAction('role', 'employees/updateRole');
    filter.setClearAction('role', 'employeesFilterModule/resetSelect');

    filter.createMultiSelectEntity('vaccinationStatuses');
    filter.setTitle('vaccinationStatuses', 'По вакцинации');
    filter.setName('vaccinationStatuses', 'multi_select_vaccination');
    filter.setPreviewName('vaccinationStatuses', 'Вакцинация');
    filter.setInitAction('vaccinationStatuses', 'employees/initVaccinationsSelect');
    filter.setAction('vaccinationStatuses', 'employeesFilterModule/updateMultiSelect');
    filter.setClassName('vaccinationStatuses', 'col-1-row-6');
    filter.setIconClassName('vaccinationStatuses', 'icon-covid-19');

    filter.createMultiSelectEntity('status');
    filter.setTitle('status', 'По статусу');
    filter.setName('status', 'multi_select_status');
    filter.setPreviewName('status', 'Статус');
    filter.setInitAction('status', 'employees/initEmployeeStatusesSelect');
    filter.setAction('status', 'employeesFilterModule/updateMultiSelect');
    filter.setClassName('status', 'col-2-row-2');
    filter.setIconClassName('status', 'icon-status');

    filter.createSelectEntity('partner');
    filter.setTitle('partner', 'По партнеру');
    filter.setName('partner', 'select_partner');
    filter.setPreviewName('partner', 'Партнер');
    filter.setInitAction('partner', 'employees/initPartnersSelect');
    filter.setAction('partner', 'employeesFilterModule/updateSelect');
    filter.setClassName('partner', 'col-2-row-6');
    filter.setIconClassName('partner', 'icon-partners');
    filter.setClearAction('partner', 'employeesFilterModule/resetSelect');
    filter.setSearchEnabled('partner', true);
    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partner', !isSupervisor);
      },
      { immediate: true }
    );

    filter.createRadioEntity('gender');
    filter.setTitle('gender', 'Пол');
    filter.setName('gender', 'radio_gender');
    filter.setPreviewName('gender', 'Пол');
    filter.setList('gender', [
      { id: 0, name: 'Мужчина', checked: false, value: 'm', icon: 'icon-man', preview: 'М' },
      { id: 1, name: '', checked: false, value: '' },
      { id: 2, name: 'Женщина', checked: false, value: 'f', icon: 'icon-woman', preview: 'Ж' },
    ]);
    filter.setClassName('gender', 'col-2-row-7');
    filter.setAction('gender', 'employeesFilterModule/updateRadio');

    filter.createCheckboxEntity('underage');
    filter.setTitle('underage', 'Несовершеннолетний');
    filter.setName('underage', 'checkbox_underage');
    filter.setPreviewName('underage', 'Несовершеннолетний');
    filter.setIgnoreNull('underage', true);
    filter.setListElement('underage', {
      id: 'underage',
      name: 'Несовершеннолетний',
      checked: false,
    });
    filter.setAction('underage', 'employeesFilterModule/updateCheckbox');
    filter.setClassName('underage', 'col-1-row-8');
    filter.setIconClassName('underage', 'icon-is-active');

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'employeesFilterModule/updateCheckbox');
    filter.setClassName('isNotTest', 'col-1-row-7');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
