import moment from 'moment/moment.js';

interface StyleByWeeks {
  [key: string]: StyleByWeek;
}

interface HourGrid {
  [key: number]: {
    gridRow: string;
    gridRowEnd: string;
  };
}

interface StyleByWeek {
  min: number;
  max: number;
  weekStyle: string;
  hourGrid: HourGrid;
}

export interface DayOfWeek {
  moment: moment.Moment;
  day: string;
  month: string;
  past: boolean;
  shifts: Record<string, unknown>;
  isToday: boolean;
  dateFormatted: string;
  date: string;
  addClass?: string;
  full?: string;
}

/**
 * Represents a Calendar interface
 */
export default class CalendarAbstract {
  defaultStartWeek: number;
  defaultEndWeek: number;
  minHourDefault: number;
  maxHourDefault: number;
  startWeek: number;
  endWeek: number;
  minHour: number | null;
  maxHour: number | null;
  hours: any;
  allHours: any;
  weeks: Record<number, DayOfWeek>;
  today: {};
  styleByWeeks: StyleByWeeks;
  monthStyle: string;
  month: number;

  /**
   * @override
   */
  constructor() {
    this.defaultStartWeek = 0;
    this.defaultEndWeek = 4;
    this.minHourDefault = 9;
    this.maxHourDefault = 24;
    this.startWeek = 0;
    this.endWeek = 4;
    this.minHour = null;
    this.maxHour = null;
    this.hours = {};
    this.allHours = {};
    this.weeks = {};
    this.today = {};
    this.styleByWeeks = {};
    this.monthStyle = '';
    this.month = parseInt(moment().format('M'));
  }

  /**
   * @override
   */
  setMonth(int: number) {
    this.month = int;
  }

  /**
   * @override
   */
  setStartWeek(int: number) {
    this.startWeek = int;
  }

  /**
   * @override
   */
  setEndWeek(int: number) {
    this.endWeek = int;
  }

  /**
   * @override
   * @param {Object} shiftsByDay in weeks
   */
  getMinHourByShifts(shiftsByDay: any) {
    if (!this.minHour && this.minHour !== 0) {
      this.minHour = parseInt(moment(shiftsByDay[0].minDayStart).format('H'));

      let shift: any;
      for (shift of Object.values(shiftsByDay)) {
        this.minHour = Math.min(parseInt(moment(shift.minDayStart).format('H')), this.minHour);
      }
    } else {
      let shift: any;
      for (shift of Object.values(shiftsByDay)) {
        this.minHour = Math.min(parseInt(moment(shift.minDayStart).format('H')), this.minHour);
      }
    }
  }

  /**
   * @override
   * @param {Object} shiftsByDay in weeks
   */
  getMaxHourByShifts(shiftsByDay: any) {
    if (!this.maxHour) {
      const minutes = moment(shiftsByDay[0].maxDayEnd).format('mm');
      this.maxHour =
        minutes === '30'
          ? parseInt(moment(shiftsByDay[0].maxDayEnd).format('H')) + 1
          : parseInt(moment(shiftsByDay[0].maxDayEnd).format('H'));

      let shift: any;
      for (shift of Object.values(shiftsByDay)) {
        this.maxHour = Math.max(parseInt(moment(shift.maxDayEnd).format('H')), this.maxHour);

        if (parseInt(moment(shift.maxDayEnd).format('H')) === 23 && parseInt(moment(shift.maxDayEnd).format('mm')) === 59) {
          this.maxHour = 24;
        }
      }
    } else {
      let shift: any;
      let minutes = '';
      for (shift of Object.values(shiftsByDay)) {
        minutes = moment(shift.maxDayEnd).format('mm');
        const maxDayEnd =
          minutes === '30'
            ? parseInt(moment(shift.maxDayEnd).format('H')) + 1
            : parseInt(moment(shift.maxDayEnd).format('H'));
        this.maxHour = Math.max(maxDayEnd, this.maxHour);

        if (parseInt(moment(shift.maxDayEnd).format('H')) === 23 && parseInt(moment(shift.maxDayEnd).format('mm')) === 59) {
          this.maxHour = 24;
        }
      }
    }
  }

  /**
   * @override
   * @param {Object} shiftsByDays in weeks
   */
  createWeeks(shiftsByDays: any) {
    const todayDate = moment().format('DD.MM.YYYY');
    const todayNumber = moment().startOf('isoWeek').day();
    let WeekOfYearStart = moment().week() + this.startWeek;
    // let currentDayOfWeek = moment().day(); //TODO: by defaultStartWeek

    let index;
    let momentLastMonday = 7 * this.startWeek + 1;
    this.weeks = {};
    this.today = {};
    this.styleByWeeks = {};
    this.monthStyle = 'grid-template-rows:';
    for (let w = this.startWeek; w <= this.endWeek; w++) {
      this.minHour = null;
      this.maxHour = null;
      const days: any = {};
      index = 1;

      for (let i = momentLastMonday; i <= w * 7 + 7; i++) {
        const dayByNumberOfWeek = moment().startOf('isoWeek').day(i);
        const dateFormat = moment(dayByNumberOfWeek).format('DD.MM.YYYY');
        const month = parseInt(moment(dayByNumberOfWeek).format('M'));

        let past = true; //TODO:: think other way
        if (i >= todayNumber) {
          past = false;
        }

        let shifts = {};
        if (Object.keys(shiftsByDays).includes(dateFormat)) {
          shifts = shiftsByDays[dateFormat];
        }

        if (Object.values(shifts).length > 0) {
          this.getMinHourByShifts(shifts);
          this.getMaxHourByShifts(shifts);
        }

        let addClass = '';

        if (past) {
          addClass = 'past';
        }

        if (dateFormat === todayDate) {
          addClass = `${addClass} today`;
        }

        addClass = this.month === month ? `${addClass} cp-current-month` : `${addClass} cp-not-current-month`;

        if (Object.values(shifts).length > 0) {
          addClass = `${addClass} has-shifts`;

          let shift: any;
          for (shift of Object.values(shifts)) {
            addClass = `${addClass} ${shift.classAdd}`;
          }
        }

        const momentOfDay = moment(dayByNumberOfWeek);

        days[index] = {
          day: momentOfDay.format('D'),
          month: momentOfDay.format('MMMM'),
          past: past,
          shifts: shifts,
          isToday: dateFormat === todayDate,
          dateFormatted: dateFormat,
          date: momentOfDay.format('MM/DD/YYYY'),
          addClass: addClass,
          full: momentOfDay.format('DD MMMM YYYY, dddd'),
          moment: momentOfDay,
        };

        if (dateFormat === todayDate) {
          this.today = days[index];
        }

        index++;
      }

      this.minHour = this.minHour || this.minHour === 0 ? this.minHour : this.minHourDefault;
      this.maxHour = this.maxHour ? this.maxHour : this.maxHourDefault;

      let weekStyle = 'grid-template-rows: [tracks] auto ';
      let hour: number;
      const hourGrid: HourGrid = {};
      for (hour = this.minHour as number; hour < this.maxHour; hour++) {
        weekStyle = `${weekStyle} [time-${hour}00] 1fr `;
        weekStyle = `${weekStyle} [time-${hour}30] 1fr `;

        hourGrid[hour] = {
          gridRow: `${hour}00`,
          gridRowEnd: `${hour}30`,
        };
      }

      momentLastMonday += 7;
      this.weeks[WeekOfYearStart] = days;
      this.styleByWeeks[WeekOfYearStart] = {
        min: this.minHour as number,
        max: this.maxHour,
        weekStyle: weekStyle,
        hourGrid: hourGrid,
      };
      this.monthStyle = `${this.monthStyle} [week-${WeekOfYearStart}] minmax(44px, auto)`;

      WeekOfYearStart += 1;
    }
  }

  /**
   * @override
   */
  createHours() {
    this.hours = {};
    let hour, nextHour;

    for (const weekNumber of Object.keys(this.weeks)) {
      this.hours[weekNumber] = {};

      for (let i = this.styleByWeeks[weekNumber].min; i < this.styleByWeeks[weekNumber].max; i++) {
        hour = i;
        nextHour = i + 1;
        let formatHour = hour.toString();

        if (hour < 10) {
          formatHour = `0${i}`;
        }

        this.hours[weekNumber][i] = {
          gridRow: `${hour}00`,
          gridRowEnd: `${nextHour}00`,
          hour: hour,
          text: `${formatHour}:00 - ${nextHour}:00`,
          textMob: `${formatHour}:00`,
        };
      }
    }
  }

  /**
   * @override
   */
  createAllHours() {
    this.allHours = {};
    let hour;

    for (let i = 0; i <= 24; i++) {
      hour = i;
      let formatHour = hour.toString();

      if (hour < 10) {
        formatHour = `0${i}`;
      }

      this.allHours[i] = {
        gridRow: `${hour}00`,
        hour: hour,
        text: `${formatHour}:00`,
      };
    }
  }

  /**
   * @override
   * @param {Number} startWeek in weeks
   * @param {Object} shiftsByDays in weeks
   */
  addWeek(startWeek: any, shiftsByDays: any) {
    const todayDate = moment().format('DD.MM.YYYY');
    const todayNumber = moment().isoWeekday();
    const weeks: any = {};
    let index;
    const momentLastMonday = 7 * startWeek + 1;
    const WeekOfYearStart = moment().week() + startWeek;

    index = 1;
    const days: any = {};
    this.minHour = null;
    this.maxHour = null;
    for (let i = momentLastMonday; i < momentLastMonday + 7; i++) {
      const dateFormat = moment(moment().isoWeekday(i)).format('DD.MM.YYYY');
      let past = true; //TODO:: think other way
      if (i >= todayNumber) {
        past = false;
      }

      let shifts = {};
      if (Object.keys(shiftsByDays).includes(dateFormat)) {
        shifts = shiftsByDays[dateFormat];
      }

      if (Object.values(shifts).length > 0) {
        this.getMinHourByShifts(shifts);
        this.getMaxHourByShifts(shifts);
      }

      days[index] = {
        moment: moment().isoWeekday(i),
        day: moment(moment().isoWeekday(i)).format('D'),
        month: moment(moment().isoWeekday(i)).format('MMMM'),
        past: past,
        shifts: shifts,
        isToday: dateFormat === todayDate,
        dateFormatted: dateFormat,
        date: moment(moment().isoWeekday(i)).format('MM/DD/YYYY'),
      };

      index++;
    }

    this.minHour = this.minHour || this.minHour === 0 ? this.minHour : this.minHourDefault;
    this.maxHour = this.maxHour ? this.maxHour : this.maxHourDefault;

    let weekStyle = 'grid-template-rows: [tracks] auto ';
    const hourGrid: HourGrid = {};
    for (let hour = this.minHour as number; hour < this.maxHour; hour++) {
      weekStyle = `${weekStyle} [time-${hour}00] 1fr `;
      weekStyle = `${weekStyle} [time-${hour}30] 1fr `;

      hourGrid[hour] = {
        gridRow: `${hour}00`,
        gridRowEnd: `${hour}30`,
      };
    }

    weeks[WeekOfYearStart] = days;
    this.styleByWeeks[WeekOfYearStart] = {
      min: this.minHour as number,
      max: this.maxHour,
      weekStyle: weekStyle,
      hourGrid: hourGrid,
    };

    this.weeks = { ...this.weeks, ...weeks };
  }
}
