/*
 *
 * */

import { Select } from '@/lib/formFactory/select.interface';
import { InputSuggestion } from '@/lib/formFactory/inputSuggestion.interface';
import SelectEntity from '@/lib/formFactory/selectEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionEntity';
import { PositionSelectData } from './shift';

export default class ShopsShiftModel {
  model: {
    position: Select<PositionSelectData>;
    employee: InputSuggestion;
  };

  constructor() {
    const positionEntity = new SelectEntity('position');
    positionEntity.setTitle('');
    positionEntity.setAction('shopsShifts/updatePosition');

    const employeesEntity = new InputSuggestionEntity('employee');
    employeesEntity.setTitle('');
    employeesEntity.setPlaceholder('Введите имя сотрудника');

    this.model = {
      position: positionEntity.entity[positionEntity.entityName],
      employee: employeesEntity.entity[employeesEntity.entityName],
    };
  }
}
