
import { Component, Vue } from '@/lib/decorator';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

library.add(faTimes);
@Component({})
export default class ResponseHandlerMain extends Vue {
  get isShow() {
    return ResponseHandlerModule.isShow;
  }

  get message() {
    return ResponseHandlerModule.message;
  }

  get type() {
    return ResponseHandlerModule.type;
  }

  closeNotify() {
    ResponseHandlerModule.hideNotify();
  }
}
