/**
 * Global API for employees.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest, makeDeleteRequest, makePostRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData, FormResponse } from '@/interfaces/shared';
import { EmployeeRequestModel } from '@/interfaces/models/employees.interface';
import { EmployeeViewType } from '@/lib/util/consts';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

/**
 * Get employees list.
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeesList = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.employees.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get employee by id
 *
 * @param id
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeeById = async (id: string): Promise<{ employee: EmployeeRequestModel; cards: any }> => {
  const url = API.employees.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Add new employee
 *
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const addNewEmployee = async (data: FormData) => {
  const url = API.employees.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save employee
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const saveEmployee = async (id: string, data: FormData) => {
  const url = API.employees.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get employee statistics list.
 *
 * @param employeeId
 * @param sort
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeeStatisticsList = async (employeeId: string, sort: string) => {
  const assemblerUrl = new AssemblerUrl(API.employees.statistics.replace('$1', employeeId));
  const url = assemblerUrl.addSort(sort).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get employee points list.
 *
 * @param employeeId
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeePointsList = async (employeeId: string) => {
  const url = API.employees.pointsList.replace('$1', employeeId);
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get employee suitable shops list.
 *
 * @param employeeId
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeeShopsList = async (employeeId: string) => {
  const url = API.employees.pointsSuitable.replace('$1', employeeId);
  const result = await makeGetRequest(url);

  return result.data.result.markets;
};

/**
 * Add new shop points for statistics
 *
 * @param employeeId
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const addNewStatisticPoint = async (employeeId: string, data: {}) => {
  const url = API.employees.pointCreate.replace('$1', employeeId);
  const result = await makeFormRequest(url, data);

  return result.data.result.point;
};

/**
 * Update save shop points for statistics
 *
 * @param employeeId
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const saveStatisticPoint = async (employeeId: string, data: {}) => {
  const url = API.employees.pointUpdate.replace('$1', employeeId);
  const result = await makeFormRequest(url, data);

  return result.data.result.point;
};

/**
 * Delete points for statistics
 *
 * @param employeeId
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const deleteStatisticPoint = async (employeeId: string) => {
  const url = API.employees.pointsDelete.replace('$1', employeeId);
  const result = await makeDeleteRequest(url);

  return result.data.result.point;
};

/**
 * update Markets Management employee statistics
 *
 * @param employeeId
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE
 */
export const updateMarketsManagement = async (employeeId: string, data: {}) => {
  const url = API.employees.marketTotal.replace('$1', employeeId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get employee favorite from employee shops list.
 *
 * @param shopId
 * @param employeeId
 *
 * @access CAN_READ_EMPLOYEE
 */
export const deleteShopFavorite = async (shopId: string, employeeId: string) => {
  const url = API.employees.shops.replace('$1', shopId).replace('$2', employeeId);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Get employee shifts list.
 *
 * @param employeeId
 * @param startWeek
 * @param endWeek
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeesShifts = async (employeeId: string, startWeek: number, endWeek: number) => {
  const url = API.employees.shift.replace('$1', employeeId).replace('$2', `?startWeek=${startWeek}&endWeek=${endWeek}`);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Add new shift employee - ready
 *
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE_SHIFT
 */
export const addNewShift = async (data: {}) => {
  const url = API.employees.addNewReadyTime;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete shift employee - shift
 *
 * @param employeeId
 * @param params
 *
 * @access CAN_EDIT_EMPLOYEE_SHIFT
 */
export const deleteShift = async (employeeId: string, params: string) => {
  const url = API.employees.shift.replace('$1', employeeId).replace('$2', params);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Save shift employee - ready
 *
 * @param shiftId
 * @param data
 *
 * @access CAN_EDIT_EMPLOYEE_SHIFT
 */
export const updateReadyTimeInfo = async (shiftId: string, data: {}) => {
  const url = API.employees.updateReadyTime.replace('$1', shiftId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete shift employee - ready
 *
 * @param shiftId
 *
 * @access CAN_EDIT_EMPLOYEE_SHIFT
 */
export const deleteReadyTimeById = async (shiftId: string) => {
  const url = API.employees.deleteReadyTime.replace('$1', shiftId);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Search employee by string
 *
 * @param value
 *
 * @access CAN_READ_EMPLOYEE
 */
export const searchEmployee = async (value: string) => {
  const url = API.shops.searchEmployee.replace('$1', value);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get employee role list
 */
export const getEmployeeRoleList = async () => {
  const url = API.employees.roles;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get vaccination select list
 */
export const getVaccinationsAll = async () => {
  const url = API.employees.vaccinationStatuses;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Delete duplicate entity
 *
 * @param duplicateId
 *
 * @access CAN_DELETE_EMPLOYEE_DOUBLES
 */
export const deleteDuplicate = async (duplicateId: string) => {
  const result = await makeDeleteRequest(API.employees.deleteDuplicate.replace('$1', duplicateId));

  return result.data.result;
};

/**
 * Get employee relations
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param params
 *
 * @access CAN_READ_EMPLOYEE
 */
export const getEmployeeRelations = async (
  pageCurrent: number,
  pageAmountItems: string,
  sort: string,
  params: { employeeId: string }
) => {
  const assemblerUrl = new AssemblerUrl(API.employees.relation.replace('$1', params.employeeId));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Send rocketwork request
 *
 * @param employeeId
 */
export const sendRocketWorkRequest = async (employeeId: string) => {
  const url = API.employees.rocketWork.replace('$1', employeeId);
  const result = await makePostRequest(url, {});

  return result.data.result;
};

/**
 * Import bulk employees
 *
 * @param data
 *
 * @access CAN_IMPORT_BULK_EMPLOYEE
 */
export const importBulkEmployees = async (data: any) => {
  const url = API.employees.bulkImport;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * get all employee comments
 *
 * @param employeeId
 * @param count
 */
export const getEmployeeComments = async (employeeId: string, count: number) => {
  const url = API.employees.getComments.replace('$1', employeeId).replace('$2', count.toString());
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * add new comment
 *
 * @param data
 */
export const addNewComment = async (data: FormData) => {
  const url = API.employees.createComment;

  return await makeFormRequest(url, data);
};

/**
 * update comment
 *
 * @param id
 * @param data
 */
export const updateComment = async (id: string, data: FormData) => {
  const url = API.employees.updateComment.replace('$1', id);

  return await makeFormRequest(url, data);
};

/**
 * delete comment
 *
 * @param id
 */
export const deleteComment = async (id: string) => {
  const url = API.employees.deleteComment.replace('$1', id);

  return await makeDeleteRequest(url);
};

/**
 * get employee phone
 *
 * @param employeeId
 * @access CAN_READ_EMPLOYEE_PHONE
 */
export const getEmployeePhone = async (employeeId: string) => {
  const url = API.employees.getPhone.replace('$1', employeeId);
  const result = await makeGetRequest(url);

  return result.data.result.phone;
};

/**
 * Изменить параметр viewed у исполнителя
 */
export const changeViewed = async (
  employeeId: string,
  isViewed: boolean,
  viewType: EmployeeViewType
): Promise<FormResponse> => {
  const url = API.employees.changeViewed(employeeId);
  const type = { view: viewType };
  const encodedType = new URLSearchParams(type).toString();
  const deleteUrl = `${url}?${encodedType}`;
  const request = isViewed ? makeFormRequest(url, type) : makeDeleteRequest(deleteUrl);
  const result = await request;

  return result.data;
};

/**
 * Get employee ratings data
 */
export const getEmployeeRatings = async (
  employeeId: string,
  pageCurrent: number,
  pageAmountItems: string
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.employees.ratings(employeeId));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.marks;
};
