/**
 * Page base module.
 *
 */

import { VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { Page, PageInterface, SortItem } from '@/lib/layouts/page/page.interface';
import { TableApiInterface, TableInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import UserModule from '@/store/user';
import { getStore, setStore } from '@/api/customStore';
import { UITable } from '@/lib/util/tableUtils';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

export default abstract class PageBaseModule extends VuexModule implements Page {
  isSettingColumn: boolean;
  temp: any = {};
  savedSettings: any = {};
  pageSettings: PageInterface;
  isGlobalError = false;

  constructor(module: PageBaseModule) {
    super(module);
    const page = new PageEntity();
    this.pageSettings = page.values;

    this.isSettingColumn = false;
  }

  @Mutation
  CHANGE_TABLE_HEADERS(value: TableTitle[]): void {
    if ('titles' in this.pageSettings.table) {
      this.pageSettings.table.titles = value;
    }
  }

  @Mutation
  CHANGE_SAVED_SETTINGS(module: { name: string; key: string; value: any[] }): void {
    let moduleObject = this.savedSettings[module.key];

    if (!moduleObject) {
      moduleObject = {};
    }

    moduleObject[module.name] = module.value;
  }

  @Mutation
  SET_IS_SETTING_COLUMN(bool: boolean): void {
    this.isSettingColumn = bool;
  }

  @Mutation
  UPDATE_TABLE_TITLES(titles: TableTitle[]): void {
    (this.pageSettings.table as TableInterface).titles = titles;
  }

  @Mutation
  UPDATE_HIDDEN_COLUMNS(columns: {}): void {
    (this.pageSettings.table as TableInterface).hiddenColumns = columns;
  }

  @Mutation
  SET_SAVED_SETTINGS(store: any = {}): void {
    this.savedSettings = store;
  }

  @Mutation
  SET_TABLE(table: TableInterface): void {
    this.pageSettings.table = table;
  }

  @Mutation
  SET_PAGE(number: number): void {
    this.pageSettings.pageCurrent = number;
  }

  @Mutation
  SET_PAGE_AMOUNT_ITEMS(number: string): void {
    this.pageSettings.pageAmountItems = number;
  }

  @Mutation
  setGlobalError(isGlobalError: boolean): void {
    this.isGlobalError = isGlobalError;
  }

  @Action({ rawError: true })
  updateIsSettingsColumn(bool: boolean): void {
    this.context.commit('SET_IS_SETTING_COLUMN', bool);
  }

  @Action({ rawError: true })
  async setPageToBegin(): Promise<void> {
    await this.context.commit('SET_PAGE', 1);
  }

  @Action({ rawError: true })
  changeTitles(value: any[]): void {
    this.context.commit('CHANGE_TABLE_HEADERS', value);
  }

  @Action({ rawError: true })
  async saveSettings(module: { name: string; headers: { hidden: any[]; visible: any[] } }) {
    try {
      await this.context.commit('CHANGE_SAVED_SETTINGS', {
        name: module.name,
        key: 'hiddenTitles',
        value: module.headers.hidden,
      });

      await this.context.commit('CHANGE_SAVED_SETTINGS', {
        name: module.name,
        key: 'orderTitles',
        value: module.headers.visible,
      });

      const store = this.savedSettings ? JSON.parse(JSON.stringify(this.savedSettings)) : {};

      const response = await setStore(store);
      if (!response.message) {
        ResponseHandlerModule.showNotify({
          message: 'Изменения сохранены',
          type: 'ok',
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: response.message,
          type: 'fail',
        });
      }

      this.context.dispatch('updateIsSettingsColumn', false);
    } catch (error) {
      if (error?.response?.data) {
        ResponseHandlerModule.showNotify({
          message: error.response.data,
          type: 'fail',
        });
      }
      console.error(error);
    }
  }

  @Action({ rawError: true })
  async getSettings() {
    try {
      const response = await getStore();

      const orderTitles: Record<string, TableHeaderInterface[]> = {};
      const hiddenTitles: Record<string, TableHeaderInterface[]> = {};

      if (Object.values(response).length) {
        if (response.orderTitles) {
          for (const [key, value] of Object.entries(response.orderTitles as Record<string, TableHeaderInterface[]>)) {
            orderTitles[key] = value.filter((item: TableHeaderInterface) => item);
          }
        }

        if (response.hiddenTitles) {
          for (const [key, value] of Object.entries(response.hiddenTitles as Record<string, TableHeaderInterface[]>)) {
            hiddenTitles[key] = value.filter((item: TableHeaderInterface) => item);
          }
        }
      }

      this.context.commit('SET_SAVED_SETTINGS', {
        orderTitles: orderTitles,
        hiddenTitles: hiddenTitles,
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  getTitleSettingsFromSaved(params: { table: TableApiInterface; moduleName: string }) {
    if (!this.savedSettings || Object.values(this.savedSettings).length === 0) {
      return params.table;
    }

    const currentSettings = this.savedSettings[params.moduleName];
    if (!currentSettings || UserModule.login !== currentSettings?.user) {
      return params.table;
    }

    const savedTitles = currentSettings.titles ? JSON.parse(JSON.stringify(currentSettings.titles)) : [];

    if (!savedTitles || Object.values(savedTitles).length === 0) {
      return params.table;
    }

    const newTitles: any = [];
    let title: any;
    for (title of Object.values(savedTitles)) {
      const apiTitle = Object.values(params.table.titles).filter((item) => item.id === title.id)[0];
      title['sort'] = apiTitle && apiTitle.sort ? apiTitle.sort : '';

      newTitles.push(title);
    }

    // it fix add new title from server
    let titleTableApi: any;
    for (titleTableApi of Object.values(params.table.titles)) {
      const savedTitle = Object.values(savedTitles).filter((item: any) => item.id === titleTableApi.id)[0];
      const leftTitle = Object.values(currentSettings.titles).filter((item: any) => item.id === titleTableApi.id)[0];

      if (savedTitle || leftTitle) {
        continue;
      }

      newTitles.push(titleTableApi);
    }
    params.table.titles = Object.values(newTitles).length > 0 ? newTitles : params.table.titles;

    return params.table;
  }

  @Action({ rawError: true })
  initList(): void {
    this.context.dispatch('getList');
  }

  getList(): void;
  getList(): Promise<void>;

  @Action({ rawError: true })
  getList(): void | Promise<void> {
    this.context.dispatch('setList', {} as TableApiInterface);
  }

  @Action({ rawError: true })
  getSortForRequest() {
    let sort = '';
    let index: string;
    let item: SortItem;
    for ([index, item] of Object.entries(this.pageSettings.sort)) {
      sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
    }

    return sort;
  }

  @Action({ rawError: true })
  initListById(id: string): void {
    this.getListById(id);
  }

  @Action({ rawError: true })
  getListById(id: string): void {
    console.info(id);
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    this.context.commit('SET_TABLE', uiTable.getTable() as any);
  }

  @Action({ rawError: true })
  updateTitleEdit(title: string) {
    this.pageSettings.titleEdit = title;
  }

  @Action({ rawError: true })
  updatePage(number: string) {
    this.context.commit('SET_PAGE', parseInt(number));
    this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.commit('SET_PAGE_AMOUNT_ITEMS', number);
    await this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  async updatePaginationSettings(params: { moduleName: string; amountItems: string }) {
    await this.context.dispatch('setPageAmountStorageValue', {
      moduleName: params.moduleName,
      number: params.amountItems,
    });
    await this.context.commit('SET_PAGE', 1);
    await this.context.commit('SET_PAGE_AMOUNT_ITEMS', params.amountItems);
  }

  @Action({ rawError: true })
  setPageAmountStorageValue(params: { moduleName: string; number: string }) {
    window.localStorage[`${params.moduleName}_pageAmountItems`] = params.number;
  }

  @Action({ rawError: true })
  getPageAmountStorageValue(moduleName: string) {
    try {
      return window.localStorage[`${moduleName}_pageAmountItems`];
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getItemsQuery(moduleName: string) {
    try {
      const pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', moduleName);

      return pageAmountItems ? `items=${pageAmountItems}&` : '';
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.context.dispatch('getList');
  }

  @Action({ rawError: true })
  sortProcessed(params: { field: string; sort: string }) {
    let sortNew: string;
    if (!params.sort) {
      sortNew = 'ASC';
    } else {
      sortNew = params.sort === 'ASC' ? 'DESC' : 'ASC';
    }

    let sortProcessed: any[] = [];
    const sortExisted = Object.values(this.pageSettings.sort).filter((sort: any) => sort.key === params.field)[0];

    if (sortExisted) {
      Object.values(this.pageSettings.sort).forEach((sortEntity: any) => {
        const sort = sortEntity.key === params.field ? sortNew : sortEntity.sort;

        if (sortEntity.key === params.field && sortExisted.sort === 'DESC') {
          return;
        }

        sortProcessed.push({ key: sortEntity.key, sort: sort });
      });

      return sortProcessed;
    }

    const index = Object.values(this.pageSettings.sort).length;
    sortProcessed[index] = {
      key: params.field,
      sort: sortNew,
    };
    sortProcessed = { ...this.pageSettings.sort, ...sortProcessed };

    return sortProcessed;
  }
}
