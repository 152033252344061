/**
 * Shop entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import {
  ShopComment,
  ShopEntity,
  ShopMinEntity,
  ShopModel,
  ShopProcessedModel,
  ShopTextFields,
} from '@/interfaces/models/shop.interface';
import { tabsNav } from './entityTabs';
import EntityModel from './entityModel';
import ShopsModule from './index';
import CustomersModule from '@/store/customers';

import {
  getShopById,
  addNewShop,
  saveShop,
  getCheckInCode,
  getCheckOutCode,
  getShopMinById,
  addNewComment,
  updateComment,
  deleteComment,
} from '@/api/shop';
import { getClientGeoHelperById } from '@/api/geo';
import { getClientById } from '@/api/client';
import { getCoordinates } from '@/api/geo';
import { assistantNameProcessed } from '@/lib/Shop';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';

import { coordinates } from '@/lib/formFactory/map.interface';
import ResponseHandlerStaticModule from '@/store/modules/responseHandlerStatic';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import userModule from '@/store/user';
import moment from 'moment';
import { CatchFormResponse, FormResponse } from '@/interfaces/shared';
import { ClientPartners } from '@/interfaces/partner.interface';

export const MODULE_NAME = 'shop';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopEntityModule extends EntityBaseModule {
  titleEdit = '';
  shop: ShopProcessedModel | {} = {};
  tabsNav: TabsNavInterface = {};
  model: ShopModel;
  assistantItems: { id: string; email: string; phone: string; value: string }[] = [];
  timeZone = 0;

  checkoutCode = '';
  checkinCode = '';
  checkInOutDate = {
    key: 'date',
    type: 'text',
    title: 'Дата',
    value: '',
    action: 'shopsEntity/updateDate',
    placeholder: '',
    required: true,
    size: 40,
    mask: {
      regex: '\\d*',
      placeholder: ' ',
      minChars: 1,
    },
    error: {
      class: '',
      message: '',
    },
    validation: {
      empty: '',
    },
  };

  get isShopOwner(): boolean {
    if (this.model.clientPartners) {
      const isOwner = ((this.model.clientPartners as unknown) as ClientPartners[]).some((item) => {
        if (userModule.partner?.uuid === item.partner.uuid) {
          return true;
        }

        return false;
      });
      if (isOwner) return true;
    }

    return false;
  }

  get isContractor(): boolean {
    return userModule.partner?.uuid === this.model.contractor;
  }

  constructor(module: ShopEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_TITLE_EDIT(title: string) {
    this.titleEdit = title;
  }

  @Mutation
  SET_SHOP(shop: ShopProcessedModel) {
    this.shop = shop;
  }

  @Mutation
  SET_ASSISTANT_ITEMS(payload: { id: string; email: string; phone: string; value: string }[]) {
    this.assistantItems = payload;
  }

  @Mutation
  SET_CHECK_IN_OUT_DATE(date: string) {
    this.checkInOutDate.value = date;
  }

  @Mutation
  SET_CHECK_IN_CODE(code: string) {
    this.checkinCode = code;
  }

  @Mutation
  SET_CHECK_OUT_CODE(code: string) {
    this.checkoutCode = code;
  }

  @Mutation
  ADD_NEW_COMMENT(comment: ShopComment) {
    this.model.comments.unshift(comment);
  }

  @Mutation
  UPDATE_COMMENT(payload: { id: number; text: string }) {
    const commentForUpdate = this.model.comments.find((comment) => comment.id === payload.id);

    if (commentForUpdate) {
      commentForUpdate.text = payload.text;
    }
  }

  @Mutation
  DELETE_COMMENT(id: number) {
    this.model.comments = this.model.comments.filter((comment) => comment.id !== id);
  }

  @Mutation
  SET_TIMEZONE(timeZone: number) {
    this.timeZone = timeZone;
  }

  @Action({ rawError: true })
  initTabs(): void {
    const newTabs: TabsNavInterface = {};

    for (const [tabKey, tab] of Object.entries(tabsNav)) {
      const hasGroupPermission = userModule.canUserRead(tab.pathName);
      const hasOwnerPermission = !tab.onlyForOwner || this.isShopOwner || userModule.isSupervisor;

      if (hasGroupPermission && hasOwnerPermission) {
        newTabs[tabKey] = tab;
      }
    }

    Object.keys(this.tabsNav).forEach((tabKey) => {
      if (!newTabs[tabKey]) {
        delete this.tabsNav[tabKey];
      }
    });

    Object.assign(this.tabsNav, newTabs);
  }

  @Action({ rawError: true })
  async initTitle(id: string) {
    try {
      const result: ShopMinEntity = await getShopMinById(id);

      await this.updateTitleEdit(result.code + ', ' + result.name);
      this.context.commit('SET_TIMEZONE', result.timeZone);
      this.context.commit('SET_MODEL_VALUE', { key: 'rating', value: result.ratingCount?.toString().substring(0, 3) });
      this.context.commit('SET_MODEL_VALUE', { key: 'sharing', value: result.sharing ?? 'none' });
      this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: result.isTest });
      this.context.commit('SET_MODEL_VALUE', { key: 'code', value: result.code });
      this.context.commit('SET_MODEL_VALUE', { key: 'partnerUuid', value: result.partner?.uuid ?? '' });
      this.context.commit('SET_MODEL_VALUE', { key: 'clientPartners', value: result.clientMarketSubcontractors });
      this.context.commit('SET_MODEL_VALUE', { key: 'clientId', value: result.client?.id ?? '' });
      this.context.commit('SET_MODEL_VALUE', { key: 'partnerName', value: result.partner?.legalName ?? '' });
      this.context.commit('SET_MODEL_VALUE', { key: 'clientId', value: result.client?.id.toString() ?? '' });
      this.context.commit('SET_MODEL_VALUE', { key: 'paymentStrategy', value: result.paymentStrategy.type });
      this.context.commit('SET_MODEL_VALUE', { key: 'contractor', value: result.client?.contractor.uuid ?? '' });
      this.context.commit('SET_MODEL_VALUE', { key: 'clientSubcontractors', value: result.client?.clientSubcontractors });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }

    this.initTabs();
  }

  @Action({ rawError: true })
  updateTitleEdit(title: string) {
    this.context.commit('SET_TITLE_EDIT', title);
  }

  @Action({ rawError: true })
  async initAdd(customerId?: string) {
    await this.getOptions(customerId);
    await this.updateAddressCoordinates(this.model.addressCoordinates.default);
    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async initEdit(params: { id: string; customerId?: string }) {
    try {
      const result: ShopEntity = await getShopById(params.id);

      await this.context.dispatch('updateTitleEdit', result.fullName);
      await this.setInfo(result);

      if (this.model.customer.id && userModule.isSupervisor) {
        await this.getAssistantsList(this.model.customer.id.toString());
      }
    } catch (error) {
      if (error.response?.data?.errors?.fields) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors?.fields, type: 'fail' });
      }
    }

    this.initTabs();
  }

  @Action
  async getOptions(customerId?: string) {
    if (customerId) {
      const result = await getClientById(customerId);
      CustomersModule.updateTitleEdit(result.name);
      this.context.commit('SET_MODEL_VALUE', {
        key: 'customer',
        value: {
          id: result.id,
          value: result.name,
        },
      });
      this.context.commit('SET_MODEL_VALUE', { key: 'clientSubcontractors', value: result.clientSubcontractors });
    }
  }

  @Action
  async setInfo(info: ShopEntity) {
    ShopsModule.updateTitleEdit(info.fullName);
    info.comments.reverse();

    this.context.commit('SET_MODEL_VALUE', { key: 'id', value: info.id.toString() });
    this.context.commit('SET_MODEL_VALUE', { key: 'code', value: info.code });
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'comments', value: info.comments });
    this.context.commit('SET_MODEL_VALUE', { key: 'marketPhones', value: info.marketPhones });
    this.context.commit('SET_MODEL_VALUE', { key: 'info', value: info.info });
    this.context.commit('SET_MODEL_VALUE', { key: 'customer', value: { id: info.client.id, value: info.client.name } });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'regularNeed', value: info.regularNeed });
    this.context.commit('SET_MODEL_VALUE', { key: 'timezone', value: info.timezone });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: info.isTest });
    this.context.commit('SET_MODEL_VALUE', { key: 'isShared', value: info.sharing === 'all' });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentStrategy', value: info.paymentStrategy?.type ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckIn', value: info.autoCheckIn });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckup', value: info.autoCheckup });
    this.context.commit('SET_MODEL_VALUE', { key: 'contractor', value: info.client.contractor.uuid });
    this.context.commit('SET_MODEL_VALUE', { key: 'clientSubcontractors', value: info.client.clientSubcontractors });

    if (info.clientMarketSubcontractors) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'partnerUuid',
        value: info.clientMarketSubcontractors.map((item) => item.partner.uuid),
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'partnerName',
        value: info.clientMarketSubcontractors.map((item) => item.partner.legalName),
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'mainPartner',
        value: info.clientMarketSubcontractors
          .filter((item) => item.main)
          .map((item) => {
            return {
              id: item.partner.uuid,
              value: item.partner.legalName,
              name: item.partner.legalName,
              isSupervisor: item.partner.isSupervisor,
            };
          })[0],
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'partner',
        value: info.clientMarketSubcontractors.map((item) => {
          return {
            id: item.partner.uuid,
            value: item.partner.legalName,
            name: item.partner.legalName,
            isSupervisor: item.partner.isSupervisor,
          };
        }),
      });
    }

    this.context.commit('SET_MODEL_VALUE', {
      key: 'region',
      value: {
        id: info.region.id,
        value: info.region.name,
        timezoneNumber: info.region.timezoneNumber,
      },
    });

    CustomersModule.updateTitleEdit(info.client.name);
    this.context.commit('SET_MODEL_VALUE', {
      key: 'customer',
      value: {
        id: info.client.id,
        value: info.client.name,
      },
    });

    this.updateAddress({ name: info.address });
    this.updateAddressCoordinates(info.addressCoordinates ? info.addressCoordinates : this.model.addressCoordinates.default);
    if (info.address) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'address',
        value: { id: info.address, value: info.address ?? '' },
      });
    }

    if (info.assistant) {
      info.assistant.value = assistantNameProcessed(info.assistant);
      this.context.commit('SET_MODEL_VALUE', {
        key: 'assistant',
        value: info.assistant,
      });
    }

    this.updateAddressCoordinates(info.addressCoordinates ? info.addressCoordinates : this.model.addressCoordinates.default);

    if (info.responsible) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsibleName',
        value: info.responsible.name,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsibleEmail',
        value: info.responsible.email,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'responsiblePhone',
        value: info.responsible.phone,
      });
    }

    if (info.assistant) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'assistantName',
        value: info.assistant.value,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'assistantEmail',
        value: info.assistant.email,
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'assistantPhone',
        value: info.assistant.phone,
      });
    }

    const { accountManager } = info;

    if (accountManager) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'accountManager',
        value: {
          id: accountManager.id,
          value: accountManager.fullName,
        },
      });
    }

    this.context.commit('SET_CREATED_AT', info.createdAt);
    this.context.commit('SET_UPDATED_AT', info.updatedAt);

    this.updateIsLoading(false);
  }

  @Action
  updateAddress(address: {}) {
    this.context.commit('SET_ADDRESS', address);
  }

  @Action
  async getCoordinates(value: string) {
    try {
      if (value.length === 0) {
        return;
      }

      const coordinates = await getCoordinates(value);
      this.updateAddressCoordinates({ latitude: coordinates.geo_lat, longitude: coordinates.geo_lon });

      return coordinates;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action
  updateAddressCoordinates(coordinates: {}) {
    this.context.commit('SET_ADDRESS_COORDINATES', coordinates);
  }

  @Action({ rawError: true })
  async addNew(params: { customerId?: string; textFieldsData: ShopTextFields }) {
    try {
      const data = await this.prepareData(params.textFieldsData);
      const result = await addNewShop(params.customerId ? params.customerId : (data.customerId as string), data);

      if (!(result as FormResponse).message) {
        ResponseHandlerModule.showNotify({ message: 'Объект создан', type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: (result as ShopEntity).isTest });
      } else {
        ResponseHandlerModule.showNotify({ message: (result as FormResponse).message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      ResponseHandlerModule.showNotify({ message: errorData.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  showUniqueError(errorData: { type: string; errors: { fields: Record<string, string | Record<string, string>> } }) {
    ResponseHandlerStaticModule.hideNotify();
    let message = '';
    let fieldText = '';
    for (const errorEntity of Object.values(errorData.errors)) {
      if (errorEntity.field === 'address') {
        fieldText = 'Объект с таким адрессом уже существует ';
      }

      if (errorEntity.field === 'code') {
        fieldText = 'Объект с таким кодом уже существует ';
      }

      const fullName = (errorEntity.custom_data as Record<string, string>).fullname;
      const id = (errorEntity.custom_data as Record<string, string>).id;
      const profileLink = `<a href="${window.location.origin}/shops/${id}" target="_blank">${fullName}</a>`;
      message += `${fieldText}: ${profileLink} <br>`;

      ResponseHandlerStaticModule.showNotify({ content: message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; textFieldsData: ShopTextFields }) {
    try {
      const data = await this.prepareData(params.textFieldsData);

      const result = await saveShop(params.id, data);

      if (!(result as FormResponse).message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: (result as ShopEntity).isTest });
      } else {
        ResponseHandlerModule.showNotify({ message: (result as FormResponse).message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      let message = '';

      if (errorData.message) {
        message = errorData.message;
      } else if (error.root && error.root.length) {
        message = error.root.join(',');
      } else if (errorData.errors && errorData.errors.fields) {
        message = errorData.errors.fields;
      }

      ResponseHandlerModule.showNotify({
        message,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  prepareData(textFieldsData?: ShopTextFields) {
    const data: Record<string, string> = {
      lat: (this.model.addressCoordinates.value as coordinates).latitude.toString(),
      long: (this.model.addressCoordinates.value as coordinates).longitude.toString(),
    };

    return Object.assign(data, textFieldsData);
  }

  @Action({ rawError: true })
  resetTitle() {
    ShopsModule.updateTitleEdit('');
  }

  @Action({ rawError: true })
  resetModel() {
    this.updateVacancies([]);

    this.context.commit('SET_MODEL_VALUE', { key: 'id', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'code', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'comment', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'comments', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'marketPhones', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'info', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'address', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'customer', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'region', value: { id: null, value: '', timezoneNumber: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'regularNeed', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'timezone', value: '' });

    this.context.commit('SET_MODEL_VALUE', { key: 'assistant', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'assistantName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'assistantPhone', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'assistantEmail', value: '' });

    this.context.commit('SET_MODEL_VALUE', { key: 'responsibleName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsiblePhone', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'responsibleEmail', value: '' });

    this.context.commit('SET_MODEL_VALUE', { key: 'accountManager', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckIn', value: true });
    this.context.commit('SET_MODEL_VALUE', { key: 'autoCheckup', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isShared', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'contractor', value: '' });

    this.context.commit('SET_CREATED_AT', '');
    this.context.commit('SET_UPDATED_AT', '');

    this.updateIsLoading(true);
  }

  @Action({ rawError: true })
  async getAssistantsList(customerId: string) {
    try {
      const result: {
        rows: {
          id: string;
          email: string;
          phone: string;
          firstName: string;
          middleName: string;
          lastName: string;
        }[];
      } = await getClientGeoHelperById(customerId);

      this.context.commit(
        'SET_ASSISTANT_ITEMS',
        result.rows.map(
          (item: { id: string; email: string; phone: string; firstName: string; middleName: string; lastName: string }) => ({
            id: item.id,
            value: item.firstName + ' ' + item.middleName + ' ' + item.lastName,
            phone: item.phone,
            email: item.email,
          })
        )
      );
    } catch (error) {
      if (error.response.data.errors?.fields) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors?.fields, type: 'fail' });
      }
    }
  }
  @Action({ rawError: true })
  async updateCheckInOutDate(params: { day: string; month: string; year: string }) {
    const newDate = moment(`${params.day}-${params.month}-${params.year}`, 'DD-MM-YYYY').format('DD/MM/YY');
    await this.context.commit('SET_CHECK_IN_OUT_DATE', newDate);
  }

  @Action({ rawError: true })
  async getCheckInOutCodes(id: string) {
    try {
      const checkInResult = await getCheckInCode(id, moment(this.checkInOutDate.value, 'DD/MM/YY').format('YYYY-MM-DD'));
      this.context.commit('SET_CHECK_IN_CODE', checkInResult.checkinCode);

      const checkOutResult = await getCheckOutCode(id, moment(this.checkInOutDate.value, 'DD/MM/YY').format('YYYY-MM-DD'));
      this.context.commit('SET_CHECK_OUT_CODE', checkOutResult.checkoutCode);
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  async addNewComment(text: string) {
    try {
      const { result } = await addNewComment({
        market: this.model.id,
        text,
      });

      this.ADD_NEW_COMMENT(result.comment);
    } catch (error) {
      const message = (error as CatchFormResponse).response?.data?.message;

      if (message) {
        ResponseHandlerModule.showNotify({ message, type: 'fail' });
      } else {
        console.error(error);
      }
    }
  }

  @Action({ rawError: true })
  async editComment(data: { id: number; text: string }) {
    try {
      await updateComment(data.id, {
        text: data.text,
      });

      this.UPDATE_COMMENT(data);
    } catch (error) {
      const message = (error as CatchFormResponse).response?.data?.message;

      if (message) {
        ResponseHandlerModule.showNotify({ message, type: 'fail' });
      } else {
        console.error(error);
      }
    }
  }

  @Action({ rawError: true })
  async deleteComment(id: number) {
    try {
      await deleteComment(id);
      this.DELETE_COMMENT(id);
    } catch (error) {
      const message = (error as CatchFormResponse).response?.data?.message;

      if (message) {
        ResponseHandlerModule.showNotify({ message, type: 'fail' });
      } else {
        console.error(error);
      }
    }
  }
}

export default getModule(ShopEntityModule);
