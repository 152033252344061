import { TableApiInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import userModule from '@/store/user';
import { prepareStatisticsWithOrders } from '@/lib/Statistic';

/**
 * Returns processed shops info for shops table
 *
 * @param {TableApiInterface} table shops data
 * @param options
 */
export function prepareList(
  table: TableApiInterface,
  options?: { order?: TableTitle[]; hidden?: TableTitle[]; sort?: { sort: string; key: string }[] }
): {
  titles: TableTitle[];
  rows: { [p: string]: string | number | boolean }[];
  pagination: {} | { currentPage: number; totalItems: number; totalPages: number };
  totalItems: number;
  filters: { name: string; value: string | null }[];
  totalData: { [index: string]: string | number } | undefined;
  additionalInfo: { [index: string]: string } | undefined;
  hiddenTitles: TableTitle[];
} {
  const uiTable = new UITable(
    table as {
      titles?: TableTitle[];
      rows?: { [p: string]: string | number | boolean }[];
      pagination?: { currentPage: number; totalItems: number; totalPages: number };
      totalItems?: number;
      sortFields?: string[];
      filters?: { name: string; value: string | null }[];
      totalData?: { [p: string]: string | number };
      additionalInfo?: { [p: string]: string };
    }
  );

  uiTable
    .removeColumn('isTest')
    .changeTitleOrder('ratingCount', 1)

    .setProperties('fullName', { id: 'fullName', width: '28%', name: 'Объект' })
    .setProperties('ratingCount', { id: 'ratingCount', align: 'center' })
    .setProperties('client', { id: 'client', width: '14%' })
    .setProperties('comment', { id: 'comment', width: '22%' })
    .setProperties('region', { id: 'region', width: '18%' })
    .setProperties('isActive', { id: 'isActive', width: '36px', name: 'А', align: 'center' })
    .setProperties('vacanciesString', { id: 'vacanciesString', width: '18%', name: 'Шаблон' })
    .setProperties('lastShiftDay', { id: 'lastShiftDay', width: '75px' });

  if (userModule.isSupervisor) {
    uiTable.addColumn({
      id: 'openedNeed',
      name: 'Заполняемость (+14 дней)',
      visible: true,
      width: '120px',
      sortable: true,
    });
  }

  if (userModule.userHasPermission('CAN_READ_CLIENT_MARKET_PHONES')) {
    uiTable.setProperties('marketPhones', { id: 'marketPhones', width: '120px' });
  }

  uiTable
    .setSortableValues(options && options.sort)
    .setHiddenTitles(options && options.hidden)
    .setOrderTitles(options && options.order);

  return uiTable.getTable();
}

interface AssistantInterface {
  lastName: string;
  firstName: string;
  middleName: string;
}

/**
 * Returns processed assistant name
 *
 * @param {Object} assistant info
 * @returns {string} assistantName
 */
export function assistantNameProcessed(assistant: AssistantInterface) {
  let assistantName = assistant.lastName ? assistant.lastName : '';
  assistantName += assistant.firstName ? ` ${assistant.firstName}` : '';
  assistantName += assistant.middleName ? ` ${assistant.middleName}` : '';

  return assistantName;
}

export function prepareShopStatistics(allStatistics: any) {
  const preparedStatistics = prepareStatisticsWithOrders(allStatistics);

  return preparedStatistics;
}
