import { VacancyModel } from '@/interfaces/models/vacancy.interface';

export default class EntityModel {
  model: VacancyModel;

  constructor() {
    this.model = {
      name: '',
      externalName: '',
      minHours: '',
      maxHours: '',
      competence: { id: null, value: '' },
      rate: '',
      externalRate: '',
      hasLmk: false,
      isActive: false,
      hasRotation: false,
      isTraining: false,
      gender: { id: 3, name: 'Не важно', value: '' },
      info: '',
      createdAt: '',
      updatedAt: '',
      vaccinationRequirement: '',
      ageFrom: '',
      ageTo: '',
    };
  }
}
