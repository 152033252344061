/**
 * Users store.
 *
 */

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import { getAllPartnersList } from '@/api/partners';

import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getSecurityGroupsListAll, getUsersList } from '@/api/users';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';

import filterModel from './filter';
import SettingsUsersFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { UITable } from '@/lib/util/tableUtils';

export const MODULE_NAME = 'settingsUsers';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UsersModule extends PageBaseModule {
  filter: Filter;
  selectDefault = {
    id: '0',
    value: '',
  };

  constructor(module: UsersModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Пользователи');
    page.setTitleAdd('Добавление пользователя');
    page.values.actionPagination = 'settingsUsers/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(SettingsUsersFilter);
    this.filter.setFilterName('usersFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setBtnClassName('col-1-row-8');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.filter.init();

    this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = (await this.getSortForRequest()).replace('fullName', 'lastName');
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = await this.getFilterForRequest();
      const result = await getUsersList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  getFilterForRequest() {
    return this.filter.filterSettings.filter;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable
      .removeColumn('firstName')
      .removeColumn('lastName')
      .removeColumn('login')

      .setProperties('isActive', {
        id: 'isActive',
        sortable: false,
        width: '32px',
        name: 'A',
        align: 'center',
      })

      .addColumnByIndex(0, {
        id: 'fullName',
        name: 'Пользователь',
        visible: true,
        sortable: true,
        sort: {},
      })

      .changeTitleOrder('partner', 1)
      .setSortableValues(sorts)
      .getTable();

    this.context.commit('SET_TABLE', tableInfo as any);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('setPageAmountStorageValue', {
      moduleName: MODULE_NAME,
      number,
    });
    await this.context.commit('SET_PAGE_AMOUNT_ITEMS', number);
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  async initSecurityGroupsSelect() {
    const result = await getSecurityGroupsListAll();

    return result.map((item: { id: string; name: string }) => ({
      id: item.id,
      name: item.name,
      value: item.name,
    }));
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    const result: PartnerSelectListItem[] = await getAllPartnersList();

    return result.map((partner) => ({
      id: partner.uuid,
      value: partner.legalName,
      name: partner.legalName,
    }));
  }
}

export default getModule(UsersModule);
