
import { Component, Vue } from '@/lib/decorator';
import IconFont from '@/components/icons/IconFont.vue';
import ModalsModule from '@/store/modals';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormButton from '@/components/form/Button/index.vue';

@Component({
  components: {
    IconFont,
    ModalBtnBottom,
    FormButton,
  },
})
export default class ModalsWarning extends Vue {
  get isShow() {
    return ModalsModule.isShow.warning;
  }

  get id() {
    return ModalsModule.params.id;
  }

  get text() {
    return ModalsModule.params.text;
  }

  get buttonsText() {
    return ModalsModule.params.buttonsText;
  }

  get handlers() {
    return ModalsModule.params.handlers;
  }

  closeModal() {
    ModalsModule.closeModalByType('warning');
  }

  handlerNo() {
    ModalsModule.closeModalByType('warning');
  }

  handlerYes() {
    this.$store.dispatch(this.handlers.yes, { id: this.id, router: this.$router });
    ModalsModule.closeModalByType('warning');
  }
}
