/**
 * Customers vacancies store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getClientVacanciesById } from '@/api/vacancies';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { getAllVaccinationStatusList } from '@/api/vacancies';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'customersVacancies';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersVacanciesModule extends PageBaseModule {
  onlyActive = true;
  customerId = '';
  actionsHandler: { additional: { [key: string]: string } };
  vaccinationsList: { id: string; name: string }[] = [];

  constructor(module: CustomersVacanciesModule) {
    super(module);

    const page = new PageEntity();
    page.setTitleAdd('Добавление типа услуг');
    page.values.actionPagination = 'customersVacancies/updatePage';
    this.pageSettings = page.values;

    this.actionsHandler = {
      additional: {
        name: 'customers/getById',
      },
    };
  }

  @Mutation
  SET_ONLY_ACTIVE(value: boolean) {
    this.onlyActive = value;
  }

  @Mutation
  SET_CUSTOMER_ID(id: string) {
    this.customerId = id;
  }

  @Mutation
  SET_VACCINATIONS_LIST(list: { id: string; name: string; value: string }[]) {
    this.vaccinationsList = list;
  }

  @Action({ rawError: true })
  async initListById(customerId: string) {
    await this.context.commit('SET_CUSTOMER_ID', customerId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getListById(customerId);
    await this.getVaccinationsList();
  }

  @Action({ rawError: true })
  async getListById(customerId: string) {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filters = this.onlyActive ? '&filters[3][id]=active&filters[3][value]=1' : '';
      const result = await getClientVacanciesById(customerId, this.pageSettings.pageCurrent, itemsQuery, filters);
      this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);

    uiTable
      .setProperty('minHours', 'align', 'right')
      .setProperty('rate', 'align', 'right')
      .setProperty('externalRate', 'align', 'right');

    const tableInfo = uiTable.getTable();

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async getVaccinationsList() {
    const vaccinationsAll: { type: string; humanType: string }[] = await getAllVaccinationStatusList();
    this.context.commit(
      'SET_VACCINATIONS_LIST',
      Object.values(vaccinationsAll).map((vaccination: { type: string; humanType: string }) => {
        return {
          id: vaccination.type,
          value: vaccination.type,
          name: vaccination.humanType,
        };
      })
    );
  }

  @Action({ rawError: true })
  async updateOnlyActive(value: boolean) {
    await this.context.commit('SET_ONLY_ACTIVE', value);
    await store.dispatch('customersVacancies/updatePage', 1);
    await this.getListById(this.customerId);
  }
}

export default getModule(CustomersVacanciesModule);
