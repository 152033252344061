import { VuexModule, Mutation, Action, Module, getModule } from 'vuex-module-decorators';
import { AxiosRequestConfig } from 'axios';
import store from '@/store';
import PaymentsModule from './index';
import { BASE_URL } from '@/config/axios-config';
import { makeGetFileRequest } from '@/api/requests';
import { exportPayments, getLastExportStatus } from '@/api/payments';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { strings } from '@/lib/stringConst';
import { saveBlob } from '@/lib/Utils';
import { CatchFormResponse } from '@/interfaces/shared';
import userModule from '@/store/user';

export const MODULE_NAME = 'payments_export';

export enum PaymentsExportStatus {
  None = 'none',
  InProgress = 'in_progress',
  Done = 'done',
}

export interface ExportPushData {
  filename?: string;
  link?: string;
}

export interface PaymentsExportStatusResponse {
  exportLink: {
    createdAt: string;
    fileName: string;
    link: string;
  } | null;
  status: PaymentsExportStatus;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PaymentsExportModule extends VuexModule {
  status = PaymentsExportStatus.None;

  lastExportFilename = '';
  lastExportLink = '';

  statusChannel = new BroadcastChannel('paymentsExportStatus');

  @Mutation
  SET_STATUS(status: PaymentsExportStatus) {
    this.status = status;
  }

  @Mutation
  SET_LAST_EXPORT_FILENAME(filename: string) {
    this.lastExportFilename = filename;
  }

  @Mutation
  SET_LAST_EXPORT_LINK(link: string) {
    this.lastExportLink = link;
  }

  @Action({ rawError: true })
  setStatusOnAllTabs(status: PaymentsExportStatus) {
    this.SET_STATUS(status);
    this.statusChannel.postMessage(status);
  }

  @Action({ rawError: true })
  async getLastExport(): Promise<void> {
    if (
      userModule.userHasPermission('CAN_PAYOUT_FINANCE_PAYOUT') ||
      userModule.userHasPermission('CAN_DOWNLOAD_FINANCE_PAYOUT')
    ) {
      const result = await getLastExportStatus();

      this.setStatusOnAllTabs(result.status);

      this.SET_LAST_EXPORT_FILENAME(result.exportLink?.fileName ?? '');
      this.SET_LAST_EXPORT_LINK(result.exportLink?.link ?? '');
    }
  }

  @Action({ rawError: true })
  async exportPayments(checked: boolean) {
    try {
      this.SET_LAST_EXPORT_FILENAME('');

      const createPayouts = checked ? '&filters[8][id]=createPayouts&filters[8][value]=1' : '';
      const dates = await PaymentsModule.getFilterDates();
      const filter = PaymentsModule.filter.filterSettings.filter;
      const filterString = `${dates}${filter}`;
      const response = await exportPayments(`${filterString}${createPayouts}`);

      if (!response.message) {
        ResponseHandlerModule.showNotify({ message: 'Выгрузка создана', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: response.message, type: 'fail' });
      }

      this.setStatusOnAllTabs(PaymentsExportStatus.InProgress);

      await PaymentsModule.hideModal('export');
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async exportPushHandler(pushData: ExportPushData) {
    if (!pushData.filename || !pushData.link) {
      return;
    }

    this.SET_STATUS(PaymentsExportStatus.Done);
    this.SET_LAST_EXPORT_LINK(pushData.link);
    this.SET_LAST_EXPORT_FILENAME(pushData.filename);

    this.downloadExportFile();
  }

  @Action({ rawError: true })
  async downloadExportFile() {
    try {
      const link = this.lastExportLink;
      const filename = this.lastExportFilename;

      if (!filename) {
        throw new Error();
      }

      const overrideConfig: AxiosRequestConfig = {};
      if (!link.match(BASE_URL)) {
        overrideConfig.headers = {};
        overrideConfig.baseURL = undefined;
      }

      const { data } = await makeGetFileRequest(link, overrideConfig);

      saveBlob(data, filename);

      this.setStatusOnAllTabs(PaymentsExportStatus.None);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse)?.response?.data?.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }
}

export const module = getModule(PaymentsExportModule);
export default module;

module.statusChannel.onmessage = (event: { data: PaymentsExportStatus }) => {
  module.SET_STATUS(event.data);
};
