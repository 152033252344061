/**
 * Reset region model.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import SettingsModule from '@/store/settings';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.name === 'split') {
    SettingsModule.reset();
  }

  next();
};

export default guard;
