import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  positions: {
    id: 'positions',
    name: 'Роли',
    template: 'positions',
    pathName: 'positions',
    icon: 'briefcase',
    iconSize: '15',
  },
  competence: {
    id: 'competence',
    name: 'Компетенции',
    template: 'competence',
    pathName: 'competence',
    icon: 'book',
    iconSize: '16',
  },
  regions: {
    id: 'regions',
    name: 'Регионы',
    template: 'regions',
    pathName: 'regions',
    icon: 'compass',
    iconSize: '16',
  },
  workMonths: {
    id: 'workMonths',
    name: 'Выработка',
    template: 'workMonths',
    pathName: 'workMonths',
    icon: 'free-shifts',
    iconSize: '16',
  },
  groups: {
    id: 'groups',
    name: 'Группы',
    template: 'groups',
    pathName: 'groups',
    icon: 'users',
    iconSize: '15',
  },
  users: {
    id: 'users',
    name: 'Пользователи',
    template: 'users',
    pathName: 'users',
    icon: 'users',
    iconSize: '15',
  },
  split: {
    id: 'split',
    name: 'Блокировки',
    template: 'split',
    pathName: 'split',
    icon: 'split',
    iconSize: '15',
  },
  notifications: {
    id: 'notifications',
    name: 'Шаблоны уведомлений',
    template: 'notifications',
    pathName: 'notifications',
    icon: 'templates',
    iconSize: '15',
  },
  trainingCenter: {
    id: 'trainingCenter',
    name: 'УЦ',
    template: 'training_center',
    pathName: 'training_center',
    icon: 'education',
    iconSize: '15',
  },
  mailings: {
    id: 'mailings',
    name: 'Рассылки',
    template: 'mailings',
    pathName: 'mailings',
    icon: 'mailings',
    iconSize: '13',
  },
  bonus: {
    id: 'bonus',
    name: 'Бонусы',
    template: 'bonus',
    pathName: 'bonus',
    icon: 'bonus',
    iconSize: '18',
  },
  technical: {
    id: 'technical',
    name: 'Технические',
    template: 'technical',
    pathName: 'technical',
    icon: 'tech-settings',
    iconSize: '18',
  },
  quizzes: {
    id: 'quizzes',
    name: 'Тесты',
    template: 'quizzes',
    pathName: 'quizzes',
    icon: 'task-sheet',
    iconSize: '18',
  },
  laborRelations: {
    id: 'laborRelations',
    name: 'Трудовые отношения',
    template: 'laborRelations',
    pathName: 'laborRelations',
    icon: 'employee',
    iconSize: '18',
  },
};
