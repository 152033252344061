import { EmployeeModel } from '@/interfaces/models/employees.interface';

export default class EntityModel {
  model: EmployeeModel;

  constructor() {
    this.model = {
      lastName: '',
      firstName: '',
      middleName: '',
      birthday: null,
      phone: '',
      email: '',
      geoRegion: '',
      timeZone: 0,
      belongingToCitizenship: '',
      competence: [],
      inn: '',
      bank: '',
      paymentType: '',
      paymentAccount: '',
      cardNumber: '',
      ks: '',
      bic: '',
      comment: '',
      lmk: null,
      lmkNumber: '',
      isSelfEmployed: false,
      split: '',
      isActive: true,
      gender: '',
      splitInfo: '',
      lastCameOut: '',
      createdAt: '',
      updatedAt: '',
      vaccinationStatus: '',
      sleeping: false,
      underage: false,
      underageLink: '',
      role: '',
      humanRocketWorkStatus: '',
      rocketWorkId: '',
      rocketWorkStatus: '',
      isTest: false,
      partnerUuid: '',
      partnerName: '',
      hasMobileApp: false,
      onDemandPaymentEnabled: false,
      rating: '',
      reliability: {
        checked: false,
        updatedAt: '',
        debtNpd: false,
        criminalRecord: false,
        trial: false,
        dispute: false,
      },
      selfEmployedDay: null,
      verification: {
        snils: false,
        inn: false,
        lmk: false,
        passport: false,
        updatedAt: '',
      },
    };
  }
}
