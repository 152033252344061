/**
 * Global API for custom store data.
 *
 */

import { makeGetRequest, makePostRequest } from '@/api/requests';

/**
 *
 * @param data
 */
export const setStore = async (data: any) => {
  const url = 'api/admin/custom/store/';
  const result = await makePostRequest(url, data);

  return result.data;
};

/**
 *
 */
export const getStore = async () => {
  const url = 'api/admin/custom/store/';
  const result = await makeGetRequest(url);

  return result.data.result;
};
