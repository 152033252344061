/**
 * Guard check access by role id.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import userModule from '@/store/user';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if ((to?.name && !userModule.canUserRead(to.name as string)) || to.name === 'not_found') {
    next();
  } else if (!to.name || !userModule.canUserRead(to.name as string)) {
    next({ name: 'not_found' });
  } else {
    next();
  }
};

export default guard;
