/*
 * filter model factory
 * */

import { filterSearchInterface as filterSearch } from '@/lib/layouts/page/filterSearch.interface';
import { filterSearchRangeInterface as filterSearchRange } from '@/lib/layouts/page/filterSearchRange.interface';
import { filterSelectInterface as filterSelect } from '@/lib/layouts/page/filterSelect.interface';
import { filterMultiSelectInterface as filterMultiSelect } from '@/lib/layouts/page/filterMultiSelect.interface';
import { filterCheckboxInterface as filterCheckbox } from '@/lib/layouts/page/filterCheckbox.interface';
import { filterDatePickerInterface as filterDatePicker } from '@/lib/layouts/page/filterDatePicker.interface';
import { filterTimeRangePickerInterface } from '@/lib/layouts/page/filterTimePicker.interface';
import { filterTimestampPickerInterface as filterTimestampPicker } from '@/lib/layouts/page/filterTimestamPicker.interface';
import { filterAutoCompleteInterface as filterAutoComplete } from '@/lib/layouts/page/filterAutoComplete.interface';

export default class FilterFactory {
  filterModel: any = {};

  createSearchEntity(key: string): void {
    const searchEntity = {
      [key]: {
        key: key,
        placeholder: '',
        name: '',
        class: '',
        previewName: '',
        type: 'text',
        value: '',
        action: 'filterModule/updateSearch',
        mask: null,
        isOpenTitle: false,
        error: {
          class: '',
          message: '',
        },
      } as filterSearch,
    };

    this.filterModel = { ...this.filterModel, ...searchEntity };
  }

  createAutoCompleteEntity(key: string): void {
    const autoCompleteEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        previewName: '',
        type: 'autocomplete',
        list: [],
        current: {
          id: '0',
          value: '',
        },
        isLoading: false,
        action: 'filterModule/updateAutoComplete',
        searchAction: '',
        error: {
          class: '',
          message: '',
        },
      } as filterAutoComplete,
    };

    this.filterModel = { ...this.filterModel, ...autoCompleteEntity };
  }

  createSelectEntity(key: string): void {
    const selectEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        previewName: '',
        type: 'select',
        list: [],
        current: {
          id: '0',
          value: '',
        },
        action: 'filterModule/updateSelect',
        initAction: '',
        clearAction: 'filterModule/resetSelectAndUpdate',
        error: {
          class: '',
          message: '',
        },
        isSearchEnabled: false,
      } as filterSelect,
    };

    this.filterModel = { ...this.filterModel, ...selectEntity };
  }

  createMultiSelectEntity(key: string): void {
    const multiSelectEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        previewName: '',
        disabled: false,
        type: 'multi-select',
        list: [],
        value: '',
        action: 'filterModule/updateMultiSelect',
        clearAction: '',
        error: {
          class: '',
          message: '',
        },
        isSearchEnabled: false,
      } as filterMultiSelect,
    };

    this.filterModel = { ...this.filterModel, ...multiSelectEntity };
  }

  createCheckboxEntity(key: string): void {
    const checkboxEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        previewName: '',
        type: 'checkbox',
        action: 'filterModule/updateCheckbox',
        list: [
          {
            id: 0,
            name: '',
            value: '',
            checked: false,
          },
        ],
      } as filterCheckbox,
    };

    this.filterModel = { ...this.filterModel, ...checkboxEntity };
  }

  createRadioEntity(key: string): void {
    const radioEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        previewName: '',
        type: 'radio',
        action: 'filterModule/updateRadio',
        value: null,
        list: [
          {
            id: 0,
            name: '',
            value: '',
            checked: false,
          },
        ],
      } as filterCheckbox,
    };

    this.filterModel = { ...this.filterModel, ...radioEntity };
  }

  createDateIntervalPickerEntity(key: string): void {
    const datePickerEntity = {
      [key]: {
        key: key,
        name: '',
        class: '',
        previewName: '',
        title: '',
        type: 'date-picker',
        list: {
          startDate: {
            key: 'start-date',
            type: 'date-picker',
            title: 'С даты',
            placeholder: '',
            value: '',
            action: 'filterModule/updateStartDate',
          },
          endDate: {
            key: 'end-date',
            type: 'date-picker',
            title: 'До даты',
            placeholder: '',
            value: '',
            action: 'filterModule/updateEndDate',
          },
        },
      } as filterDatePicker,
    };

    this.filterModel = { ...this.filterModel, ...datePickerEntity };
  }

  createTimeIntervalPickerEntity(key: string): void {
    const timePickerEntity = {
      [key]: {
        key: key,
        name: '',
        class: '',
        previewName: '',
        title: '',
        type: 'time-range',
        onlyHour: false,
        value: '',
        action: 'filterModule/updateTimesRange',
        list: {
          from: {
            title: '',
            placeholder: '',
            hour: '',
            minute: '',
            value: '',
            date: '',
            time: '',
          },
          to: {
            title: '',
            placeholder: '',
            hour: '',
            minute: '',
            value: '',
            date: '',
            time: '',
          },
        },
      } as filterTimeRangePickerInterface,
    };

    this.filterModel = { ...this.filterModel, ...timePickerEntity };
  }

  createTimestampIntervalPickerEntity(key: string): void {
    const timestampPickerEntity = {
      [key]: {
        key: key,
        name: '',
        class: '',
        previewName: '',
        title: '',
        placeholder: '',
        type: 'timestamp-range',
        onlyHour: false,
        list: {
          startTimestamp: {
            key: 'start-time',
            type: 'timestamp-range',
            title: 'С',
            placeholder: '',
            hour: '',
            minute: '',
            time: '',
            date: '',
            action: 'filterModule/updateStartTimestamp',
            required: true,
            start: 0,
            end: 24,
            step: 30,
          },
          endTimestamp: {
            key: 'end-time',
            type: 'timestamp-range',
            title: 'До',
            placeholder: '',
            hour: '',
            minute: '',
            time: '',
            date: '',
            action: 'filterModule/updateStartTimestamp',
            required: true,
            start: 0,
            end: 24,
            step: 30,
          },
        },
      } as filterTimestampPicker,
    };

    this.filterModel = { ...this.filterModel, ...timestampPickerEntity };
  }

  createSearchRangeEntity(key: string): void {
    const searchEntity = {
      [key]: {
        key: key,
        title: '',
        name: '',
        class: '',
        disabled: true,
        type: 'search-range',
        list: {
          startValue: {
            key: 'start-value',
            placeholder: '',
            name: '',
            class: '',
            previewName: '',
            type: 'text',
            value: '',
            action: 'filterModule/updateSearch',
            mask: null,
            isOpenTitle: false,
            error: {
              class: '',
              message: '',
            },
          },
          endValue: {
            key: 'end-value',
            placeholder: '',
            name: '',
            class: '',
            previewName: '',
            type: 'text',
            value: '',
            action: 'filterModule/updateSearch',
            mask: null,
            isOpenTitle: false,
            error: {
              class: '',
              message: '',
            },
          },
        },
      } as filterSearchRange,
    };

    this.filterModel = { ...this.filterModel, ...searchEntity };
  }

  createTitle(key: string): void {
    const titleEntity = {
      [key]: {
        key: key,
        name: '',
        class: '',
        title: '',
      },
    };

    this.filterModel = { ...this.filterModel, ...titleEntity };
  }

  setPlaceholder(key: string, placeholder: string): void {
    this.filterModel[key]['placeholder'] = placeholder;
  }

  setName(key: string, name: string): void {
    this.filterModel[key]['name'] = name;
  }

  setMask(key: string, mask: any): void {
    this.filterModel[key]['mask'] = mask;
  }

  setClassName(key: string, className: string): void {
    this.filterModel[key]['class'] = className;
  }

  setIconClassName(key: string, className: string): void {
    this.filterModel[key]['iconClass'] = className;
  }

  setIconPreviewClassName(key: string, className: string): void {
    this.filterModel[key]['iconPreviewClass'] = className;
  }

  setPreviewName(key: string, previewName: string): void {
    this.filterModel[key]['previewName'] = previewName;
  }

  setAction(key: string, action: string): void {
    this.filterModel[key]['action'] = action;
  }

  setInitAction(key: string, action: string): void {
    this.filterModel[key]['initAction'] = action;
  }

  setClearAction(key: string, action: string): void {
    this.filterModel[key]['clearAction'] = action;
  }

  setIsDefaultFirstItem(key: string, value: boolean): void {
    this.filterModel[key]['isDefaultFirstItem'] = value;
  }

  setTitle(key: string, title: string): void {
    this.filterModel[key]['title'] = title;
  }

  setListElement(key: string, list: {}): void {
    this.filterModel[key]['list'][0] = list;
  }

  setList(key: string, list: Array<any>): void {
    this.filterModel[key]['list'] = list;
  }

  setDateIntervalList(key: string, list: any): void {
    this.filterModel[key]['list'] = list;
  }

  setIgnoreNull(key: string, bool: boolean): void {
    this.filterModel[key]['ignoreNull'] = bool;
  }

  setIgnorePreviewOnNull(key: string, bool: boolean): void {
    this.filterModel[key]['ignorePreviewOnNull'] = bool;
  }

  setAllowZeroId(key: string, bool: boolean): void {
    this.filterModel[key]['allowZeroId'] = bool;
  }

  setOpenTitle(key: string, bool: boolean): void {
    this.filterModel[key]['isOpenTitle'] = bool;
  }

  setTimeOnlyHour(key: string, bool: boolean): void {
    this.filterModel[key]['onlyHour'] = bool;
  }

  setDisabled(key: string, bool: boolean): void {
    this.filterModel[key]['disabled'] = bool;
  }

  setSearchAction(key: string, action: string): void {
    this.filterModel[key]['searchAction'] = action;
  }

  setChangeAction(key: string, action: string): void {
    this.filterModel[key]['changeAction'] = action;
  }

  setSelectAction(key: string, action: string): void {
    this.filterModel[key]['selectAction'] = action;
  }

  setSearchEnabled(key: string, bool: boolean): void {
    this.filterModel[key]['isSearchEnabled'] = bool;
  }

  setCurrent(key: string, value: { id: string; value: string }): void {
    this.filterModel[key]['current'] = value;
  }

  setValue(key: string, value: string): void {
    this.filterModel[key]['value'] = value;
  }

  setIsHidden(key: string, value: boolean): void {
    this.filterModel[key]['isHidden'] = value;
  }
}
