import { makeFormRequest, makeGetRequest } from '@/api/requests';
import API from '@/config/api-routers';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { FormData } from '@/interfaces/shared';

/**
 * Get client geo helper list
 *
 * @param id
 * @param pageCurrent
 * @param pageAmountItems
 */
export const getClientGeoHelperById = async (id: string, pageCurrent?: number, pageAmountItems?: string) => {
  const assemblerUrl = new AssemblerUrl(API.geoHelper.getList.replace('$1', id));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get all geo helpers
 *
 * @param id
 */
export const getAllGeoHelpers = async (id?: string) => {
  id = id ? `${id}/` : '';
  const url = `${API.geoHelper.getAllList}${id}`;
  const result = await makeGetRequest(url);

  return result.data.result.assistants;
};

/**
 * Get geo helper
 *
 * @param id
 */
export const getClientGeoHelperEntityById = async (id: string) => {
  const url = API.geoHelper.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.assistant;
};

/**
 * Add new geo helper
 *
 * @param data
 *
 * @access CAN_EDIT_CLIENT
 */
export const addNewGeoHelper = async (data: FormData) => {
  const url = API.geoHelper.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save geo helper
 *
 * @param helperId
 * @param data
 *
 * @access CAN_EDIT_CLIENT
 */
export const saveGeoHelper = async (helperId: string, data: FormData) => {
  const url = API.geoHelper.edit.replace('$1', helperId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Search address
 *
 * @param value
 */
export const searchAddress = async (value: string) => {
  const url = API.geo.address.replace('$1', value);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get coordinates by address
 *
 * @param value
 */
export const getCoordinates = async (value: string) => {
  const url = API.geo.coordinates.replace('$1', value);
  const result = await makeGetRequest(url);

  return result.data.result.coordinates;
};

/**
 *
 *
 * @param latitude
 * @param longitude
 */
export const getAddressViaCoordinates = async (latitude: number, longitude: number) => {
  const result = await makeGetRequest(
    API.geo.addressViaCoordinates.replace('$1', latitude.toString()).replace('$2', longitude.toString())
  );

  return result.data.result.address;
};
