/**
 * Global API for region.
 *
 */

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest, makeDeleteRequest } from './requests';
import { FormData, FormResponse } from '@/interfaces/shared';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { CompetenceEntity } from '@/interfaces/models/competence.interface';

/**
 * Get competence list
 *
 * @access CAN_READ_COMPETENCE
 */
export const getCompetenceList = async <T = TableApiInterface<{ id: number; isActive: boolean; name: string }>>(
  pageCurrent: number,
  pageAmountItems = '',
  sort = '',
  filter = ''
): Promise<T> => {
  const assemblerUrl = new AssemblerUrl(API.competence.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest<{ result: { table: T } }>(url);

  return result.data.result.table;
};

/**
 * Get all competence list
 *
 * @access CAN_READ_COMPETENCE
 */
export const getAllCompetenceList = async (): Promise<CompetenceEntity[]> => {
  const url = API.competence.getAll;
  const result = await makeGetRequest<{ result: { competence: CompetenceEntity[] } }>(url);

  return result.data.result.competence;
};

/**
 * Get competence by id
 *
 * @param competenceId
 *
 * @access CAN_READ_COMPETENCE
 */
export const getCompetenceById = async (competenceId: string): Promise<CompetenceEntity> => {
  const result = await makeGetRequest(API.competence.read.replace('$1', competenceId));

  return result.data.result.competence;
};

/**
 * Add new competence
 *
 * @param data
 *
 * @access CAN_EDIT_COMPETENCE
 */
export const addNewCompetence = async (data: FormData): Promise<FormResponse> => {
  const url = API.competence.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save competence
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_COMPETENCE
 */
export const saveCompetence = async (id: string, data: FormData): Promise<FormResponse> => {
  const url = API.competence.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete competence
 *
 * @param id
 *
 * @access CAN_EDIT_COMPETENCE
 */
export const removeCompetence = async (id: string): Promise<FormResponse> => {
  const url = API.competence.delete.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};
