export class AssemblerUrl {
  url;

  constructor(url: string) {
    this.url = `${url}?`;
  }

  addPage(page?: string | number) {
    if (page) {
      this.url += `page=${page}`;
    }

    return this;
  }

  addAmount(amount?: string | number) {
    if (amount) {
      this.url += `&${amount}`;
    }

    return this;
  }

  addSort(sort?: string) {
    if (sort) {
      this.url += `&${sort}`;
    }

    return this;
  }

  addFilter(filter?: string) {
    if (filter) {
      this.url += filter;
    }

    return this;
  }

  getUrl() {
    return this.url;
  }
}
