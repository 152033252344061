import InputDatePickerEntity from '@/lib/formFactory/inputDatePickerEntity';

import { CardModel } from '@/interfaces/models/cards.interface';

export default class CardsDatabaseModel {
  model: CardModel;

  constructor() {
    const dateEntity = new InputDatePickerEntity('date');
    dateEntity.setAction('cardsDatabase/updateDate');
    dateEntity.setTitle('Дата');

    this.model = {
      employeeName: { id: null, value: '' },
      date: dateEntity.entity[dateEntity.entityName],
      cardType: { id: null, value: '' },
      cardReason: { id: null, value: '' },
      cardQuantity: { id: null, value: '' },
      comment: '',
    };
  }
}
