import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mr-input-default" }
const _hoisted_2 = ["value", "id", "placeholder", "disabled", "name", "autofocus", "readonly", "rows", "maxLength", "data-name", "data-error", "data-error-messages"]
const _hoisted_3 = {
  key: 0,
  class: "mr-input-default__additional"
}
const _hoisted_4 = {
  key: 0,
  class: "cp-hint-message"
}
const _hoisted_5 = {
  key: 1,
  class: "cp-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mr-input-default__wrapper", { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("label", null, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["mr-input-default__title cd-form-textarea-label", {
          'cd-form-textarea-label-active': _ctx.ui.isLabelActive.value || (_ctx.modelValue && _ctx.modelValue.length) || _ctx.labelActive,
        }])
          }, _toDisplayString(!_ctx.placeholder || (_ctx.placeholder && _ctx.value) ? _ctx.label : ''), 3))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("textarea", {
          ref: "root",
          value: _ctx.modelValue,
          id: _ctx.id,
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          name: _ctx.name,
          autofocus: _ctx.autofocus,
          readonly: _ctx.readonly,
          rows: _ctx.rows,
          maxLength: _ctx.maxLength,
          "data-name": _ctx.nameResult.nameInput.value,
          "data-error": _ctx.errorResult.result.value,
          "data-error-messages": _ctx.errorResult.messages.value,
          class: _normalizeClass(["cp-textarea-radius-50", {
            'cp-error': _ctx.errorResult.result.value || _ctx.errorResult.messages.value.length,
            'cp-textarea-vertical-resize': _ctx.onlyVerticalResize,
          }]),
          onFocusin: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ui.addClassIn && _ctx.ui.addClassIn(...args))),
          onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ui.removeClassIn && _ctx.ui.removeClassIn(...args))),
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args))),
          onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args))),
          onPaste: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args)))
        }, null, 42, _hoisted_2),
        (_ctx.additional)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.additional), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.hint && !_ctx.errorResult.result.value && !_ctx.errorResult.messages.value.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.hint), 1))
      : _createCommentVNode("", true),
    (_ctx.errorResult.messages.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.errorResult.messages.value), 1))
      : _createCommentVNode("", true)
  ], 2))
}