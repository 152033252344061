/**
 * Employees store.
 *
 */

import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import store from '@/store';
import filterModel from './filter';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getEmployeesList, getVaccinationsAll } from '@/api/employees';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/Employees';
import { getAllCompetenceList } from '@/api/competence';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import FilterModule from '@/store/filter';

export const MODULE_NAME = 'employees';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { getRegionsGeoAll } from '@/api/region';
import { employeeStatusesList } from '@/lib/util/employeeStatuses';
import { getCitizenshipsAll } from '@/api/citizenship';
import { FilterHandlers } from '@/lib/layouts/page/filter.interface';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { strings } from '@/lib/stringConst';
import { getAllPartnersList } from '@/api/partners';
import { Checkbox } from '@/interfaces/filter.interface';
import { TimestampPickerEntityList } from '@/lib/layouts/page/filterTimestamPicker.interface';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { geoRegionEntity } from '@/interfaces/regionResponse.interface';

@Module({ dynamic: true, store, name: 'employeesFilterModule', namespaced: true })
class EmployeesFilterModule extends FilterModule {
  constructor(module: EmployeesFilterModule) {
    super(module);
  }

  @Action({ rawError: true })
  setFiltersModelFromLocalStorage(params: { filterName: string; key: string }) {
    const filterModel = this.filterSettings.filterModel;
    const key: string = params.key;
    const filterName: string = params.filterName;

    if (filterName.toLowerCase().indexOf(key.toLowerCase()) === -1) {
      return;
    }

    if (JSON.parse(window.localStorage[filterName]) == null) {
      return;
    }

    if (filterModel[key].type === 'checkbox') {
      try {
        this.self.SET_CHECKBOX_LIST({ key: key, list: JSON.parse(window.localStorage[filterName]) });
      } catch (error) {
        console.warn(error);
        window.localStorage[filterName] = JSON.stringify('');
      }
    }

    if (filterModel[key].type === 'multi-select') {
      let currentValues: string[];

      try {
        currentValues = JSON.parse(window.localStorage[filterName]).split(', ');
      } catch (error) {
        console.warn(error);
        currentValues = [];
        window.localStorage[filterName] = JSON.stringify('');
      }

      const result: Checkbox[] = (filterModel[key].list as Checkbox[]).filter((item: Checkbox) => {
        if (currentValues.includes(item.name)) {
          return { id: item.id, value: item.name, checked: item.checked };
        }
      });
      result.forEach((item) => {
        if (!item.checked) {
          this.self.SET_MULTI_SELECT({ key: key, value: item });
        }
      });
    }

    if (filterModel[key].type === 'select') {
      const storedValue = JSON.parse(window.localStorage[filterName]);
      const result: { id: number | string; value: string } = storedValue ? storedValue : { id: 0, value: '' };

      if (
        key === 'role' &&
        filterModel.status &&
        (!result.value || result.id === 'ROLE_DEACTIVATED' || result.id === 'ROLE_AWAITING')
      ) {
        filterModel.status.disabled = true;
      }

      this.self.SET_SELECT({ key: key, value: result });
    }

    if (filterModel[key].type === 'text') {
      this.self.SET_SEARCH({ key: key, value: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'radio') {
      this.self.updateRadio({ key: key, value: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'date-picker') {
      for (const dateKey of Object.keys(filterModel[key].list as DatePickerEntityList)) {
        if (filterName.toLowerCase().indexOf(dateKey.toLowerCase()) !== -1) {
          this.self.SET_FILTER_DATE({
            key: key,
            dateKey: dateKey,
            date: JSON.parse(window.localStorage[filterName]),
          });
        }
      }
    }

    if (filterModel[key].type === 'time-range') {
      this.self.SET_FILTER_TIME_RANGE({ key: key, time: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'timestamp-range') {
      for (const timestampKey of Object.keys(filterModel[key].list as TimestampPickerEntityList)) {
        if (filterName.toLowerCase().indexOf(timestampKey.toLowerCase()) !== -1) {
          this.self.SET_FILTER_TIMESTAMP({
            key: key,
            timestampKey,
            timestamp: JSON.parse(window.localStorage[filterName]),
          });
        }
      }
    }
  }
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeesModule extends PageBaseModule {
  filter: { filterHandlers: FilterHandlers; filterModel: {} };
  filterModule: FilterModule;
  citizenshipsList: { id: string; name: string }[] = [];

  constructor(module: EmployeesModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Исполнители');
    page.setTitleAdd('Добавление  исполнителя');
    page.values.actionPagination = 'employees/updatePage';
    this.pageSettings = page.values;

    this.filter = new filterModel();
    this.filterModule = getModule(EmployeesFilterModule);
    this.filterModule.setFilterName('employeesFilter');
    this.filterModule.setTemplateClassName('template-lg');
    this.filterModule.setBtnClassName('col-1-row-9');
    this.filterModule.setFilterModel(this.filter.filterModel);
    this.filterModule.setFilterHandlers(this.filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filterModule.resetSelect('partner');
        }
      },
      { immediate: true }
    );
  }

  get hiddenTitles() {
    return 'hiddenTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.hiddenTitles
      ? this.savedSettings.hiddenTitles[MODULE_NAME]
      : [];
  }

  get orderTitles() {
    return 'orderTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.orderTitles
      ? this.savedSettings.orderTitles[MODULE_NAME]
      : [];
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_STATUS(selected: { key: string; value: { id: number | string; value: string } }) {
    this.filterModule.updateSelect(selected);
  }

  @Mutation
  SET_CITIZENSHIPS_LIST(list: { id: string; name: string }[]) {
    this.citizenshipsList = list;
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.employeesSort ? JSON.parse(window.localStorage.employeesSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filterModule.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      let sort = '';
      let index: string;
      let item: { key: string; sort: string };
      for ([index, item] of Object.entries(this.pageSettings.sort)) {
        sort += `sort[${index}][id]=${item.key}&sort[${index}][value]=${item.sort}&`;
      }
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);

      const filter = this.filterModule.filterSettings.filter;
      const result = await getEmployeesList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    await this.getSettings();
    const hidden = this.hiddenTitles;
    const order = this.orderTitles;
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { hidden, order, sort }));
  }

  @Action({ rawError: true })
  async saveSettingsToStore(headers: { hidden: TableHeaderInterface[]; visible: TableHeaderInterface[] }) {
    await this.saveSettings({ name: MODULE_NAME, headers });
  }

  @Action({ rawError: true })
  async updateRole(params: { key: string; value: { id: number | string; value: string } }) {
    switch (params.value.id) {
      case 'ROLE_EMPLOYEE': {
        this.filterModule.self.SET_MODEL_PREVIEW_NAME({ key: 'role', previewName: 'Активен' });
        this.filterModule.self.SET_SELECT_ICON_PREVIEW_CLASS({ key: 'role', className: 'icon-is-active' });
        break;
      }
      case 'ROLE_DEACTIVATED': {
        this.filterModule.self.SET_MODEL_PREVIEW_NAME({ key: 'role', previewName: 'Заблокирован' });
        this.filterModule.self.SET_SELECT_ICON_PREVIEW_CLASS({ key: 'role', className: 'icon-is-inactive' });
        break;
      }
      case 'ROLE_ONBOARDING': {
        this.filterModule.self.SET_MODEL_PREVIEW_NAME({ key: 'role', previewName: 'Авторизован' });
        this.filterModule.self.SET_SELECT_ICON_PREVIEW_CLASS({ key: 'role', className: 'icon-is-awaiting' });
        break;
      }
      case 'ROLE_REJECTED': {
        this.filterModule.self.SET_MODEL_PREVIEW_NAME({ key: 'role', previewName: 'Отклонен' });
        this.filterModule.self.SET_SELECT_ICON_PREVIEW_CLASS({ key: 'role', className: 'icon-is-awaiting' });
        break;
      }
    }

    await this.filterModule.updateSelect(params);
    if (!params.value.value || params.value.id === 'ROLE_DEACTIVATED' || params.value.id === 'ROLE_AWAITING') {
      await this.filterModule.resetMultiSelect('status');
      this.filterModule.filterSettings.filterModel.status.disabled = true;
    } else {
      this.filterModule.filterSettings.filterModel.status.disabled = false;
    }
  }

  @Action({ rawError: true })
  async updateRegion(params: { key: string; value: { id: number | string; value: string } }) {
    await this.filterModule.updateSelect(params);
  }

  @Action({ rawError: true })
  async initCompetences() {
    try {
      const result = await getAllCompetenceList();

      return (Object.values(result) as { id: string; name: string }[]).map((item: { id: string; name: string }) => {
        return {
          id: item.id,
          name: item.name,
          checked: false,
        };
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return [];
    }
  }

  @Action({ rawError: true })
  async initRegionsSelect() {
    try {
      const geoRegionsAll = await getRegionsGeoAll();

      return geoRegionsAll.map((geoRegion: geoRegionEntity) => {
        return {
          id: geoRegion.id,
          value: geoRegion.name,
        };
      });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return [];
    }
  }

  @Action({ rawError: true })
  async initCitizenshipsSelect() {
    const result = await getCitizenshipsAll();

    if (!this.citizenshipsList.length) {
      const citizenshipsList = result.map((citizenship) => {
        return {
          id: citizenship.id,
          value: citizenship.name,
          name: citizenship.name,
        };
      });

      this.context.commit('SET_CITIZENSHIPS_LIST', citizenshipsList);
    }

    return this.citizenshipsList;
  }

  @Action({ rawError: true })
  async initVaccinationsSelect() {
    const vaccinationsAll = await getVaccinationsAll();

    return (Object.values(vaccinationsAll) as { type: string; humanType: string }[]).map(
      (vaccination: { type: string; humanType: string }) => {
        return {
          id: vaccination.type,
          value: vaccination.type,
          name: vaccination.humanType,
        };
      }
    );
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll).map((partner: PartnerSelectListItem) => {
        return {
          id: partner.uuid,
          value: partner.legalName,
          name: partner.legalName,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  initEmployeeStatusesSelect() {
    return employeeStatusesList;
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }
}

export default getModule(EmployeesModule);
