import { messaging } from 'firebase';
import { fireApp } from '@/firebase';
import { sendToken } from '@/api/firebase';
import PaymentsExportModule from '@/store/finance/payments/export';
import PayoutsExportModule from '@/store/finance/payouts/index';
import { CatchFormResponse } from '@/interfaces/shared';
import { generateUUIDv4 } from '@/lib/Utils';

type MessagePayload = messaging.MessagePayload & { fcmMessageId: string };

const PUSH_THROTTLE_MS = 500;
const pushHandlerUuid = generateUUIDv4();
const pushChannel = new BroadcastChannel('pushChannel');
const pushCollector = new Map<string, Map<string, MessagePayload>>();

pushChannel.onmessage = function (event: { data: { pushHandlerUuid: string; payload: MessagePayload } }): void {
  collectMessage(event.data.pushHandlerUuid, event.data.payload);
};

export function setTokenSentToServer(currentToken: string): void {
  window.localStorage.setItem('sentFirebaseMessagingToken', currentToken || '');
}

export async function sendTokenToServer(token: string): Promise<void> {
  try {
    console.info('Отправка токена на сервер...');

    const response = await sendToken(token);
    if (!response.message) {
      setTokenSentToServer(token);
    } else {
      setTokenSentToServer('');
      console.info(response.message);
    }
  } catch (error) {
    console.error((error as CatchFormResponse)?.response?.data);
  }
}

function addEventOnMessage(messaging: messaging.Messaging) {
  messaging.onMessage(function (payload: MessagePayload) {
    console.info('Message received. ', payload);

    throttlePush(payload);
  });
}

export async function initMessaging(): Promise<void> {
  let messaging: messaging.Messaging | null = null;
  try {
    messaging = fireApp.messaging();
  } catch (error) {
    console.warn(error);
  }

  if (messaging === null) {
    return;
  }

  messaging
    .requestPermission()
    .then(function () {
      console.info('Notification permission granted.');

      messaging
        ?.getToken()
        .then(function (currentToken: string) {
          if (currentToken) {
            sendTokenToServer(currentToken);
          } else {
            console.warn('Не удалось получить токен.');
            setTokenSentToServer('');
          }
        })
        .catch(function (err: Error) {
          console.warn('An error occurred while retrieving token. ', err);
        });
    })
    .catch(function (error: Error) {
      console.warn(error);
    });

  addEventOnMessage(messaging);
}

function throttlePush(payload: MessagePayload): void {
  collectMessage(pushHandlerUuid, payload);
  pushChannel.postMessage({ pushHandlerUuid, payload });

  setTimeout(() => {
    const pushMap = pushCollector.get(payload.fcmMessageId);
    if (!pushMap) {
      return;
    }

    const smallestUuid = Array.from(pushMap.keys()).sort()[0];

    if (smallestUuid === pushHandlerUuid) {
      handlePush(payload);
    }

    pushCollector.delete(payload.fcmMessageId);
  }, PUSH_THROTTLE_MS);
}

function handlePush(payload: MessagePayload) {
  if (document.location.pathname === '/finance/payouts/') {
    PayoutsExportModule.export(payload.data?.filename ?? '');

    return;
  }

  PaymentsExportModule.exportPushHandler({
    filename: payload.data?.filename ?? '',
    link: payload.data?.link ?? '',
  });

  Notification.requestPermission(function (result) {
    if (result === 'granted') {
      navigator.serviceWorker.ready
        .then(function (registration) {
          if (payload.notification?.title) {
            registration.showNotification(payload.notification.title, payload.notification);
          }
        })
        .catch(function (error) {
          console.error('ServiceWorker registration failed', error);
        });
    }
  });
}

function collectMessage(handlerUuid: string, payload: MessagePayload): void {
  if (!pushCollector.has(payload.fcmMessageId)) {
    pushCollector.set(payload.fcmMessageId, new Map());
  }

  pushCollector.get(payload.fcmMessageId)?.set(handlerUuid, payload);
}
