
import { ref, toRefs, defineComponent, PropType } from 'vue';

import nameTextFieldApi from '@/lib/formFactory/textField/name';
import uiTextFieldApi from '@/lib/formFactory/textField/ui';
import handlerTextFieldApi from '@/lib/formFactory/textField/handler';
import typesTextFieldApi from '@/lib/formFactory/textField/types';
import masksTextFieldApi from '@/lib/formFactory/textField/masks';
import validateTextFieldApi from '@/lib/formFactory/textField/validate';
import errorTextFieldApi from '@/lib/formFactory/textField/error';
import rulesTextFieldApi from '@/lib/formFactory/textField/rules';

import { ParamsValidateMessages } from '@/lib/formFactory/textField/validate';

export default defineComponent({
  name: 'form-textarea',
  emits: ['update:modelValue', 'update:isError', 'update', 'validate'],
  props: {
    modelValue: { default: '', type: [String, Number] },
    autofocus: { type: Boolean },
    additional: { type: String },
    disabled: { type: Boolean },
    placeholder: { type: String },
    label: { type: String },
    readonly: { type: Boolean },
    name: { type: String },
    dataName: { type: String },
    id: { type: String },
    hint: { type: String },
    autofill: { default: false, type: Boolean },
    labelActive: { type: Boolean },
    type: { type: String, default: 'text' },
    mask: { type: String },
    maskConfig: { type: Object },
    autoMaskOff: { type: Boolean },
    autocomplete: { type: String, default: 'off' },
    validateOff: { type: Boolean },
    validateMessages: { type: Object as PropType<ParamsValidateMessages> },
    required: { type: Boolean },
    minChars: { type: [String, Number] },
    maxChars: { type: [String, Number] },
    maxLength: { type: [String, Number] },
    error: { default: '', type: [String, Boolean] },
    errorMessages: { default: '', type: [String, Array] },
    rules: { type: Function as PropType<() => boolean | string> },
    rows: {},
    onlyVerticalResize: { type: Boolean },
  },
  setup(props, { emit }) {
    const propsRefs = toRefs(props);

    const value = propsRefs.modelValue;
    const propError = propsRefs.error;
    const propErrorMessages = propsRefs.errorMessages;

    const root = ref(null);

    const ui = uiTextFieldApi();
    const handler = handlerTextFieldApi({
      input: emit as (eventName: string, eventData: unknown) => void,
      value: props.modelValue,
      type: props.type,
    });

    const types = typesTextFieldApi({ type: props.type });
    const nameResult = nameTextFieldApi({ dataName: propsRefs.dataName, label: propsRefs.label });

    const masks = masksTextFieldApi({
      el: root,
      type: props.type,
      autoMaskOff: props.autoMaskOff,
      mask: props.mask,
      maskConfig: props.maskConfig,
    });

    const validate = validateTextFieldApi({
      value,
      type: props.type,
      validateOff: props.validateOff,
      required: props.required,
      validateMessages: props.validateMessages,
      minChars: props.minChars,
      maxChars: props.maxChars,
    });

    const rulesResult = rulesTextFieldApi({
      value,
      rules: props.rules,
    });

    const errorResult = errorTextFieldApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      error: propError,
      errorMessages: propErrorMessages,
      validate: validate.validate,
      validateMessage: validate.validateMessage,
      rules: rulesResult.validate,
      rulesMessage: rulesResult.validateMessage,
    });

    return {
      value,
      propError,
      propErrorMessages,

      root,

      ui,
      handler,

      types,
      nameResult,

      masks,
      validate,
      rulesResult,
      errorResult,
    };
  },
});
