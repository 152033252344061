/**
 * Calendar base module.
 *
 */

import { VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Input } from '@/lib/formFactory/input.interface';
import { ceilMinutesToHoursTime, isDateStartOverDateEnd } from '@/lib/Utils';
import moment from 'moment';
import { StyleInterface } from '@/store/employees/calendar';
import { ShiftEmployeeStatusTitles } from '@/lib/util/consts';

export default abstract class CalendarBaseModule extends VuexModule {
  text = {
    addBtn: 'Добавить заказ',
    addBtnSecondary: 'Добавить время',
    deleteBtn: 'Удалить заказ',
    weeks: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
  };
  statuses = ShiftEmployeeStatusTitles;
  statusesByTime = {
    default: {
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      reserve: 'Резерв',
    },
    beforeShift72hours: {
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift24hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift12hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    beforeShift2hours: {
      did_not_go_out: 'Не вышел',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      reserve: 'Резерв',
    },
    shiftStarted: {
      did_not_go_out: 'Не вышел',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
      is_planned: 'Запланирован',
      refused_at_this_time: 'Отказался/время',
      refused_in_this_market: 'Отказался/место',
    },
    shiftEnded: {
      came_out: 'Вышел',
      did_not_go_out: 'Не вышел',
      fake_call: 'Ложный вызов',
      fast_replacement: 'Срочная замена',
    },
  };
  notCountStatuses = ['fake_call', 'refused_at_this_time', 'refused_in_this_market', 'did_not_go_out', 'reserve'];
  countWeekStart = 0;
  countWeekEnd = 4;
  startWeek = 0;
  endWeek = 4;
  shifts = {};
  calendar = {};
  calendarEntity: any;
  hours = {};
  calendarStyleByWeeks: Record<string, StyleInterface> = {};
  modalParams: any;
  isShiftLoading = true;
  isBlock = false;
  conflict = false;
  oldTime = {
    hour: '00',
    minute: '00',
  };

  @Mutation
  SET_IS_BLOCK(value: boolean) {
    this.isBlock = value;
  }

  @Mutation
  SET_CONFLICT(value: boolean) {
    this.conflict = value;
  }

  @Mutation
  SET_OLD_TIME(value: { hour: string; minute: string }) {
    this.oldTime.hour = value.hour;
    this.oldTime.minute = value.minute;
  }

  @Mutation
  SET_COUNT_START_WEEK(startWeek: number) {
    this.countWeekStart = startWeek;
  }

  @Mutation
  SET_COUNT_END_WEEK(endWeek: number) {
    this.countWeekEnd = endWeek;
  }

  @Mutation
  SET_START_WEEK(startWeek: number) {
    this.startWeek = startWeek;
  }

  @Mutation
  SET_END_WEEK(endWeek: number) {
    this.endWeek = endWeek;
  }

  @Mutation
  SET_SHIFTS(shifts: any) {
    this.shifts = shifts;
  }

  @Mutation
  SET_HOURS(hours: any) {
    this.hours = hours;
  }

  @Mutation
  SET_CALENDAR(calendar: any) {
    this.calendar = calendar;
  }

  @Mutation
  SET_CALENDAR_STYLE(style: any) {
    this.calendarStyleByWeeks = style;
  }

  @Mutation
  SET_EDIT_TYPE(type: string) {
    this.modalParams.edit.entityType = type;
  }

  @Mutation
  SET_MODAL_SHIFT_TITLE(payload: { type: string; title: string }) {
    this.modalParams[payload.type].title = payload.title;
  }

  @Mutation
  UPDATE_DATES(dates: string[]) {
    this.modalParams.add.model.dates = dates;
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    this.modalParams.add.model.dateStart.value = date;

    const dateCurrent = date.split('.');
    const momentDate = `${dateCurrent[1]}/${dateCurrent[0]}/${dateCurrent[2]}`;
    this.modalParams.add.model.dateStart.currentValue.day = dateCurrent[0];
    const month = moment(momentDate).format('MMM');
    this.modalParams.add.model.dateStart.currentValue.month = month.replace('.', '');
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    this.modalParams.add.model.dateEnd.value = date;

    const dateCurrent = date.split('.');
    const momentDate = `${dateCurrent[1]}/${dateCurrent[0]}/${dateCurrent[2]}`;
    this.modalParams.add.model.dateEnd.currentValue.day = dateCurrent[0];
    const month = moment(momentDate).format('MMM');
    this.modalParams.add.model.dateEnd.currentValue.month = month.replace('.', '');
  }

  @Mutation
  UPDATE_DELETE_DATE_START(date: string) {
    this.modalParams.delete.model.dateStart.value = date;

    const dateCurrent = date.split('.');
    const momentDate = `${dateCurrent[1]}/${dateCurrent[0]}/${dateCurrent[2]}`;
    this.modalParams.delete.model.dateStart.currentValue.day = dateCurrent[0];
    const month = moment(momentDate).format('MMM');
    this.modalParams.delete.model.dateStart.currentValue.month = month.replace('.', '');
  }

  @Mutation
  UPDATE_DELETE_DATE_END(date: string) {
    this.modalParams.delete.model.dateEnd.value = date;

    const dateCurrent = date.split('.');
    const momentDate = `${dateCurrent[1]}/${dateCurrent[0]}/${dateCurrent[2]}`;
    this.modalParams.delete.model.dateEnd.currentValue.day = dateCurrent[0];
    const month = moment(momentDate).format('MMM');
    this.modalParams.delete.model.dateEnd.currentValue.month = month.replace('.', '');
  }

  @Mutation
  UPDATE_INTERVAL_START(payload: { type: string; value: { type: string; value: string } }) {
    this.modalParams[payload.type].model.intervalStart[payload.value.type] = payload.value.value;
  }

  @Mutation
  UPDATE_INTERVAL_END(payload: { type: string; value: { type: string; value: string } }) {
    this.modalParams[payload.type].model.intervalEnd[payload.value.type] = payload.value.value;
  }

  @Mutation
  SET_LUNCH(payload: { type: string; value: { type: string; value: string } }) {
    this.modalParams[payload.type].model.lunch[payload.value.type] = payload.value.value;
  }

  @Mutation
  SET_LUNCH_MINUTES(payload: { type: string; value: number }) {
    this.modalParams[payload.type].model.lunch.lunchMinutes = payload.value;
  }

  @Mutation
  UPDATE_LUNCH(payload: { type: string; value: { type: string; value: string } }) {
    if (payload.type === 'add') {
      this.modalParams[payload.type].model.lunch[payload.value.type] = payload.value.value;

      return;
    }

    // if edit shift
    if (payload.value.type === 'hour') {
      this.modalParams[payload.type].model.lunch[payload.value.type] = payload.value.value;

      return;
    }

    const oldLunchMinutes = this.modalParams[payload.type].model.lunch.lunchMinutes ?? 0;
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      const employeeLunchMinutes = this.modalParams[payload.type].model.employees.list[index].lunchMinutes;
      if (oldLunchMinutes !== employeeLunchMinutes) {
        continue;
      }

      this.modalParams[payload.type].model.employees.list[index].lunch.hour = this.modalParams[
        payload.type
      ].model.lunch.hour;
      this.modalParams[payload.type].model.employees.list[index].lunch.minute = payload.value.value;

      const lunchHour = this.modalParams.edit.model.employees.list[index].lunch.hour;
      const lunchMinute = this.modalParams.edit.model.employees.list[index].lunch.minute;
      this.modalParams[payload.type].model.employees.list[index].lunchMinutes = lunchHour * 60 + parseInt(lunchMinute);
    }

    this.modalParams[payload.type].model.lunch[payload.value.type] = payload.value.value;
    const lunchHour = this.modalParams[payload.type].model.lunch.hour;
    const lunchMinute = this.modalParams[payload.type].model.lunch.minute;
    this.modalParams[payload.type].model.lunch.lunchMinutes = lunchHour * 60 + parseInt(lunchMinute);
  }

  @Mutation
  UPDATE_LUNCH_MINUTES(payload: { type: string }) {
    const lunchHour = this.modalParams[payload.type].model.lunch.hour;
    const lunchMinute = this.modalParams[payload.type].model.lunch.minute;
    this.modalParams[payload.type].model.lunch.lunchMinutes = lunchHour * 60 + parseInt(lunchMinute);
  }

  @Mutation
  UPDATE_IS_ADDITIONAL(bool: boolean) {
    this.modalParams.edit.model.isAdditional.list[0].checked = bool;
  }

  @Mutation
  UPDATE_IS_SHARED(bool: boolean) {
    this.modalParams.edit.model.isShared.list[0].checked = bool;
  }

  @Mutation
  UPDATE_NOT_DIVIDE(bool: boolean) {
    this.modalParams.edit.model.notDevide.list[0].checked = bool;
  }

  @Mutation
  UPDATE_PARTNER_UUID(params: { partnerUuid: string; type: string }) {
    this.modalParams[params.type].model.partnerUuid = params.partnerUuid;
  }

  @Mutation
  UPDATE_PAYMENT_STRATEGY(params: { paymentStrategy: string; type: string }): void {
    this.modalParams[params.type].model.paymentStrategy = params.paymentStrategy;
  }

  @Mutation
  UPDATE_PARTNER_NAME(params: { partnerName: string; type: string }) {
    this.modalParams[params.type].model.partnerName = params.partnerName;
  }

  @Mutation
  SET_IS_ADDITIONAL(bool: boolean) {
    this.modalParams.add.model.isAdditional.list[0].checked = bool;
  }

  @Mutation
  SET_IS_SHARED(bool: boolean) {
    this.modalParams.add.model.isShared.list[0].checked = bool;
  }

  @Mutation
  SET_NOT_DIVIDE(bool: boolean) {
    this.modalParams.add.model.notDevide.list[0].checked = bool;
  }

  @Mutation
  SET_DELETE_IS_EXCLUDE_WEEKDAY(bool: boolean) {
    this.modalParams.delete.model.isExcludeWeekday.list[0].checked = bool;
  }

  @Mutation
  SET_DELETE_IS_EXCLUDE_WEEKENDS(bool: boolean) {
    this.modalParams.delete.model.isExcludeWeekends.list[0].checked = bool;
  }

  @Mutation
  SET_IS_TIME_INTERVAL(bool: boolean) {
    this.modalParams.delete.model.isTimeInterval.list[0].checked = bool;
  }

  @Mutation
  UPDATE_EMPLOYEE(employees: {}) {
    this.modalParams.edit.model.employees.list = employees;
  }

  @Mutation
  SET_SHIFT(shift: {}) {
    this.modalParams.edit.shift = shift;
  }

  @Mutation
  SET_IS_SHIFT_LOAD(bool: boolean) {
    this.isShiftLoading = bool;
  }

  @Mutation
  SET_INCLUDE_WEEKDAYS(bool: boolean) {
    this.modalParams.add.model.includeWeekdays.list[0].checked = bool;
  }

  @Mutation
  SET_INCLUDE_WEEKENDS(bool: boolean) {
    this.modalParams.add.model.includeWeekends.list[0].checked = bool;
  }

  @Mutation
  SET_DELETE_IS_EXCLUDE_SHIFTS(bool: boolean) {
    this.modalParams.delete.model.isExcludeShifts.list[0].checked = bool;
  }

  @Mutation
  UPDATE_EMPLOYEE_HINTS(list: {}) {
    this.modalParams.edit.model.employees.hintsList = list;
  }

  @Mutation
  UPDATE_STATUS_EMPLOYEE(payload: { selected: any; employeeId: string }) {
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.employeeId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].generalStatus = payload.selected.id;
      break;
    }
  }

  @Mutation
  UPDATE_INTERVAL_START_EMPLOYEE(payload: { selected: any; employeeId: string }) {
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.employeeId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].intervalStart[payload.selected.type] = payload.selected.value;
      break;
    }
  }

  @Mutation
  UPDATE_INTERVAL_END_EMPLOYEE(payload: { selected: any; employeeId: string }) {
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.employeeId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].intervalEnd[payload.selected.type] = payload.selected.value;
      break;
    }
  }

  @Mutation
  UPDATE_LUNCH_EMPLOYEE(payload: { selected: any; employeeId: string }) {
    const list = this.modalParams.edit.model.employees.list;

    const index = Object.values(list).findIndex((employee: any) => {
      return employee.employee.id === payload.employeeId;
    });

    if (index === -1) return;

    this.modalParams.edit.model.employees.list[index].lunch[payload.selected.type] = payload.selected.value;

    const lunchHour = this.modalParams.edit.model.employees.list[index].lunch.hour;
    const lunchMinute = this.modalParams.edit.model.employees.list[index].lunch.minute;
    this.modalParams.edit.model.employees.list[index].lunchMinutes = lunchHour * 60 + parseInt(lunchMinute);
  }

  @Mutation
  UPDATE_WORK_TIME_EMPLOYEE(payload: { employeeId: string }) {
    const list = this.modalParams.edit.model.employees.list;

    const index = Object.values(list).findIndex((employee: any) => {
      return employee.employee.id === payload.employeeId;
    });

    if (index === -1) return;

    const employee = this.modalParams.edit.model.employees.list[index];
    employee.workHours = ceilMinutesToHoursTime(
      parseInt(employee.intervalEnd.hour) * 60 +
        parseInt(employee.intervalEnd.minute) -
        parseInt(employee.intervalStart.hour) * 60 -
        parseInt(employee.intervalStart.minute) -
        employee.lunchMinutes
    );
  }

  @Mutation
  UPDATE_LUNCH_ALL_EMPLOYEES() {
    const list = this.modalParams.edit.model.employees.list as any[];

    const oldlunchHour = this.oldTime.hour;
    const oldlunchMinute = this.oldTime.minute;

    const newlunchHour = this.modalParams.edit.model.lunch.hour;
    const newlunchMinute = this.modalParams.edit.model.lunch.minute;

    this.conflict = false;
    Object.values(list).forEach((_, index: number) => {
      const oldEmployelunchHour = this.modalParams.edit.model.employees.list[index].lunch.hour;
      const oldEmployelunchMinute = this.modalParams.edit.model.employees.list[index].lunch.minute;

      if (
        parseInt(oldEmployelunchHour) === parseInt(newlunchHour) &&
        parseInt(oldEmployelunchMinute) == parseInt(newlunchMinute)
      ) {
        return;
      }

      if (
        parseInt(oldEmployelunchHour) === parseInt(oldlunchHour) &&
        parseInt(oldEmployelunchMinute) == parseInt(oldlunchMinute)
      ) {
        const employee = this.modalParams.edit.model.employees.list[index];

        employee.lunch.hour = newlunchHour;
        employee.lunch.minute = newlunchMinute;
        employee.lunchMinutes = newlunchHour * 60 + parseInt(newlunchMinute);

        return;
      }

      if (!this.conflict) {
        this.conflict = true;
      }
    });
  }

  @Mutation
  UPDATE_LUNCH_MINUTES_EMPLOYEE(payload: { type: string; employeeIndex: string }) {
    const lunchHour = this.modalParams.edit.model.employees.list[payload.employeeIndex].lunch.hour;
    const lunchMinute = this.modalParams.edit.model.employees.list[payload.employeeIndex].lunch.minute;
    this.modalParams[payload.type].model.employees.list[payload.employeeIndex].lunchMinutes =
      lunchHour * 60 + parseInt(lunchMinute);
  }

  @Mutation
  UPDATE_COMMENT_EMPLOYEE(payload: { value: string; entityId: string }) {
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.entityId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].comment = payload.value;
      break;
    }
  }

  @Mutation
  UPDATE_EMPLOYEE_PAYMENT_PARTNER(payload: { value: string; entityId: string }): void {
    const shiftEmployee = Object.values<{ employee: { id: string }; paymentPartner: string }>(
      this.modalParams.edit.model.employees.list
    ).find(({ employee }) => payload.entityId === employee.id);

    if (shiftEmployee) {
      shiftEmployee.paymentPartner = payload.value;
    }
  }

  @Mutation
  UPDATE_YELLOW_CARD_EMPLOYEE(payload: { value: string; entityId: string }) {
    let index: string;
    let employee: any;
    for ([index, employee] of Object.entries(this.modalParams.edit.model.employees.list)) {
      if (employee.employee.id !== payload.entityId) {
        continue;
      }

      this.modalParams.edit.model.employees.list[index].yellowCard = payload.value;
      break;
    }
  }

  @Mutation
  SET_ERROR_BY_FIELD(entity: { field: string; message: string }) {
    this.modalParams.add.model[entity.field].error.class = 'cp-error';
    this.modalParams.add.model[entity.field].error.message = entity.message;
  }

  @Mutation
  RESET_ERROR_BY_FIELD(field: string) {
    this.modalParams.add.model[field].error.class = '';
    this.modalParams.add.model[field].error.message = '';
  }

  @Action({ rawError: true })
  initWeeksState(params?: { start: number; end: number }) {
    this.context.commit('SET_COUNT_START_WEEK', params?.start ? params.start : this.calendarEntity.defaultStartWeek);
    this.context.commit('SET_COUNT_END_WEEK', params?.end ? params.end : this.calendarEntity.defaultEndWeek);
    this.context.commit('SET_START_WEEK', params?.start ? params.start : this.calendarEntity.defaultStartWeek);
    this.context.commit('SET_END_WEEK', params?.end ? params.end : this.calendarEntity.defaultEndWeek);
  }

  @Action({ rawError: true })
  updateIntervalStart(value: any) {
    this.context.commit('UPDATE_INTERVAL_START', { type: 'add', value });

    if (value.type === 'hour') {
      value.value = parseInt(value.value) + 5;
      value.value = parseInt(value.value) < 10 ? `0${value.value}` : value.value;
      value.value = parseInt(value.value) >= 24 ? value.value - 24 : value.value;
      this.context.commit('UPDATE_INTERVAL_END', { type: 'add', value });
    }
  }

  @Action({ rawError: true })
  updateIntervalEnd(value: any) {
    this.context.commit('UPDATE_INTERVAL_END', { type: 'add', value });
  }

  @Action({ rawError: true })
  updateLunch(value: { type: string; value: string }) {
    this.context.commit('UPDATE_LUNCH', { type: 'add', value });
  }

  @Action({ rawError: true })
  updateEditLunch(value: { type: string; value: string }) {
    if (value.type === 'hour') {
      this.context.commit('SET_OLD_TIME', {
        hour: this.modalParams.edit.model.lunch.hour,
        minute: this.modalParams.edit.model.lunch.minute,
      });
    }

    this.context.commit('UPDATE_LUNCH', { type: 'edit', value });

    if (value.type === 'minute') {
      this.context.commit('UPDATE_LUNCH_ALL_EMPLOYEES');
    }
  }

  @Action({ rawError: true })
  updateEditLunchMinutes() {
    this.context.commit('UPDATE_LUNCH_ALL_EMPLOYEES');
    this.context.commit('UPDATE_LUNCH_MINUTES', { type: 'edit' });
  }

  @Action({ rawError: true })
  updateEditEmployeeLunch(params: { selected: { type: string; value: string }; employeeId: string }) {
    this.context.commit('UPDATE_LUNCH_EMPLOYEE', params);
    this.context.commit('UPDATE_WORK_TIME_EMPLOYEE', { employeeId: params.employeeId });
  }

  @Action({ rawError: true })
  updateDates(dates: string[]) {
    this.context.commit('UPDATE_DATES', dates);
  }

  @Action({ rawError: true })
  updateDateStart(date: string) {
    this.context.commit('UPDATE_DATE_START', date);

    if (isDateStartOverDateEnd(date, this.modalParams.add.model.dateEnd.value, '.')) {
      this.context.commit('UPDATE_DATE_END', date);
    }
  }

  @Action({ rawError: true })
  updateDateEnd(date: string) {
    this.context.commit('UPDATE_DATE_END', date);

    if (isDateStartOverDateEnd(this.modalParams.add.model.dateStart.value, date, '.')) {
      this.context.commit('UPDATE_DATE_START', date);
    }
  }

  @Action({ rawError: true })
  updateIncludeWeekdays(params: { bool: boolean }) {
    this.context.commit('SET_INCLUDE_WEEKDAYS', params.bool);
  }

  @Action({ rawError: true })
  updateIncludeWeekends(params: { bool: boolean }) {
    this.context.commit('SET_INCLUDE_WEEKENDS', params.bool);
  }

  @Action({ rawError: true })
  updateDeleteDateStart(date: string) {
    this.context.commit('UPDATE_DELETE_DATE_START', date);

    if (isDateStartOverDateEnd(date, this.modalParams.delete.model.dateEnd.value, '.')) {
      this.context.commit('UPDATE_DELETE_DATE_END', date);
    }
  }

  @Action({ rawError: true })
  updateDeleteDateEnd(date: string) {
    this.context.commit('UPDATE_DELETE_DATE_END', date);

    if (isDateStartOverDateEnd(this.modalParams.delete.model.dateStart.value, date, '.')) {
      this.context.commit('UPDATE_DELETE_DATE_START', date);
    }
  }

  @Action({ rawError: true })
  updateEditIntervalStart(value: any) {
    this.context.commit('UPDATE_INTERVAL_START', { type: 'edit', value });
  }

  @Action({ rawError: true })
  updateEditIntervalEnd(value: any) {
    this.context.commit('UPDATE_INTERVAL_END', { type: 'edit', value });
  }

  @Action({ rawError: true })
  updateDeleteIntervalStart(value: any) {
    this.context.commit('UPDATE_INTERVAL_START', { type: 'delete', value });
  }

  @Action({ rawError: true })
  updateDeleteIntervalEnd(value: any) {
    this.context.commit('UPDATE_INTERVAL_END', { type: 'delete', value });
  }

  @Action({ rawError: true })
  updateIsAdditional(params: { bool: boolean }) {
    this.context.commit('UPDATE_IS_ADDITIONAL', params.bool);
  }

  @Action({ rawError: true })
  updateIsShared(params: { bool: boolean }) {
    this.context.commit('UPDATE_IS_SHARED', params.bool);
  }

  @Action({ rawError: true })
  updatePartnerUuid(params: { partnerUuid: string; type: string }) {
    this.context.commit('UPDATE_PARTNER_UUID', params);
  }

  @Action({ rawError: true })
  updatePaymentStrategy(params: { paymentStrategy: string; type: string }): void {
    this.context.commit('UPDATE_PAYMENT_STRATEGY', params);
  }

  @Action({ rawError: true })
  updatePartnerName(params: { partnerName: string; type: string }) {
    this.context.commit('UPDATE_PARTNER_NAME', params);
  }

  @Action({ rawError: true })
  updateNotDevide(params: { bool: boolean }) {
    this.context.commit('UPDATE_NOT_DIVIDE', params.bool);
  }

  @Action({ rawError: true })
  setIsAdditional(params: { bool: boolean }) {
    this.context.commit('SET_IS_ADDITIONAL', params.bool);
  }

  @Action({ rawError: true })
  setIsShared(params: { bool: boolean }) {
    this.context.commit('SET_IS_SHARED', params.bool);
  }

  @Action({ rawError: true })
  setNotDevide(params: { bool: boolean }) {
    this.context.commit('SET_NOT_DIVIDE', params.bool);
  }

  @Action({ rawError: true })
  updateDeleteIsExcludeWeekday(params: { bool: boolean }) {
    this.context.commit('SET_DELETE_IS_EXCLUDE_WEEKDAY', params.bool);
  }

  @Action({ rawError: true })
  updateDeleteIsExcludeWeekends(params: { bool: boolean }) {
    this.context.commit('SET_DELETE_IS_EXCLUDE_WEEKENDS', params.bool);
  }

  @Action({ rawError: true })
  updateIsTimeInterval(params: { bool: boolean }) {
    this.context.commit('SET_IS_TIME_INTERVAL', params.bool);
  }

  @Action({ rawError: true })
  updateIsExcludeShifts(params: { bool: boolean }) {
    this.context.commit('SET_DELETE_IS_EXCLUDE_SHIFTS', params.bool);
  }

  @Action({ rawError: true })
  updateStatus(params: { selected: any; employeeId: string }) {
    this.context.commit('UPDATE_STATUS_EMPLOYEE', params);
  }

  @Action({ rawError: true })
  updateEditEmployeeIntervalStart(params: { selected: any; employeeId: string }) {
    this.context.commit('UPDATE_INTERVAL_START_EMPLOYEE', params);
    this.context.commit('UPDATE_WORK_TIME_EMPLOYEE', { employeeId: params.employeeId });
  }

  @Action({ rawError: true })
  updateEditEmployeeIntervalEnd(params: { selected: any; employeeId: string }) {
    this.context.commit('UPDATE_INTERVAL_END_EMPLOYEE', params);
    this.context.commit('UPDATE_WORK_TIME_EMPLOYEE', { employeeId: params.employeeId });
  }

  @Action({ rawError: true })
  updateEmployeePaymentPartner(params: { value: string; entityId: string | number }): void {
    this.context.commit('UPDATE_EMPLOYEE_PAYMENT_PARTNER', params);
  }

  @Action({ rawError: true })
  updateEditEmployeeComment(params: { value: string; entityId: string }) {
    this.context.commit('UPDATE_COMMENT_EMPLOYEE', params);
  }

  @Action({ rawError: true })
  updateEditEmployeeCard(params: { value: string; entityId: string }) {
    this.context.commit('UPDATE_CARD_EMPLOYEE', params);
  }

  @Action({ rawError: true })
  updateEditEmployeeYellowCard(params: { value: string; entityId: string }) {
    this.context.commit('UPDATE_YELLOW_CARD_EMPLOYEE', params);
  }

  @Action({ rawError: true })
  setValidateState(entity: { index: string; field: Input }) {
    if (!entity.field.value) {
      this.context.commit('SET_ERROR_BY_FIELD', { field: entity.index, message: entity.field.validation.empty });
    } else {
      this.context.commit('RESET_ERROR_BY_FIELD', entity.index);
    }
  }

  @Action({ rawError: true })
  async prepareWeekRange(
    date: Date
  ): Promise<{
    startWeek: number;
    endWeek: number;
    isSameWeek: boolean;
  }> {
    const weekRange = 5;

    const desiredWeek = moment(date).startOf('week');
    const currentWeek = moment().add(this.countWeekStart, 'weeks');

    const isSameWeek = desiredWeek.isSame(currentWeek, 'week');
    const weekDifference = currentWeek.diff(desiredWeek, 'weeks');

    let startWeek = this.countWeekStart - weekDifference;
    const endWeek = startWeek + weekRange;

    if (startWeek > this.countWeekStart) {
      startWeek += 1;
    }

    await this.context.commit('SET_START_WEEK', startWeek);
    await this.context.commit('SET_END_WEEK', endWeek);

    return { startWeek, endWeek, isSameWeek };
  }

  @Action({ rawError: true })
  async setCalendarRange(params: {
    startWeek: number;
    endWeek: number;
    shiftsByDays: Record<string, unknown>;
  }): Promise<void> {
    this.calendarEntity.weeks = {};

    for (let week = params.startWeek; week < params.endWeek; week++) {
      await this.calendarEntity.addWeek(week, params.shiftsByDays);
    }

    await this.calendarEntity.createHours();

    this.context.commit('SET_CALENDAR', this.calendarEntity.weeks);
    this.context.commit('SET_CALENDAR_STYLE', this.calendarEntity.styleByWeeks);
    this.context.commit('SET_HOURS', this.calendarEntity.hours);

    this.context.commit('SET_COUNT_START_WEEK', params.startWeek);
    this.context.commit('SET_COUNT_END_WEEK', params.endWeek - 1);
  }
}
