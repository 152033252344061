type CallbackFunction = (data?: any) => void;

class Event {
  events: any;

  constructor() {
    this.events = {};
  }

  $on(event: string | string[], callback: CallbackFunction) {
    if (Array.isArray(event)) {
      return; //TODO:: ??
    }

    this.events[event] = this.events[event] || [];
    this.events[event].push(callback);
  }

  $off(event?: string | string[], callback?: CallbackFunction) {
    if (!event || Array.isArray(event)) {
      return; //TODO:: ??
    }

    if (!this.events[event]) {
      return;
    }

    for (let i = 0; i < this.events[event].length; i++) {
      if (this.events[event][i] === callback) {
        this.events[event].splice(i, 1);
        break;
      }
    }
  }

  $emit(event: string, data?: any) {
    if (this.events[event]) {
      this.events[event].forEach(function (callback: CallbackFunction) {
        callback(data);
      });
    }
  }
}

export const EventBus = new Event();
