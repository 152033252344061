import API from '@/config/api-routers';
import { makeGetRequest, makeFormRequest, makeDeleteRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';

/**
 * Get cards rating list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const getRatingList = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.cards.getRatingList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get cards list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const getCardsList = async (pageCurrent: number, pageAmountItems: string, sort: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.cards.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Create new card
 *
 * @param data
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const createCard = async (data: any) => {
  const url = API.cards.create;

  return await makeFormRequest(url, data);
};

/**
 * Get cards types list
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const getCardTypes = async () => {
  const url = API.cards.getCardTypes;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Edit card
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const editCard = async (id: number, data: any) => {
  const url = API.cards.edit.replace('$1', id.toString());

  return await makeFormRequest(url, data);
};

/**
 * Delete card
 *
 * @param id
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const deleteCard = async (id: number) => {
  const url = API.cards.delete.replace('$1', id.toString());

  return await makeDeleteRequest(url);
};

/**
 * Cancel card
 *
 * @param id
 *
 * @access CAN_EDIT_YELLOW_CARD
 */
export const cancelCard = async (id: number) => {
  const url = API.cards.cancelCard.replace('$1', id.toString());

  return await makeFormRequest(url, {});
};
