import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mr-notify__close" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["mr-notify__wrapper", [_ctx.type]])
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'times'],
            size: "lg",
            onClick: _ctx.closeNotify
          }, null, 8, ["onClick"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}