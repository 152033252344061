
import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'ModalBtnBottomLayout',
  props: {
    position: {
      type: [Number, String],
    },
  },
  computed: {
    classPosition(): string {
      let classPosition = this.position === 'end' ? 'mr_modal-btn__end' : '';
      classPosition = this.position === 'start' ? 'mr_modal-btn__start' : classPosition;
      classPosition = this.position === 'between' ? 'mr_modal-btn__between' : classPosition;

      return classPosition;
    },
  },
});

export default component;
