/**
 * Helpers for work with API requests.
 *
 */

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import INSTANCE from '@/config/axios-config';
import userModule from '@/store/user';
import { buildFormData } from '@/lib/util/helpers';
import { CacheStorage } from './cacheStorage';

const requestsCacheStorage = new CacheStorage<AxiosResponse>(60000 * 2);

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeSimpleGetRequest = <T = any>(url: string, baseURL: string): Promise<AxiosResponse<T>> => {
  return INSTANCE.request<T>({
    url,
    baseURL,
    method: 'GET',
  } as AxiosRequestConfig);
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeGetRequest = <T = any>(
  url: string,
  config: Partial<{
    withCache: boolean;
  }> = {}
): Promise<AxiosResponse<T>> => {
  const fetcher = () =>
    INSTANCE.request<T>({
      url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userModule.token}`,
      },
    });

  if (!config.withCache) {
    return fetcher();
  }

  return requestsCacheStorage.executeOrGetFromCache(fetcher, url);
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeGetFileRequest = <T = any>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> => {
  return INSTANCE.request<T>({
    url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${userModule.token}`,
    },
    ...config,
  });
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeGetFileSberRequest = <T = any>(url: string): Promise<AxiosResponse<T>> => {
  return INSTANCE.request<T>({
    url,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv;charset=windows-1251',
      Authorization: `Bearer ${userModule.token}`,
    },
  });
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeFormRequest = <T = any>(
  url: string,
  payload: { [key: string]: string | number | Blob | undefined },
  method: 'POST' | 'PUT' | 'PATCH' = 'POST'
): Promise<AxiosResponse<T>> => {
  const formData = new FormData();
  buildFormData(formData, payload);

  return INSTANCE.request<T>({
    url,
    method,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${userModule.token}`,
    },
  });
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param formData
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makePostRequest = <T = any>(
  url: string,
  formData: { [key: string]: string },
  method: 'POST' | 'PUT' | 'PATCH' = 'POST'
): Promise<AxiosResponse<T>> => {
  const data = formData;

  return INSTANCE.request<T>({
    url,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userModule.token}`,
    },
  });
};

/**
 * Send request with Form data.
 *
 * @param url
 * @param method
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const makeDeleteRequest = <T = any>(url: string): Promise<AxiosResponse<T>> => {
  return INSTANCE.request<T>({
    url,
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${userModule.token}`,
    },
  });
};
