export const employeeStatusesList = [
  { id: 'beginner', name: 'Новичок', checked: false },
  { id: 'beginner_calendar', name: 'Новичок с графиком', checked: false },
  { id: 'beginner_add', name: 'Новичок запланирован', checked: false },
  { id: 'no_shifts', name: 'Нет заказов', checked: false },
  { id: 'not_working', name: 'Не работает', checked: false },
  { id: 'few_shifts', name: 'Мало заказов', checked: false },
  { id: 'need_calendar', name: 'Нужен график', checked: false },
  { id: 'is_working', name: 'Работает', checked: false },
  { id: 'calendar_end', name: 'График заканчивается', checked: false },
  { id: 'newbie_ignores', name: 'Новичок игнорирует', checked: false },
  { id: 'sleeping', name: 'В спящем режиме', checked: false },
];
