/**
 * Global API for payouts.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { payoutsResponse } from '@/interfaces/payoutsResponse.interface';
import { AssemblerUrl } from '@/lib/UtilsApi';

/**
 * Get payouts list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param filter
 *
 * @access CAN_READ_FINANCE_PAYOUT
 */
export const getPayoutsList = async (pageCurrent: number, pageAmountItems: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.payouts.getPayoutsList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest<payoutsResponse>(url);

  return result.data.result.table;
};

/**
 * Create new payout
 *
 * @param employeeId
 * @param data
 *
 * @access CAN_PAYOUT_FINANCE_PAYOUT
 */
export const newPayout = async (employeeId: string, data: {}) => {
  const url = API.payouts.newPayout.replace('$1', employeeId);
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Create multi payments
 *
 * @param data
 *
 * @access CAN_PAYOUT_FINANCE_PAYOUT
 */
export const multiplePayouts = async (data: {}) => {
  const url = API.payouts.multiplePayouts;
  const result = await makeFormRequest(url, data);

  return result.data.result;
};

/**
 * Update payout
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_FINANCE_PAYOUT
 */
export const updatePayout = async (id: string, data: any) => {
  const url = API.payouts.updatePayout.replace('$1', id);

  return (await makeFormRequest(url, data)) as any;
};

/**
 * Delete payouts
 *
 * @param data
 *
 * @access CAN_EDIT_FINANCE_PAYOUT
 */
export const deletePayouts = async (data: any) => {
  const url = API.payouts.deletePayout;

  return await makeFormRequest(url, data);
};

/**
 * Export payouts
 *
 * @param filter
 *
 * @access CAN_DOWNLOAD_FINANCE_PAYOUT
 */
export const exports = async (filter: any) => {
  const url = `${API.payouts.export}${filter}`;

  return await makeGetRequest(url);
};

/**
 * Get rocket work statuses
 *
 * @access CAN_READ_FINANCE_PAYOUT
 */
export const getRocketWorkStatuses = async () => {
  const url = API.payouts.rocketWorkStatuses;
  const result = await makeGetRequest(url);

  return [
    ...result.data.result,
    {
      type: 'null',
      humanType: '-',
    },
  ];
};
