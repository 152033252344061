import { TOKEN_STORAGE_KEY } from '@/store/user';

interface CacheItem<T = unknown> {
  createdAt: number;
  expiredAt: number;
  data: T;
}

interface CacheItemConfig {
  ttl?: number;
}

export class CacheStorage<I> {
  private items = new Map<string, CacheItem<I>>();

  private get currentTimestamp() {
    return Date.now();
  }

  private get expiredTimestamp() {
    return this.currentTimestamp + this.defaultTtl;
  }

  constructor(private defaultTtl: number) {}

  async executeOrGetFromCache<T extends I>(asyncTask: () => Promise<T>, key: string, config?: CacheItemConfig): Promise<T> {
    const cacheItem = this.get(key);

    if (cacheItem) {
      return cacheItem.data as T;
    }

    const result = await asyncTask();
    this.set(key, result, config);

    return result;
  }

  private get(shortKey: string) {
    this.invalidationCheck(shortKey);
    const fullKey = this.getFullCacheKey(shortKey);

    return this.items.get(fullKey);
  }

  private set(shortKey: string, data: I, config?: CacheItemConfig) {
    this.invalidationCheck();
    const fullKey = this.getFullCacheKey(shortKey);

    this.items.set(fullKey, {
      createdAt: this.currentTimestamp,
      expiredAt: this.expiredTimestamp,
      data,
    });

    setTimeout(() => {
      this.invalidationCheck(shortKey);
    }, config?.ttl || this.defaultTtl);
  }

  private invalidationCheck(shortKey?: string) {
    const keys = shortKey ? [this.getFullCacheKey(shortKey)] : Array.from(this.items.keys());

    for (const key of keys) {
      const item = this.items.get(key);

      if (item && item.expiredAt <= this.currentTimestamp) {
        this.items.delete(key);
      }
    }
  }

  private getFullCacheKey(shortKey: string) {
    const tokenSignature = window.localStorage[TOKEN_STORAGE_KEY]?.split('.')[1] ?? 'guest';

    return `${tokenSignature}:${shortKey}`;
  }
}
