import { makeDeleteRequest, makeFormRequest, makeGetRequest } from '@/api/requests';
import API from '@/config/api-routers';
import { AssemblerUrl } from '@/lib/UtilsApi';

interface FormData {
  [index: string]: any;
}

/**
 * Get training center list
 *
 * @param pageCurrent
 * @param pageAmountItems
 *
 * @access CAN_READ_TRAINING_CENTER
 */
export const getTrainingCenterList = async (pageCurrent: number, pageAmountItems: string) => {
  const assemblerUrl = new AssemblerUrl(API.trainingCenter.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).getUrl();
  const result = await makeGetRequest<any>(url);

  return result.data.result.table;
};

/**
 * Get info by id
 *
 * @param id
 *
 * @access CAN_READ_TRAINING_CENTER
 */
export const getInfoById = async (id: string) => {
  const url = API.trainingCenter.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Add new training center
 *
 * @param data
 *
 * @access CAN_EDIT_TRAINING_CENTER
 */
export const addTrainingCenter = async (data: FormData) => {
  const url = API.trainingCenter.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Update training center
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_TRAINING_CENTER
 */
export const updateTrainingCenter = async (id: string, data: FormData) => {
  const url = API.trainingCenter.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete training center
 *
 * @param id
 *
 * @access CAN_EDIT_TRAINING_CENTER
 */
export const deleteTrainingCenter = async (id: string) => {
  const url = API.trainingCenter.delete.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};
