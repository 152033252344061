export type PayoutsModalDateType = string | Record<string, string | boolean | number | Record<string, string | number>>;
export type PayoutsModalParamsType = Record<string, Record<string, string | boolean | Record<string, PayoutsModalDateType>>>;

export const modalParams: PayoutsModalParamsType = {
  dateRange: {
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: '',
        value: '',
        action: 'payouts/updateDateStart',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: '',
        value: '',
        action: 'payouts/updateDateEnd',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
  editPayout: {
    isShow: false,
    title: 'Редактирование выплаты',
  },
  deleteWarning: {
    text: 'Вы точно уверены что хотите удалить все выбранные выплаты?',
    handlers: {
      yes: 'payouts/deletePayouts',
      no: '',
    },
  },
};
