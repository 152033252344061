/**
 * Global API for region.
 *
 */

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest, makePostRequest } from './requests';

/**
 *
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_MAILING
 */
export const getMailingsList = async (pageCurrent?: number, pageAmountItems?: string, sort?: string, filter?: string) => {
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(
    `${API.mailings.getList}?${pageCurrent ? `page=${pageCurrent}` : ''}&${pageAmountItems}${sort}${filter}`
  );

  return result.data.result.table;
};

/**
 * Get mailing status list
 *
 * @access CAN_READ_MAILING
 */
export const getMailingStatusList = async () => {
  const url = API.mailings.status;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get mailing by id
 *
 * @param id
 *
 * @access CAN_READ_MAILING
 */
export const getMailingById = async (id: string) => {
  const url = API.mailings.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.mailing;
};

/**
 * Add new mailing
 *
 * @param data
 *
 * @access CAN_EDIT_MAILING
 */
export const addNewMailing = async (data: any) => {
  const url = API.mailings.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save mailing
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_MAILING
 */
export const saveMailing = async (id: string, data: any) => {
  const url = API.mailings.edit.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Delete mailing
 *
 * @param id
 *
 * @access CAN_EDIT_MAILING
 */
export const deleteMailing = async (id: string) => {
  const url = API.mailings.delete.replace('$1', id);
  const result = await makeDeleteRequest(url);

  return result.data;
};

/**
 * Copy mailing
 *
 * @param id
 *
 * @access CAN_EDIT_MAILING
 */
export const copyMailing = async (id: string) => {
  const url = API.mailings.copy.replace('$1', id);
  const result = await makePostRequest(url, {});

  return result.data;
};

/**
 * Prepare mailing
 *
 * @param id
 *
 * @access CAN_PREPARE_MAILING
 */
export const prepareMailing = async (id: string) => {
  const url = API.mailings.prepare.replace('$1', id);
  const result = await makePostRequest(url, {});

  return result.data;
};

/**
 * Cancel mailing
 *
 * @param id
 *
 * @access CAN_EDIT_MAILING
 */
export const cancelMailing = async (id: string) => {
  const url = API.mailings.cancel.replace('$1', id);
  const result = await makePostRequest(url, {});

  return result.data;
};

/**
 * Get mailing participants
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param sort
 * @param filter
 *
 * @access CAN_READ_MAILING
 */
export const getMailingParticipants = async (
  pageCurrent?: number,
  pageAmountItems?: string,
  sort?: string,
  filter?: string
) => {
  const url = `${API.mailings.participantList}?${
    pageCurrent ? `page=${pageCurrent}` : ''
  }&${pageAmountItems}${sort}${filter}`;
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(url);

  return result.data.result.table;
};

/**
 * Get mailing markers
 *
 * @access CAN_READ_MAILING
 */
export const getMailingMarkers = async () => {
  const url = API.mailings.markers;
  const result = await makeGetRequest(url);

  return result.data.result.markers;
};
