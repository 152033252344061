import { ShiftEmployeeWorkHours, ShiftEntityInterface } from '@/store/shops/shiftModalParams';

/**
 * Returns the shift time formatted (0h 00m)
 *
 * @param {number} timestamp The market shifts from API
 * @returns {string} timeFormatted
 */
export function shiftHoursFormat(timestamp: number) {
  let timeFormatted = '';

  const hours = Math.floor(timestamp / 3600);
  const minutes = Math.ceil((timestamp - hours * 3600) / 60);

  let formatMinutes = minutes.toString();
  if (minutes < 10) {
    formatMinutes = `0${minutes}`;
  }

  timeFormatted = `${hours}:${formatMinutes}`;

  return timeFormatted;
}

/**
 * Returns the shift ceil time formatted (0h 00m)
 *
 * @param {number} timestamp The market shifts from API
 * @returns {string} timeFormatted
 */
export function shiftCeilHoursFormat(timestamp: number) {
  let timeFormatted = '';

  const hours = Math.ceil(timestamp / 3600);

  timeFormatted = `${hours}:00`;

  return timeFormatted;
}

/**
 * Returns the shift work hours time formatted (0h 00m)
 *
 * @param {string} startTime The market shifts from API
 * @param {string} endTime The market shifts from API
 * @param {string} lunchTime The market shifts from API
 * @returns {string} workHours
 */
export function getWorkHours(startTime: string, endTime: string, lunchTime: number) {
  if (!startTime || !endTime) {
    return '00:00';
  }
  const lunchTimeInSeconds = lunchTime * 60;

  const employeeStartTimestamp = new Date(startTime).getTime() / 1000;
  const employeeEndTimestamp = new Date(endTime).getTime() / 1000;
  const workHoursTimestamp = employeeEndTimestamp - employeeStartTimestamp - lunchTimeInSeconds;

  return shiftCeilHoursFormat(workHoursTimestamp);
}

export function getCalendarWorkHours(employeeId: string, shift: ShiftEntityInterface): ShiftEmployeeWorkHours | null {
  if (!shift.workHours || !(employeeId in shift.workHours)) {
    return null;
  }

  return shift.workHours[employeeId];
}

/**
 * Returns the shift late hours time formatted (0h 00m)
 *
 * @param {string} employeeStart The market shifts from API
 * @param {string} shiftStart The market shifts from API
 * @returns {string} lateHours
 */
export function getLateHours(employeeStart: any, shiftStart: any) {
  if (!employeeStart || !shiftStart) {
    return {
      isLate: false,
      time: '00:00',
    };
  }

  let isLate = true; //Опоздание

  const employeeStartTimestamp = new Date(employeeStart).getTime() / 1000;
  const shiftStartTimestamp = new Date(shiftStart).getTime() / 1000;
  const workHoursTimestamp =
    employeeStartTimestamp > shiftStartTimestamp
      ? employeeStartTimestamp - shiftStartTimestamp
      : shiftStartTimestamp - employeeStartTimestamp;

  if (employeeStartTimestamp <= shiftStartTimestamp) {
    isLate = false; //Пришел раньше
  }

  return {
    isLate: isLate,
    time: shiftHoursFormat(workHoursTimestamp),
  };
}

export function getLunchTimeFormat(minutes: number) {
  if (!minutes) {
    return {
      hours: '00',
      minutes: '00',
    };
  }

  const lunchHour = minutes >= 60 ? Math.floor(minutes / 60) : 0;
  const lunchMinute = minutes >= 60 ? Math.ceil(minutes - lunchHour * 60) : minutes;
  let lunchHourFormat = lunchHour === 0 ? '00' : lunchHour.toString();
  let lunchMinuteFormat = lunchMinute ? lunchMinute.toString() : '00';

  if (lunchHour < 10 && lunchHour !== 0) {
    lunchHourFormat = `0${lunchHourFormat}`;
  }
  if (lunchMinute < 10 && lunchMinute !== 0) {
    lunchMinuteFormat = `0${lunchMinuteFormat}`;
  }

  return {
    hours: lunchHourFormat,
    minutes: lunchMinuteFormat,
  };
}
