/**
 * Customers templates entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import { TemplateModel, TemplateRequestModel } from '@/interfaces/models/template.interface';
import store from '@/store';
import EntityModel from './templateEntityModel';
import CustomersTemplatesModule from './templates';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import moment from 'moment';
import { getTemplateInfo, addNewTemplate, updateTemplate } from '@/api/templates';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'customersTemplatesEntity';
export const DEFAULT_HOURS = 100000;

export interface TextFieldsData {
  [index: string]: string | number | boolean | null | undefined;
  name: string;
  serviceType?: string | number | null;
  isActive: number | boolean;
  isTraining: number | boolean;
  rate?: number;
  minHours?: number;
  maxHours?: number;
  isPreviouslyWorked?: boolean | string | number;
  externalRate?: number;
  hasLmk: number | boolean;
  gender?: string;
  info?: string;
  vaccinationRequirement: string;
  forLk: string | boolean | number;
  'age[from]': string | number;
  'age[to]': string | number;
  externalName?: string;
  educationLink?: string;
  unitType?: string | number | null;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersTemplatesEntityModule extends EntityBaseModule {
  model: TemplateModel;

  constructor(module: CustomersTemplatesEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    (this as any).model[params.key] = params.value;
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    await this.getById(id);
  }

  @Action({ rawError: true })
  async getById(id: string) {
    try {
      const result = await getTemplateInfo(id);
      this.setInfoById(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfoById(info: TemplateRequestModel) {
    CustomersTemplatesModule.updateTitleEdit(info.name);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalName', value: info.externalName });
    this.context.commit('SET_MODEL_VALUE', { key: 'minHours', value: info.minHours });
    this.context.commit('SET_MODEL_VALUE', { key: 'maxHours', value: info.maxHours });
    this.context.commit('SET_MODEL_VALUE', { key: 'rate', value: info.rate });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalRate', value: info.externalRate });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'hasLmk', value: info.hasLmk });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTraining', value: info.isTraining });
    this.context.commit('SET_MODEL_VALUE', { key: 'forLk', value: info.forLk });
    this.context.commit('SET_MODEL_VALUE', { key: 'info', value: info.info });
    this.context.commit('SET_MODEL_VALUE', { key: 'vaccinationRequirement', value: info.vaccinationRequirement });
    this.context.commit('SET_MODEL_VALUE', { key: 'isPreviouslyWorked', value: info.isPreviouslyWorked });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingWebLink', value: info.competence?.trainingWebLink });

    this.context.commit('SET_MODEL_VALUE', {
      key: 'serviceType',
      value: { id: info.serviceType.id, value: info.serviceType.name },
    });

    this.context.commit('SET_MODEL_VALUE', {
      key: 'unitType',
      value: { id: info.unitType.id, value: info.unitType.shortName },
    });

    if (info.citizenship) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'citizenship',
        value: info.citizenship.map((item) => ({
          id: item.id.toString(),
          value: item.name,
          name: item.name,
        })),
      });
    }

    if (info.gender) {
      this.context.commit('SET_MODEL_VALUE', { key: 'gender', value: { value: info.gender } });
    }

    if (info.age) {
      this.context.commit('SET_MODEL_VALUE', { key: 'ageFrom', value: info.age.from });
      this.context.commit('SET_MODEL_VALUE', { key: 'ageTo', value: info.age.to });
    }

    this.context.commit('SET_CREATED_AT', moment(info.createdAt).format('DD.MM.YYYY HH:mm'));
    this.context.commit('SET_UPDATED_AT', moment(info.updatedAt).format('DD.MM.YYYY HH:mm'));
  }

  @Action({ rawError: true })
  async addNew(params: { customerId: string; textFieldsData: TextFieldsData }) {
    try {
      const data = await this.prepareFormData(params.textFieldsData);
      const result = await addNewTemplate(params.customerId, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Шаблон создан', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; textFieldsData: TextFieldsData }) {
    try {
      const data = await this.prepareFormData(params.textFieldsData);
      const result = await updateTemplate(params.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async prepareFormData(textFieldsData: TextFieldsData) {
    try {
      textFieldsData.maxHours = textFieldsData.maxHours === 0 ? DEFAULT_HOURS : textFieldsData.maxHours;

      return textFieldsData;
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return {};
    }
  }

  @Action({ rawError: true })
  resetModel() {
    CustomersTemplatesModule.updateTitleEdit('');

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'minHours', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'maxHours', value: 100000 });
    this.context.commit('SET_MODEL_VALUE', { key: 'rate', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'externalRate', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'hasLmk', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTraining', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'forLk', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'info', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'vaccinationRequirement', value: 'not_vaccinated' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isPreviouslyWorked', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingWebLink', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'serviceType', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'unitType', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'gender', value: { id: 3, name: 'Не важно', value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'citizenship', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'ageFrom', value: 14 });
    this.context.commit('SET_MODEL_VALUE', { key: 'ageTo', value: 65 });
    this.context.commit('SET_CREATED_AT', '');
    this.context.commit('SET_UPDATED_AT', '');
  }
}

export default getModule(CustomersTemplatesEntityModule);
