import UserModule from '@/store/user';

interface HelpDeskChatIdentifier {
  name: string;
  token?: string;
}

export default function useHelpDeskChat(): { init: () => void } {
  const DEFAULT_NAME = 'Пользователь HRM';
  const CHAT_TOKEN_KEY = 'chatData';

  async function init() {
    document.addEventListener('click', (event) => {
      const isWidgetButton = (event.target as HTMLElement)?.id === 'uw-main-button';

      if (isWidgetButton) {
        userIdentify();
      }
    });

    await loadWidgetScript();

    window.__usedeskWidgetInitedCallback = () => {
      const name = getUserName();
      const token = getCurrentChatToken();

      if (token) {
        const storageData = JSON.stringify({ name, token });
        window.localStorage.setItem(CHAT_TOKEN_KEY, storageData);
      }
    };
  }

  function userIdentify(): void {
    if (window?.usedeskMessenger?.userIdentify) {
      const params: HelpDeskChatIdentifier = {
        name: getUserName(),
      };

      const token = getSavedChatToken();
      if (token) {
        params.token = token;
      }

      window.usedeskMessenger.userIdentify(params);
    }
  }

  function getUserName(): string {
    const fullName = UserModule.fullName || DEFAULT_NAME;
    const partnerName = UserModule.partner?.legalName;

    return [fullName, partnerName].filter(Boolean).join(', ').replaceAll('"', '');
  }

  function getSavedChatToken(): string {
    let result = '';

    try {
      const rawChatData = window.localStorage.getItem(CHAT_TOKEN_KEY) ?? '';
      const chatData = JSON.parse(rawChatData);

      if (chatData?.token) {
        result = chatData.token;
      }
    } catch (error) {
      console.warn('chatData is empty');
    }

    return result;
  }

  function getCurrentChatToken(): string {
    return window.usedeskMessenger?.getChatToken?.() ?? '';
  }

  async function loadWidgetScript() {
    const script = document.createElement('script');
    script.src = 'https://lib.usedesk.ru/secure.usedesk.ru/widget_160235_48958.js';
    document.body.appendChild(script);

    return new Promise((resolve, reject) => {
      script.onload = resolve;
      script.onabort = reject;
    });
  }

  return {
    init,
  };
}
