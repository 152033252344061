/**
 * Payments finance store.
 *
 */

import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './filter';
import PaymentsExportModule from './export';
import { tableTitles, requisitesTitles } from './tableTitles';
import { modalParams, PaymentsModalParamsType } from './modalParams';
import { prepareList } from '@/lib/Payments';
import { getDateRange } from '@/lib/Payouts';
import { getAllPartnersList } from '@/api/partners';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getBalance, getPaymentsList } from '@/api/payments';
import { newPayout, multiplePayouts } from '@/api/payouts';
import { TableTitle, TableApiInterface, TableRow } from '@/lib/layouts/page/table.interface';
import SiteModule from '@/store/site';
import moment from 'moment';
import { getShortName } from '@/lib/Utils';
import PaymentsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import userModule from '@/store/user';
import { strings } from '@/lib/stringConst';
import { PaymentModel } from '@/interfaces/models/payout.interface';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';

export const MODULE_NAME = 'payments';

export interface RequisitesModalInterface {
  bank: string;
  bic: string;
  cardNumber: string;
  earned: number;
  earnedTax: number;
  forPayout: number;
  fullName: string;
  hasNotPayout: boolean;
  hasNotPayoutAndSelfEmployed: boolean;
  id: number;
  inn: string;
  isAccountUpdated: boolean;
  isSelfEmployed: boolean;
  ks: string;
  paymentAccount: string;
  worked: number;
  shiftEmployeeId: number | null;
  balanceId: number | null;
  partnerUuid: string | null;
}

const defaultRequisites = {
  bank: '',
  bic: '',
  cardNumber: '',
  earned: 0,
  earnedTax: 0,
  forPayout: 0,
  fullName: '',
  hasNotPayout: false,
  hasNotPayoutAndSelfEmployed: false,
  id: 0,
  inn: '',
  isAccountUpdated: false,
  isSelfEmployed: false,
  ks: '',
  paymentAccount: '',
  worked: 0,
  shiftEmployeeId: null,
  balanceId: null,
  partnerUuid: null,
};

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
export class PaymentsModule extends PageBaseModule {
  titles: TableTitle[] = tableTitles;
  requisitesTitles: TableTitle[] = requisitesTitles;
  modalParams: PaymentsModalParamsType = modalParams;
  currentItem: RequisitesModalInterface = defaultRequisites;
  selectedEmployees: string[] = [];
  model: PaymentModel = {
    payment: '',
  };
  selectAllCheckbox: {
    key: string;
    name: string;
    type: string;
    list: {
      0: {
        id: number;
        name: string;
        checked: boolean;
      };
    };
    handler: string;
  };
  filter: Filter;
  userCanExportPayments = false;
  userCanReadLastExport = false;
  dataLoaded = false;
  balance = 0;

  constructor(module: PaymentsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Начисления исполнителям');
    page.values.actionPagination = 'payments/updatePage';
    page.values.filterAdditional = '';

    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        fullName: 'payments/getById',
        _actions: 'payments/showModal',
      },
    };
    this.selectAllCheckbox = {
      key: 'isSelectedAll',
      name: '',
      type: 'checkbox',
      list: {
        0: {
          id: 0,
          name: '',
          checked: false,
        },
      },
      handler: 'payments/updateIsSelectedAll',
    };

    const filter = new filterModel();
    this.filter = getModule(PaymentsFilter);
    this.filter.setFilterName('paymentsFilter');
    this.filter.setTemplateClassName(['template-md', 'left-ext']);
    this.filter.setBtnClassName('col-1-row-6');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetSelect('partnerUuid');
        }
      },
      { immediate: true }
    );
  }

  @Mutation
  SET_DATA_LOADED(value: boolean) {
    this.dataLoaded = value;
  }

  @Mutation
  SET_MODEL_VALUE<T extends keyof PaymentModel>(params: { key: T; value: PaymentModel[T] }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  UPDATE_DATE_START(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value = date;
  }

  @Mutation
  UPDATE_EXPORT_LOAD_DATE(date: string) {
    this.modalParams.exportLoad.date = date;
  }

  @Mutation
  UPDATE_DATE_END(date: string) {
    (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value = date;
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    this.modalParams[payload.type].isShow = payload.bool;
  }

  @Mutation
  SET_CURRENT_ITEM(item: RequisitesModalInterface) {
    this.currentItem = item;
  }

  @Mutation
  SET_SELECTED(values: string[]) {
    this.selectedEmployees = Object.assign([], values);
  }

  @Mutation
  SET_IS_SELECTED_ALL(bool: boolean) {
    this.selectAllCheckbox.list[0].checked = bool;
  }

  @Mutation
  RESET_SELECTED() {
    this.selectedEmployees.splice(0, this.selectedEmployees.length);
  }

  @Mutation
  SET_MULTIPLE_PAYMENT_TITLE(title: string) {
    this.modalParams.payments.title = title;
  }

  @Mutation
  SET_PAYMENT_TITLE(title: string) {
    this.modalParams.payment.title = title;
  }

  @Mutation
  SET_CREATE_PAYMENTS(bool: boolean) {
    (this.modalParams.dateRange.model as Record<
      string,
      Record<string, Record<string, Record<string, boolean>>>
    >).createPayouts.list[0].checked = bool;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.customersLogsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_CAN_EXPORT_PAYMENTS(bool: boolean) {
    this.userCanExportPayments = bool;
  }

  @Mutation
  SET_CAN_READ_LAST_EXPORT(bool: boolean) {
    this.userCanReadLastExport = bool;
  }

  @Mutation
  SET_BALANCE(balance: number) {
    this.balance = balance;
  }

  @Action({ rawError: true })
  async init() {
    this.RESET_SELECTED();
    this.context.commit('SET_IS_SELECTED_ALL', false);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
    await PaymentsExportModule.getLastExport();
    this.context.commit('SET_CAN_EXPORT_PAYMENTS', userModule.userHasPermission('CAN_PAYOUT_FINANCE_PAYOUT'));
    this.context.commit('SET_CAN_READ_LAST_EXPORT', userModule.userHasPermission('CAN_PAYOUT_FINANCE_PAYOUT'));
  }

  @Action({ rawError: true })
  async initList() {
    await this.initDateRange();
    await this.filter.init();
    await this.setBalance();
    await this.getList();
  }

  @Action({ rawError: true })
  initDateRange() {
    const range = getDateRange();

    this.context.commit('UPDATE_DATE_START', range.startDay);
    this.context.commit('UPDATE_DATE_END', range.endDay);
  }

  @Action({ rawError: true })
  async setBalance() {
    try {
      if (UserModule.userHasPermission('CAN_READ_ROCKET_WORK_BALANCE')) {
        const result = await getBalance();
        this.context.commit('SET_BALANCE', result.balance);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getList() {
    try {
      this.context.commit('SET_DATA_LOADED', false);

      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const dates = await this.getFilterDates();
      const filter = this.filter.filterSettings.filter;
      const filterString = `${dates}${filter}`;
      const result = await getPaymentsList(this.pageSettings.pageCurrent, itemsQuery, sort, filterString);
      await this.context.dispatch('setList', result.table);

      this.context.commit('SET_DATA_LOADED', true);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async initPartnersFilter() {
    try {
      if (!UserModule.isSupervisor) {
        return;
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initPaymentType() {
    try {
      return [
        {
          id: 'bank_account',
          value: 'По реквизитам',
          name: 'По реквизитам',
        },
        {
          id: 'card',
          value: 'По номеру карты',
          name: 'По номеру карты',
        },
        {
          id: 'sbp_account',
          value: 'По СБП',
          name: 'По СБП',
        },
      ];
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  getFilterDates() {
    const dateStartFormatted = moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value,
      'DD/MM/YY'
    ).format('DD/MM/YYYY');
    const dateEndFormatted = moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value,
      'DD/MM/YY'
    ).format('DD/MM/YYYY');
    const dateStart = dateStartFormatted.split('/');
    const dateStartFilter = `${dateStart[2]}-${dateStart[1]}-${dateStart[0]}`;
    const dateEnd = dateEndFormatted.split('/');
    const dateEndFilter = `${dateEnd[2]}-${dateEnd[1]}-${dateEnd[0]}`;

    let datesFilter = `&filters[0][id]=periodStart&filters[0][value]=${dateStartFilter}`;
    datesFilter += `&filters[1][id]=periodEnd&filters[1][value]=${dateEndFilter}&`;

    return datesFilter;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  async updateDateStart(date: string) {
    await this.context.commit('UPDATE_DATE_START', date);

    const dataEnd = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value.split(
      '.'
    );
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp > dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_END', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updateDateEnd(date: string) {
    await this.context.commit('UPDATE_DATE_END', date);

    const dataEnd = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value.split('.');
    const dataEndTimestamp = new Date(dataEnd[1] + '/' + dataEnd[0] + '/' + dataEnd[2]).getTime() / 1000;

    const dateStart = (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value.split(
      '.'
    );
    const dateStartTimestamp = new Date(dateStart[1] + '/' + dateStart[0] + '/' + dateStart[2]).getTime() / 1000;

    if (dateStartTimestamp >= dataEndTimestamp) {
      await this.context.commit('UPDATE_DATE_START', date);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  updateIsSelected(params: { id: string; bool: boolean }) {
    this.context.commit('SET_SELECTED', params);
  }

  @Action({ rawError: true })
  updateIsSelectedAll(bool: boolean) {
    bool = typeof bool === 'boolean' ? bool : !this.selectAllCheckbox.list[0].checked;
    let index: string;
    let entry: TableRow;

    if ('rows' in this.pageSettings.table) {
      for ([index, entry] of Object.entries(this.pageSettings.table.rows)) {
        if (entry.isSelfEmployed === true) {
          entry.selected.list[0].checked = bool;
          this.updateIsSelected({ id: entry.id, bool: bool });
        }
      }
    }

    this.context.commit('SET_IS_SELECTED_ALL', bool);
  }

  @Action({ rawError: true })
  async openRequisitesModal(item: RequisitesModalInterface) {
    await this.context.commit('SET_CURRENT_ITEM', item);
    this.showModal('requisites');
  }

  @Action({ rawError: true })
  openPaymentModal(item: RequisitesModalInterface) {
    const shortName = getShortName(item.fullName);
    this.SET_PAYMENT_TITLE(`Добавить выплату - <span class="cp-modal-title__accent">${shortName}</span>`);
    this.context.commit('SET_CURRENT_ITEM', item);
    this.context.commit('SET_MODEL_VALUE', { key: 'payment', value: item.forPayout.toString() });
    this.context.commit('SET_MODEL_VALUE', { key: 'shiftEmployee', value: item.shiftEmployeeId });
    this.context.commit('SET_MODEL_VALUE', { key: 'balanceId', value: item.balanceId });
    this.context.commit('SET_MODEL_VALUE', { key: 'partner', value: item.partnerUuid });

    this.showModal('payment');
  }

  @Action({ rawError: true })
  openModalAddAll(params: { sumPayout: number; count: number }) {
    this.context.commit('SET_MODEL_VALUE', {
      key: 'payment',
      value: params.sumPayout.toString(),
    });

    const title = `Оплатить всем (выбрано ${params.count} сотрудников)`;
    this.context.commit('SET_MULTIPLE_PAYMENT_TITLE', title);
    this.showModal('payments');
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });
  }

  @Action({ rawError: true })
  async hideModal(type: string) {
    this.context.commit('SET_CURRENT_ITEM', {});
    await this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
    SiteModule.setIsBlock(false);
  }

  @Action({ rawError: true })
  async addNewPayment(amount: number) {
    try {
      const data = await this.preparePaymentData(amount);
      const result = await newPayout((this.currentItem as RequisitesModalInterface).id.toString(), data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Оплата создана', type: 'ok' });
        await this.hideModal('payment');
        await this.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
        SiteModule.setIsBlock(false);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
      SiteModule.setIsBlock(false);
    }
  }

  @Action({ rawError: true })
  preparePaymentData(amount: number): Record<string, string | number> {
    const dateRange = this.modalParams.dateRange.model as Record<string, Record<string, string>>;
    const start = moment(dateRange.dateStart.value, 'DD/MM/YY').format('YYYY-MM-DD');
    const end = moment(dateRange.dateEnd.value, 'DD/MM/YY').format('YYYY-MM-DD');

    const data: Record<string, string | number> = {
      amount,
      voucher: 0,
      start: start,
      end: end,
    };

    if (this.model.shiftEmployee) {
      data.shiftEmployee = this.model.shiftEmployee;
    }

    if (this.model.balanceId) {
      data.balance = this.model.balanceId;
    }

    if (this.model.partner) {
      data.partner = this.model.partner;
    }

    return data;
  }

  @Action({ rawError: true })
  validateMultiplePayouts() {
    return new Promise((resolve) => {
      let isError = false;

      if (this.model.payment === '0') {
        isError = true;
      }

      if (isError) {
        ResponseHandlerModule.showNotify({ message: 'Выбрано 0 сотрудников', type: 'fail' });
      }

      resolve(isError);
    });
  }

  @Action({ rawError: true })
  async multiplePayouts() {
    try {
      const data = await this.preparePaymentAllData();
      const result = await multiplePayouts(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Оплаты созданы', type: 'ok' });
        await this.hideModal('payments');
        await this.getList();
        this.context.commit('RESET_SELECTED');
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
        SiteModule.setIsBlock(false);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
      SiteModule.setIsBlock(false);
    }
  }

  @Action({ rawError: true })
  preparePaymentAllData() {
    const start = moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateStart.value,
      'DD/MM/YY'
    ).format('YYYY-MM-DD');
    const end = moment(
      (this.modalParams.dateRange.model as Record<string, Record<string, string>>).dateEnd.value,
      'DD/MM/YY'
    ).format('YYYY-MM-DD');
    const data: Record<string, string | number> = {
      start: start,
      end: end,
    };

    const payloadIds = this.selectedEmployees.reduce(
      (idList, uuid) => {
        const [id, shiftEmployee, balance, partner] = uuid.split('_');

        if (balance) {
          idList.balances.add(balance);
        } else if (shiftEmployee) {
          idList.clientMarketShiftEmployees.add(shiftEmployee);
        } else {
          idList.employees.push(id);
          idList.partners.push(partner);
        }

        return idList;
      },
      {
        employees: [] as string[],
        clientMarketShiftEmployees: new Set<string>(),
        balances: new Set<string>(),
        partners: [] as string[],
      }
    );

    Object.entries(payloadIds).forEach(([payloadKey, idSet]) => {
      Array.from(idSet).forEach((id, index) => {
        data[`${payloadKey}[${index}]`] = id;
      });
    });

    return data;
  }

  @Action({ rawError: true })
  updateCreatePayouts(data: { bool: boolean }) {
    this.SET_CREATE_PAYMENTS(data.bool);
  }
}
export default getModule(PaymentsModule);
