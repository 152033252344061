import { RouteRecordRaw } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import API from '@/config/api-routers';

import authGuard from './auth.guard';
import blockGuard from './block.guard';
import numericParamsGuard from './numericParams.guard';
import accessControl from './accessControl.guard';
import regionGuard from './region.guard';
import competenceGuard from './competence.guard';
import employeeGuard from './employee.guard';
import customerGuard from './customer.guard';
import userGuard from './user.guard';
import shopGuard from './shop.guard';
import cardsGuard from './cards.guard';
import splitGuard from './split.guard';
import mailingGuard from './mailing.guard';
import trainingCenterGuard from './trainingCenter.guard';
import permissionGuard from './permission.guard';

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: () => import('../views/login/Index.vue'),
  },

  {
    path: '',
    component: () => import('../layouts/Default.vue'),
    children: [
      /*
       *   Common
       */
      {
        path: '',
        name: 'home',
        meta: { requiresAuth: true },
        component: () => import('../views/home/Index.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        meta: { requiresAuth: true },
        component: () => import('../views/profile/Index.vue'),
      },
      /*
       *   Orders
       */
      {
        path: 'orders/',
        name: 'orders',
        meta: { requiresAuth: true },
        component: () => import('../views/orders/Main.vue'),
      },
      /*
       *   Shop
       */
      {
        path: 'shops/',
        name: 'shops',
        meta: { requiresAuth: true },
        component: () => import('../views/shops/Main.vue'),
      },
      {
        path: 'shops/add',
        name: 'shop_add',
        meta: { requiresAuth: true, permission: 'CAN_EDIT_CLIENT_MARKET' },
        component: () => import('../views/shops/Add.vue'),
      },
      {
        path: 'shops/:shopId',
        name: 'shop_edit',
        meta: { requiresAuth: true, entityRoute: API.shops.read },
        component: () => import('../views/shops/Edit.vue'),
      },
      {
        path: 'shops/:shopId/shift',
        name: 'shop_shift',
        meta: { requiresAuth: true, entityRoute: API.shops.readMin },
        component: () => import('../views/shops/shift/Index.vue'),
      },
      {
        path: 'shops/:shopId/shift/:serviceTypeId',
        name: 'shop_shift_service_type_id',
        meta: { requiresAuth: true },
        component: () => import('../views/shops/shift/Index.vue'),
      },
      {
        path: 'shops/:shopId/employees',
        name: 'shop_employees',
        meta: { requiresAuth: true, entityRoute: API.shops.readMin },
        component: () => import('../views/shops/employees/Index.vue'),
      },
      {
        path: 'shops/:shopId/statistics',
        name: 'shop_statistics',
        meta: { requiresAuth: true, entityRoute: API.shops.readMin },
        component: () => import('../views/shops/statistics/Index.vue'),
      },
      {
        path: 'shops/:shopId/logs',
        name: 'shop_logs',
        meta: { requiresAuth: true, entityRoute: API.shops.readMin },
        component: () => import('../views/shops/logs/Index.vue'),
      },
      {
        path: 'shops/:shopId/subscriptions',
        name: 'shop_subscriptions',
        meta: { requiresAuth: true, entityRoute: API.shops.readMin },
        component: () => import('../views/shops/subscriptions/Index.vue'),
      },
      /*
       *   Employees
       */
      {
        path: 'employees/',
        name: 'employees',
        meta: { requiresAuth: true },
        component: () => import('../views/employees/Main.vue'),
      },
      {
        path: 'employees/add',
        name: 'employee_add',
        meta: { requiresAuth: true },
        component: () => import('../views/employees/Add.vue'),
      },
      {
        path: 'employees/:employeeId',
        name: 'employee_edit',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/Edit.vue'),
      },
      {
        path: 'employees/:employeeId/points',
        name: 'employee_points',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/points/Index.vue'),
      },
      {
        path: 'employees/:employeeId/schedule',
        name: 'employees_schedule',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/schedule/Index.vue'),
      },
      {
        path: 'employees/:employeeId/logs',
        name: 'employee_logs',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/logs/Index.vue'),
      },
      {
        path: 'employees/:employeeId/relations',
        name: 'employee_relations',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/relations/Index.vue'),
      },
      {
        path: 'employees/:employeeId/ratings',
        name: 'employee_ratings',
        meta: { requiresAuth: true, requiresNumericParams: ['employeeId'], entityRoute: API.employees.read },
        component: () => import('../views/employees/ratings/Index.vue'),
      },
      /*
       *   Customers
       */
      {
        path: 'customers/',
        name: 'customers',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/Main.vue'),
      },
      {
        path: 'customers/add',
        name: 'customer_add',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/Add.vue'),
      },
      {
        path: 'customers/:customerId',
        name: 'customer_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/Edit.vue'),
      },
      {
        path: 'customers/:customerId/shops',
        name: 'customers_shops',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/shops/Index.vue'),
      },
      {
        path: 'customers/:customerId/shops/add',
        name: 'customers_shop_add',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/shops/Add.vue'),
      },
      {
        path: 'customers/:customerId/templates',
        name: 'customers_vacancies',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/templates/Index.vue'),
      },
      {
        path: 'customers/:customerId/templates/add',
        name: 'template_add',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/templates/Add.vue'),
      },
      {
        path: 'customers/:customerId/templates/:templateId',
        name: 'template_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/templates/Edit.vue'),
      },
      {
        path: 'customers/:customerId/helpers',
        name: 'customers_helpers',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/helpers/Index.vue'),
      },
      {
        path: 'customers/:customerId/helpers/add',
        name: 'helper_add',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/helpers/Add.vue'),
      },
      {
        path: 'customers/:customerId/helpers/:helperId',
        name: 'helper_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/helpers/Edit.vue'),
      },
      {
        path: 'customers/:customerId/logs',
        name: 'customers_logs',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/logs/Index.vue'),
      },
      {
        path: 'customers/:customerId/statistics',
        name: 'customers_statistics',
        meta: { requiresAuth: true },
        component: () => import('../views/customers/statistics/Index.vue'),
      },
      /*
       *   Settings
       */
      {
        path: 'settings/regions',
        name: 'regions',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/regions/Main.vue'),
      },
      {
        path: 'settings/workMonths',
        name: 'workMonths',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/workMonths/Main.vue'),
      },
      {
        path: 'settings/competence',
        name: 'competence',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/competence/Main.vue'),
      },
      {
        path: 'settings/groups',
        name: 'groups',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/groups/Main.vue'),
      },
      {
        path: 'settings/groups/add',
        name: 'group_add',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/groups/Add.vue'),
      },
      {
        path: 'settings/groups/:id',
        name: 'group_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/groups/Edit.vue'),
      },
      {
        path: 'settings/users',
        name: 'users',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/users/Main.vue'),
      },
      {
        path: 'settings/users/add',
        name: 'user_add',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/users/Add.vue'),
      },
      {
        path: 'settings/users/:id',
        name: 'user_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/users/Edit.vue'),
      },
      {
        path: 'settings/split',
        name: 'split',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/split/Main.vue'),
      },
      {
        path: 'settings/notifications',
        name: 'notifications',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/notifications/Main.vue'),
      },
      {
        path: 'settings/training_center',
        name: 'training_center',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/trainingCenter/Main.vue'),
      },
      {
        path: 'settings/training_center/add',
        name: 'training_center_add',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/trainingCenter/Add.vue'),
      },
      {
        path: 'settings/training_center/:trainingCenterId',
        name: 'training_center_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/trainingCenter/Edit.vue'),
      },
      {
        path: 'settings/mailings/',
        name: 'mailings',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/mailings/Main.vue'),
      },
      {
        path: 'settings/mailings/add',
        name: 'mailings_add',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/mailings/Edit.vue'),
      },
      {
        path: 'settings/mailings/:id',
        name: 'mailings_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/mailings/Edit.vue'),
      },
      {
        path: 'settings/bonus/',
        name: 'bonus',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/bonus/Main.vue'),
      },
      {
        path: 'settings/technical/',
        name: 'technical',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/technical/Main.vue'),
      },
      {
        path: 'settings/quizzes/',
        name: 'quizzes',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/quizzes/Main.vue'),
      },
      {
        path: 'settings/labor-relations/',
        name: 'laborRelations',
        meta: { requiresAuth: true },
        component: () => import('../views/settings/laborRelations/Main.vue'),
      },
      /*
       *   Tasks
       */
      {
        path: 'tasks/outputs',
        name: 'outputs',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/outputs/Main.vue'),
      },
      {
        path: 'tasks/free_market_shifts',
        name: 'free_market_shifts',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/freeMarketShifts/Main.vue'),
      },
      {
        path: 'tasks/free_market_shifts/:shiftId',
        name: 'free_market_shifts_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/freeMarketShifts/Edit.vue'),
      },
      {
        path: 'tasks/found_employees',
        name: 'found_employees',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/foundEmployees/Main.vue'),
      },
      {
        path: 'tasks/first_shift',
        name: 'first_shift',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/firstShift/Main.vue'),
      },
      {
        path: 'tasks/beginners',
        name: 'beginners',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/beginners/Main.vue'),
      },
      {
        path: 'tasks/reactivation/',
        name: 'reactivation',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/reactivation/Main.vue'),
      },
      {
        path: 'tasks/revise',
        name: 'revise',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/revise/Index.vue'),
      },
      {
        path: 'tasks/revise/:shopId',
        name: 'revise_shop',
        meta: { requiresAuth: true },
        component: () => import('../views/tasks/revise/Main.vue'),
      },
      /*
       *   Finance
       */
      {
        path: 'finance/date_brief',
        name: 'date_brief',
        meta: { requiresAuth: true },
        component: () => import('../views/finance/dateBrief/Main.vue'),
      },
      {
        path: 'finance/payouts',
        name: 'payouts',
        meta: { requiresAuth: true },
        component: () => import('../views/finance/payouts/Main.vue'),
      },
      {
        path: 'finance/payments',
        name: 'payments',
        meta: { requiresAuth: true },
        component: () => import('../views/finance/payments/Main.vue'),
      },
      {
        path: 'finance/customer',
        name: 'finance_customer',
        meta: { requiresAuth: true },
        component: () => import('../views/finance/customer/Main.vue'),
      },
      {
        path: 'finance/employee',
        name: 'finance_employee',
        meta: { requiresAuth: true, permission: 'CAN_READ_EMPLOYEE_FINANCE' },
        component: () => import('../views/finance/employeeFinance/Main.vue'),
      },
      {
        path: 'finance/periods',
        name: 'finance_periods',
        meta: { requiresAuth: true, permission: 'CAN_READ_MOBILE_FINANCE_PERIOD' },
        component: () => import('../views/finance/periods/Main.vue'),
      },
      {
        path: 'finance/periods/:id',
        name: 'finance_periods_view',
        meta: { requiresAuth: true },
        component: () => import('../views/finance/periods/View.vue'),
      },
      /*
       *   Cards
       */
      {
        path: 'cards/rating',
        name: 'cards_rating',
        meta: { requiresAuth: true },
        component: () => import('../views/cards/rating/Index.vue'),
      },
      {
        path: 'cards/database',
        name: 'cards_database',
        meta: { requiresAuth: true },
        component: () => import('../views/cards/database/Index.vue'),
      },
      {
        path: 'cards/database/add',
        name: 'cards_database_add',
        meta: { requiresAuth: true, permission: 'CAN_EDIT_YELLOW_CARD' },
        component: () => import('../views/cards/database/Add/Index.vue'),
      },
      {
        path: 'cards/database/edit/:cardId',
        name: 'cards_database_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/cards/database/Add/Index.vue'),
      },
      /*
       *   Analytics
       */
      {
        path: 'analytics/by_customers',
        name: 'analytics_by_customers',
        meta: { requiresAuth: true },
        component: () => import('../views/analytics/byCustomers/Index.vue'),
      },
      {
        path: 'analytics/by_employees',
        name: 'analytics_by_employees',
        meta: { requiresAuth: true },
        component: () => import('../views/analytics/byEmployees/Index.vue'),
      },
      /*
       *   Partners
       */
      {
        path: 'partners/',
        name: 'partners',
        meta: { requiresAuth: true },
        component: () => import('../views/partners/Main.vue'),
      },
      {
        path: 'partners/add',
        name: 'partner_add',
        meta: { requiresAuth: true },
        component: () => import('../views/partners/Add.vue'),
      },
      {
        path: 'partners/:partnerId',
        name: 'partner_edit',
        meta: { requiresAuth: true },
        component: () => import('../views/partners/Edit.vue'),
      },
      {
        path: 'partners/:partnerId/shops',
        name: 'partner_shops',
        meta: { requiresAuth: true },
        component: () => import('../views/partners/shops/Index.vue'),
      },
      {
        path: 'partners/:partnerId/logs',
        name: 'partner_logs',
        meta: { requiresAuth: true },
        component: () => import('../views/partners/logs/Index.vue'),
      },
      /*
       *   404
       */
      {
        path: '/:catchAll(.*)*',
        name: 'not_found',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../views/PageNotFound.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

export const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes,
});

router.beforeEach((to, from, next) => {
  if (from.name) {
    sessionStorage.setItem('previousRoute', from.name.toString());
  }
  if (to.name) {
    sessionStorage.setItem('nextRoute', to.name.toString());
  }
  next();
});

router.beforeEach(authGuard);
router.beforeEach(accessControl);
router.beforeEach(blockGuard);
router.beforeEach(numericParamsGuard);
router.beforeEach(shopGuard);
router.beforeEach(regionGuard);
router.beforeEach(competenceGuard);
router.beforeEach(employeeGuard);
router.beforeEach(customerGuard);
router.beforeEach(userGuard);
router.beforeEach(cardsGuard);
router.beforeEach(splitGuard);
router.beforeEach(trainingCenterGuard);
router.beforeEach(mailingGuard);
router.beforeEach(permissionGuard);

export default router;
