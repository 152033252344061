/**
 * Global API for citizenship.
 *
 */

import API from '@/config/api-routers';
import { makeGetRequest } from '@/api/requests';

/**
 * Get all citizenship items
 */
export const getCitizenshipsAll = async () => {
  const url = API.citizenship.getAll;
  const result = await makeGetRequest<{ result: { citizenships: { id: number; name: string; sortOrder: number }[] } }>(url);

  return result.data.result.citizenships;
};
