export const strings = {
  SUCCESS_UPDATE: 'Изменения сохранены',
  SUCCESS_DELETE: 'Успешно удален',
  NOT_FOUND: 'Страница не найдена',
  UNKNOWN_ERROR: 'Неопознанная ошибка',
  btnSave: 'Сохранить',
  btnCreate: 'Создать',
  btnAdd: 'Добавить',
  btnOk: 'Подтвердить',
  btnApply: 'Применить',
  btnCancel: 'Отменить',
  btnDelete: 'Удалить',
  taskToday: 'Задачи на сегодня',
  autoSet: 'A',
  export: 'Выгрузить',

  errorRequired: 'Не заполнено поле',
  errorMaxLength: 'Превышено количество символов',
  errorAccessDenied: 'Доступ запрещён',
};
