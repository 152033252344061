/*
 *
 * */

import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';
import { phoneMask } from '@/lib/layouts/page/filterMasks';

export default class ShopsFilter {
  filterHandlers = {
    update: 'shopsFilterModule/updateFilter',
    reset: 'shopsFilterModule/resetFilter',
    resetState: 'shopsFilterModule/resetFilterState',
    removeSelected: 'shopsFilterModule/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullMarketName');
    filter.setPlaceholder('fullMarketName', 'По объекту');
    filter.setName('fullMarketName', 'search_fullMarketName');
    filter.setPreviewName('fullMarketName', 'Объект');
    filter.setClassName('fullMarketName', 'col-1-row-1');
    filter.setIconClassName('fullMarketName', 'icon-store');
    filter.setAction('fullMarketName', 'shopsFilterModule/updateSearch');

    filter.createSelectEntity('client');
    filter.setTitle('client', 'По клиенту');
    filter.setName('client', 'select_client');
    filter.setPreviewName('client', 'Клиент');
    filter.setAction('client', 'shops/updateClients');
    filter.setInitAction('client', 'shops/initClientsSelect');
    filter.setClassName('client', 'col-2-row-1');
    filter.setIconClassName('client', 'icon-clients');
    filter.setClearAction('client', 'shopsFilterModule/resetSelect');
    filter.setSearchEnabled('client', true);

    filter.createSearchEntity('marketPhones');
    filter.setPlaceholder('marketPhones', 'По телефону');
    filter.setName('marketPhones', 'search_market_phones');
    filter.setPreviewName('marketPhones', 'Тел');
    filter.setClassName('marketPhones', 'col-1-row-2 phone_mask');
    filter.setIconClassName('marketPhones', 'icon-phone');
    filter.setAction('marketPhones', 'shopsFilterModule/updateSearch');
    filter.setMask('marketPhones', phoneMask);

    filter.createSelectEntity('region');
    filter.setTitle('region', 'По региону');
    filter.setName('region', 'select_region');
    filter.setPreviewName('region', 'Регион');
    filter.setInitAction('region', 'shops/initRegionsSelect');
    filter.setAction('region', 'shopsFilterModule/updateSelect');
    filter.setClassName('region', 'col-2-row-2');
    filter.setIconClassName('region', 'icon-compass');
    filter.setClearAction('region', 'shopsFilterModule/resetSelect');
    filter.setSearchEnabled('region', true);

    filter.createSearchEntity('comment');
    filter.setPlaceholder('comment', 'По комментарию');
    filter.setName('comment', 'search_comment');
    filter.setPreviewName('comment', 'Комментарий');
    filter.setClassName('comment', 'col-1-row-3');
    filter.setIconClassName('comment', 'icon-comment-second');
    filter.setAction('comment', 'shopsFilterModule/updateSearch');

    filter.createSelectEntity('assistant');
    filter.setTitle('assistant', 'По Территориальному помощнику');
    filter.setName('assistant', 'select_helper');
    filter.setPreviewName('assistant', 'Ассистент');
    filter.setInitAction('assistant', 'shops/initAssistantsSelect');
    filter.setClassName('assistant', 'col-1-row-4');
    filter.setIconClassName('assistant', 'icon-user');
    filter.setAction('assistant', 'shopsFilterModule/updateSelect');
    filter.setClearAction('assistant', 'shopsFilterModule/resetSelect');
    filter.setSearchEnabled('assistant', true);

    filter.createMultiSelectEntity('serviceType');
    filter.setTitle('serviceType', 'По типу услуги');
    filter.setName('serviceType', 'multi_select_service_type');
    filter.setPreviewName('serviceType', 'Тип услуги');
    filter.setInitAction('serviceType', 'shops/initServiceTypes');
    filter.setClassName('serviceType', 'col-2-row-3');
    filter.setIconClassName('serviceType', 'icon-position');
    filter.setAction('serviceType', 'shopsFilterModule/updateMultiSelect');
    filter.setSearchEnabled('serviceType', true);

    filter.createMultiSelectEntity('partners');
    filter.setTitle('partners', 'По партнёру');
    filter.setName('partners', 'multi_select_partners');
    filter.setPreviewName('partners', 'Партнёр');
    filter.setInitAction('partners', 'shops/initPartnersSelect');
    filter.setIconClassName('partners', 'icon-partners');
    filter.setAction('partners', 'shopsFilterModule/updateMultiSelect');
    filter.setClassName('partners', 'col-2-row-4');
    filter.setSearchEnabled('partners', true);

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'shopsFilterModule/updateCheckbox');
    filter.setClassName('isNotTest', 'col-1-row-5');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    filter.createCheckboxEntity('regularNeed');
    filter.setTitle('regularNeed', 'Рег. потребность');
    filter.setName('regularNeed', 'checkbox_regularNeed');
    filter.setPreviewName('regularNeed', 'Рег. потребность');
    filter.setIgnoreNull('regularNeed', true);
    filter.setListElement('regularNeed', {
      id: 'regularNeed',
      name: 'Рег. потребность',
      checked: false,
    });
    filter.setAction('regularNeed', 'shopsFilterModule/updateCheckbox');
    filter.setClassName('regularNeed', 'col-1-row-5 cp-filter__checkbox-flex-end cp-margin__r--39');
    filter.setIconClassName('regularNeed', 'icon-is-active');

    filter.createCheckboxEntity('overNeed');
    filter.setTitle('overNeed', 'Перенайм');
    filter.setName('overNeed', 'checkbox_overNeed');
    filter.setPreviewName('overNeed', 'Перенайм');
    filter.setIgnoreNull('overNeed', true);
    filter.setListElement('overNeed', {
      id: 'overNeed',
      name: 'Перенайм',
      checked: false,
    });
    filter.setAction('overNeed', 'shopsFilterModule/updateCheckbox');
    filter.setClassName('overNeed', 'col-2-row-5 cp-margin__r--39');
    filter.setIconClassName('overNeed', 'icon-is-active');

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setPreviewName('date', 'Заказ');
    filter.setTitle('date', 'Посл. заказ');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'lastShiftDayFrom',
        type: 'date-picker',
        title: 'С даты',
        placeholder: '',
        value: 'Выбрать',
        action: 'shopsFilterModule/updateStartDate',
        clearAction: 'shopsFilterModule/clearStartDate',
      },
      endDate: {
        key: 'lastShiftDayTo',
        type: 'date-picker',
        title: 'До даты',
        placeholder: '',
        value: 'Выбрать',
        action: 'shopsFilterModule/updateEndDate',
        clearAction: 'shopsFilterModule/clearEndDate',
      },
    });
    filter.setClassName('date', 'col-1-row-7');
    filter.setIconClassName('date', 'icon-clock');

    filter.createRadioEntity('isActive');
    filter.setName('isActive', 'radio_is_active');
    filter.setPreviewName('isActive', 'Акт');
    filter.setList('isActive', [
      { id: 0, name: 'Активность', checked: false, value: 'true' },
      { id: 1, name: '', checked: false },
      { id: 2, name: 'Неактивность', checked: false, value: 'false' },
    ]);
    filter.setClassName('isActive', 'col-2-row-7');
    filter.setAction('isActive', 'shopsFilterModule/updateRadio');
    filter.setIconClassName('isActive', 'icon-is-active');

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partners', !isSupervisor);
        filter.setIsHidden('assistant', !isSupervisor);
      },
      { immediate: true }
    );

    this.filterModel = filter.filterModel;
  }
}
