/**
 * filter entity for state
 */

import { Mutation, Action, VuexModule } from 'vuex-module-decorators';
import {
  Filter,
  FilterEntityModel,
  FilterModel,
  FilterSettings,
  SearchRangeList,
} from '@/lib/layouts/page/filter.interface';
import { convertObjectToQueryParams, isAfter, isBefore, isDateStartOverDateEnd } from '@/lib/Utils';
import moment from 'moment';
import { Checkbox } from '@/interfaces/filter.interface';
import { CheckboxItem } from '@/lib/formFactory/checkbox.interface';
import { RadioIcon, RadioItem } from '@/lib/formFactory/radio.interface';
import {
  filterTimestampPickerInterface,
  TimestampPickerEntityList,
  TimestampPickerEntityListItem,
} from '@/lib/layouts/page/filterTimestamPicker.interface';
import {
  DatePickerEntityList,
  DatePickerEntityListItem,
  filterDatePickerInterface,
} from '@/lib/layouts/page/filterDatePicker.interface';
import {
  filterTimeRangePickerInterface,
  TimeRangePickerEntityList,
  TimeRangePickerEntityListItem,
} from '@/lib/layouts/page/filterTimePicker.interface';
import { filterCheckboxInterface } from '@/lib/layouts/page/filterCheckbox.interface';
import { filterSelectInterface } from '@/lib/layouts/page/filterSelect.interface';
import { filterSearchInterface } from '@/lib/layouts/page/filterSearch.interface';
import { filterMultiSelectInterface } from '@/lib/layouts/page/filterMultiSelect.interface';
import { filterAutoCompleteInterface } from '@/lib/layouts/page/filterAutoComplete.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

export type filterEntityGeneral =
  | filterCheckboxInterface
  | filterDatePickerInterface
  | filterSelectInterface
  | filterSearchInterface
  | filterMultiSelectInterface
  | filterAutoCompleteInterface
  | filterTimeRangePickerInterface
  | filterTimestampPickerInterface;

export const MODULE_NAME = 'filterModule';

export interface FilterPreview {
  [key: string]: string | boolean | undefined;
  name?: string;
  iterable?: boolean;
  iconClass?: string;
}

export interface PreparedFilter {
  string: string;
  preview: Record<string, FilterPreview>;
}

export default abstract class FilterModule extends VuexModule implements Filter {
  self = this;
  filterSettings: FilterSettings;
  index: number;
  defaultIndex = 2;

  constructor(module: FilterModule) {
    super(module);
    this.filterSettings = {
      name: '',
      getListAction: '',
      pageCurrent: 1,
      sort: {},
      filter: '',
      filterAdditional: '',
      filterPreview: {},
      filterModel: {},
      filterValues: {},
      filterHandlers: {
        update: '',
        reset: '',
        resetState: '',
        removeSelected: '',
      },
      templateClass: '',
      btnClass: 'col-1-row-5',
    };
    this.index = 2;
  }

  @Mutation
  SET_FILTER_NAME(name: string): void {
    this.filterSettings.name = name;
  }

  @Mutation
  SET_GET_LIST_ACTION(action: string): void {
    this.filterSettings.getListAction = action;
  }

  @Mutation
  SET_TEMPLATE_CLASS_NAME(className: string | string[]): void {
    this.filterSettings.templateClass = className;
  }

  @Mutation
  SET_BTN_CLASS_NAME(className: string): void {
    this.filterSettings.btnClass = className;
  }

  @Mutation
  SET_FILTER(filter: string): void {
    this.filterSettings.filter = filter;
    window.localStorage[`${this.filterSettings.name}String`] = JSON.stringify(filter);
  }

  @Mutation
  SET_FILTER_PREVIEW(filterPreview: Record<string, FilterPreview>): void {
    this.filterSettings.filterPreview = filterPreview;
    window.localStorage[`${this.filterSettings.name}Preview`] = JSON.stringify(filterPreview);
  }

  @Mutation
  SET_MODEL_PREVIEW_NAME(params: { key: string; previewName: string }): void {
    this.filterSettings.filterModel[params.key].previewName = params.previewName;
  }

  @Mutation
  DEL_FILTER_PREVIEW(filterName: string): void {
    delete this.filterSettings.filterPreview[filterName];
    window.localStorage[`${this.filterSettings.name}Preview`] = JSON.stringify(this.filterSettings.filterPreview);
  }

  @Mutation
  SET_PAGE(number: number): void {
    this.filterSettings.pageCurrent = number;
  }

  @Mutation
  SET_FILTER_MODEL(filterModel: {}): void {
    this.filterSettings.filterModel = filterModel;
  }

  @Mutation
  SET_FILTER_HANDLERS(filterHandlers: { update: string; reset: string; resetState: string; removeSelected: string }): void {
    this.filterSettings.filterHandlers = filterHandlers;
  }

  @Mutation
  SET_FILTER_INDEX(index: number): void {
    this.index = index;
  }

  @Mutation
  SET_FILTER_DEFAULT_INDEX(index: number): void {
    this.defaultIndex = index;
  }

  @Mutation
  SET_SEARCH(params: { key: string; value: string }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    this.filterSettings.filterModel[params.key].value = params.value;
  }

  @Mutation
  SET_SELECT_LIST(params: { key: string; list: Checkbox[] | undefined }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    this.filterSettings.filterModel[params.key].list = params.list;
  }

  @Mutation
  SET_SELECT(params: { key: string; value: { id: number | string; value: string } }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    this.filterSettings.filterModel[params.key].current = params.value;
  }

  @Mutation
  SET_AUTO_COMPLETE_LIST(params: { key: string; list: Checkbox[] | undefined }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    this.filterSettings.filterModel[params.key].list = params.list;
  }

  @Mutation
  SET_AUTO_COMPLETE(params: { key: string; value: { id: number | string; value: string } }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    this.filterSettings.filterModel[params.key].current = params.value;
  }

  @Mutation
  SET_MULTI_SELECT_LIST(params: { key: string; list: Checkbox[] | undefined }): void {
    this.filterSettings.filterModel[params.key].list = params.list;

    this.filterSettings.filterModel[params.key].value = (this.filterSettings.filterModel[params.key].list as Checkbox[])
      .filter((item) => item.checked)
      .map((item) => item.name)
      .join(', ');
  }

  @Mutation
  SET_MULTI_SELECT(params: { key: string; value: Checkbox }): void {
    const checkboxList = this.filterSettings.filterModel[params.key].list as Checkbox[];
    const changedCheckbox = checkboxList.find((checkbox) => checkbox.id === params.value.id);

    if (!changedCheckbox) {
      return;
    }

    changedCheckbox.checked = !changedCheckbox.checked;

    const checkedItems = checkboxList.filter((item) => item.checked);
    this.filterSettings.filterModel[params.key].checkedItems = checkedItems;
    this.filterSettings.filterModel[params.key].value = checkedItems.map((item) => item.name).join(', ');
  }

  @Mutation
  RESET_MULTI_SELECT(key: string): void {
    if (!this.filterSettings.filterModel[key]) {
      return;
    }
    this.filterSettings.filterModel[key].value = '';
    (this.filterSettings.filterModel[key].list as Checkbox[]).forEach((item: Checkbox) => {
      item.checked = false;
    });
  }

  @Mutation
  SET_CHECKBOX_LIST(params: { key: string; list: Checkbox[] | undefined }): void {
    this.filterSettings.filterModel[params.key].list = params.list;
  }

  @Mutation
  SET_CHECKBOX(params: { key: string; checkbox: Checkbox }): void {
    (this.filterSettings.filterModel[params.key].list as CheckboxItem[]).forEach((item: CheckboxItem) => {
      if (item.id == params.checkbox.id) {
        item.checked = (params.checkbox as any).bool;
      }

      return item;
    });
  }

  @Mutation
  RESET_CHECKBOX(key: string): void {
    (this.filterSettings.filterModel[key].list as CheckboxItem[]).forEach((item: CheckboxItem) => {
      item.checked = false;

      return item;
    });
  }

  @Mutation
  SET_RADIO(params: { key: string; value: number }): void {
    if (params.value === 1) {
      (this.filterSettings.filterModel[params.key].list as Checkbox[]).forEach((item: Checkbox) => {
        item.checked = false;
      });
      this.filterSettings.filterModel[params.key].value = null;

      return;
    }

    const dict: { [key: number]: boolean | null } = {
      0: true,
      1: null,
      2: false,
    };

    (this.filterSettings.filterModel[params.key].list as Checkbox[]).forEach((item: Checkbox) => {
      if (item.id === params.value) {
        item.checked = true;
        this.filterSettings.filterModel[params.key].value = item.value ? (item.value as number).toString() : dict[item.id];

        return;
      }
      item.checked = false;
    });
  }

  @Mutation
  SET_RADIO_ICON_CLASS(params: { key: string; className: string }): void {
    this.filterSettings.filterModel[params.key].iconClass = params.className;
  }

  @Mutation
  SET_SELECT_ICON_PREVIEW_CLASS(params: { key: string; className: string }): void {
    this.filterSettings.filterModel[params.key].iconPreviewClass = params.className;
  }

  @Mutation
  SET_FILTER_DATE(params: { key: string; dateKey: string; date: string }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }
    (this.filterSettings.filterModel[params.key].list as DatePickerEntityList)[params.dateKey].value = params.date;
  }

  @Mutation
  SET_FILTER_TIME_RANGE(params: {
    key: string;
    time: { from: TimeRangePickerEntityListItem; to: TimeRangePickerEntityListItem; value?: string };
  }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }

    const item = this.filterSettings.filterModel[params.key];
    const { from, to } = item.list as TimeRangePickerEntityList;

    from.hour = params.time.from.hour;
    from.minute = params.time.from.minute;
    from.value = params.time.from.value;

    to.hour = params.time.to.hour;
    to.minute = params.time.to.minute;
    to.value = params.time.to.value;

    item.value = params.time.value;
  }

  @Mutation
  SET_FILTER_TIMESTAMP(params: { key: string; timestampKey: string; timestamp: TimestampPickerEntityListItem }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }

    const item = (this.filterSettings.filterModel[params.key].list as TimestampPickerEntityList)[params.timestampKey];

    (item as TimestampPickerEntityListItem).minute = params.timestamp.minute;
    (item as TimestampPickerEntityListItem).hour = params.timestamp.hour;
    (item as TimestampPickerEntityListItem).time = params.timestamp.time;
    (item as TimestampPickerEntityListItem).date = params.timestamp.date;
  }

  @Mutation
  SET_SEARCH_RANGE(params: { key: string; startValue: string; endValue: string; disabled: boolean }): void {
    if (!this.filterSettings.filterModel[params.key]) {
      return;
    }

    (this.filterSettings.filterModel[params.key].list as SearchRangeList).startValue.value = params.startValue;
    (this.filterSettings.filterModel[params.key].list as SearchRangeList).endValue.value = params.endValue;
    this.filterSettings.filterModel[params.key].disabled = params.disabled;
  }

  @Mutation
  SET_DISABLED(params: { key: string; value: boolean }): void {
    this.filterSettings.filterModel[params.key].disabled = params.value;
  }

  @Action({ rawError: true })
  getFilterSettings(): FilterSettings {
    return this.filterSettings;
  }

  @Action({ rawError: true })
  setFilterModel(filterModel: {}): void {
    this.self.SET_FILTER_MODEL(filterModel);
  }

  @Action({ rawError: true })
  setFilterHandlers(filterHandlers: { update: string; reset: string; resetState: string; removeSelected: string }): void {
    this.self.SET_FILTER_HANDLERS(filterHandlers);
  }

  @Action({ rawError: true })
  setFilterName(filterName: string): void {
    this.self.SET_FILTER_NAME(filterName);
  }

  @Action({ rawError: true })
  setTemplateClassName(className: string | string[]): void {
    this.self.SET_TEMPLATE_CLASS_NAME(className);
  }

  @Action({ rawError: true })
  setBtnClassName(className: string): void {
    this.self.SET_BTN_CLASS_NAME(className);
  }

  /*
  search
   */
  @Action({ rawError: true })
  async updateSearch(params: { key: string; value: string }): Promise<void> {
    if (~params.key.indexOf('phone') || ~params.key.indexOf('Phone')) {
      params.value = params.value.replace(/[(\\+\-)\s]/g, '');
      params.value = params.value.length === 11 ? params.value.substr(1) : params.value;
    }

    await this.self.SET_SEARCH(params);
    await this.self.saveValueToLocalStorage(params);
  }

  @Action({ rawError: true })
  async resetSearch(key: string): Promise<void> {
    const result = { key, value: '' };
    await this.self.SET_SEARCH(result);
    await this.self.saveValueToLocalStorage(result);
  }

  @Action({ rawError: true })
  async resetSearchState(key: string): Promise<void> {
    await this.self.SET_SEARCH({ key, value: '' });
  }

  /*
  select
   */
  @Action({ rawError: true })
  updateSelectList(params: { key: string; list: Checkbox[] }): void {
    this.self.SET_SELECT_LIST({ key: params.key, list: params.list });
  }

  @Action({ rawError: true })
  async updateSelect(params: { key: string; value: { id: number | string; value: string } }): Promise<void> {
    const selectModel = this.filterSettings.filterModel[params.key];
    const firstItem = (selectModel?.list as Checkbox[])?.[0];

    if (!params.value.id && selectModel?.isDefaultFirstItem && firstItem) {
      params.value = (firstItem as unknown) as SelectItem;
    }

    this.self.SET_SELECT(params);
    await this.self.saveValueToLocalStorage(params);
  }

  @Action({ rawError: true })
  async resetSelect(key: string): Promise<void> {
    await this.self.updateSelect({ key: key, value: { id: 0, value: '' } });
  }

  @Action({ rawError: true })
  resetSelectState(key: string): void {
    this.self.SET_SELECT({ key, value: { id: 0, value: '' } });
  }

  @Action({ rawError: true })
  async resetSelectAndUpdate(params: string): Promise<void> {
    await this.self.resetSelect(params);
    await this.self.updateFilter();
  }

  @Action({ rawError: true })
  async updateMultiSelectList(params: { key: string; list: Checkbox[] }): Promise<void> {
    let newList: Checkbox[] = params.list;
    const oldList: Checkbox[] = this.filterSettings.filterModel[params.key].list as Checkbox[];
    const prevValues: number[] = oldList.filter((item) => item.checked).map((item) => item.id);

    newList = newList.map((item) => {
      if (prevValues.includes(item.id)) {
        item.checked = true;
      }

      return item;
    });

    await this.self.SET_MULTI_SELECT_LIST({ key: params.key, list: newList });
  }

  @Action({ rawError: true })
  async updateMultiSelect(params: { key: string; value: Checkbox }): Promise<void> {
    await this.self.SET_MULTI_SELECT(params);
    const currentValue = (this.filterSettings.filterModel[params.key].list as Checkbox[])
      .filter((item) => item.checked)
      .map((item) => item.id)
      .join(', ');
    await this.self.saveValueToLocalStorage({ key: params.key, value: currentValue as string });
  }

  /**
   * метод задает значения мультиселекту до его инициализации
   * (используется в Аналитике по исполнителям для перехода в Исполнители с выбранным фильтром)
   */
  @Action({ rawError: true })
  async updateMultiSelectBeforeInit(params: { key: string; value: { id: number; value: string } }): Promise<void> {
    this.filterSettings.filterModel[params.key].value = params.value.value;
    await this.self.saveValueToLocalStorage({ key: params.key, value: params.value.value });
  }

  @Action({ rawError: true })
  async resetMultiSelect(key: string): Promise<void> {
    this.self.RESET_MULTI_SELECT(key);
    await this.self.saveValueToLocalStorage({ key: key, value: '' });
  }

  @Action({ rawError: true })
  clearSelect(key: string): void {
    this.self.SET_SELECT({ key: key, value: { id: 0, value: '' } });
  }

  /*
  checkbox
   */
  @Action({ rawError: true })
  async updateCheckbox(params: { key: string; checkbox: Checkbox }): Promise<void> {
    if (params.checkbox === null) {
      await this.self.RESET_CHECKBOX(params.key);

      return;
    }
    await this.self.SET_CHECKBOX(params);
    await this.self.saveValueToLocalStorage({
      key: params?.key,
      value: this.filterSettings.filterModel[params.key].list as Checkbox[],
    });
  }

  @Action({ rawError: true })
  async updateCheckboxList(params: { key: string; list: Checkbox[] }): Promise<void> {
    await this.self.SET_CHECKBOX_LIST(params);
    await this.self.saveValueToLocalStorage({
      key: params.key,
      value: this.filterSettings.filterModel[params.key].list as Checkbox[],
    });
  }

  @Action({ rawError: true })
  async resetCheckboxList(key: string): Promise<void> {
    const list = (this.filterSettings.filterModel[key].list as Checkbox[]).map((item: Checkbox) => {
      item.checked = false;

      return item;
    });
    await this.self.SET_CHECKBOX_LIST({ key, list });
    await this.self.saveValueToLocalStorage({
      key: key,
      value: this.filterSettings.filterModel[key].list as Checkbox[],
    });
  }

  @Action({ rawError: true })
  async resetCheckboxListState(key: string): Promise<void> {
    const list = (this.filterSettings.filterModel[key].list as Checkbox[]).map((item: Checkbox) => {
      item.checked = false;

      return item;
    });
    await this.self.SET_CHECKBOX_LIST({ key, list });
  }

  /*
  radio
   */
  @Action({ rawError: true })
  async updateRadio(params: { key: string; value: string }): Promise<void> {
    const paramsProcessed = { key: params.key, value: parseInt(params.value) };
    await this.self.SET_RADIO(paramsProcessed);

    const currentValues: RadioItem[] = Object.values(this.filterSettings.filterModel[params.key].list as RadioItem[]).filter(
      (item: RadioItem) => item.id === parseInt(params.value)
    );
    const currentValue: RadioItem | {} = currentValues && Object.values(currentValues).length > 0 ? currentValues[0] : {};

    let iconClass = '';
    if (parseInt(params.value) === 0 && !(currentValue as RadioItem).icon) {
      iconClass = 'icon-is-active';
    }
    if (parseInt(params.value) === 2 && !(currentValue as RadioItem).icon) {
      iconClass = 'icon-is-inactive';
    }
    iconClass =
      currentValue && (currentValue as RadioItem).icon ? ((currentValue as RadioItem).icon as RadioIcon)[0] : iconClass;
    this.self.SET_RADIO_ICON_CLASS({ key: params.key, className: iconClass });

    if ((currentValue as RadioItem).preview) {
      this.self.SET_MODEL_PREVIEW_NAME({ key: params.key, previewName: (currentValue as RadioItem).preview as string });
    }

    await this.self.saveValueToLocalStorage(paramsProcessed);
  }

  @Action({ rawError: true })
  resetRadio(key: string): void {
    const params = { key, value: 1 };
    this.self.SET_RADIO(params);
    this.self.SET_RADIO_ICON_CLASS({ key, className: '' });

    this.self.saveValueToLocalStorage(params);
  }

  @Action({ rawError: true })
  resetRadioState(key: string): void {
    const params = { key, value: 1 };
    this.self.SET_RADIO(params);
    this.self.SET_RADIO_ICON_CLASS({ key, className: '' });
  }

  /*
  autocomplete
   */
  @Action({ rawError: true })
  updateAutoCompleteList(params: { key: string; list: [] }): void {
    this.self.SET_AUTO_COMPLETE_LIST({ key: params.key, list: params.list });
  }

  @Action({ rawError: true })
  async updateAutoComplete(params: { key: string; value: { id: number | string; value: string } }): Promise<void> {
    this.self.SET_AUTO_COMPLETE(params);
    await this.self.saveValueToLocalStorage(params);
  }

  @Action({ rawError: true })
  async resetAutoComplete(key: string): Promise<void> {
    this.self.updateAutoComplete({ key: key, value: { id: 0, value: '' } });
  }

  @Action({ rawError: true })
  resetAutoCompleteState(key: string): void {
    this.self.SET_AUTO_COMPLETE({ key, value: { id: 0, value: '' } });
  }

  // localStorage routine
  @Action({ rawError: true })
  getLocalStorageName(key: string): string {
    return `${this.filterSettings.name}${key[0].toUpperCase()}${key.slice(1)}`;
  }

  @Action({ rawError: true })
  getLocalStorageValue(key: string): string | null {
    const storageKey = this.self.getLocalStorageName(key);

    return window.localStorage.getItem(storageKey);
  }

  @Action({ rawError: true })
  async saveValueToLocalStorage(params: { key: string; value: string | number | Checkbox[] | {} }): Promise<void> {
    const localStorageName = this.self.getLocalStorageName(params.key);

    window.localStorage[localStorageName] = JSON.stringify(params.value);
  }

  @Action({ rawError: true })
  async setFilterFromLocalStorage(): Promise<void> {
    const filterModel = this.filterSettings.filterModel;

    this.self.setFilterModeFromLocalStorage(filterModel);
    await this.prepareFilterFromModal();
  }

  @Action({ rawError: true })
  setFilterModeFromLocalStorage(filterModel: FilterModel): void {
    for (const filterName of Object.keys(window.localStorage)) {
      if (filterName.indexOf(this.filterSettings.name) === -1) {
        continue;
      }

      const key = Object.keys(filterModel).find((key) =>
        new RegExp(`Filter${key}((Start|End)Timestamp)?$`, 'i').test(filterName)
      );
      if (!key) {
        continue;
      }

      try {
        this.self.setFiltersModelFromLocalStorage({ filterName, key });
      } catch (error) {
        console.warn(error);
      }
    }
  }

  @Action({ rawError: true })
  setFiltersModelFromLocalStorage(params: { filterName: string; key: string }): void {
    const filterModel = this.filterSettings.filterModel;
    const key: string = params.key;
    const filterName: string = params.filterName;

    if (filterName.toLowerCase().indexOf(key.toLowerCase()) === -1) {
      return;
    }

    if (JSON.parse(window.localStorage[filterName]) == null) {
      return;
    }

    if (filterModel[key].type === 'checkbox') {
      try {
        this.self.SET_CHECKBOX_LIST({ key: key, list: JSON.parse(window.localStorage[filterName]) });
      } catch (error) {
        console.warn(error);
        window.localStorage[filterName] = JSON.stringify('');
      }
    }

    if (filterModel[key].type === 'multi-select') {
      let currentValues: string[];

      try {
        currentValues = JSON.parse(window.localStorage[filterName]).split(', ');
      } catch (error) {
        console.warn(error);
        currentValues = [];
        window.localStorage[filterName] = JSON.stringify('');
      }

      const result: Checkbox[] = (filterModel[key].list as Checkbox[]).filter((item: Checkbox) => {
        if (currentValues.includes(item.id.toString())) {
          return { id: item.id, value: item.name, checked: item.checked };
        }
      });
      result.forEach((item) => {
        if (!item.checked) {
          this.self.SET_MULTI_SELECT({ key: key, value: item });
        }
      });
    }

    if (filterModel[key].type === 'select') {
      const storedValue = JSON.parse(window.localStorage[filterName]);
      const result: { id: number; value: string } = storedValue ? storedValue : { id: 0, value: '' };
      this.self.SET_SELECT({ key: key, value: result });
    }

    if (filterModel[key].type === 'text') {
      this.self.SET_SEARCH({ key: key, value: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'radio') {
      this.self.updateRadio({ key: key, value: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'date-picker') {
      for (const dateKey of Object.keys(filterModel[key].list as DatePickerEntityList)) {
        if (filterName.toLowerCase().indexOf(dateKey.toLowerCase()) !== -1) {
          this.self.SET_FILTER_DATE({
            key: key,
            dateKey: dateKey,
            date: JSON.parse(window.localStorage[filterName]),
          });
        }
      }
    }

    if (filterModel[key].type === 'time-range') {
      this.self.SET_FILTER_TIME_RANGE({ key: key, time: JSON.parse(window.localStorage[filterName]) });
    }

    if (filterModel[key].type === 'timestamp-range') {
      for (const timestampKey of Object.keys(filterModel[key].list as TimestampPickerEntityList)) {
        if (filterName.toLowerCase().indexOf(timestampKey.toLowerCase()) !== -1) {
          this.self.SET_FILTER_TIMESTAMP({
            key: key,
            timestampKey,
            timestamp: JSON.parse(window.localStorage[filterName]),
          });
        }
      }
    }

    if (filterModel[key].type === 'autocomplete') {
      const storedValue = JSON.parse(window.localStorage[filterName]);
      const result: { id: number; value: string } = storedValue ? storedValue : { id: 0, value: '' };
      this.self.SET_AUTO_COMPLETE({ key: key, value: result });
    }
  }

  /*
  date picker
   */
  @Action({ rawError: true })
  async updateStartDate(params: { key: string; date: { day: string; month: string; year: string } }): Promise<void> {
    const date = params.date.day ? `${params.date.day}/${params.date.month}/${params.date.year}` : '';
    const dateParams = { key: params.key, dateKey: '', date: date };

    this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'startDate' } });
    await this.self.saveValueToLocalStorage({
      key: `${params.key}StartDate`,
      value: (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).startDate.value,
    });

    if (
      isDateStartOverDateEnd(date, (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).endDate.value)
    ) {
      this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'endDate' } });
      await this.self.saveValueToLocalStorage({
        key: `${params.key}EndDate`,
        value: (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).endDate.value,
      });
    }
  }

  @Action({ rawError: true })
  async updateEndDate(params: { key: string; date: { day: string; month: string; year: string } }): Promise<void> {
    const date = params.date.day ? `${params.date.day}/${params.date.month}/${params.date.year}` : '';
    const dateParams = { key: params.key, dateKey: '', date: date };

    this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'endDate' } });
    await this.self.saveValueToLocalStorage({
      key: `${params.key}EndDate`,
      value: (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).endDate.value,
    });

    if (
      isDateStartOverDateEnd(
        (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).startDate.value,
        date
      )
    ) {
      this.self.SET_FILTER_DATE({ ...dateParams, ...{ dateKey: 'startDate' } });
      await this.self.saveValueToLocalStorage({
        key: `${params.key}StartDate`,
        value: (this.filterSettings.filterModel[params.key].list as DatePickerEntityList).startDate.value,
      });
    }
  }

  /*
  timestamp range
   */
  @Action({ rawError: true })
  async updateTimesRange(params: { key: string; time: TimeRangePickerEntityList }): Promise<void> {
    const getTimeString = (hours = '', minutes = '') => {
      let result = '';

      if (hours && minutes) {
        result = `${hours}:${minutes}`;
      }

      return result;
    };

    const { from, to } = params.time;
    from.value = getTimeString(from.hour, from.minute);
    to.value = getTimeString(to.hour, to.minute);

    this.self.SET_FILTER_TIME_RANGE({ key: params.key, time: { ...params.time } });
    await this.self.saveValueToLocalStorage({ key: params.key, value: params.time });
  }

  @Action({ rawError: true })
  async updateStartTimestamp(params: {
    key: string;
    timestamp: { hour: string; minute: string; date: { day: string; month: string; year: string } };
  }): Promise<void> {
    const hour = params.timestamp.hour || '00';
    const minute = params.timestamp.minute || '00';
    const time = `${hour}:${minute}`;
    const date = params.timestamp.date.day
      ? `${params.timestamp.date.day}/${params.timestamp.date.month}/${params.timestamp.date.year.substring(2)}`
      : '';
    const timestamp: TimestampPickerEntityListItem = { hour, minute, time, date } as TimestampPickerEntityListItem;
    const dateParams = { key: params.key, timestamp };

    this.self.SET_FILTER_TIMESTAMP({ ...dateParams, timestampKey: 'startTimestamp' });
    await this.self.saveValueToLocalStorage({ key: `${params.key}StartTimestamp`, value: timestamp });

    const end = (this.filterSettings.filterModel[params.key].list as TimeRangePickerEntityList).endTimestamp.date;
    if (end && isAfter(date, end, 'DD/MM/YY HH:mm')) {
      this.self.SET_FILTER_TIMESTAMP({ ...dateParams, timestampKey: 'endTimestamp' });
      await this.self.saveValueToLocalStorage({ key: `${params.key}EndTimestamp`, value: timestamp });
    }
  }

  @Action({ rawError: true })
  async updateEndTimestamp(params: {
    key: string;
    timestamp: { hour: string; minute: string; date: { day: string; month: string; year: string } };
  }): Promise<void> {
    const hour = params.timestamp.hour || '23';
    const minute = params.timestamp.minute || '59';
    const time = `${hour}:${minute}`;
    const date = params.timestamp.date.day
      ? `${params.timestamp.date.day}/${params.timestamp.date.month}/${params.timestamp.date.year.substring(2)}`
      : '';
    const timestamp: TimestampPickerEntityListItem = { hour, minute, time, date } as TimestampPickerEntityListItem;
    const dateParams = { key: params.key, timestamp };

    this.self.SET_FILTER_TIMESTAMP({ ...dateParams, timestampKey: 'endTimestamp' });
    await this.self.saveValueToLocalStorage({ key: `${params.key}EndTimestamp`, value: timestamp });

    const start = (this.filterSettings.filterModel[params.key].list as TimestampPickerEntityList).startTimestamp.date;
    if (start && isBefore(date, start, 'DD/MM/YY HH:mm')) {
      this.self.SET_FILTER_TIMESTAMP({ ...dateParams, timestampKey: 'startTimestamp' });
      await this.self.saveValueToLocalStorage({ key: `${params.key}StartTimestamp`, value: timestamp });
    }
  }

  @Action({ rawError: true })
  updateDisabled(params: { key: string; value: boolean }): void {
    this.self.SET_DISABLED(params);
  }

  @Action({ rawError: true })
  async resetDates(key: string): Promise<void> {
    this.self.resetDatesState(key);
    await this.self.saveValueToLocalStorage({ key: `${key}EndDate`, value: '' });
    await this.self.saveValueToLocalStorage({ key: `${key}StartDate`, value: '' });
  }

  @Action({ rawError: true })
  resetDatesState(key: string): void {
    this.self.SET_FILTER_DATE({ key, dateKey: 'startDate', date: '' });
    this.self.SET_FILTER_DATE({ key, dateKey: 'endDate', date: '' });
  }

  @Action({ rawError: true })
  async resetTimesRange(key: string): Promise<void> {
    this.self.resetTimesRangeState(key);
    await this.self.saveValueToLocalStorage({ key: key, value: '' });
  }

  @Action({ rawError: true })
  resetTimesRangeState(key: string): void {
    this.self.SET_FILTER_TIME_RANGE({
      key,
      time: {
        value: '',
        from: {
          title: '',
          placeholder: '',
          hour: '',
          minute: '',
          value: '',
          date: '',
          time: '',
        },
        to: {
          title: '',
          placeholder: '',
          hour: '',
          minute: '',
          value: '',
          date: '',
          time: '',
        },
      },
    });
  }

  @Action({ rawError: true })
  async resetTimestamp(key: string): Promise<void> {
    this.self.resetTimestampState(key);
    await this.self.saveValueToLocalStorage({ key: `${key}EndTimestamp`, value: '' });
    await this.self.saveValueToLocalStorage({ key: `${key}StartTimestamp`, value: '' });
  }

  @Action({ rawError: true })
  resetTimestampState(key: string): void {
    this.self.SET_FILTER_TIMESTAMP({
      key,
      timestampKey: 'startTimestamp',
      timestamp: {
        key: 'start-time',
        type: 'timestamp-range',
        title: 'С',
        placeholder: '',
        hour: '',
        minute: '',
        time: '',
        date: '',
        action: 'filterModule/updateStartTimestamp',
        required: true,
        start: 0,
        end: 24,
        step: 30,
      },
    });
    this.self.SET_FILTER_TIMESTAMP({
      key,
      timestampKey: 'endTimestamp',
      timestamp: {
        key: 'end-time',
        type: 'timestamp-range',
        title: 'До',
        placeholder: '',
        hour: '',
        minute: '',
        time: '',
        date: '',
        action: 'filterModule/updateStartTimestamp',
        required: true,
        start: 0,
        end: 24,
        step: 30,
      },
    });
  }

  @Action({ rawError: true })
  resetSearchRange(key: string): void {
    this.self.SET_SEARCH_RANGE({
      key,
      startValue: '',
      endValue: '',
      disabled: true,
    });
  }

  // init
  @Action({ rawError: true })
  async init(): Promise<void> {
    const filterModel = this.filterSettings.filterModel;

    let filterEntity: FilterEntityModel;

    for (filterEntity of Object.values(filterModel)) {
      if (!filterEntity?.initAction) {
        continue;
      }

      const result = await this.context.dispatch(filterEntity.initAction, {}, { root: true });

      if (filterEntity.name?.match(/checkbox|switch/)) {
        await this.self.updateCheckboxList({ key: filterEntity.key, list: result });
      }

      if ((filterEntity.name as string).indexOf('multi_select') !== -1) {
        await this.self.updateMultiSelectList({ key: filterEntity.key, list: result });
      }

      if ((filterEntity.name as string).indexOf('select') === 0) {
        this.self.updateSelectList({ key: filterEntity.key, list: result });
      }

      if ((filterEntity.name as string).indexOf('date') !== -1) {
        await this.self.updateStartDate({ key: filterEntity.key, date: result.startDate });
        await this.self.updateEndDate({ key: filterEntity.key, date: result.endDate });
      }

      if ((filterEntity.name as string).indexOf('timestamp') !== -1) {
        await this.self.updateStartDate({ key: filterEntity.key, date: result.startTimestamp });
        await this.self.updateEndDate({ key: filterEntity.key, date: result.endTimestamp });
      }
    }

    await this.self.setFilterFromLocalStorage();
  }

  @Action({ rawError: true })
  async prepareFilterFromModal(): Promise<PreparedFilter> {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };
    const filterModel = this.filterSettings.filterModel;

    this.self.SET_FILTER_INDEX(this.defaultIndex);

    for (let i = 0; i < Object.values(filterModel).length; i++) {
      let result: { string: string; preview: Record<string, FilterPreview> } | null = null;
      const filterEntity: filterEntityGeneral = Object.values(filterModel)[i] as filterEntityGeneral;

      if (filterEntity.name?.match(/checkbox|switch/)) {
        result = this.self.prepareCheckbox(filterEntity as filterCheckboxInterface);
      }

      if ((filterEntity.name as string).indexOf('search') !== -1 && (filterEntity as filterSearchInterface).value) {
        result = this.self.prepareSearch(filterEntity as filterSearchInterface);
      }

      if (
        (filterEntity.name as string).indexOf('multi_select') !== -1 &&
        ((filterEntity as filterMultiSelectInterface).value as string).length > 0
      ) {
        result = this.self.prepareMultiSelect(filterEntity as filterMultiSelectInterface);
      }

      if (
        filterEntity.name.indexOf('select') === 0 &&
        (filterEntity as filterSelectInterface).current.value &&
        ((filterEntity as filterSelectInterface).current.id !== '0' || (filterEntity as filterSelectInterface).allowZeroId)
      ) {
        result = this.self.prepareSelect(filterEntity as filterSelectInterface);
      }

      if ((filterEntity.name as string).indexOf('date') !== -1) {
        result = this.self.prepareDatePicker(filterEntity as filterDatePickerInterface);
      }

      if (
        (filterEntity.name as string).indexOf('time') !== -1 &&
        (filterEntity.name as string).indexOf('timestamp') === -1 &&
        (filterEntity.name as string).indexOf('_time') === -1
      ) {
        result = this.self.prepareTimeRangePicker(filterEntity as filterTimeRangePickerInterface);
      }

      if ((filterEntity.name as string).indexOf('timestamp') !== -1) {
        result = this.self.prepareTimestampPicker(filterEntity as filterTimestampPickerInterface);
      }

      if (
        (filterEntity.name as string).indexOf('radio') !== -1 &&
        (filterEntity as filterCheckboxInterface).value !== null
      ) {
        result = this.self.prepareRadio(filterEntity as filterCheckboxInterface);
      }

      if (
        (filterEntity.name as string).indexOf('autocomplete') === 0 &&
        ((filterEntity as filterAutoCompleteInterface).current as {
          id: number | string;
          value: string;
        }).value &&
        ((filterEntity as filterAutoCompleteInterface).current as {
          id: number | string;
          value: string;
        }).id !== '0'
      ) {
        result = this.self.prepareAutoComplete(filterEntity as filterAutoCompleteInterface);
      }

      if ((filterEntity.name as string).startsWith('picker_time_')) {
        result = this.self.prepareTimeRangePicker(filterEntity as filterTimeRangePickerInterface);
      }

      if (result) {
        await this.self.SET_FILTER_INDEX(this.self.index + 1);

        filter.string += (result as { string: string }).string;
        filter.preview = { ...(result as { preview: Record<string, FilterPreview> }).preview, ...filter.preview };
      }
    }

    this.self.SET_FILTER(filter.string);
    this.self.SET_FILTER_PREVIEW(filter.preview);

    return filter;
  }

  @Action({ rawError: true })
  prepareCheckbox(filterEntity: filterCheckboxInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    if ((filterEntity.name as string).indexOf('checkbox_bool') !== -1) {
      const checkedValue = (filterEntity.list as Checkbox[])[0].checked;

      filter.string = `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${checkedValue}`;
      filter.preview[filterEntity.key] = {
        name: filterEntity.previewName,
        iterable: false,
        iconClass: filterEntity.iconClass || '',
      };

      return filter;
    }

    const checkbox = (filterEntity.list as Checkbox[])[0];
    const checkedValue = checkbox.checked ? 1 : 0;

    if (filterEntity.ignoreNull == true && checkedValue === 0) {
      return filter;
    }

    let filterString = `filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${checkedValue}`;

    if (checkedValue && Object.prototype.hasOwnProperty.call(checkbox, 'value')) {
      filterString = convertObjectToQueryParams({
        [`filters[${this.self.index}]`]: {
          id: filterEntity.key,
          value: checkbox.value,
        },
      });
    }

    filter.string = `&${filterString}`;

    if (filterEntity.ignorePreviewOnNull == true && checkedValue === 0) {
      return filter;
    }

    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconClass || '',
    };

    return filter;
  }

  @Action({ rawError: true })
  prepareSearch(filterEntity: filterSearchInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.value}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconClass || '',
    };

    return filter;
  }

  @Action({ rawError: true })
  prepareMultiSelect(filterEntity: filterMultiSelectInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    let i = 0;
    let previewCounter = 0;
    const filterBase = `&filters[${this.self.index}][id]=${filterEntity.key}`;
    let filterValue = '';
    for (const item of filterEntity.list) {
      if (item.checked) {
        previewCounter++;
        filterValue += `&filters[${this.self.index}][value][${i++}]=${item.id}`;
      }
    }

    filter.string = `${filterBase}${filterValue}`;
    filter.preview[filterEntity.key] = { name: `${filterEntity.previewName} ${previewCounter}`, iterable: true };

    return filter;
  }

  @Action({ rawError: true })
  prepareSelect(filterEntity: filterSelectInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.current.id}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconPreviewClass || filterEntity.iconClass || '',
    };

    return filter;
  }

  @Action({ rawError: true })
  prepareDatePicker(filterEntity: filterDatePickerInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    Object.entries(filterEntity.list).forEach((entry: [string, DatePickerEntityListItem]) => {
      if (entry[1].value !== '' && entry[1].value !== 'Выбрать') {
        const value = moment(entry[1].value, 'DD/MM/YY').format('YYYY-MM-DD');
        filter.string += `&filters[${this.index}][id]=${entry[1].key}&filters[${this.index}][value]=${value}`;
        filter.preview[filterEntity.key] = {
          name: filterEntity.previewName,
          iterable: false,
          iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);
      }
    });

    return filter;
  }

  @Action({ rawError: true })
  prepareTimeRangePicker(filterEntity: filterTimeRangePickerInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    Object.entries(filterEntity.list).forEach((entry: [string, TimeRangePickerEntityListItem]) => {
      if (entry[1].value !== '') {
        const value = filterEntity.onlyHour ? filterEntity.list[entry[0]].hour : filterEntity.list[entry[0]].value;
        filter.string += `&filters[${this.index}][id]=${filterEntity.key}${entry[0][0].toUpperCase()}${entry[0].slice(
          1
        )}&filters[${this.index}][value]=${value}`;
        filter.preview[filterEntity.key] = {
          name: filterEntity.previewName,
          iterable: false,
          iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);
      }
    });

    return filter;
  }

  @Action({ rawError: true })
  prepareTimestampPicker(filterEntity: filterTimestampPickerInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    Object.entries(filterEntity.list).forEach((entry: [string, TimestampPickerEntityListItem]) => {
      if (entry[1].date && entry[1].time) {
        const value = moment(`${entry[1].date} ${entry[1].time}`, 'DD/MM/YY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        filter.string += `&filters[${this.index}][id]=${entry[1].key}&filters[${this.index}][value]=${value}`;
        filter.preview[filterEntity.key] = {
          name: filterEntity.previewName,
          iterable: false,
          iconClass: filterEntity.iconClass || '',
        };
        this.self.SET_FILTER_INDEX(this.self.index + 1);
      }
    });

    return filter;
  }

  @Action({ rawError: true })
  prepareRadio(filterEntity: filterCheckboxInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.value}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconClass || '',
    };
    this.self.SET_FILTER_INDEX(this.self.index + 1);

    return filter;
  }

  @Action({ rawError: true })
  prepareAutoComplete(filterEntity: filterAutoCompleteInterface): PreparedFilter {
    const filter: PreparedFilter = {
      string: '',
      preview: {},
    };

    filter.string += `&filters[${this.self.index}][id]=${filterEntity.key}&filters[${this.self.index}][value]=${filterEntity.current.id}`;
    filter.preview[filterEntity.key] = {
      name: filterEntity.previewName,
      iterable: false,
      iconClass: filterEntity.iconPreviewClass || filterEntity.iconClass || '',
    };

    return filter;
  }

  // update
  @Action({ rawError: true })
  async updateFilter(): Promise<void> {
    await this.self.prepareFilterFromModal();

    if (this.filterSettings.getListAction) {
      await this.context.dispatch(this.filterSettings.getListAction, {}, { root: true });
    }
  }

  // reset
  @Action({ rawError: true })
  async removeSelectedFilter(params: any): Promise<void> {
    const filterEntity = this.filterSettings.filterModel[params];
    const filterType = filterEntity.type;

    if (filterType === 'text') {
      await this.self.resetSearch(params);
    }

    if (filterType === 'select') {
      await this.self.resetSelect(params);
    }

    if (filterEntity.type === 'checkbox') {
      await this.self.resetCheckboxList(params);
    }

    if (filterType === 'multi-select') {
      await this.self.resetMultiSelect(params);
    }

    if (filterType === 'radio') {
      await this.self.resetRadio(params);
    }

    if (filterType === 'date-picker') {
      for (const dateKey of Object.keys(filterEntity.list as DatePickerEntityList)) {
        await this.context.dispatch(
          (filterEntity.list as DatePickerEntityList)[dateKey].action,
          { key: filterEntity.key, date: { day: '', month: '', year: '' } },
          { root: true }
        );
      }
    }

    if (filterEntity.type === 'time-range') {
      await this.self.resetTimesRange(filterEntity.key);
    }

    if (filterEntity.type === 'timestamp-range') {
      await this.self.resetTimestamp(filterEntity.key);
    }

    if (filterEntity.type === 'search-range') {
      await this.self.resetSearchRange(filterEntity.key);
    }

    if (filterEntity.type === 'autocomplete') {
      await this.self.resetAutoComplete(filterEntity.key);
    }

    this.self.DEL_FILTER_PREVIEW(params);
    await this.self.updateFilter();
  }

  @Action({ rawError: true })
  async resetFilter(): Promise<void> {
    await this.self.SET_FILTER('');
    await this.self.SET_FILTER_PREVIEW({});
    await this.self.SET_PAGE(1);

    await this.self.resetFilterValues();
    if (this.filterSettings.getListAction) {
      await this.context.dispatch(this.filterSettings.getListAction, {}, { root: true });
    }
  }

  @Action({ rawError: true })
  async resetFilterValues(): Promise<void> {
    for (const filterEntity of Object.values(this.filterSettings.filterModel)) {
      if (filterEntity.type === 'checkbox') {
        await this.self.resetCheckboxList(filterEntity.key);
      }

      if (filterEntity.type === 'text') {
        await this.self.resetSearch(filterEntity.key);
      }

      if (filterEntity.type === 'select') {
        await this.self.resetSelect(filterEntity.key);
      }

      if (filterEntity.type === 'multi-select') {
        await this.self.resetMultiSelect(filterEntity.key);
      }

      if (filterEntity.type === 'radio') {
        await this.self.resetRadio(filterEntity.key);
      }

      if (filterEntity.type === 'date-picker') {
        await this.self.resetDates(filterEntity.key);
      }

      if (filterEntity.type === 'time-range') {
        await this.self.resetTimesRange(filterEntity.key);
      }

      if (filterEntity.type === 'timestamp-range') {
        await this.self.resetTimestamp(filterEntity.key);
      }

      if (filterEntity.type === 'search-range') {
        await this.self.resetSearchRange(filterEntity.key);
      }

      if (filterEntity.type === 'autocomplete') {
        await this.self.resetAutoComplete(filterEntity.key);
      }
    }

    await this.self.updateFilter();
  }

  @Action({ rawError: true })
  async resetFilterState(): Promise<void> {
    for (const filterEntity of Object.values(this.filterSettings.filterModel)) {
      if (filterEntity.type === 'checkbox') {
        await this.self.resetCheckboxListState(filterEntity.key);
      }

      if (filterEntity.type === 'text') {
        await this.self.resetSearchState(filterEntity.key);
      }

      if (filterEntity.type === 'select') {
        await this.self.resetSelectState(filterEntity.key);
      }

      if (filterEntity.type === 'multi-select') {
        await this.self.RESET_MULTI_SELECT(filterEntity.key);
      }

      if (filterEntity.type === 'radio') {
        await this.self.resetRadioState(filterEntity.key);
      }

      if (filterEntity.type === 'date-picker') {
        await this.self.resetDatesState(filterEntity.key);
      }

      if (filterEntity.type === 'time-range') {
        await this.self.resetTimesRangeState(filterEntity.key);
      }

      if (filterEntity.type === 'timestamp-range') {
        await this.self.resetTimestampState(filterEntity.key);
      }

      if (filterEntity.type === 'autocomplete') {
        await this.self.resetAutoCompleteState(filterEntity.key);
      }
    }
  }

  @Action({ rawError: true })
  setDefaultIndex(index: number): void {
    this.self.SET_FILTER_DEFAULT_INDEX(index);
    this.self.SET_FILTER_INDEX(index);
  }
}
