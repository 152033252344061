/*
 * @update="handler" возращает значение самого инпута
 * Все другие обработчики будут работать как для обычного инпута
 * Кастомный инпут поддерживает v-model
 */
import { ref } from 'vue';

interface EventTargetValue {
  target: {
    value: string;
  };
}

export interface FormInputHandlerParams {
  input: (eventName: string, eventData?: unknown) => void;
  value: string | number;
  type?: string;
}

export interface FormInputHandlerResult {
  update: (params: EventTargetValue) => void;
  value: string | number;
  paste: (event: ClipboardEvent) => void;
  focus: () => void;
  blur: () => void;
  clear: () => void;
}

export default function formInputHandler(params: FormInputHandlerParams): FormInputHandlerResult {
  const { input, value, type } = params;
  const modelValue = ref(value);

  const update = ({ target: { value } }: EventTargetValue) => {
    input('update:modelValue', value);
    input('update', value);
    modelValue.value = value;
  };

  const pastePhone = (event: any) => {
    const value = event.clipboardData.getData('text');

    if ((value.length && value[0] === '7') || value[0] === '8') {
      const modifyValue = `+7${value.slice(1)}`;

      update({ target: { value: modifyValue } });

      return;
    }

    update(event);
  };

  const paste = (event: any) => {
    if (type === 'phone') {
      pastePhone(event);

      return;
    }

    update(event);
  };

  const focus = () => {
    input('focus');
  };

  const blur = () => {
    input('blur');
  };

  const clear = () => {
    update({ target: { value: '' } });
  };

  return {
    update,
    paste,
    focus,
    blur,
    clear,
    value,
  };
}
