/**
 * Response Handler store.
 *
 */

import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';

export const MODULE_NAME = 'modals';

interface ModalParamsInterface {
  id: string | null;
  title: string;
  name: string;
  text: string;
  router: string | null;
  handlers: {
    no: string;
    yes: string;
  };
  buttonsText: {
    no: string;
    yes: string;
  };
  default: {
    buttonsText: {
      yes: string;
      no: string;
    };
  };
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ModalsModule extends VuexModule {
  isShow: Record<string, boolean> = {
    empty: false,
    warning: false,
  };
  params: ModalParamsInterface = {
    id: null,
    title: '',
    name: '',
    text: '',
    router: '',
    buttonsText: {
      yes: 'Удалить',
      no: 'Отмена',
    },
    handlers: {
      yes: '',
      no: '',
    },
    default: {
      buttonsText: {
        yes: 'Удалить',
        no: 'Отмена',
      },
    },
  };

  @Mutation
  SET_PARAMS(params: ModalParamsInterface) {
    this.params.id = params.id;
    this.params.text = params.text;
    this.params.handlers.yes = params.handlers.yes;

    this.params.buttonsText = params.buttonsText ? params.buttonsText : this.params.default.buttonsText;
  }

  @Mutation
  TOGGLE_MODAL_BY_TYPE(params: { type: string; bool: boolean }) {
    this.isShow[params.type] = params.bool;
  }

  @Action({ rawError: true })
  updateParams(params: {}) {
    this.context.commit('SET_PARAMS', params);
  }

  @Action({ rawError: true })
  openModalByType(type: string) {
    this.context.commit('TOGGLE_MODAL_BY_TYPE', { type: type, bool: true });
  }

  @Action({ rawError: true })
  closeModalByType(type: string) {
    this.context.commit('TOGGLE_MODAL_BY_TYPE', { type: type, bool: false });
  }
}
export default getModule(ModalsModule);
