import { ShopModel } from '@/interfaces/models/shop.interface';
import SelectEntity from '@/lib/formFactory/selectEntity';
import MapEntity from '@/lib/formFactory/mapEntity';
import InputSuggestionEntity from '@/lib/formFactory/inputSuggestionEntity';

export default class EntityModel {
  model: ShopModel;

  constructor() {
    const regionEntity = new SelectEntity('region');
    regionEntity.setTitle('Регион');

    const addressCoordinates = new MapEntity('addressCoordinates');
    addressCoordinates.setAction('customers/shops/updateAddressCoordinates');

    const accountManager = new InputSuggestionEntity('accountManager');
    const customerSuccessManager = new InputSuggestionEntity('customerSuccessManager');

    this.model = {
      id: '',
      name: '',
      code: '',
      marketPhones: [],
      marketPhonesBase: '',
      customer: { id: null, value: '' },
      region: { id: null, value: '', timezoneNumber: '' },
      address: { id: null, value: '' },
      addressCoordinates: addressCoordinates.entity[addressCoordinates.entityName],
      comment: '',
      comments: [],
      vacancies: [],
      isActive: 0,
      regularNeed: 0,
      info: '',
      accountManager: accountManager.entity[accountManager.entityName],
      customerSuccessManager: customerSuccessManager.entity[customerSuccessManager.entityName],
      assistant: { id: null, value: '' },
      responsibleName: '',
      responsiblePhone: '',
      responsibleEmail: '',
      timezone: '',
      isTest: false,
      partnerUuid: '',
      clientId: '',
      paymentStrategy: '',
      autoCheckIn: true,
      autoCheckup: false,
      rating: '',
      contractor: '',
      clientPartners: [],
      clientSubcontractors: [],
      partner: [],
    };
  }
}
