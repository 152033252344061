/**
 * User store.
 *
 */

import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { MenuItem } from '@/interfaces/menuItem.interface';
import { convertPermissionsToRoutes } from '@/lib/util/permissions';
import { User, UserPartner, UserSecurityGroup } from '@/interfaces/user.interface';
import { UserNotificationSubscription } from '@/interfaces/user.interface';
import { NotificationSubscriptionCategory } from '@/interfaces/notifications.interface';
import moment from 'moment';

export const MODULE_NAME = 'user';
export const TOKEN_STORAGE_KEY = 'tokenAdmin';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UserModule extends VuexModule {
  id: number | null = null;
  login: string | null = null;
  lastName: string | null = null;
  firstName: string | null = null;
  middleName: string | null = null;
  fullName: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  humanRole: string | null = null;
  allowedItems: string[] = [];
  isAuthenticated = false;
  token: string | null = null;
  workTimeFrom: string | null = null;
  workTimeTo: string | null = null;
  partner: UserPartner | null = null;
  securityGroup: UserSecurityGroup | null = null;
  chief: User | null = null;

  permissions: string[] = [];
  userRoutes: string[] = [];
  notifySubscriptions = new Map<NotificationSubscriptionCategory, string>();

  get isSupervisor(): boolean {
    return !!this.securityGroup?.isSupervisor;
  }

  @Mutation
  setToken(token: string) {
    this.token = token;
    if (typeof window.localStorage === 'object') {
      window.localStorage[TOKEN_STORAGE_KEY] = token;
    }
  }

  @Mutation
  setAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  @Mutation
  setUser({
    user,
    permissions = [],
    notifySubscriptions = [],
  }: {
    user: User;
    permissions?: string[];
    notifySubscriptions?: UserNotificationSubscription[];
  }) {
    this.id = user.id;
    this.login = user.login;
    this.lastName = user.lastName;
    this.firstName = user.firstName;
    this.middleName = user.middleName;
    this.phone = user.phone;
    this.email = user.email;
    this.humanRole = user.humanRole;
    this.securityGroup = user.securityGroup;
    this.partner = user.partner ?? null;

    this.fullName = '';
    this.fullName += user.firstName ? user.firstName : '';
    this.fullName += user.lastName ? ` ${user.lastName}` : '';

    this.workTimeFrom = moment(user.workTimeFrom).format('HH:mm');
    this.workTimeTo = moment(user.workTimeTo).format('HH:mm');

    this.chief = user.chief;

    this.permissions = permissions;
    this.userRoutes = convertPermissionsToRoutes(permissions);

    this.notifySubscriptions.clear();
    for (const subscription of notifySubscriptions) {
      this.notifySubscriptions.set(subscription.category, subscription.email);
    }
  }

  @Mutation
  clearUser() {
    this.id = null;
    this.login = '';
    this.lastName = '';
    this.firstName = '';
    this.middleName = '';
    this.phone = '';
    this.email = '';
    this.isAuthenticated = false;
    this.userRoutes = [];
    this.permissions = [];
    this.partner = null;
    this.securityGroup = null;
    this.workTimeFrom = null;
    this.workTimeTo = null;
    this.chief = null;
  }

  @Mutation
  setAllowedItems(menu: MenuItem[]) {
    this.allowedItems = menu.map((item) => item.code);
  }

  get isBlockAllowed() {
    return (code?: string | null) => typeof code === 'string' && this.allowedItems.includes(code);
  }

  get canUserRead() {
    return (path: string) => {
      return this.userRoutes.includes(path);
    };
  }

  get userHasPermission() {
    return (permission: string) => {
      return this.permissions.includes(permission);
    };
  }
}

export default getModule(UserModule);
