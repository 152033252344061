export const phoneMask = {
  mask: '+7 (999) 999-99-99',
  greedy: false,
  autoUnmask: true,
  showMaskOnHover: false,
  showMaskOnFocus: false,
  placeholder: '_',
  minChars: 10,
  onBeforePaste: (value: string): string => value.trim().replace(/^\+?(7|8)/, ''),
};
