/**
 * Customers templates store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import { getClientTemplatesById } from '@/api/templates';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { getAllVaccinationStatusList, getServiceTypeAll } from '@/api/templates';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'customersTemplates';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersTemplatesModule extends PageBaseModule {
  onlyActive = false;
  customerId = '';
  actionsHandler: { additional: { [key: string]: string } };
  vaccinationsList: { id: string; name: string }[] = [];
  serviceTypeList: { id: string; name: string }[] = [];

  constructor(module: CustomersTemplatesModule) {
    super(module);

    const page = new PageEntity();
    page.setTitleAdd('Добавление шаблона');
    page.setTitleEdit('Шаблон заказа');
    page.values.actionPagination = 'customersTemplates/updatePage';
    this.pageSettings = page.values;

    this.actionsHandler = {
      additional: {
        name: 'customers/getById',
      },
    };
  }

  @Mutation
  SET_ONLY_ACTIVE(value: boolean) {
    this.onlyActive = value;
  }

  @Mutation
  SET_CUSTOMER_ID(id: string) {
    this.customerId = id;
  }

  @Mutation
  SET_VACCINATIONS_LIST(list: { id: string; name: string; value: string }[]) {
    this.vaccinationsList = list;
  }

  @Mutation
  SET_SERVICE_TYPE_LIST(list: { id: string; name: string }[]) {
    this.serviceTypeList = list;
  }

  @Action({ rawError: true })
  async initListById(customerId: string) {
    await this.context.commit('SET_CUSTOMER_ID', customerId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.getListById(customerId);
    await this.getVaccinationsList();
  }

  @Action({ rawError: true })
  async getListById(customerId: string) {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filters = this.onlyActive ? '&filters[3][id]=active&filters[3][value]=1' : '';
      const result = await getClientTemplatesById(customerId, this.pageSettings.pageCurrent, itemsQuery, filters);
      this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);

    uiTable
      .setProperties('isActive', { id: 'isActive', width: '36px', name: 'А', align: 'center' })
      .setProperties('hasLmk', { id: 'hasLmk', name: 'ЛМК' })
      .setProperties('serviceTypeName', { id: 'serviceTypeName', name: 'Тип услуги' })
      .setProperties('unitTypeName', { id: 'unitTypeName', name: 'Ед. измерения' })
      .setProperties('ageTo', { id: 'ageTo', name: 'Возраст' })
      .setProperties('ageFrom', { id: 'ageFrom', visible: false })
      .setProperties('forLk', { id: 'forLk', name: 'ЛК', width: '36px', align: 'center' })
      .setProperties('rate', { id: 'rate', name: 'Ставка, ₽' })
      .setProperties('externalRate', { id: 'externalRate', visible: false });

    const tableInfo = uiTable.getTable();

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getListById(this.customerId);
  }

  @Action({ rawError: true })
  async getVaccinationsList() {
    const vaccinationsAll: { type: string; humanType: string }[] = await getAllVaccinationStatusList();
    this.context.commit(
      'SET_VACCINATIONS_LIST',
      Object.values(vaccinationsAll).map((vaccination: { type: string; humanType: string }) => {
        return {
          id: vaccination.type,
          value: vaccination.type,
          name: vaccination.humanType,
        };
      })
    );
  }

  @Action({ rawError: true })
  async getServiceTypeList() {
    const serviceTypeAll = await getServiceTypeAll();
    this.context.commit('SET_SERVICE_TYPE_LIST', serviceTypeAll);
  }

  @Action({ rawError: true })
  async updateOnlyActive(value: boolean) {
    await this.context.commit('SET_ONLY_ACTIVE', value);
    await store.dispatch('customersTemplates/updatePage', 1);
    await this.getListById(this.customerId);
  }
}

export default getModule(CustomersTemplatesModule);
