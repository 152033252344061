/**
 * Employees entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import {
  EmployeeModel,
  EmployeeDuplicateModel,
  EmployeeRequestModel,
  EmployeeTextFields,
} from '@/interfaces/models/employees.interface';
import store from '@/store';
import { tabsNav } from './entityTabs';
import EntityModel from './entityModel';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import EmployeesModule from './index';

import {
  getEmployeeById,
  addNewEmployee,
  saveEmployee,
  deleteDuplicate,
  addNewComment,
  updateComment,
  getEmployeeComments,
  deleteComment,
} from '@/api/employees';
import moment from 'moment';
import ResponseHandlerStaticModule from '@/store/modules/responseHandlerStatic';
import { clearPhone } from '@/lib/Utils';
import userModule from '@/store/user';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { strings } from '@/lib/stringConst';
import { CatchFormResponse } from '@/interfaces/shared';
import { CompetenceModel } from '@/interfaces/models/competence.interface';
import { FormData } from '@/interfaces/shared';

export interface Cards {
  green: number;
  yellow: number;
  red: number;
}

export interface PreparedCard {
  color: string;
  value: number;
}

export interface EmployeeComment {
  id: string;
  createdAt: string;
  editable: boolean;
  text: string;
  userId: string;
  userName: string;
}

export const MODULE_NAME = 'employeeEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class EmployeeEntityModule extends EntityBaseModule {
  model: EmployeeModel;
  tabsNav: TabsNavInterface = {};
  cards: Cards | PreparedCard | boolean;
  showDuplicateModal: boolean;
  duplicate: EmployeeDuplicateModel;
  comments: EmployeeComment[] = [];
  commentsTotalCount = 0;
  commentsCurrentCount = 0;
  commentCountIncrement = 5;
  rocketWorkStatusSendBlockUntil = 0;

  constructor(module: EmployeeEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;

    this.cards = {
      red: 0,
      green: 0,
      yellow: 0,
    };

    this.showDuplicateModal = false;
    this.duplicate = {
      id: 0,
      name: '',
      geoRegion: '',
      phone: '',
      email: '',
      role: '',
      createdAt: '',
      inn: '',
    };
    this.commentsCurrentCount = this.commentCountIncrement;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_CARDS(cards: PreparedCard | boolean) {
    this.cards = cards;
  }

  @Mutation
  SET_SHOW_DUPLICATE_MODAL(bool: boolean) {
    this.showDuplicateModal = bool;
  }

  @Mutation
  SET_DUPLICATE(payload: EmployeeDuplicateModel) {
    this.duplicate = { ...payload };
  }

  @Mutation
  SET_COMMENTS(comments: EmployeeComment[]) {
    this.comments = comments;
  }

  @Mutation
  SET_COMMENTS_TOTAL_COUNT(value: number) {
    this.commentsTotalCount = value;
  }

  @Mutation
  SET_COMMENTS_CURRENT_COUNT(value: number) {
    this.commentsCurrentCount = value;
  }

  @Mutation
  ROCKET_WORK_STATUS_SEND_BLOCK_UNTIL(value: number) {
    this.rocketWorkStatusSendBlockUntil = value;
  }

  @Action({ rawError: true })
  prepareCards(cards: Cards) {
    if (cards.red) {
      return {
        color: 'red',
        value: cards.red,
      };
    }

    if (cards.yellow) {
      return {
        color: 'yellow',
        value: cards.yellow,
      };
    }

    if (cards.green) {
      return {
        color: 'green',
        value: cards.green,
      };
    }

    return false;
  }

  @Action({ rawError: true })
  async initEdit(id: string) {
    const result = await this.getById(id);

    if (!result) {
      return;
    }

    await this.getCommentsById(id);

    const rocketWorkBlockUntil = (await this.getRocketWorkStatusSendBlockUntil(id)) || 0;
    this.ROCKET_WORK_STATUS_SEND_BLOCK_UNTIL(rocketWorkBlockUntil);

    for (const tab of Object.keys(tabsNav)) {
      if (userModule.canUserRead(tabsNav[tab].pathName)) {
        this.tabsNav[tab] = tabsNav[tab];
      }
    }
  }

  @Action({ rawError: true })
  async getById(id: string): Promise<boolean> {
    try {
      const result = await getEmployeeById(id);

      this.setInfo(result.employee);
      const data: PreparedCard | boolean = await this.prepareCards(result.cards);
      this.SET_CARDS(data);

      return true;
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });

      return false;
    }
  }

  @Action({ rawError: true })
  async getCommentsById(id: string) {
    try {
      const result = await getEmployeeComments(id, this.commentsCurrentCount);
      this.context.commit('SET_COMMENTS', result.rows);
      this.context.commit('SET_COMMENTS_TOTAL_COUNT', result.totalItems);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse).response.data.message ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async addNewComment(data: { employee: string; text: string }) {
    await addNewComment(data);
    await this.getCommentsById(data.employee);
  }

  @Action({ rawError: true })
  async editComment(data: { id: string; employee: string; text: string }) {
    await updateComment(data.id, {
      employee: data.employee,
      text: data.text,
    });
    await this.getCommentsById(data.employee);
  }

  @Action({ rawError: true })
  async deleteComment(data: { id: string; employee: string }) {
    await deleteComment(data.id);
    await this.getCommentsById(data.employee);
  }

  @Action({ rawError: true })
  async commentsLoadMore(id: string) {
    await this.context.commit('SET_COMMENTS_CURRENT_COUNT', this.commentsCurrentCount + this.commentCountIncrement);
    await this.getCommentsById(id);
  }

  @Action({ rawError: true })
  setInfo(info: EmployeeRequestModel) {
    EmployeesModule.updateTitleEdit(`${info.lastName} ${info.firstName}`);

    this.context.commit('SET_MODEL_VALUE', { key: 'lastName', value: info.lastName });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstName', value: info.firstName });
    this.context.commit('SET_MODEL_VALUE', { key: 'middleName', value: info.middleName });
    this.context.commit('SET_MODEL_VALUE', { key: 'birthday', value: info.birthday ? new Date(info.birthday) : null });
    this.context.commit('SET_MODEL_VALUE', { key: 'gender', value: info.gender });
    if (info.belongingToCitizenship) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'belongingToCitizenship',
        value: info.belongingToCitizenship.id.toString(),
      });
    }
    if (info.geoRegion) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'geoRegion',
        value: info.geoRegion.id.toString(),
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'timeZone',
        value: info.geoRegion.timeZone,
      });
    }
    this.context.commit('SET_MODEL_VALUE', { key: 'rating', value: info.rating.substring(0, 3) });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: info.phone });
    this.context.commit('SET_MODEL_VALUE', { key: 'email', value: info.email });
    this.context.commit('SET_MODEL_VALUE', { key: 'partnerUuid', value: info.partner?.uuid ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'partnerName', value: info.partner?.legalName ?? '' });

    if (info.competences.length) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'competence',
        value: info.competences.map((item: CompetenceModel) => {
          return item.id.toString();
        }),
      });
    }
    this.context.commit('SET_MODEL_VALUE', { key: 'lmk', value: info.lmk ? new Date(info.lmk) : null });
    this.context.commit('SET_MODEL_VALUE', { key: 'lmkNumber', value: info.lmkNumber });

    this.context.commit('SET_MODEL_VALUE', { key: 'role', value: info.role.name });
    if (info.split) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'split',
        value: info.split.id.toString(),
      });
    }

    if (info.verification) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'verification',
        value: {
          snils: info.verification.snils,
          inn: info.verification.inn,
          lmk: info.verification.lmk,
          passport: info.verification.passport,
          updatedAt: info.verification.updatedAt,
        },
      });
    }

    if (info.reliability) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'reliability',
        value: {
          checked: info.reliability.checked,
          updatedAt: info.reliability.updatedAt,
          debtNpd: info.reliability.debtNpd,
          criminalRecord: info.reliability.criminalRecord,
          trial: info.reliability.trial,
          dispute: info.reliability.dispute,
        },
      });
    }

    this.context.commit('SET_MODEL_VALUE', { key: 'vaccinationStatus', value: info.vaccinationStatus });
    this.context.commit('SET_MODEL_VALUE', { key: 'underage', value: info.underage });
    this.context.commit('SET_MODEL_VALUE', { key: 'underageLink', value: info.underageLink });
    this.context.commit('SET_MODEL_VALUE', { key: 'sleeping', value: info.sleeping });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: info.isTest });
    this.context.commit('SET_MODEL_VALUE', { key: 'sharing', value: info.sharing ?? 'none' });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkId', value: info.rocketWorkId });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkStatus', value: info.rocketWorkStatus });
    this.context.commit('SET_MODEL_VALUE', { key: 'humanRocketWorkStatus', value: info.humanRocketWorkStatus });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: info.inn });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: info.bank });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentAccount', value: info.paymentAccount });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentType', value: info.paymentType });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardNumber', value: info.cardNumber });
    this.context.commit('SET_MODEL_VALUE', { key: 'ks', value: info.ks });
    this.context.commit('SET_MODEL_VALUE', { key: 'bic', value: info.bic ?? '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSelfEmployed', value: info.isSelfEmployed });
    this.context.commit('SET_MODEL_VALUE', { key: 'selfEmployedDay', value: info.selfEmployedDay });

    this.context.commit('SET_MODEL_VALUE', { key: 'hasMobileApp', value: info.hasMobileApp });
    this.context.commit('SET_MODEL_VALUE', { key: 'onDemandPaymentEnabled', value: info.onDemandPaymentEnabled });

    if (info.lastCameOut) {
      const formattedLastCameOut = moment(info.lastCameOut).format('DD.MM.YYYY');
      this.context.commit('SET_MODEL_VALUE', { key: 'lastCameOut', value: formattedLastCameOut });
    }

    this.context.commit('SET_CREATED_AT', moment(info.createdAt).format('DD.MM.YYYY HH:mm'));
    this.context.commit('SET_UPDATED_AT', moment(info.updatedAt).format('DD.MM.YYYY HH:mm'));

    let splitInfo = '';
    if (info.splitDay) {
      splitInfo = `Дата блокировки ${moment(info.splitDay).format('DD.MM.YYYY HH:mm')}`;
    }
    if (info.split) {
      splitInfo = `${splitInfo} (${info.split.name})`;
    }
    this.context.commit('SET_MODEL_VALUE', { key: 'splitInfo', value: splitInfo });
  }

  @Action({ rawError: true })
  async addNew(params: { textFields: EmployeeTextFields; deleteDoubleId?: string }) {
    try {
      if (typeof params.deleteDoubleId !== 'undefined') {
        params.textFields.deleteDoubleId = params.deleteDoubleId as string;
      }
      const result = await addNewEmployee(params.textFields as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Сотрудник создан', type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: (result as EmployeeModel).isTest });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'employee_has_doubles' && errorData.result && errorData.result.doubles) {
        const duplicate = errorData.result.doubles.shift();

        this.context.commit('SET_DUPLICATE', {
          id: duplicate.id,
          name: duplicate.name,
          geoRegion: duplicate.region,
          phone: clearPhone(duplicate.phone),
          email: duplicate.email,
          role: duplicate.role,
          createdAt: duplicate.createdAt,
          inn: duplicate.inn,
        });
        this.context.commit('SET_SHOW_DUPLICATE_MODAL', true);

        return;
      }

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      if (errorData.type === 'bad_request') {
        ResponseHandlerModule.showNotify({ message: (error as CatchFormResponse).response.data.message, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>).response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: { id: string; textFields: EmployeeTextFields; deleteDoubleId?: string }) {
    try {
      if (typeof params.deleteDoubleId !== 'undefined') {
        params.textFields.deleteDoubleId = params.deleteDoubleId as string;
      }

      const result = await saveEmployee(params.id, params.textFields as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
        //
        // обновим isTest для бейджа
        //
        this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: (result as EmployeeModel).isTest });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }

      return result;
    } catch (error) {
      const errorData = error.response.data;

      if (errorData.type === 'employee_has_doubles' && errorData.result && errorData.result.doubles) {
        const duplicate = errorData.result.doubles.shift();

        this.context.commit('SET_DUPLICATE', {
          id: duplicate.id,
          name: duplicate.name,
          geoRegion: duplicate.region,
          phone: clearPhone(duplicate.phone),
          email: duplicate.email,
          role: duplicate.role,
          createdAt: duplicate.createdAt,
          inn: duplicate.inn,
        });
        this.context.commit('SET_SHOW_DUPLICATE_MODAL', true);

        return;
      }

      if (errorData.type === 'entity_unique_violation_error') {
        this.showUniqueError(errorData);

        return;
      }

      if (error.response.data.type === 'validation_error') {
        const errorFields = error.response.data.errors.fields;
        let message = '';
        for (const field of Object.values(errorFields)) {
          message += `${field} <br/>`;
        }
        ResponseHandlerStaticModule.showNotify({ content: message, type: 'fail' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: (error as CatchFormResponse).response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  showUniqueError(errorData: { type: string; errors: { fields: Record<string, string> } }) {
    const fields = errorData.errors.fields;

    ResponseHandlerStaticModule.hideNotify();
    ResponseHandlerStaticModule.showNotify({ content: Object.values(fields)[0] as string, type: 'fail' });
  }

  @Action({ rawError: true })
  resetTitle() {
    EmployeesModule.updateTitleEdit('');
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('SET_MODEL_VALUE', { key: 'lastName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'middleName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'birthday', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'gender', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'belongingToCitizenship', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'geoRegion', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'timeZone', value: 0 });
    this.context.commit('SET_MODEL_VALUE', { key: 'phone', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'email', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'partnerUuid', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'partnerName', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'competence', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'lmk', value: null });
    this.context.commit('SET_MODEL_VALUE', { key: 'lmkNumber', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'role', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'split', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'vaccinationStatus', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'underage', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'underageLink', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'sleeping', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isTest', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'sharing', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkId', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkStatus', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'humanRocketWorkStatus', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'inn', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bank', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentAccount', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'paymentType', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardNumber', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'ks', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'bic', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isSelfEmployed', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'hasMobileApp', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'onDemandPaymentEnabled', value: false });

    this.context.commit('SET_CREATED_AT', '');
    this.context.commit('SET_UPDATED_AT', '');
    this.context.commit('SET_MODEL_VALUE', { key: 'splitInfo', value: '' });
  }

  @Action({ rawError: true })
  closeDuplicateModal() {
    this.context.commit('SET_SHOW_DUPLICATE_MODAL', false);
  }

  @Action({ rawError: true })
  async deleteDuplicate(duplicateId: string) {
    try {
      return await deleteDuplicate(duplicateId);
    } catch (error) {
      if (error) {
        ResponseHandlerModule.showNotify({
          message: (error as CatchFormResponse).response.data.message,
          type: 'fail',
        });
      } else {
        ResponseHandlerModule.showNotify({
          message: 'Ошибка',
          type: 'fail',
        });
      }
    }
  }

  @Action({ rawError: true })
  blockSendRocketWorkStatus(id: string): void {
    const blockPeriodMs = 15 * 60 * 1000;
    const blockUntil = Date.now() + blockPeriodMs;

    this.setRocketWorkStatusSendBlockUntil({ employeeId: id, timestamp: blockUntil });
    this.context.commit('ROCKET_WORK_STATUS_SEND_BLOCK_UNTIL', blockUntil);
  }

  getRocketWorkStatusSendBlockUntil(): Record<string, number>;
  getRocketWorkStatusSendBlockUntil(id: string): number;

  @Action({ rawError: true })
  getRocketWorkStatusSendBlockUntil(id?: string) {
    let result: Record<string, number> | number = JSON.parse(
      window.localStorage.rocketWorkStatusSendBlockUntil ?? '{}'
    ) as Record<string, number>;

    if (id) {
      result = result[id];
    }

    return result;
  }

  @Action({ rawError: true })
  setRocketWorkStatusSendBlockUntil(payload: { employeeId: string; timestamp: number }) {
    const storageValue = this.getRocketWorkStatusSendBlockUntil();
    const actualBlocks = Object.entries(storageValue).filter(([, value]) => value > Date.now());
    const blockedEmployees = Object.fromEntries(actualBlocks);

    blockedEmployees[payload.employeeId] = payload.timestamp;
    window.localStorage.rocketWorkStatusSendBlockUntil = JSON.stringify(blockedEmployees);
  }
}

export default getModule(EmployeeEntityModule);
