<template>
  <div class="markdown-info-wrapper">
    <table class="markdown-info">
      <colgroup>
        <col width="240" />
        <col width="240" />
        <col />
      </colgroup>
      <thead class="markdown-info__header">
        <tr>
          <td>Разметка</td>
          <td>Пример</td>
          <td>Проверьте свой текст</td>
        </tr>
      </thead>
      <tbody class="markdown-info__body">
        <template v-for="(title, key) in titles" :key="key">
          <tr class="markdown-info-title">
            <td colspan="2">
              {{ title }}
            </td>

            <td class="markdown-info-example" v-if="key === 'appearance'" rowspan="12">
              <form-textarea
                v-model="markdown"
                placeholder="Введите любой текст"
                class="markdown-info-example__input"
                @keyup.enter.stop
              />

              <div class="markdown-info-example__html" v-html="html" />
            </td>
          </tr>

          <tr v-for="(item, index) in rows[key]" :key="`${key}_${index}`">
            <td>
              <pre>{{ item }}</pre>
            </td>
            <td>
              <span v-html="marked(item)" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import './markdown-info.scss';
import { defineComponent } from 'vue';
import FormTextarea from '@/components/form/Textarea';
import marked from 'marked';

export default defineComponent({
  name: 'MarkdownInfo',
  components: { FormTextarea },
  data: () => {
    return {
      markdown: '',
      titles: {
        appearance: 'Внешний вид',
        headers: 'Заголовки',
        links: 'Ссылка',
        lists: 'Списки',
      },
      rows: {
        appearance: ['*Выделение*', '**Жирный**', '***Жирный и выделение***', '~~Выделение~~'],
        headers: ['# Заголовок 1 уровня', '## Заголовок 2 уровня'],
        links: ['[Gigant](https://gigwork.ru)'],
        lists: ['1. первое\n2. второе\n    - второе I\n    - второе II\n3. третье'],
      },
    };
  },
  computed: {
    html() {
      return marked.parse(this.markdown);
    },
  },
  methods: { marked },
});
</script>
