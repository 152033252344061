import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class MailingsFilter {
  filterHandlers = {
    update: 'mailingsFilter/updateFilter',
    reset: 'mailingsFilter/resetFilter',
    resetState: 'mailingsFilter/resetFilterState',
    removeSelected: 'mailingsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSearchEntity('name');
    filter.setPlaceholder('name', 'По названию');
    filter.setName('name', 'search_name');
    filter.setPreviewName('name', 'Название');
    filter.setIconClassName('name', 'icon-user-search-light');
    filter.setClassName('name', 'row-1');
    filter.setAction('name', 'mailingsFilter/updateSearch');

    filter.createDateIntervalPickerEntity('created');
    filter.setName('created', 'picker_date');
    filter.setPreviewName('created', 'Создана');
    filter.setTitle('created', 'Создана');
    filter.setDateIntervalList('created', {
      startDate: {
        key: 'createdAtFrom',
        type: 'date-picker',
        title: 'С даты',
        value: '',
        action: 'mailingsFilter/updateStartDate',
        clearAction: 'mailingsFilter/clearStartDate',
      },
      endDate: {
        key: 'createdAtTo',
        type: 'date-picker',
        title: 'До даты',
        value: '',
        action: 'mailingsFilter/updateEndDate',
        clearAction: 'mailingsFilter/clearEndDate',
      },
    });
    filter.setClassName('created', 'row-2');
    filter.setIconClassName('created', 'icon-clock');

    filter.createDateIntervalPickerEntity('send');
    filter.setName('send', 'picker_date');
    filter.setPreviewName('send', 'Отправлена');
    filter.setTitle('send', 'Отправлена');
    filter.setDateIntervalList('send', {
      startDate: {
        key: 'finishedAtFrom',
        type: 'date-picker',
        title: 'С даты',
        value: '',
        action: 'mailingsFilter/updateStartDate',
        clearAction: 'mailingsFilter/clearStartDate',
      },
      endDate: {
        key: 'finishedAtTo',
        type: 'date-picker',
        title: 'До даты',
        value: '',
        action: 'mailingsFilter/updateEndDate',
        clearAction: 'mailingsFilter/clearEndDate',
      },
    });
    filter.setClassName('send', 'row-3');
    filter.setIconClassName('send', 'icon-clock');

    this.filterModel = filter.filterModel;
  }
}
