
import { Component, Vue } from '@/lib/decorator';
import WarningModal from '@/components/modals/Warning.vue';
import ModalBlock from '@/components/Modal.vue';

import { EventBus } from '@/lib/event-bus';
import MarkdownInfo from '@/components/markdown/MarkdownInfo.vue';
import useHelpDeskChat from '@/composition/app/useHelpDeskChat';

@Component({
  components: {
    MarkdownInfo,
    WarningModal,
    ModalBlock,
  },
})
export default class AppWrapper extends Vue {
  modals: Record<string, boolean> = {
    markdown: false,
  };

  listenKeyUp(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      EventBus.$emit('pressEnter');
    }

    if (e.key === 'Esc' || e.keyCode === 27) {
      EventBus.$emit('pressEsc');
    }
  }

  showModal(params: { name: string; flag: boolean }) {
    const { name, flag } = params;
    const { modals } = this;
    if (name in modals) {
      modals[name] = flag;
    }
  }

  mounted() {
    document.addEventListener('keyup', this.listenKeyUp);
    EventBus.$on('showModal', this.showModal);

    const { init: initChat } = useHelpDeskChat();
    initChat();
  }

  unmounted() {
    document.removeEventListener('keyup', this.listenKeyUp);
    EventBus.$off('showModal', this.showModal);
  }
}
