/**
 * Customers store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getClientsList, getClients } from '@/api/client';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import userModule from '@/store/user';
import { Filter } from '@/lib/layouts/page/filter.interface';
import filterModel from '@/store/customers/filter';
import CustomersMainFilter from '@/store/customers/filterEntity';

export const MODULE_NAME = 'customers';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersModule extends PageBaseModule {
  filter: Filter;
  onlyActive = true;

  constructor(module: CustomersModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Клиенты');
    page.setTitleAdd('Добавление клиента');
    page.values.actionPagination = 'customers/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(CustomersMainFilter);
    this.filter.setFilterName('customersFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setBtnClassName('col-1-row-8');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  get hiddenTitles() {
    return 'hiddenTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.hiddenTitles
      ? this.savedSettings.hiddenTitles[MODULE_NAME]
      : [];
  }

  get orderTitles() {
    return 'orderTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.orderTitles
      ? this.savedSettings.orderTitles[MODULE_NAME]
      : [];
  }

  @Mutation
  SET_ONLY_ACTIVE(value: boolean) {
    this.onlyActive = value;
  }

  @Action({ rawError: true })
  async initList() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.filter.init();

    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      let filter = await this.getFilterForRequest();
      if (this.onlyActive) {
        filter += '&filters[3][id]=active&filters[3][value]=1';
      }
      const result = await getClientsList(this.pageSettings.pageCurrent, itemsQuery, filter);
      await this.setList(result);
    } catch (error) {
      console.error(error);
    }
  }

  @Action({ rawError: true })
  getFilterForRequest() {
    return this.filter.filterSettings.filter;
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    await this.getSettings();
    const uiTable = new UITable(table as any);
    uiTable.removeColumn('isTest');

    if (!userModule.userHasPermission('CAN_READ_CLIENT_PHONE')) {
      uiTable.removeColumn('phone');
    }

    uiTable
      .setProperties('markets', { id: 'markets', name: 'Кол-во объектов' })
      .setHiddenTitles(this.hiddenTitles)
      .setOrderTitles(this.orderTitles);

    const tableInfo = uiTable.getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async saveSettingsToStore(headers: { hidden: TableHeaderInterface[]; visible: TableHeaderInterface[] }) {
    await this.saveSettings({ name: MODULE_NAME, headers });
  }

  @Action({ rawError: true })
  async getAll() {
    try {
      const result: { table: TableApiInterface } = await getClients();
      await this.context.dispatch('setList', result.table);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async updateOnlyActive(value: boolean) {
    await this.context.commit('SET_ONLY_ACTIVE', value);
    await store.dispatch('customers/updatePage', 1);
    await this.getList();
  }
}

export default getModule(CustomersModule);
