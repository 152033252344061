export type PaymentsModalParamsType = Record<
  string,
  Record<
    string,
    | string
    | boolean
    | Record<
        string,
        Record<
          string,
          string | number | boolean | Record<string | number, string | number | Record<string, string | number | boolean>>
        >
      >
  >
>;

export const modalParams: PaymentsModalParamsType = {
  dateRange: {
    model: {
      dateStart: {
        key: 'dateStart',
        type: 'text',
        title: '',
        value: '',
        action: 'payments/updateDateStart',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
      dateEnd: {
        key: 'dateEnd',
        type: 'text',
        title: '',
        value: '',
        action: 'payments/updateDateEnd',
        required: true,
        size: 40,
        mask: {
          regex: '\\d*',
          placeholder: ' ',
          minChars: 1,
        },
        error: {
          class: '',
          message: '',
        },
        validation: {
          empty: 'Поле не заполено',
        },
      },
    },
  },
  requisites: {
    isShow: false,
    title: 'Реквизиты',
    item: {},
    model: {},
  },
  payments: {
    isShow: false,
    title: 'Оплатить ВСЕМ',
  },
  payment: {
    isShow: false,
    title: 'Добавить выплату',
  },
  export: {
    isShow: false,
    title: 'Выгрузка данных',
    model: {
      createPayouts: {
        key: 'createPayouts',
        name: 'createPayouts',
        type: 'checkbox',
        list: {
          0: {
            id: 1,
            name: 'Создать выплаты',
            checked: false,
          },
        },
        handler: 'payments/updateCreatePayouts',
      },
    },
  },
};
