/**
 * Guard check if user is authenticated.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';

import userModule from '@/store/user';

const guard: NavigationGuard = (to: RouteLocationNormalized, _, next): void => {
  if (to.meta.requiresAuth && !userModule.token) {
    next({ name: 'login' });
  } else if (to.name === 'login' && userModule.token && userModule.token?.length > 0) {
    next({ name: 'home' });
  } else {
    next();
  }
};

export default guard;
