import { watch } from 'vue';
import UserModule from '@/store/user';
import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
  filterHandlers = {
    update: 'paymentsFilter/updateFilter',
    reset: 'paymentsFilter/resetFilter',
    resetState: 'paymentsFilter/resetFilterState',
    removeSelected: 'paymentsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fio');
    filter.setPlaceholder('fio', 'По ФИО');
    filter.setName('fio', 'search_fio');
    filter.setPreviewName('fio', 'ФИО');
    filter.setIconClassName('fio', 'icon-user-search-light');
    filter.setAction('fio', 'paymentsFilter/updateSearch');

    filter.createSearchEntity('minPayout');
    filter.setPlaceholder('minPayout', 'По минимальной сумме к выплате');
    filter.setName('minPayout', 'search_minPayout');
    filter.setPreviewName('minPayout', 'Мин');
    filter.setClassName('minPayout', 'minPayout');
    filter.setIconClassName('minPayout', 'icon-payments');
    filter.setAction('minPayout', 'paymentsFilter/updateSearch');
    filter.setMask('minPayout', {
      alias: 'numeric',
      allowMinus: false,
      rightAlign: false,
      showMaskOnHover: false,
      digits: 2,
    });

    filter.createSearchEntity('bic');
    filter.setPlaceholder('bic', 'По БИК');
    filter.setName('bic', 'search_bic');
    filter.setPreviewName('bic', 'БИК');
    filter.setClassName('bic', 'col-1-row-3');
    filter.setIconClassName('bic', 'icon-tax');
    filter.setAction('bic', 'paymentsFilter/updateSearch');
    filter.setMask('bic', {
      regex: '[0-9]{9}',
      placeholder: ' ',
      showMaskOnHover: false,
      maxChars: 9,
    });

    filter.createSelectEntity('paymentType');
    filter.setTitle('paymentType', 'По типу оплаты');
    filter.setName('paymentType', 'select_partnerUuid');
    filter.setPreviewName('paymentType', 'По типу оплаты');
    filter.setInitAction('paymentType', 'payments/initPaymentType');
    filter.setClassName('paymentType', 'col-1-row-4');
    filter.setIconClassName('paymentType', 'icon-finance');
    filter.setAction('paymentType', 'paymentsFilter/updateSelect');
    filter.setSearchEnabled('paymentType', true);

    filter.createSelectEntity('partnerUuid');
    filter.setTitle('partnerUuid', 'По партнёру');
    filter.setName('partnerUuid', 'select_partnerUuid');
    filter.setPreviewName('partnerUuid', 'По партнёру');
    filter.setInitAction('partnerUuid', 'payments/initPartnersFilter');
    filter.setClassName('partnerUuid', 'col-1-row-5');
    filter.setIconClassName('partnerUuid', 'icon-partners');
    filter.setAction('partnerUuid', 'paymentsFilter/updateSelect');
    filter.setSearchEnabled('partnerUuid', true);
    watch(
      () => UserModule.securityGroup?.isSupervisor,
      (isSupervisor = false) => {
        filter.setIsHidden('partnerUuid', !isSupervisor);
      }
    );

    filter.createCheckboxEntity('isSelfEmployed');
    filter.setTitle('isSelfEmployed', 'Самозанятый');
    filter.setName('isSelfEmployed', 'checkbox_isSelfEmployed');
    filter.setPreviewName('isSelfEmployed', 'Самозанятый');
    filter.setIgnoreNull('isSelfEmployed', true);
    filter.setListElement('isSelfEmployed', {
      id: 'isSelfEmployed',
      name: 'Самозанятый',
      checked: false,
    });
    filter.setAction('isSelfEmployed', 'paymentsFilter/updateCheckbox');
    filter.setClassName('isSelfEmployed', 'col-2-row-1');
    filter.setIconClassName('isSelfEmployed', 'icon-is-active');

    filter.createCheckboxEntity('requisites');
    filter.setTitle('requisites', 'Реквизиты заполнены');
    filter.setName('requisites', 'checkbox_requisites');
    filter.setPreviewName('requisites', 'Реквизиты');
    filter.setIgnoreNull('requisites', true);
    filter.setListElement('requisites', {
      id: 'requisites',
      name: 'Реквизиты заполнены',
      checked: false,
    });
    filter.setAction('requisites', 'paymentsFilter/updateCheckbox');
    filter.setClassName('requisites', 'col-2-row-2');
    filter.setIconClassName('requisites', 'icon-is-active');

    filter.createCheckboxEntity('bonus');
    filter.setTitle('bonus', 'Доп. начисления');
    filter.setName('bonus', 'checkbox_bonus');
    filter.setPreviewName('bonus', 'Доп. начисления');
    filter.setListElement('bonus', {
      id: 'bonus',
      name: 'Доп. начисления',
      checked: false,
    });
    filter.setAction('bonus', 'paymentsFilter/updateCheckbox');
    filter.setClassName('bonus', 'col-2-row-3');
    filter.setIconClassName('bonus', 'icon-finance');
    filter.setIgnorePreviewOnNull('bonus', true);

    filter.createCheckboxEntity('rocketwork');
    filter.setTitle('rocketwork', 'РокетВорк');
    filter.setName('rocketwork', 'checkbox_rocketwork');
    filter.setPreviewName('rocketwork', 'РокетВорк');
    filter.setListElement('rocketwork', {
      id: 'rocketwork',
      name: 'РокетВорк',
      checked: false,
    });
    filter.setAction('rocketwork', 'paymentsFilter/updateCheckbox');
    filter.setClassName('rocketwork', 'col-2-row-4');
    filter.setIgnorePreviewOnNull('rocketwork', true);
    filter.setIconClassName('rocketwork', 'icon-is-active');

    filter.createCheckboxEntity('isNotTest');
    filter.setTitle('isNotTest', 'Без тестовых');
    filter.setName('isNotTest', 'checkbox_isTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'paymentsFilter/updateCheckbox');
    filter.setClassName('isNotTest', 'col-2-row-5');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
