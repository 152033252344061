import { TabItemInterface, TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface & {
  [key: string]: TabItemInterface & { onlyForOwner?: boolean };
} = {
  shift: {
    id: 'shift',
    name: 'Заказы',
    template: 'shift',
    pathName: 'shop_shift',
    icon: 'shift',
    iconSize: '16',
  },
  profile: {
    id: 'profile',
    name: 'Профиль',
    template: 'profile',
    pathName: 'shop_edit',
    icon: 'store',
    iconSize: '14',
  },
  employees: {
    id: 'employees',
    name: 'Исполнители',
    template: 'employees',
    pathName: 'shop_employees',
    icon: 'employee',
    iconSize: '16',
  },
  statistics: {
    id: 'statistics',
    name: 'Статистика',
    template: 'statistics',
    pathName: 'shop_statistics',
    icon: 'analytics',
    iconSize: '16',
    onlyForOwner: true,
  },
  logs: {
    id: 'logs',
    name: 'Логи',
    template: 'logs',
    pathName: 'shop_logs',
    icon: 'log',
    iconSize: '15',
  },
  subscriptions: {
    id: 'subscriptions',
    name: 'Уведомления',
    template: 'subscriptions',
    pathName: 'shop_subscriptions',
    icon: 'tax',
    iconSize: '15',
  },
};
