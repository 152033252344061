/**
 * Global API for vacancies of client.
 *
 */

import API from '@/config/api-routers';
import { makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { ClientVacancyModel, TotalVacancyModel } from '@/interfaces/models/vacancy.interface';

/**
 * Get vacancy info by client id
 *
 * @param id
 *
 * @access CAN_READ_SHIFT_TEMPLATE
 */
export const getVacancyId = async (id: string) => {
  const url = API.vacancies.read.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.vacancy;
};

/**
 * Add new vacancy
 *
 * @param customerId
 * @param data
 *
 * @access CAN_EDIT_SHIFT_TEMPLATE
 */
export const addNewVacancy = async (customerId: string, data: {}) => {
  const url = API.vacancies.create.replace('$1', customerId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save vacancy
 *
 * @param id
 * @param data
 *
 * @access CAN_EDIT_SHIFT_TEMPLATE
 */
export const saveVacancy = async (id: string, data: {}) => {
  const url = API.vacancies.update.replace('$1', id);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get all vacancies
 *
 * @access CAN_READ_SHIFT_TEMPLATE
 */
export const getTotalVacancies = async (): Promise<TotalVacancyModel[]> => {
  const url = API.vacancies.total;
  const result = await makeGetRequest(url);

  return result.data.result.shiftTemplates;
};

/**
 * Get vaccination requirements
 */
export const getAllVaccinationStatusList = async () => {
  const url = API.vacancies.getVaccinationRequirements;
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get client vacancy list
 *
 * @param id
 * @param pageCurrent
 * @param pageAmountItems
 * @param filter
 *
 * @access CAN_READ_SHIFT_TEMPLATE
 */
export const getClientVacanciesById = async (id: string, pageCurrent: number, pageAmountItems: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.vacancies.getList.replace('$1', id));
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.table;
};

/**
 * Get all vacancies by store
 *
 * @param shopId
 *
 * @access CAN_READ_SHIFT_TEMPLATE
 */
export const getAllVacanciesByShopId = async (shopId: string) => {
  const url = API.vacancies.getAllByStore.replace('$1', shopId);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get all vacancies
 *
 * @param clientId
 */
export const getAllVacancies = async (clientId?: string) => {
  clientId = clientId && parseInt(clientId) !== 0 ? `${clientId}/` : '';
  const url = API.vacancies.getAll.replace('$1', clientId);
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get vacancies by client ids
 *
 * @param clientIds
 */
export const getActiveVacancyByClients = async (clientIds?: number[]): Promise<{ serviceTypes: ClientVacancyModel[] }> => {
  const url = API.vacancies.getActiveByClients + '?clients[]=' + clientIds?.join('&clients[]=');
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * get all vacancies list with clients
 */
export const getVacanciesWithClientsList = async () => {
  const url = API.vacancies.getAllWithClients;
  const result = await makeGetRequest(url);

  return result.data.result.vacancies;
};
