import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  schedule: {
    id: 'schedule',
    name: 'Календарь',
    template: 'schedule',
    pathName: 'employees_schedule',
    icon: 'calendar-alt',
    iconSize: '16',
  },
  points: {
    id: 'points',
    name: 'Точки',
    template: 'points',
    pathName: 'employee_points',
    icon: 'marker',
    iconSize: '15',
  },
  profile: {
    id: 'profile',
    name: 'Профиль',
    template: 'profile',
    pathName: 'employee_edit',
    icon: 'profile',
    iconSize: '15',
  },
  logs: {
    id: 'logs',
    name: 'Логи',
    template: 'logs',
    pathName: 'employee_logs',
    icon: 'log',
    iconSize: '15',
  },
  relations: {
    id: 'relations',
    name: 'Отношения',
    template: 'relations',
    pathName: 'employee_relations',
    icon: 'smile-love',
    iconSize: '18',
  },
  ratings: {
    id: 'ratings',
    name: 'Оценки',
    template: 'ratings',
    pathName: 'employee_ratings',
    icon: 'star',
    iconSize: '18',
  },
};
