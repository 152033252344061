/**
 * Shops store.
 *
 */

import { watch } from 'vue';
import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import UserModule from '@/store/user';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import filterModel from './filter';
import { prepareList } from '@/lib/Shop';
import { getShopsList } from '@/api/shop';
import { getClients } from '@/api/client';
import { deleteShopById } from '@/api/shop';
import { getServiceTypeAll } from '@/api/templates';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getRegionsAll } from '@/api/region';
import ModalsModule from '@/store/modals';

import { PageSort } from '@/lib/layouts/page/page.interface';
import { Checkbox } from '@/interfaces/filter.interface';
import { GeoHelperItem } from '@/interfaces/geoHelper.interface';
import { getAllGeoHelpers, getClientGeoHelperById } from '@/api/geo';
import FilterModule from '@/store/filter';
import { Filter } from '@/lib/layouts/page/filter.interface';

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { getAddressViaCoordinates } from '@/api/geo';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import userModule from '@/store/user';
import { Router } from 'vue-router';
import { PartnerSelectListItem } from '@/interfaces/partner.interface';
import { getAllPartnersList } from '@/api/partners';
import { FormResponse } from '@/interfaces/shared';

@Module({ dynamic: true, store, name: 'shopsFilterModule', namespaced: true })
class ShopsFilterModule extends FilterModule {
  constructor(module: ShopsFilterModule) {
    super(module);
  }
}

export const MODULE_NAME = 'shops';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopsModule extends PageBaseModule {
  modalParams = {
    delete: {
      id: '',
      router: null,
      name: '',
      text: 'Ты действительно хочешь удалить этот объект?',
      handlers: {
        yes: 'shops/deleteShopById',
        no: '',
      },
    },
  };
  defaultSelectValue = {
    id: '0',
    value: '',
  };
  filter: Filter;
  selectedAddress = {};

  constructor(module: ShopsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Объекты');
    page.setTitleAdd('Добавление объекта');
    page.values.actionPagination = 'shops/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(ShopsFilterModule);
    this.filter.setFilterName('shopsFilter');
    this.filter.setTemplateClassName('template-lg');
    this.filter.setBtnClassName('col-1-row-8');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    watch(
      () => UserModule.isSupervisor,
      (isSupervisor = false) => {
        if (!isSupervisor) {
          this.filter.resetMultiSelect('partners');
          this.filter.resetSelect('assistant');
        }
      },
      { immediate: true }
    );
  }

  get shopDetailLink() {
    if (userModule.canUserRead('shop_shift')) {
      return 'shop_shift';
    }

    if (userModule.canUserRead('shop_statistics')) {
      return 'shop_statistics';
    }

    if (userModule.canUserRead('shop_logs')) {
      return 'shop_logs';
    }

    return '';
  }

  get hiddenTitles() {
    return 'hiddenTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.hiddenTitles
      ? this.savedSettings.hiddenTitles[MODULE_NAME]
      : [];
  }

  get orderTitles() {
    return 'orderTitles' in this.savedSettings && MODULE_NAME in this.savedSettings.orderTitles
      ? this.savedSettings.orderTitles[MODULE_NAME]
      : [];
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.shopsSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_DELETE_ID(id: string) {
    this.modalParams.delete.id = id;
  }

  @Mutation
  SET_SELECTED_ADDRESS(address: { address: string; latitude: number; longitude: number }) {
    this.selectedAddress = address;
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit('SET_SORT', window.localStorage.shopsSort ? JSON.parse(window.localStorage.shopsSort) : {});
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const table = await getShopsList(this.pageSettings.pageCurrent, itemsQuery, sort, this.filter.filterSettings.filter);
      await this.context.dispatch('setList', table);
    } catch (error) {
      if (error.response?.data) {
        ResponseHandlerModule.showNotify({
          message: error.response.data,
          type: 'fail',
        });

        return;
      }
      ResponseHandlerModule.showNotify({ message: error, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    await this.getSettings();
    const hidden = this.hiddenTitles;
    const order = this.orderTitles;
    const sort = Object.values(this.pageSettings.sort);
    this.context.commit('SET_TABLE', prepareList(table, { hidden, order, sort }));
  }

  @Action({ rawError: true })
  async saveSettingsToStore(headers: { hidden: TableHeaderInterface[]; visible: TableHeaderInterface[] }) {
    await this.saveSettings({ name: MODULE_NAME, headers });
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async deleteShopById(params: { id: string; router: Router }) {
    try {
      const result = await deleteShopById(params.id);

      if (!(result as FormResponse).message) {
        ResponseHandlerModule.showNotify({
          message: 'Объект удалён',
          type: 'ok',
        });
        await params.router.push({ name: 'shops' });
      } else {
        ResponseHandlerModule.showNotify({
          message: (result as FormResponse).message,
          type: 'fail',
        });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.message,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    try {
      const result: { clients: { id: string; name: string }[] } = await getClients();

      return Object.values(result.clients).map((client: { id: string; name: string }) => {
        return {
          id: client.id,
          value: client.name,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initRegionsSelect() {
    try {
      const regionsAll: { id: string; name: string }[] = await getRegionsAll();

      return Object.values(regionsAll).map((region: { id: string; name: string }) => {
        return {
          id: region.id,
          value: region.name,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action
  async initAssistantsSelect() {
    if (!UserModule.isSupervisor) {
      return;
    }

    const result = await getAllGeoHelpers();

    return result.map((item: GeoHelperItem) => {
      item.value = '';
      item.value += item.firstName ? item.firstName : '';
      item.value += item.middleName ? ` ${item.middleName}` : '';
      item.value += item.lastName ? ` ${item.lastName}` : '';

      return item;
    });
  }

  @Action({ rawError: true })
  async initServiceTypes() {
    try {
      const serviceTypes = await getServiceTypeAll();
      const filterModel = (this.filter.filterSettings.filterModel.serviceType?.list ?? []) as Checkbox[];

      return serviceTypes.map((serviceType) => {
        const filterModelItem = filterModel.find((filterItem) => filterItem.id == serviceType.id);
        const checked = filterModelItem?.checked ?? false;

        return {
          id: serviceType.id,
          value: serviceType.id,
          name: serviceType.name,
          checked,
        };
      });
    } catch (e) {
      return [];
    }
  }

  @Action({ rawError: true })
  async updateClients(params: { clear?: boolean; key: string; value: { id: number; value: string } }) {
    let error = false;

    this.filter.updateSelect({
      key: params.key,
      value: params.value,
    });

    if (!params.value.id) {
      let initAssistants = [];

      if (UserModule.isSupervisor) {
        initAssistants = await this.initAssistantsSelect();
      }

      this.filter.updateSelectList({
        key: 'assistant',
        list: initAssistants,
      });

      error = true;
    }

    if (!params.clear) {
      this.filter.resetSelect('assistant');
    }

    if (error) {
      return;
    }

    if (UserModule.isSupervisor) {
      const resultHelpers = await getClientGeoHelperById(params.value.id.toString());
      const helpersProcessed = await this.updateSelectAssistantList(resultHelpers.rows);
      this.filter.updateSelectList({
        key: 'assistant',
        list: helpersProcessed,
      });
    }
  }

  @Action({ rawError: true })
  updateSelectAssistantList(list: GeoHelperItem[]) {
    list = list.map((item: GeoHelperItem) => {
      item.value = '';
      item.value += item.firstName ? item.firstName : '';
      item.value += item.middleName ? ` ${item.middleName}` : '';
      item.value += item.lastName ? ` ${item.lastName}` : '';

      return item;
    });

    return list;
  }

  @Action({ rawError: true })
  updateCheckboxVacancyList(list: { id: number; name: string }[]) {
    const filterModelVacancies: Checkbox[] = this.filter.filterSettings.filterModel.vacancies.list as Checkbox[];

    return list.map((vacancy: { id: number; name: string }) => {
      const match = filterModelVacancies.filter((item: Checkbox) => item.id == vacancy.id)[0];
      const checked = match ? match.checked : false;

      return {
        id: vacancy.id,
        value: vacancy.id,
        name: vacancy.name,
        checked: checked,
      };
    });
  }

  @Action({ rawError: true })
  clearShopsSort() {
    this.context.commit('SET_SORT', {});
  }

  @Action({ rawError: true })
  deleteShopModal(shopId: string) {
    this.context.commit('SET_DELETE_ID', shopId);
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  updateSelectedAddress(coordinates: { latitude: number; longitude: number }) {
    getAddressViaCoordinates(coordinates.latitude, coordinates.longitude).then((addressString) => {
      if (addressString !== '') {
        this.context.commit('SET_SELECTED_ADDRESS', {
          address: addressString,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
        });
      }
    });
  }

  @Action({ rawError: true })
  async initPartnersSelect() {
    try {
      if (!UserModule.isSupervisor) {
        return [];
      }

      const partnersAll: PartnerSelectListItem[] = await getAllPartnersList();

      return Object.values(partnersAll)
        .filter((partner: PartnerSelectListItem) => partner.isSupplier)
        .map((partner: PartnerSelectListItem) => {
          return {
            id: partner.uuid,
            value: partner.legalName,
            name: partner.legalName,
          };
        });
    } catch (e) {
      return [];
    }
  }
}

export default getModule(ShopsModule);
