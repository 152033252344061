import { TableApiInterface, TableTitle } from '@/lib/layouts/page/table.interface';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { FilterPreview } from '@/store/filter';

const defaultTitleOptions = {
  id: '',
  name: '',
  default: '-',
  visible: false,
  sort: {},
  swappable: true,
};

export function assembleTitle(titleOptionsServer: TableTitle, additionalOptions: any = {}) {
  return {
    ...defaultTitleOptions,
    ...titleOptionsServer,
    ...additionalOptions,
  };
}

type PropertyValueInterface = string | number | boolean | Record<string, string | number>;

export class UITable {
  titles: TableTitle[] = [];
  hiddenTitles: TableTitle[] = [];
  titlesKeyToIndex: { [index: string]: number } = {};
  rows: { [index: string]: string | number | boolean }[] = [];
  pagination: { currentPage: number; totalItems: number; totalPages: number } | {} = {};
  totalItems = 0;
  filters: { name: string; value: string | null }[] = [];
  totalData?: { [index: string]: string | number } = {};
  additionalInfo?: { [index: string]: string } = {};

  constructor(
    table:
      | {
          titles?: TableTitle[];
          rows?: { [index: string]: string | number | boolean }[];
          pagination?: { currentPage: number; totalItems: number; totalPages: number };
          totalItems?: number;
          sortFields?: string[];
          filters?: { name: string; value: string | null }[];
          totalData?: { [index: string]: string | number };
          additionalInfo?: { [index: string]: string };
        }
      | TableApiInterface = {}
  ) {
    const {
      titles = [],
      rows = [],
      pagination = {},
      totalItems = 0,
      filters,
      totalData = {},
      sortFields = [],
      additionalInfo = {},
    } = table;

    this.titles = titles
      .filter((title: TableTitle) => title.visible)
      .map((title: TableTitle, index: number) => {
        this.titlesKeyToIndex[title.id] = index;

        return {
          ...defaultTitleOptions,
          ...title,
          sortable: sortFields.includes(title.id),
        };
      });

    this.rows = rows;
    this.pagination = pagination || {};
    this.totalItems = totalItems;
    this.totalData = totalData;
    this.additionalInfo = additionalInfo;
    this.filters = filters as { name: string; value: string | null }[];
  }

  removeColumn(id: string) {
    if (!(id in this.titlesKeyToIndex)) {
      return this;
    }

    const index = this.titlesKeyToIndex[id];

    this.titles.splice(index, 1);

    Object.keys(this.titlesKeyToIndex).forEach((key: string) => {
      const elIndex = this.titlesKeyToIndex[key];

      if (elIndex < index) {
        return;
      }

      this.titlesKeyToIndex[key] = elIndex - 1;
    });

    return this;
  }

  addColumn(title: TableTitle) {
    if (title.id in this.titlesKeyToIndex) {
      return this;
    }

    const newTitle = {
      ...defaultTitleOptions,
      ...title,
    };

    this.titlesKeyToIndex[title.id] = this.titles.length;
    this.titles.push(newTitle);

    return this;
  }

  addColumnByIndex(index: number, title: TableTitle) {
    if (title.id in this.titlesKeyToIndex) {
      return this;
    }

    const newTitle = {
      ...defaultTitleOptions,
      ...title,
    };

    this.titles.splice(index, 0, newTitle);

    Object.keys(this.titlesKeyToIndex).forEach((key: string) => {
      const elIndex = this.titlesKeyToIndex[key];

      if (elIndex < index) {
        return;
      }

      this.titlesKeyToIndex[key] = elIndex + 1;
    });

    this.titlesKeyToIndex[title.id] = index;

    return this;
  }

  deleteColumnByIndex(index: number) {
    if (!(index in this.titles)) {
      return this;
    }

    this.titles.splice(index, 1);

    const newTitlesKeyToIndex: { [index: string]: number } = {};
    Object.keys(this.titlesKeyToIndex).forEach((key: string) => {
      const elIndex = this.titlesKeyToIndex[key];

      if (elIndex === index) {
        return;
      }

      newTitlesKeyToIndex[key] = elIndex < index ? elIndex : elIndex - 1;
    });
    this.titlesKeyToIndex = newTitlesKeyToIndex;

    return this;
  }

  changeTitleOrder(id: string, newIndex: number) {
    if (!(id in this.titlesKeyToIndex)) {
      return this;
    }

    const index = this.titlesKeyToIndex[id];
    const el = this.titles[index];

    this.deleteColumnByIndex(index);
    this.addColumnByIndex(newIndex, el);

    return this;
  }

  setProperties(id: string, title: TableTitle) {
    if (!(id in this.titlesKeyToIndex)) {
      return this;
    }

    const index = this.titlesKeyToIndex[id];
    this.titles[index] = {
      ...this.titles[index],
      ...title,
    };

    return this;
  }

  setProperty(id: string, property: string, value: PropertyValueInterface) {
    if (!(id in this.titlesKeyToIndex)) {
      return this;
    }

    const index = this.titlesKeyToIndex[id];
    // @ts-ignore
    this.titles[index][property] = value;

    return this;
  }

  setPropertySomeColumns(ids: string[], property: string, value: PropertyValueInterface) {
    ids.forEach((id: string) => this.setProperty(id, property, value));
  }

  addRow(row: { [index: string]: string | number }) {
    this.rows.push(row);

    return this;
  }

  setTotalData(data: Record<string, string | number>) {
    this.totalData = data;

    return this;
  }

  setSortableValues(values?: { sort: string; key: string }[]) {
    if (!values || !values.length) {
      return this;
    }

    values.forEach((el: { sort: string; key: string }, index: number) => {
      const sort = { value: el.sort, id: index + 1 };
      this.setProperty(el.key, 'sort', sort);
    });

    return this;
  }

  setHiddenTitles(titles?: TableTitle[]) {
    if (!titles || !titles.length) {
      return this;
    }
    this.hiddenTitles = [];
    titles.forEach((title: TableTitle) => {
      if (!(title.id in this.titlesKeyToIndex)) {
        return false;
      }

      const index = this.titlesKeyToIndex[title.id];
      const element = this.titles[index];

      this.hiddenTitles.push(element);
      this.removeColumn(title.id);
    });

    return this;
  }

  setOrderTitles(titles?: TableTitle[]) {
    if (!titles || !titles.length || this.titles.length !== titles.length) {
      return this;
    }

    this.titles = titles.map((title: TableTitle) => {
      const index = this.titlesKeyToIndex[title.id];

      return this.titles[index];
    });

    return this;
  }

  getTable() {
    return {
      titles: this.titles,
      rows: this.rows,
      pagination: this.pagination,
      totalItems: this.totalItems,
      filters: this.filters,
      totalData: this.totalData,
      additionalInfo: this.additionalInfo,
      hiddenTitles: this.hiddenTitles,
    };
  }
}

export function getFilterQuery(filters: Record<string, unknown>): string {
  const searchParams = new URLSearchParams();
  let i = 0;
  Object.keys(filters).forEach((key) => {
    if (!filters[key]) {
      return;
    }

    if (Array.isArray(filters[key])) {
      // @ts-ignore
      if (filters[key].length === 0) {
        return;
      }

      searchParams.append('filters[' + i + '][id]', key);
      // @ts-ignore
      filters[key].forEach((item, j) => {
        searchParams.append('filters[' + i + '][value][' + j + ']', item);
      });
    } else {
      searchParams.append('filters[' + i + '][id]', key);
      // @ts-ignore
      searchParams.append('filters[' + i + '][value]', filters[key]);
    }
    i++;
  });

  return searchParams.toString();
}

export function getSortQuery(headers: TableHeaderInterface[]): string {
  const searchParams = new URLSearchParams();

  headers.forEach((header) => {
    if (header.sort && header.sort.id && header.sort.value) {
      searchParams.append('sort[' + header.sort.id + '][id]', header.id);
      searchParams.append('sort[' + header.sort.id + '][value]', header.sort.value);
    }
  });

  return searchParams.toString();
}

export function getFilterPreview(filters: Record<string, unknown>): Record<string, FilterPreview> {
  const obj = {} as Record<string, FilterPreview>;

  Object.keys(filters).forEach((key) => {
    // @ts-ignore
    if (Array.isArray(filters[key]) && filters[key].length == 0) {
      return;
    }

    // @ts-ignore
    if (!filters[key]) {
      return;
    }

    if (
      // @ts-ignore
      (typeof filters[key] === 'string' || filters[key] instanceof String) &&
      // @ts-ignore
      (filters[key].trim().length === 0 || filters[key] == '0')
    ) {
      return;
    }

    if (key === 'clients') {
      obj[key] = {
        name: 'Клиент',
        iterable: false,
        iconClass: 'icon-clients',
      };

      return;
    }

    if (key === 'fullMarketName') {
      obj[key] = {
        name: 'Объект',
        iterable: false,
        iconClass: 'icon-store',
      };

      return;
    }

    if (['vacancyId', 'serviceTypeId'].includes(key)) {
      obj[key] = {
        name: 'Тип услуги',
        iterable: false,
        iconClass: 'icon-position',
      };

      return;
    }

    if (key === 'partnerUuid') {
      obj[key] = {
        name: 'По партнёру',
        iterable: false,
        iconClass: 'icon-partners',
      };

      return;
    }

    if (key === 'regions') {
      obj[key] = {
        name: 'Регион',
        iterable: false,
        iconClass: 'icon-compass',
      };

      return;
    }

    if (key === 'onlyFree') {
      obj[key] = {
        name: 'Незакрытая потребность',
        iterable: false,
        iconClass: 'icon-is-active',
      };

      return;
    }

    if (key === 'withoutAdditional') {
      obj[key] = {
        name: 'Без доп. заказов',
        iterable: false,
        iconClass: 'icon-is-active',
      };

      return;
    }
  });

  return obj;
}

export function resetFilter(filters: unknown, key: string): void {
  // @ts-ignore
  if (Array.isArray(filters[key])) {
    // @ts-ignore
    filters[key] = [];

    return;
  }
  // @ts-ignore
  if (typeof filters[key] == 'string' || filters[key] instanceof String) {
    // @ts-ignore
    filters[key] = '';

    return;
  }

  // @ts-ignore
  filters[key] = null;
}

export function appendSortToHeaders(headers: TableHeaderInterface[], sortId: string): TableHeaderInterface[] {
  const header = headers.find((item) => item.id === sortId);

  if (!header) {
    return headers;
  }

  let maxIndex = 0;
  headers.forEach((item) => {
    if (item.sort?.id && Number(item.sort.id) > maxIndex) {
      maxIndex = Number(item.sort.id);
    }
  });

  if (!header.sort) {
    header.sort = {
      id: (maxIndex + 1).toString(),
      value: 'ASC',
    };
  } else if (header.sort?.value == 'ASC') {
    header.sort.value = 'DESC';
  } else {
    const deletedId = header.sort.id;
    header.sort = undefined;
    if (deletedId) {
      headers.forEach((item) => {
        if (item.sort?.id && Number(item.sort.id) > Number(deletedId)) {
          item.sort.id = (Number(item.sort.id) - 1).toString();
        }
      });
    }
  }

  return headers;
}
