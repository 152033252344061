import { ref } from 'vue';
import { Ref } from '@vue/reactivity/dist/reactivity';

export interface FormInputTypesParams {
  type: string;
}

export interface FormInputTypesResult {
  type: Ref<unknown>;
  typePassword?: boolean;
  isHidden?: Ref<boolean>;
  toggleType?: () => void;
}

export default function formInputTypes(params: FormInputTypesParams): FormInputTypesResult {
  const type = params.type;

  if (type === 'password') {
    const refType = ref(type);
    const isHidden = ref(true);

    const toggleType = () => {
      refType.value = refType.value === 'password' ? 'text' : 'password';

      isHidden.value = !isHidden.value;
    };

    return {
      type: refType,
      typePassword: true,
      isHidden: isHidden,
      toggleType,
    };
  }

  const resultType = ['number', 'email', 'date'].some((el) => el == type) ? 'text' : type;

  return {
    type: ref(resultType),
  };
}
