/*
 *
 * */

import { Map } from '@/lib/formFactory/map.interface';
export default class MapEntity {
  defaultValue: Map;
  entityName: string;
  entity: any;

  constructor(name: string) {
    this.defaultValue = {
      key: name,
      name: name,
      type: 'map',
      value: {},
      default: {
        latitude: 55.754871,
        longitude: 37.624113,
      },
      action: '',
    };
    this.entityName = name;
    this.entity = { [this.entityName]: this.defaultValue };
  }

  setType(type: string) {
    this.entity[this.entityName]['type'] = type;
  }

  setDefault(payload: { latitude: number; longitude: number }) {
    this.entity[this.entityName]['default'] = payload;
  }

  setValue(payload: { latitude: number; longitude: number }) {
    this.entity[this.entityName]['value'] = payload;
  }

  setAction(action: string) {
    this.entity[this.entityName]['action'] = action;
  }
}
