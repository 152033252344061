export const GIGANT_INN = '7839117549';

export const EMPTY_BODY = 'Нет данных для отображения';
export const EMPTY_BODY_BY_CRITERIA = 'По вашему запросу ничего не найдено, уточните критерии поиска';
export const EXCEED_LENGTH_MESSAGE = 'Превышено количество символов, при отправке может возникнуть сбой';

export enum PlanningMark {
  FromReserve = 'fromReserve',
  Auto = 'auto',
}

export const ShiftEmployeeStatusTitles = {
  came_out: 'Вышел',
  did_not_go_out: 'Не вышел',
  is_planned: 'Запланирован',
  refused_at_this_time: 'Отказался/время',
  refused_in_this_market: 'Отказался/место',
  fake_call: 'Ложный вызов',
  fast_replacement: 'Срочная замена',
  reserve: 'Резерв',
};

export enum ShiftEmployeeStatus {
  CameOut = 'came_out',
  DidNotGoOut = 'did_not_go_out',
  IsPlanned = 'is_planned',
  RefusedAtThisTime = 'refused_at_this_time',
  RefusedInThisMarket = 'refused_in_this_market',
  FakeCall = 'fake_call',
  FastReplacement = 'fast_replacement',
  Reserve = 'reserve',
}

export enum EmployeeViewType {
  Newbies = 'newbies',
  Statuses = 'statuses',
}

export enum NotificationMarker {
  Name = '{name}',
  Phone = '{phone}',
  ShiftDay = '{shift_day}',
  ShiftWeekday = '{shift_weekday}',
  ShiftMonth = '{shift_month}',
  ShiftMonthWhen = '{shift_month_when}',
  ShiftStart = '{shift_start}',
  ShiftEnd = '{shift_end}',
  Address = '{address}',
  Position = '{position}',
  Client = '{client}',
  HoursInterval = '{hours_interval}',
  DaysInterval = '{days_interval}',
  VacancyInfo = '{vacancy_info}',
  MarketInfo = '{market_info}',
  Duration = '{duration}',
  Practice = '{practice}',
  CompetenceInfo = '{competence_info}',
}
