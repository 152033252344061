import { TabsNavInterface } from '@/interfaces/tabs.interface';

export const tabsNav: TabsNavInterface = {
  cards_rating: {
    id: 'cards_rating',
    name: 'Антирейтинг',
    template: 'cards_rating',
    pathName: 'cards_rating',
    icon: 'smile-hated',
    iconSize: '16',
  },
  cards_database: {
    id: 'cards_database',
    name: 'БД карточек',
    template: 'cards_database',
    pathName: 'cards_database',
    icon: 'calendar-alt',
    iconSize: '16',
  },
};
