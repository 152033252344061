/**
 * Global API for client.
 *
 */

import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { CustomerMinResponseModel, CustomerResponseModel } from '@/interfaces/models/customer.interface';
import { BinaryBoolean, FormResponse } from '@/interfaces/shared';
import { ClientServiceType } from '@/views/customers/parts/composables/useClientServiceType';

/**
 * Get clients list
 *
 * @param pageCurrent
 * @param pageAmountItems
 * @param filter
 */
export const getClientsList = async (
  pageCurrent: number,
  pageAmountItems: string,
  filter = ''
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.client.getList);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addFilter(filter).getUrl();
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(url);

  return result.data.result.table;
};

/**
 * Get clients all
 */
export const getClients = async (
  isActive = true
): Promise<{ clients: CustomerResponseModel[]; table: TableApiInterface }> => {
  const assemblerUrl = new AssemblerUrl(API.client.getTotalList);

  if (isActive) {
    assemblerUrl.addFilter('isActive=1');
  }

  const url = assemblerUrl.getUrl();
  const result = await makeGetRequest(url);

  return result.data.result;
};

/**
 * Get client by Id
 *
 * @param id
 */
export const getClientById = async (id: string | number): Promise<CustomerResponseModel> => {
  const url = API.client.read.replace('$1', id.toString());
  const result = await makeGetRequest(url);

  return result.data.result.client;
};

/**
 * Get min client info by Id
 *
 * @param id
 */
export const getClientMinById = async (id: string): Promise<CustomerMinResponseModel> => {
  const url = API.client.readMin.replace('$1', id);
  const result = await makeGetRequest(url);

  return result.data.result.client;
};

/**
 * Add new client
 *
 * @param data
 *
 * @access CAN_EDIT_CLIENT
 */
export const addNewClient = async (data: {}): Promise<FormResponse> => {
  const url = API.client.create;
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Save client
 *
 * @param customerId
 * @param data
 *
 * @access CAN_EDIT_CLIENT
 */
export const saveClient = async (customerId: string, data: {}): Promise<FormResponse<{ client: CustomerResponseModel }>> => {
  const url = API.client.update.replace('$1', customerId);
  const result = await makeFormRequest(url, data);

  return result.data;
};

/**
 * Get client statistics
 *
 * @param customerId
 * @param filter
 *
 * @access CAN_EDIT_CLIENT
 */
export const getClientStatistics = async (customerId: string, filter: string) => {
  const assemblerUrl = new AssemblerUrl(API.client.statistics.replace('$1', customerId));
  const url = assemblerUrl.addFilter(filter).getUrl();
  const result = await makeGetRequest(url);

  return result.data.result.statistics;
};

/**
 * get client phone
 *
 * @param customerId
 * @access CAN_READ_CLIENT_PHONE
 */
export const getClientPhone = async (customerId: string) => {
  const url = API.client.getPhone.replace('$1', customerId);
  const result = await makeGetRequest(url);

  return result.data.result.phone;
};

/**
 * get lunch schemes
 *
 * @param shiftId
 * @access CAN_READ_SHIFT_LUNCH_SCHEMES
 */
export const getLunchSchemes = async (shiftId: string) => {
  const url = API.client.getLunchSchemes.replace('$1', shiftId);
  const result = await makeGetRequest<{ result: { lunch_schemes: { duration: number; isPaid: boolean }[] } }>(url);

  return result.data.result.lunch_schemes;
};

/**
 * get lunch schemes
 *
 * @param clientId
 * @access CAN_READ_CLIENT_LUNCH_SCHEMES
 */
export const getClientLunchSchemes = async (clientId: string) => {
  const url = API.client.getClientLunchSchemes.replace('$1', clientId);
  const result = await makeGetRequest<{ result: { lunch_schemes: { duration: number; isPaid: boolean }[] } }>(url);

  return result.data.result.lunch_schemes;
};

export const getServiceTypeList = async (clientId: string) => {
  const url = API.client.getServiceTypeList(clientId.toString());
  const result = await makeGetRequest<{
    result: { table: TableApiInterface<ClientServiceType> };
  }>(url);

  return result.data.result.table;
};

export const createServiceType = async (clientId: string, payload: { serviceType: string; rotation: BinaryBoolean }) => {
  const url = API.client.createServiceType(clientId);
  const result = await makeFormRequest(url, payload);

  return result.data;
};

export const updateServiceType = async (clientId: string, clientServiceTypeId: string, rotation: BinaryBoolean) => {
  const url = API.client.updateServiceType(clientId, clientServiceTypeId);
  const result = await makeFormRequest(url, { rotation });

  return result.data;
};

export const deleteServiceType = async (clientId: string, clientServiceTypeId: string) => {
  const url = API.client.deleteServiceType(clientId, clientServiceTypeId);

  return await makeDeleteRequest(url);
};
