/**
 * Reset region model.
 *
 */

import { NavigationGuard, RouteLocationNormalized } from 'vue-router';
import CustomerEntityModule from '@/store/customers/entity';
import CustomersHelperEntityModule from '@/store/customers/helpersEntity';
import CustomersVacanciesEntityModule from '@/store/customers/vacanciesEntity';
import CustomersTemplatesEntityModule from '@/store/customers/templatesEntity';

const guard: NavigationGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next): void => {
  if (to.name === 'customer_add' || to.name === 'customers') {
    CustomerEntityModule.resetModel();
  }

  if (to.name === 'helper_add' || to.name === 'helper_edit') {
    CustomersHelperEntityModule.resetModel();
  }

  if (to.name === 'vacancy_add' || from.name === 'vacancy_edit') {
    CustomersVacanciesEntityModule.resetModel();
  }

  if (to.name === 'template_add' || from.name === 'template_edit') {
    CustomersTemplatesEntityModule.resetModel();
  }

  next();
};

export default guard;
