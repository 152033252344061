/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class UsersFilter {
  filterHandlers = {
    update: 'settingsUsersFilter/updateFilter',
    reset: 'settingsUsersFilter/resetFilter',
    resetState: 'settingsUsersFilter/resetFilterState',
    removeSelected: 'settingsUsersFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По имени');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'Пользователь');
    filter.setIconClassName('fullName', 'icon-user-search');
    filter.setAction('fullName', 'settingsUsersFilter/updateSearch');
    filter.setClassName('fullName', 'col-1-row-1');

    filter.createSearchEntity('login');
    filter.setPlaceholder('login', 'По логину');
    filter.setName('login', 'search_login');
    filter.setPreviewName('login', 'Логин');
    filter.setIconClassName('login', 'icon-user');
    filter.setAction('login', 'settingsUsersFilter/updateSearch');
    filter.setClassName('login', 'col-2-row-1');

    filter.createSelectEntity('partner');
    filter.setIconClassName('partner', 'icon-partners');
    filter.setIconPreviewClassName('partner', 'icon-users');
    filter.setTitle('partner', 'Партнёр');
    filter.setPreviewName('partner', 'Партнёр');
    filter.setName('partner', 'select_partner');
    filter.setAction('partner', 'settingsUsersFilter/updateSelect');
    filter.setInitAction('partner', 'settingsUsers/initPartnersSelect');
    filter.setClearAction('partner', 'settingsUsersFilter/resetSelect');
    filter.setSearchEnabled('partner', true);
    filter.setClassName('partner', 'col-1-row-2');

    filter.createSelectEntity('securityGroup');
    filter.setIconClassName('securityGroup', 'icon-users');
    filter.setIconPreviewClassName('securityGroup', 'icon-users');
    filter.setTitle('securityGroup', 'Группа');
    filter.setPreviewName('securityGroup', 'Группа');
    filter.setName('securityGroup', 'select_securityGroup');
    filter.setAction('securityGroup', 'settingsUsersFilter/updateSelect');
    filter.setInitAction('securityGroup', 'settingsUsers/initSecurityGroupsSelect');
    filter.setClearAction('securityGroup', 'settingsUsersFilter/resetSelect');
    filter.setSearchEnabled('securityGroup', true);
    filter.setClassName('securityGroup', 'col-2-row-2');

    filter.createRadioEntity('isActive');
    filter.setName('isActive', 'radio_is_active');
    filter.setPreviewName('isActive', 'Акт');
    filter.setList('isActive', [
      { id: 0, name: 'Активность', checked: false, value: 'true' },
      { id: 1, name: '', checked: false },
      { id: 2, name: 'Неактивность', checked: false, value: 'false' },
    ]);
    filter.setAction('isActive', 'settingsUsersFilter/updateRadio');
    filter.setClassName('isActive', 'col-1-row-3');

    this.filterModel = filter.filterModel;
  }
}
