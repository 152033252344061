import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import entityModel from './databaseEntityModel';
import store from '@/store';
import ModalsModule from '@/store/modals';
import { CardTypesResponse } from '@/interfaces/cardsResponse.interface';

import { getCardTypes, createCard, editCard, deleteCard, cancelCard } from '@/api/cards';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import moment from 'moment';
import { CardTypeItem } from '@/interfaces/models/cards.interface';

export const MODULE_NAME = 'cardsDatabaseEntity';

export interface CardsDatabaseTextFields {
  cardType: string;
  comment: string;
  count: string;
  employee: string | null;
}

interface CardsDatabaseModel {
  employeeName: { id: string; value: string };
  comment: string;
  cardType: { id: string; value: string; list?: { id: string; value: string }[] };
  cardReason: { id: string | null; value: string; data?: { minCount: number; maxCount: number } };
  cardQuantity: { id: number | null; value: string };
  date: { value: string };
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CardsDatabaseEntity extends EntityBaseModule {
  model: CardsDatabaseModel;
  cardId = 0;
  cardTypes: Record<string, CardTypeItem[]> = {};
  employeeId: number | null = null;
  titlePage: { [index: string]: string } = {
    add: 'Выдача карточки',
    edit: 'Редактирование карточки',
  };
  dictionary: { [index: string]: string } = {
    green: 'Зелёная',
    yellow: 'Жёлтая',
    red: 'Красная',
  };
  editCard: Record<string, string | number | Record<string, string | number>> = {};
  modalParams = {
    deleteWarning: {
      text: 'Вы уверены что хотите удалить карточку?',
      handlers: {
        yes: 'cardsDatabaseEntity/deleteCard',
        no: '',
      },
    },
    cancelWarning: {
      text: 'Вы уверены что хотите отменить карточку?',
      handlers: {
        yes: 'cardsDatabaseEntity/cancelCard',
        no: '',
      },
      buttonsText: {
        yes: 'Да',
        no: 'Отмена',
      },
    },
  };
  isCardUpdated = false;

  constructor(module: CardsDatabaseEntity) {
    super(module);

    const cardsDatabaseModel = new entityModel();
    this.model = cardsDatabaseModel.model as any;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    // @ts-ignore
    this.model[params.key] = params.value;
  }

  @Mutation
  SET_CARD_ID(id: number) {
    this.cardId = id;
  }

  @Mutation
  SET_EMPLOYEE_ID(employeeId: number) {
    this.employeeId = employeeId;
  }

  @Mutation
  SET_DATE(date: string) {
    this.model.date.value = date;
  }

  @Mutation
  SET_EDIT_CARD(cardInfo: Record<string, string | number | Record<string, string | number>>) {
    this.editCard = cardInfo;
  }

  @Mutation
  SET_IS_CARD_UPDATED(bool: boolean) {
    this.isCardUpdated = bool;
  }

  @Mutation
  SET_CARD_TYPE_LIST(cardTypes: CardTypesResponse) {
    const cardTypesArrayReverted = Object.entries(cardTypes).reverse();
    this.cardTypes = Object.fromEntries(cardTypesArrayReverted);
    this.model.cardType.list = Object.keys(cardTypes).map((item: string) => {
      return {
        id: item,
        value: this.dictionary[item],
      };
    });
  }

  @Action({ rawError: true })
  async getCardTypesList() {
    const result = await getCardTypes();

    this.context.commit('SET_CARD_TYPE_LIST', result);
  }

  @Action({ rawError: true })
  async initForm() {
    this.context.commit('SET_DATE', moment().format('DD/MM/YY'));
  }

  @Action({ rawError: true })
  async setEditCardModel(params: Record<string, string | number | Record<string, string | number>>) {
    this.context.commit('SET_EMPLOYEE_ID', params.employeeId);
    this.context.commit('SET_CARD_ID', params.id);
    this.context.commit('SET_DATE', params.day);

    let type = '';
    if (params.count > 0) {
      type = 'green';
    }
    if (params.count < 0) {
      type = 'yellow';
    }
    if (params.count === 0) {
      type = 'red';
    }

    if (params.adminData) {
      this.context.commit('SET_MODEL_VALUE', {
        key: 'cardReason',
        value: { id: params.cardType, value: params.cardType },
      });

      this.context.commit('SET_MODEL_VALUE', {
        key: 'cardQuantity',
        value: { id: params.count, value: params.count },
      });
    } else {
      const count = Math.abs(params.count as number).toString();

      this.context.commit('SET_MODEL_VALUE', {
        key: 'cardReason',
        value: { id: params.cardType, value: params.typeName },
      });
      this.context.commit('SET_MODEL_VALUE', { key: 'cardQuantity', value: { id: count, value: count } });
    }

    this.context.commit('SET_MODEL_VALUE', { key: 'comment', value: params.comment });
    this.context.commit('SET_MODEL_VALUE', {
      key: 'employeeName',
      value: { id: params.employeeId, value: params.fullName },
    });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardType', value: { id: type, value: type } });

    return this.model;
  }

  @Action({ rawError: true })
  async initEditForm() {
    await this.setEditCardModel(this.editCard);
  }

  @Action({ rawError: true })
  updateEditCard(params: Record<string, string | number | Record<string, string | number>>) {
    this.context.commit('SET_EDIT_CARD', params);
  }

  @Action({ rawError: true })
  updateEmployeeId(employeeId: number) {
    this.context.commit('SET_EMPLOYEE_ID', employeeId);
  }

  @Action({ rawError: true })
  updateEmployeeIsSelected(bool: boolean) {
    this.context.commit('SET_EMPLOYEE_IS_SELECTED', bool);
  }

  @Action({ rawError: true })
  setDate(params: { day: number; month: string; year: string }) {
    const date = moment(`${params.day}/${params.month}/${params.year}`, 'DD/MM/YYYY').format('DD/MM/YY');
    this.context.commit('SET_DATE', date);
    this.context.commit('RESET_ERROR_BY_FIELD', 'date');
  }

  @Action({ rawError: true })
  updateIsCardUpdated(bool: boolean) {
    this.context.commit('SET_IS_CARD_UPDATED', bool);
  }

  @Action({ rawError: true })
  prepareFormData(textFieldsData: { comment: string }) {
    const data: {
      employee: string;
      cardType: string;
      count: string;
      day: string;
    } = {
      employee: '',
      cardType: '',
      count: '',
      day: '',
    };

    if (typeof this.employeeId === 'number') {
      data['employee'] = this.employeeId.toString();
    }

    const day = moment(this.model.date.value, 'DD/MM/YY').format('YYYY-MM-DD');
    data['day'] = day;

    return Object.assign(data, textFieldsData);
  }

  @Action({ rawError: true })
  async sendCreate(textFieldsData: CardsDatabaseTextFields) {
    try {
      const data = await this.prepareFormData(textFieldsData);
      const result = (await createCard(data)) as any;

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Карточка создана', type: 'ok' });
        this.updateIsCardUpdated(true);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async sendEdit(textFieldsData: CardsDatabaseTextFields) {
    try {
      const data = await this.prepareFormData(textFieldsData);
      const result = (await editCard(this.cardId, data)) as any;

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Карточка обновлена', type: 'ok' });
        this.updateIsCardUpdated(true);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async deleteCard() {
    try {
      const result = (await deleteCard(this.cardId)) as any;

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Карточка удалена', type: 'ok' });
        this.updateIsCardUpdated(true);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async cancelCard() {
    try {
      const result = (await cancelCard(this.cardId)) as any;

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Карточка отменена', type: 'ok' });
        this.updateIsCardUpdated(true);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  deleteCardWarning() {
    ModalsModule.updateParams(this.modalParams.deleteWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  cancelCardWarning() {
    ModalsModule.updateParams(this.modalParams.cancelWarning);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  reset() {
    this.resetState();
    this.resetErrorState();
  }

  @Action({ rawError: true })
  resetState() {
    this.context.commit('SET_EDIT_CARD', {});
    this.context.commit('SET_CARD_ID', 0);
    this.context.commit('SET_EMPLOYEE_ID', 0);
    this.context.commit('SET_DATE', '');
    this.context.commit('SET_MODEL_VALUE', { key: 'comment', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'employeeName', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardType', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardReason', value: { id: null, value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'cardQuantity', value: { id: null, value: '' } });
  }

  @Action({ rawError: true })
  resetErrorState() {
    this.context.commit('RESET_ERROR_BY_FIELD', 'date');
  }
}

export default getModule(CardsDatabaseEntity);
