import { countPercent } from '@/lib/Utils';
import { StatisticsByClientModel } from '@/store/analytics/byCustomers';
import { RawShopStatisticsDataInterface } from '@/store/shops/statistics';
import { CustomerStatisticsModel } from '@/interfaces/models/customer.interface';

export function prepareStatistics(
  allStatistics: StatisticsByClientModel | CustomerStatisticsModel
): {
  closed: Record<string, string | number>[];
  successClosedCount: number;
  statistics: Record<string, string | number>[];
  statisticsTotalCount: number;
} {
  const sumStatistic =
    allStatistics['success_closed'] +
    allStatistics['closed_with_late'] +
    allStatistics['need_opened'] +
    allStatistics['fake_call'];

  const sumClosed = allStatistics['by_system'] + allStatistics['by_employee'] + allStatistics['by_admin'];

  return {
    statisticsTotalCount: allStatistics['need_count_count'],

    statistics: [
      {
        id: 'allClosed',
        count: allStatistics['success_closed'] + allStatistics['closed_with_late'],
        name: 'общее количество закрытых заказов',
        percent: countPercent(allStatistics['success_closed'] + allStatistics['closed_with_late'], sumStatistic),
        class: 'cp-table-list-row--group-1 cp-table-list-row--first',
      },
      {
        id: 'successClosed',
        count: allStatistics['success_closed'],
        name: 'успешно закрытых',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'closedWithLate',
        count: allStatistics['closed_with_late'],
        name: 'опозданий',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'needOpened',
        count: allStatistics['need_opened_count'],
        name: 'незакрытых',
        percent: countPercent(allStatistics['need_opened_count'], sumStatistic),
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'openedDueToAbsence',
        count: allStatistics['opened_due_to_absence_count'],
        name: 'из них из-за невыходов',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'fakeCall',
        count: allStatistics['fake_call'],
        name: 'ложных вызовов',
        percent: countPercent(allStatistics['fake_call'], sumStatistic),
        class: 'cp-table-list-row--group-1 cp-table-list-row--last',
      },
      {
        id: 'didNotGoOut',
        count: allStatistics['did_not_go_out'],
        name: 'невыходов',
        class: 'cp-table-list-row--group-2 cp-table-list-row--first',
      },
      {
        id: 'fastReplacement',
        count: allStatistics['fast_replacement'],
        name: 'срочных замен',
        class: 'cp-table-list-row--group-2',
      },
    ],

    successClosedCount: allStatistics['success_closed'],

    closed: [
      {
        id: 'bySystem',
        percent: countPercent(allStatistics['by_system'], sumClosed),
        count: allStatistics['by_system'],
        name: 'Назначены системой',
        class: 'cp-table-list-row--group-1 cp-table-list-row--first',
      },
      {
        id: 'bySystemFastReplacement',
        count: allStatistics['by_system_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'byAdmin',
        percent: countPercent(allStatistics['by_admin'], sumClosed),
        count: allStatistics['by_admin'],
        name: 'Назначены менеджером',
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'byAdminFastReplacement',
        count: allStatistics['by_admin_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'byEmployee',
        percent: countPercent(allStatistics['by_employee'], sumClosed),
        count: allStatistics['by_employee'],
        name: 'Назначены исполнителем',
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'byEmployeeFastReplacement',
        count: allStatistics['by_employee_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
    ],
  };
}

export function prepareStatisticsWithOrders(
  allStatistics: RawShopStatisticsDataInterface
): {
  closed: Record<string, string | number>[];
  successClosedCount: number;
  statistics: Record<string, string | number>[];
  statisticsTotalCount: number;
  statisticsTotalOrders: number;
} {
  const sumStatistic =
    allStatistics['success_closed'] +
    allStatistics['closed_with_late'] +
    allStatistics['need_opened'] +
    allStatistics['fake_call'];

  const sumClosed =
    allStatistics['by_system'] + allStatistics['by_employee'] + allStatistics['by_admin'] + allStatistics['by_partner'];

  return {
    statisticsTotalCount: allStatistics['need_count'],
    statisticsTotalOrders: allStatistics['need_count_count'],

    statistics: [
      {
        id: 'allClosed',
        count: allStatistics['success_closed'] + allStatistics['closed_with_late'],
        orders: allStatistics['success_closed_count'] + allStatistics['closed_with_late_count'],
        name: 'общее количество закрытых заказов',
        percent: countPercent(allStatistics['success_closed'] + allStatistics['closed_with_late'], sumStatistic),
        class: 'cp-table-list-row--group-1 cp-table-list-row--first',
      },
      {
        id: 'successClosed',
        count: allStatistics['success_closed'],
        orders: allStatistics['success_closed_count'],
        name: 'успешно закрытых',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'closedWithLate',
        count: allStatistics['closed_with_late'],
        orders: allStatistics['closed_with_late_count'],
        name: 'опозданий',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'needOpened',
        count: allStatistics['need_opened'],
        orders: allStatistics['need_opened_count'],
        name: 'незакрытых',
        percent: countPercent(allStatistics['need_opened'], sumStatistic),
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'openedDueToAbsence',
        count: allStatistics['opened_due_to_absence'],
        orders: allStatistics['opened_due_to_absence_count'],
        name: 'из них из-за невыходов',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'fakeCall',
        count: allStatistics['fake_call'],
        orders: allStatistics['fake_call_count'],
        name: 'ложных вызовов',
        percent: countPercent(allStatistics['fake_call'], sumStatistic),
        class: 'cp-table-list-row--group-1 cp-table-list-row--last',
      },
      {
        id: 'didNotGoOut',
        count: allStatistics['did_not_go_out'],
        orders: allStatistics['did_not_go_out_count'],
        name: 'невыходов',
        class: 'cp-table-list-row--group-1 cp-table-list-row--first',
      },
      {
        id: 'fastReplacement',
        count: allStatistics['fast_replacement'],
        orders: allStatistics['fast_replacement_count'],
        name: 'срочных замен',
        class: 'cp-table-list-row--group-1',
      },
    ],

    successClosedCount:
      allStatistics['by_admin'] + allStatistics['by_employee'] + allStatistics['by_partner'] + allStatistics['by_system'],

    closed: [
      {
        id: 'bySystem',
        percent: countPercent(allStatistics['by_system'], sumClosed),
        count: allStatistics['by_system'],
        name: 'Назначены системой',
        class: 'cp-table-list-row--group-1 cp-table-list-row--first',
      },
      {
        id: 'bySystemFastReplacement',
        count: allStatistics['by_system_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'byAdmin',
        percent: countPercent(allStatistics['by_admin'], sumClosed),
        count: allStatistics['by_admin'],
        name: 'Назначены менеджером',
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'byAdminFastReplacement',
        count: allStatistics['by_admin_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'byEmployee',
        percent: countPercent(allStatistics['by_employee'], sumClosed),
        count: allStatistics['by_employee'],
        name: 'Назначены исполнителем',
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'byEmployeeFastReplacement',
        count: allStatistics['by_employee_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
      {
        id: 'byPartner',
        percent: countPercent(allStatistics['by_partner'], sumClosed),
        count: allStatistics['by_partner'],
        name: 'Назначены партнёром',
        class: 'cp-table-list-row--group-1',
      },
      {
        id: 'byPartnerFastReplacement',
        count: allStatistics['by_partner_fast_replacement'],
        name: 'из них срочных',
        class: 'cp-table-list-row--sub-group-1',
      },
    ],
  };
}
