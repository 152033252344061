import { onMounted, onUpdated } from 'vue';
import Inputmask from 'inputmask';
import { Ref } from '@vue/reactivity/dist/reactivity';

export interface FormInputMasksParams {
  el: Ref<null | HTMLElement>;
  type: string;
  autoMaskOff: boolean;
  mask?: string;
  maskConfig?: Record<string, unknown>;
}

export default function formInputMasks(params: FormInputMasksParams): void {
  const initMask = () => {
    const el = params.el.value;

    if (params.autoMaskOff) {
      return false;
    }

    const maskConfigDefault = {
      placeholder: '',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      numericInput: false,
      regex: '',
    };

    if (params.maskConfig) {
      const mask = new Inputmask(params.maskConfig);

      if (el) {
        mask.mask(params.el.value as HTMLElement);
      }

      return;
    }

    if (params.mask) {
      const mask = new Inputmask(params.mask, maskConfigDefault);

      if (el) {
        mask.mask(params.el.value as HTMLElement);
      }

      return;
    }

    if (params.type === 'phone') {
      const mask = new Inputmask('+7 [(999) 999[-99][-99]]', maskConfigDefault);

      if (el) {
        mask.mask(params.el.value as HTMLElement);
      }

      return;
    }

    if (params.type === 'birthday' || params.type === 'date') {
      const mask = new Inputmask({
        mask: '99.99.9999',
        placeholder: 'дд.мм.гггг',
        showMaskOnHover: false,
      });

      if (el) {
        mask.mask(params.el.value as HTMLElement);
      }

      return;
    }

    if (params.type === 'int') {
      const mask = new Inputmask({
        regex: '^\\d+$',
        placeholder: '',
        showMaskOnHover: false,
      });

      if (el) {
        mask.mask(params.el.value as HTMLElement);
      }

      return;
    }
  };

  onMounted(initMask);
  onUpdated(initMask);
}
