import sendingTime from '@/views/settings/mailings/sendingTime';
import { MailingsModel } from '@/interfaces/models/mailings.interface';

export default class EntityModel {
  model: MailingsModel;

  constructor() {
    this.model = {
      address: '',
      count: 0,
      createdAt: '',
      excludeClients: false,
      excludeCompetences: false,
      excludeRegions: false,
      excludeRoles: false,
      excludeStatuses: false,
      excludeServiceTypes: false,
      finishedAt: '',
      id: 0,
      individualMailing: false,
      pushTitle: '',
      status: '',
      statuses: [],
      updatedAt: '',
      name: '',
      delayedDispatch: false,
      useTimezone: false,
      scheduledDate: sendingTime,
      sendPush: false,
      pushText: '',
      sendSms: false,
      smsText: '',
      sendNotification: false,
      notificationTitle: '',
      notificationText: '',
      coordinates: {
        latitude: 55.754871,
        longitude: 37.624113,
      },
      distance: 1000,
      employees: [],
      roles: [],
      competences: [],
      clients: [],
      regions: [],
      serviceTypes: [],
    };
  }
}
