import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { PaymentsModule } from '@/store/finance/payments';

/**
 * Returns processed payments info for table
 *
 * @param {Object} table payments data
 * @param {Object} state component's state
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface, state: PaymentsModule): Partial<UITable> {
  table.rows.forEach((item) => {
    const idPart = item.id.toString();
    const shiftEmployeePart = item.shiftEmployeeId || '';
    const balancePart = item.balanceId || '';
    const partnerPart = item.partnerUuid || '';

    item.uuid = [idPart, shiftEmployeePart, balancePart, partnerPart].join('_');
  });

  const uiTable = new UITable(table);
  const sorts = Object.values(state.pageSettings.sort) as { sort: string; key: string }[];

  return uiTable
    .removeColumn('hasNotPayout')
    .removeColumn('hasNotPayoutAndSelfEmployed')
    .removeColumn('isAccountUpdated')
    .removeColumn('bic')
    .removeColumn('ks')
    .removeColumn('paymentAccount')
    .removeColumn('bank')
    .removeColumn('inn')
    .removeColumn('isSelfEmployed')
    .removeColumn('isTest')

    .setProperty('positions', 'align', 'right')
    .setProperty('worked', 'align', 'right')
    .setProperty('earned', 'align', 'right')
    .setProperty('earnedTax', 'align', 'right')
    .setProperty('forPayout', 'align', 'right')

    .addColumn({
      id: 'detail',
      name: 'Реквизиты',
      visible: true,
      default: '',
    })
    .addColumn({
      id: 'pay',
      name: 'Добавить выплату',
      visible: true,
      default: '',
    })

    .setSortableValues(sorts)

    .getTable();
}
