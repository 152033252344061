/**
 * Global API for site.
 *
 */

import { User } from '@/interfaces/user.interface';
import { UserNotificationSubscription } from '@/interfaces/user.interface';
import API from '@/config/api-routers';
import { makeGetRequest } from './requests';

/**
 * Get admin menu.
 */
export const getSiteInit = async () => {
  const url = API.user.init;
  const result = await makeGetRequest<{
    result: { notifySubscriptions: UserNotificationSubscription[]; user: User; permissions: string[] };
  }>(url);

  return result.data.result;
};
