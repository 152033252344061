import moment from 'moment';

/**
 * Returns 9999 9999 9999 9999
 *
 * @param cardNumber string for formatting 9999999999999999
 * @returns {string} cardNumber
 */
export function formatCardNumber(cardNumber: string) {
  if (!cardNumber) {
    return '';
  }

  return cardNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
}

/**
 * Returns +9 999 999 99 99
 *
 * @param {string} phoneNumber string for formatting 99999999999
 * @returns {string} phoneNumber
 */

export function formatPhoneWithBrackets(phoneNumber: string) {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1($2)$3-$4-$5');
}

/**
 * Returns +9 999 999 99 99
 *
 * @param {string} phoneNumber string for formatting 99999999999
 * @returns {string} phoneNumber
 */

export function formatPhone(phoneNumber: string) {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5');
}

/**
 * Returns 99999999999
 *
 * @param {string} phoneNumber string for clear  +9 999 999 99 99
 * @returns {string} phoneNumber
 */
export function clearPhone(phoneNumber: string) {
  if (!phoneNumber) {
    return '';
  }

  phoneNumber = phoneNumber.replace(/^(0|\D)+/g, '');
  phoneNumber = phoneNumber.replace(/\D/g, '');

  if (phoneNumber.length === 11) {
    phoneNumber = phoneNumber.replace(/^8/, '7');
  }

  return phoneNumber;
}

export function clearPhoneForInputMaskBeforePaste(pastedValue: string) {
  const result = pastedValue.replace(/\D/g, '');

  if (result.length >= 11 && (result[0] === '7' || result[0] === '8')) {
    return result.substr(1);
  }

  return result;
}

/**
 * Returns HMTL string
 *
 * @param {array} phonesArray array to be formatted to HTML string
 * @returns {string} result
 */
export function phonesArrayToHtml(phonesArray: []) {
  let result = '';
  phonesArray.map((phone) => (result += `<div class="cp-not-break">${phone}</div>`));

  return result;
}

export function prepareMoneyFormat(value: string | number) {
  const valueString = value.toString();

  const isPoint = valueString.indexOf('.') !== -1;

  let formatted = isPoint ? valueString.replace('.', ',') : valueString;

  formatted = formatted.replace(/,\d+/, (match) => match.padEnd(3, '0'));
  formatted = formatted.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  return isPoint ? formatted : `${formatted},00`;
}

export function numberFormat(value: string | number = '0') {
  const tmp = typeof value === 'number' ? value.toString() : value;

  return tmp.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

/*
 * clear money from formatting && round up to ceil
 * */
export function clearMoneyFormat(value: string): number {
  const clearValue = value.replace(/,/g, '.').replace(/\s/g, '');
  const valueToFloat = parseFloat(clearValue);

  return Math.ceil(valueToFloat);
}

/**
 * Returns formatted float with two decimals
 *
 * @param {number} value float number
 * @returns {number} float
 */
export function prepareFloatFormat(value: number) {
  return Number(value.toFixed(2));
}

export function isDateStartOverDateEnd(dateStart: string, dateEnd: string, separator = '/') {
  const dataEndValues = dateEnd.split(separator);
  const dataEndTimestamp = new Date(dataEndValues[1] + '/' + dataEndValues[0] + '/' + dataEndValues[2]).getTime() / 1000;

  const dateStartValues = dateStart.split(separator);
  const dateStartTimestamp =
    new Date(dateStartValues[1] + '/' + dateStartValues[0] + '/' + dateStartValues[2]).getTime() / 1000;

  return dateStartTimestamp >= dataEndTimestamp;
}

export function getShortName(fullName: string) {
  const shortNameArray = fullName.split(' ');
  let shortName = `${shortNameArray[0]}`;
  shortName = shortNameArray[1] ? `${shortName} ${shortNameArray[1].slice(0, 1)}.` : shortName;
  shortName = shortNameArray[2] ? `${shortName} ${shortNameArray[2].slice(0, 1)}.` : shortName;

  return shortName;
}

export function getPlural(number: number, pluralTexts: string[]) {
  const cases = [2, 0, 1, 1, 1, 2];
  let setsText = '';
  if (number % 100 > 4 && number % 100 < 20) {
    setsText = pluralTexts[2];
  } else {
    setsText = pluralTexts[cases[Math.min(number % 10, 5)]];
  }

  return setsText;
}

export function compareDate(date1: string, date2: string, format: string) {
  const d1 = moment(date1, format);
  const d2 = moment(date2, format);

  if (d1.isAfter(d2)) return 1;
  if (d1.isBefore(d2)) return -1;

  return 0;
}

export function destructuringDateString(stringDate: string, separator: '/' | '.' | '-') {
  const { 0: day, 1: month, 2: year } = stringDate.split(separator);

  return { day, month, year };
}

export function setByPath(obj: Record<string, any>, path: string, value: any) {
  const arrayPath = path.split('/');
  let sum = obj;
  const result = arrayPath.every((el: string, index: number, arr: string[]) => {
    if (typeof sum !== 'object' || !(el in sum)) {
      return false;
    }

    sum = sum[el];
    if (index === arr.length - 1) {
      sum = value;
    }

    return true;
  });

  return result;
}

export function getByPath(obj: Record<string, unknown>, path: string) {
  const arrayPath = path.split('/');

  return arrayPath.reduce((sum: any, el: string) => {
    return sum[el];
  }, obj);
}

export function countPercent(el: number, sum: number) {
  if (!sum) {
    return 0;
  }

  return Math.round((el / sum) * 100);
}

export function isAfter(start: string, end: string, format?: string) {
  const startDate = moment(start, format);
  const endDate = moment(end, format);

  return startDate.isAfter(endDate);
}

export function isBefore(start: string, end: string, format?: string) {
  const startDate = moment(start, format);
  const endDate = moment(end, format);

  return startDate.isBefore(endDate);
}

export function isBetween(params: { date?: any; start: any; end: any }, format?: string, strict?: boolean) {
  const dateMoment = moment(params.date, format);
  const startDateMoment = moment(params.start, format);
  const endDateMoment = moment(params.end, format);

  return dateMoment.isBetween(startDateMoment, endDateMoment, 'minutes', strict ? '()' : '[]');
}

export function dateFormat(value: string, format: { from?: string; to: string }) {
  return moment(value, format.from).format(format.to);
}

export function dateFullFormat(value: string) {
  const separator = value.split('T');
  const date = separator[0].split('-');
  const time = separator[1].split(':');

  return `${date[2]}/${date[1]}/${date[0]} ${time[0]}:${time[1]}`;
}

export function dateToMask(date: string) {
  if (!date) {
    return '';
  }

  return moment(date).format('DD.MM.YYYY');
}

export function dateRemoveMask(date: string) {
  if (!date) {
    return '';
  }

  let newDate: any = date.split('.');

  newDate = newDate[1] + '/' + newDate[0] + '/' + newDate[2];

  return moment(new Date(newDate)).format('YYYY-MM-DD');
}

export function dateRemoveMaskBirthday(date: string) {
  if (!date) {
    return '';
  }

  let newDate: any = date.split('.');

  newDate = newDate[1] + '/' + newDate[0] + '/' + newDate[2];

  return moment(new Date(newDate)).format('YYYY-MM-DDT00:00:00');
}

export function shortFullName(fullName: string) {
  const [lastName, firstName, middleName] = fullName.split(' ');

  return lastName + (firstName ? ` ${firstName[0]}.` : '') + (firstName && middleName ? ` ${middleName[0]}.` : '');
}

export function getShortFullName(item: any) {
  const { lastName, firstName, middleName } = item;

  return lastName + (firstName ? ` ${firstName[0]}.` : '') + (firstName && middleName ? ` ${middleName[0]}.` : '');
}

export function getFullName(item: any) {
  const { lastName, firstName, middleName } = item;

  return (lastName ? lastName : '') + (firstName ? ` ${firstName}` : '') + (firstName && middleName ? ` ${middleName}` : '');
}

export function downloadFile(params: { file: string; fileName: any }) {
  const downloadUrl = window.URL.createObjectURL(new Blob([params.file]));

  const link = document.createElement('a');

  link.href = downloadUrl;
  link.setAttribute('download', params.fileName);

  document.body.appendChild(link);

  link.click();
  link.remove();

  window.close();
}

export function timeToMinutes(time: string | undefined): number {
  if (!time) return 0;
  const [hour, minute] = time.split(':');

  return +hour * 60 + +minute;
}

export function minutesToTime(minutes: number): string {
  const hour = Math.floor(minutes / 60);
  const minute = minutes - hour * 60;

  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
}

export function ceilMinutesToHoursTime(minutes: number): string {
  const hour = Math.ceil(minutes / 60);

  return `${hour.toString().padStart(2, '0')}:00`;
}

export function countSmsMessages(smsText: string): number {
  const maxBytesForSingleSms = 140;
  const maxBytesForMultipleSms = 134;
  const byteCount = new Blob([smsText || '']).size;

  let messageCount = 1;

  if (byteCount > maxBytesForSingleSms) {
    messageCount = Math.ceil(byteCount / maxBytesForMultipleSms);
  }

  return messageCount;
}

export function isObject(value: unknown) {
  return value && typeof value === 'object';
}

export function flattenObject(value: unknown, keyPath = [] as string[]): Record<string, unknown> {
  if (isObject(value)) {
    return Object.entries(value as Record<string, unknown>).reduce((acc, [key, currentValue]) => {
      const flatValue = flattenObject(currentValue, [...keyPath, key]);

      return Object.assign(acc, flatValue);
    }, {});
  }

  const [firstKey, ...restKeys] = keyPath;
  const processedPath = [firstKey, ...restKeys.map((key: string) => `[${key}]`)].join('');

  return { [processedPath]: value };
}

/**
 * Конвертирует объект с вложенной структурой в строковое представление query-параметров
 * { filters: { 8: { id: 'status', value: ['is_planned', 'reserve'] } } }
 * filters[8][id]=status&filters[8][value][0]=is_planned&filters[8][value][1]=reserve
 */
export function convertObjectToQueryParams(queryParams: Record<string, unknown>): string {
  const flatObject = flattenObject(queryParams);

  return Object.entries(flatObject)
    .map((keyValueEntry: [string, unknown]) => keyValueEntry.join('='))
    .join('&');
}

export function escapeRegExpSpecialChars(input: string): string {
  return input.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');
}

export function saveBlob(blob: Blob, filename: string): void {
  const linkElement = document.createElement('a');
  const objectUrl = window.URL.createObjectURL(blob);

  linkElement.href = objectUrl;
  linkElement.download = filename;
  linkElement.dispatchEvent(new MouseEvent('click'));

  window.URL.revokeObjectURL(objectUrl);
}

export function changeQueryParamsSilently(queryParams: Record<string, string | number | undefined>): void {
  const path = window.location.pathname;
  const newParams = Object.entries(queryParams)
    .map((entry) => entry.join('='))
    .join('&');

  const newPath = [path, newParams].filter(Boolean).join('?');
  history.pushState({}, '', newPath);
}

export function getRandomIntInRange(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

export function generateUUIDv4(): string {
  const uuidMask = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  const variant = getRandomIntInRange(8, 12).toString(16);
  const getRandomHexadecimal = () => getRandomIntInRange(0, 16).toString(16);

  return uuidMask.replace('y', variant).replaceAll('x', getRandomHexadecimal);
}

export type DeepFreeze<T> = T extends Record<string, unknown> ? { readonly [K in keyof T]: DeepFreeze<T[K]> } : T;

export function deepFreeze<T extends Record<string, any>>(object: T): DeepFreeze<T> {
  const clonedObject = structuredClone(object);

  for (const key in clonedObject) {
    const value = clonedObject[key];
    const hasOwnProperty = Object.prototype.hasOwnProperty.call(clonedObject, key);
    const isObjectValue = value !== null && typeof value === 'object';

    if (hasOwnProperty && isObjectValue) {
      clonedObject[key] = deepFreeze(value) as T[Extract<keyof T, string>];
    }
  }

  return Object.freeze(clonedObject) as DeepFreeze<T>;
}
