import { ref } from 'vue';
import { Ref } from '@vue/reactivity';

export interface FormInputUIParams {
  isLabelActive: Ref<boolean>;
  addClassIn: () => void;
  removeClassIn: () => void;
}

export default function formInputUI(): FormInputUIParams {
  const isLabelActive = ref(false);

  const addClassIn = () => {
    isLabelActive.value = true;
  };

  const removeClassIn = () => {
    isLabelActive.value = false;
  };

  return {
    isLabelActive,

    addClassIn,
    removeClassIn,
  };
}
