/**
 * Customers helper entity store.
 *
 */

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import CustomersHelpersModule from './helpers';
import { FormData } from '@/interfaces/shared';

import { getClientGeoHelperEntityById, addNewGeoHelper, saveGeoHelper } from '@/api/geo';
import { strings } from '@/lib/stringConst';
import { CustomerHelperEntityModel } from '@/interfaces/models/customer.interface';

export const MODULE_NAME = 'customersHelperEntity';

export interface ResponseParams {
  [key: string]: string | undefined;
  client: string;
  helperId?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  email?: string;
  party?: string;
  comment?: string;
}

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CustomersHelperEntityModule extends EntityBaseModule {
  model: CustomerHelperEntityModel;
  text = {
    btnAddNew: 'Добавить  ',
    btnSave: 'Сохранить',
  };

  constructor(module: CustomersHelperEntityModule) {
    super(module);

    this.model = {
      firstName: '',
      lastName: '',
      middleName: '',
      phone: '',
      email: '',
      comment: '',
      party: '',
    };
  }

  @Mutation
  SET_MODEL_KEY(params: { key: string; value: string }) {
    this.model[params.key] = params.value;
  }

  @Action({ rawError: true })
  async initEdit(helperId: string) {
    await this.context.dispatch('getById', helperId);
  }

  @Action({ rawError: true })
  async getById(helperId: string) {
    try {
      const result = await getClientGeoHelperEntityById(helperId);

      await this.context.dispatch('setInfo', result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfo(info: CustomerHelperEntityModel) {
    CustomersHelpersModule.updateTitleEdit(info.firstName);

    this.context.commit('SET_MODEL_KEY', { key: 'firstName', value: info.firstName });
    this.context.commit('SET_MODEL_KEY', { key: 'lastName', value: info.lastName });
    this.context.commit('SET_MODEL_KEY', { key: 'middleName', value: info.middleName });
    this.context.commit('SET_MODEL_KEY', { key: 'phone', value: info.phone });
    this.context.commit('SET_MODEL_KEY', { key: 'email', value: info.email });
    this.context.commit('SET_MODEL_KEY', { key: 'comment', value: info.comment });
    this.context.commit('SET_MODEL_KEY', { key: 'party', value: info.party });
  }

  @Action({ rawError: true })
  async addNew(data: ResponseParams) {
    try {
      const result = await addNewGeoHelper(data as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Помощник создан', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(data: ResponseParams) {
    try {
      const result = await saveGeoHelper(data.helperId as string, data as FormData);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  resetModel() {
    CustomersHelpersModule.updateTitleEdit('');
    this.context.commit('SET_MODEL_KEY', { key: 'firstName', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'lastName', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'middleName', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'phone', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'email', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'comment', value: '' });
    this.context.commit('SET_MODEL_KEY', { key: 'party', value: '' });
  }
}
export default getModule(CustomersHelperEntityModule);
