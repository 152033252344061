import moment from 'moment';

export default {
  key: 'time',
  type: 'time-range',
  title: 'Дата, время отправки',
  placeholder: '',
  time: '00:00',
  date: moment(new Date().setDate(new Date().getDate() + 1)).format('DD/MM/YYYY'),
  hour: '00',
  minute: '00',
  action: '/',
  required: true,
  start: 0,
  end: 24,
  step: 1,
};
